import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ApisLogsService } from './apis-logs.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, CustomTableBackendData, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

interface Filter {
	year: number,
	month: number,
	end_point: number,
	user_id: number,
	fromTimeLocal: string,
	toTimeLocal: string,
	success: boolean
}

@Component({
	selector: 'app-apis-logs',
	templateUrl: './apis-logs.component.html',
	styleUrls: ['./apis-logs.component.css']
})
export class ApisLogsComponent implements OnInit {
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	logs: any[] = [];
	filter: Filter = {
		year: 2023,
		month: 1,
		fromTimeLocal: '',
		toTimeLocal: '',
		end_point: null,
		user_id: null,
		success: null
	};

	usersList: {id: number, name: string}[] = [];
	endPointList: {id: string, name: string}[] = [
		{id: '/sctportal/device/check-existing-devices', name: 'check-existing-devices'},
		{id: '/sctportal/device/get-devices-energy-values', name: 'get-devices-energy-values'},
		{id: '/sctportal/device/get-site-devices-energy-values', name: 'get-site-devices-energy-values'},
		{id: '/sctportal/device/get-devices-info', name: 'get-devices-info'},
		{id: '/sctportal/device/get-site-devices-info', name: 'get-site-devices-info'},
	];
	apiStatusList: {id: boolean, name: string}[] = [
		{id: true, name: this.translateService.instant('g.success')},
		{id: false, name: this.translateService.instant('g.failed')},
	];
	yearsList: {id: number, name: string}[] = [{id: 2023, name: "2023"}];
	monthsList: {id: number, name: string}[] = [{id: 0, name: "Jan"}];

	maxTime: string = '';
	minTime: string = '';

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		pageSizeOptions: [20, 50, 100, 200],
		isBackendPagination: true,
		fitScreen: true,
		hideNoData: true
	};

	constructor(
		public userService: UsersService,
		private router: Router,
		private apisLogsService: ApisLogsService,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService,
		private commonDataService: CommonDataService
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('apis_logs'))
			return this.router.navigate(['/unauthorized']);

		this.columnConfig = [
			{ key: 'id', name: this.translateService.instant('g.id'), type: 'number', hasFilter: false, hasSort: true},
			{ key: 'created_at', name: this.translateService.instant('users.create_date'), type: "date", hasFilter: false},
			{ key: 'user', name: this.translateService.instant('login_register.user_name'), type: "string", filterType: 'dropdown', items: [], hasFilter: false},
			{ key: 'serial_number', name: this.translateService.instant('g.serial_number'), type: "list", items: [], hasFilter: false, hasSort: false},
			{ key: 'end_point', name: this.translateService.instant('apis_logs.endpoint'), type: "string", hasFilter: false},
			{ key: 'api_version', name: this.translateService.instant('apis_logs.api_version'), type: "number", hasFilter: false, hasSort: false},
			{ key: 'success', name: this.translateService.instant('g.success'), type: "boolean", hasFilter: false},
			{ key: 'action', name: this.translateService.instant('g.view'), type: "action_link", hasFilter: false, hasSort: false},
		];

		this.sideMenuService.hide();
		this.updateTimeOptions();
		return this.selectYear();;
	}

	getLogs(paginationData) {
		if(!this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const tableData = [];

		const options = {
			currentPage: paginationData.currentPage,
			filters: {
				created_at: {
					from: this.filter.fromTimeLocal,
					to: this.filter.toTimeLocal,
				},
				user_id: this.filter.user_id,
				success: this.filter.success,
				end_point: this.filter.end_point
			},
			sort: paginationData.sort,
			limit,
			getCount: this.filter.user_id ? false : true
		};

		if(options.sort?.field == 'user')
			options.sort.field = 'user_id';

		this.apisLogsService.getLogs(options).subscribe((logs: CustomTableBackendData) => {
			logs.data.forEach((log: any) => {
				tableData.push({
					id: {value: log.id},
					created_at: { value: moment(log.created_at*1000).utc().format('MM/DD/YYYY HH:mm:ss') },
					user: { value: log.user },
					serial_number: {value: this.getSerials(log)},
					user_id: { value: log.user_id },
					api_version: { value: log.request?.api_version },
					end_point: { value: log.end_point },
					success: { value: log.success },
					action: {value: this.translateService.instant('g.view'), action: (log)=>{this.viewLog(log)}},
					request: log.request,
					response: log.response,
				});
			});
			this.tableData = tableData;
				this.sctCustomTable.updatePagination(logs.totalDataCount, logs.overallItemsCount);

			if(logs.items.usersList.length)
				this.usersList = logs.items.usersList;
		});
	}

	getSerials(log) {
		if (log.response?.serial_number)
			return [log.response.serial_number];

		if (log.response?.devices)
			return Object.keys(log.response.devices);

		if (log.request?.serial_numbers)
			return typeof(log.request?.serial_numbers) == 'string' ? [log.request.serial_numbers] : log.request.serial_numbers;

		return [];
	}

	viewLog(log: any) {
		if (log.response?.err)
			log.response.error = this.apisLogsService.getLogError(log.response.err);

		const data = { request: log.request, response: log.response };

		const document = window.open('', '')?.document;
		if (!document)
			return;

		document.open();
		document.write([
			'<!DOCTYPE html>', '<html>', '<head>', '</head>', '<body>', '<pre>',
			JSON.stringify(data, null, 4),
			'</pre>', '</body>'
		].join(""));

		document.title = `Log ${log.id.value}`;
		document.close();
	}

	updateTimeOptions() {
		this.updateYearList();
		this.updateMonthList(true);
	}

	updateYearList() {
		const startYear = 2023;
		const currentYear = new Date().getFullYear();
		this.filter.year = currentYear;
		const yearsList = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
		this.yearsList = yearsList.reverse().map(year => ({id: year, name: String(year)}));
	}

	updateMonthList(currentYear = false) {
		const monthNames = this.commonDataService.shortMonths;
		let currentMonth = new Date().getMonth(); // 0 = January, 11 = December
		if (!currentYear)
			currentMonth = 11;

		let monthsList: number[] = Array.from({ length: currentMonth + 1 }, (_, i) => i);

		// No data before 11/2023
		if (this.filter.year == 2023)
			monthsList = [10, 11];

		if (this.filter.year < 2023)
			monthsList = [];

		this.monthsList = monthsList.map(month => ({id: month, name: monthNames[month]}));
		this.filter.month = monthsList[0];
	}

	selectYear() {
		const isCurrentYear = new Date().getFullYear() == this.filter.year;
		this.updateMonthList(isCurrentYear);
		this.setTimeRange();

		this.filter.fromTimeLocal = this.minTime;
		this.filter.toTimeLocal = this.maxTime;
	}

	selectMonth() {
		this.setTimeRange();

		this.filter.fromTimeLocal = this.minTime;
		this.filter.toTimeLocal = this.maxTime;
	}

	setTimeRange() {
		const zoneDiff = new Date().getTimezoneOffset() * -1;

		const maxTimeDate = new Date(this.filter.year, this.filter.month + 1, 0, 23, 59, 59);
		maxTimeDate.setMinutes(maxTimeDate.getMinutes() + zoneDiff);

		const minTimeDate = new Date(this.filter.year, this.filter.month, 1, 0, 0, 0);
		minTimeDate.setMinutes(minTimeDate.getMinutes() + zoneDiff);

		this.maxTime = maxTimeDate.toISOString().slice(0, 16);
		this.minTime = minTimeDate.toISOString().slice(0, 16);
	}

	formatTime(field, value: string) {
		this.filter[field] = moment(value).format('YYYY/MM/DD HH:mm:ss');
	}

	applyFilter() {
		this.getLogs({currentPage: 1});
	}
}

