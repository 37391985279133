import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})

export class OrdersService {

	readonly PRICES: any = {
		0: 'FULL_PRICE',
		1: 'DISCOUNT_PRICE',
		2: 'FREE_PRICE',
	};

	readonly SALES_CHANNEL: any = {
		0: 'ADVANCED_CHARGING_TECHNOLOGIES',
		1: 'DIRECT_SALES',
	};

	readonly SUBSCRIPTION_TERMS: any = {
		6: 'SIX_MONTHS',
		12: 'TWELVE_MONTHS',
		18: 'EIGHTEEN_MONTHS',
		24: 'TWENTY_FOUR_MONTHS',
		36: 'THIRTY_SIX_MONTHS',
		60: 'SIXTY_MONTHS',
		0: 'UNLIMITED',
	};

	readonly STATUSES: any = {
		0: 'NEW',
		1: 'IN_PROCESS',
		2: 'READY_TO_SHIP',
		3: 'SHIPPED',
	};

	readonly ORDERS_FILTER_BY_DEVICE_TYPE: any = {
		'ChargLink': 0,
		'IoTAh': 1,
		'IoTAh_PS':2
	};

	readonly SEARCH_CRITERIA: any = {
		DATE: 'created_at',
		PO_NUMBER: 'po_number',
		RECEIVER_EMAIL: 'receiver_email',
		RECEIVER_NAME: 'receiver_name',
		RECEIVER_COMPANY_EMAIL: 'receiver_company_email',
		RECEIVER_COMPANY_NAME: 'receiver_company_name',
	};

	readonly route: string = '/orders';

	readonly STATUSES_OPTIONS = [
		{ label: this.translateService.instant('orders.new'), value: 0 },
		{ label: this.translateService.instant('orders.in_process'), value: 1 },
		{ label: this.translateService.instant('orders.ready_to_ship'), value: 2 },
		{ label: this.translateService.instant('orders.shipped'), value: 3 },
	];

	readonly SALES_CHANNEL_OPTIONS = [
		{ label: this.translateService.instant('orders.advanced_charging_technologies'), value: 0 },
		{ label: this.translateService.instant('orders.direct_sales'), value: 1 },
	];

	tabs = {
		unshipped: 'unshipped',
		shipped: 'shipped'
	};

	activeTab: string = this.tabs.unshipped;
	shouldBackToSelectedTab = false;

	constructor(
		private httpClient: HttpClient,
		private translateService: TranslateService,
	) { }

	setActiveTab (tab: string){
		this.activeTab = tab;
	}

	getActiveTab() {
		if (this.shouldBackToSelectedTab)
			return this.activeTab;

		return this.tabs.unshipped;
	}

	getPricesOptions() {
		return Object.keys(this.PRICES).map((price: any) => {
			return { label: this.translateService.instant(`orders.${this.PRICES[price].toLocaleLowerCase()}`), value: +price }
		});
	};

	getSalesChannelOptions() {
		return Object.keys(this.SALES_CHANNEL).map(channel => {
			return { label: this.translateService.instant(`orders.${this.SALES_CHANNEL[channel].toLocaleLowerCase()}`), value: +channel }
		});
	};

	getSubscriptionTermsOptions() {
		return Object.keys(this.SUBSCRIPTION_TERMS).map(term => {
			return { label: this.translateService.instant(`orders.${this.SUBSCRIPTION_TERMS[term].toLocaleLowerCase()}`), value: +term }
		});
	};

	getOrdersStatusesOptions() {
		return Object.keys(this.STATUSES).map(status => {
			return { label: this.translateService.instant(`orders.${this.STATUSES[status].toLocaleLowerCase()}`), value: +status }
		});
	};

	getStatusesByKey(): any {
		const mapped: any = {};
		Object.keys(this.STATUSES).map(status => { mapped[this.STATUSES[status]] = status });
		return mapped;
	};

	getOrders(options: { lastId?: any, isBack?: any, criteria?: any }) {
		return this.httpClient.get(`${this.route}/getOrders`, {
			params: options
		});
	}

	getOrder(id: number) {
		return this.httpClient.get(`${this.route}/getOrder/${id}`);
	}

	saveOrder(order: any) {
		return this.httpClient.post(`${this.route}/save`, { order });
	}

	getOrderPoNumbers() {
		return this.httpClient.get(`${this.route}/getOrderPoNumbers`);
	}

	addTrackingNumber(orderId: number, trackingNumber: string, shippingCompany: string, zoneDiff: number, shippingDate: Date) {
		return this.httpClient.post(`${this.route}/addTrackingNumber`, { orderId, trackingNumber, shippingCompany, zoneDiff, shippingDate });
	}

	sentAttachmentEmail(orderId: number, poNumber: string, files: string[], pageSource: 'shipping' | 'order', fromDelete: boolean) {
		return this.httpClient.post(`${this.route}/sentAttachmentEmail`, { orderId, poNumber, files, pageSource, fromDelete });
	}

	deleteOrder(orderId: number) {
		return this.httpClient.delete(`${this.route}/deleteOrder`, {
			observe: "body",
			params: {
				orderId
			}
		});
	}
}
