import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { StudiesService } from '../studies.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as lo from 'lodash-es';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ColumnsConfig, TableConfig } from 'src/app/shared/custom-table/custom-table-interface';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-studies-list',
	templateUrl: './studies-list.component.html',
	styleUrls: ['./studies-list.component.css']
})
export class StudiesListComponent implements OnInit, AfterViewInit {

	@Input() parent: 'device'|'admin'|'customer'|'site' = 'admin';
	@Input() customerId = 0;
	@Input() customerName = "";
	@Input() siteId = 0;
	@Input() device;

	studies: any = [];
	filteredStudies: any = [];
	styles: string = '';

	statusFilterList = [
		{ id: 0, value: this.translateService.instant('studies.status_any') },
		{ id: 1, value: this.translateService.instant('studies.status_not_done') },
		{ id: 2, value: this.translateService.instant('studies.status_done') }
	];

	showNewStudyForm = false;
	canDeleteStudy = false;
	listingColumns: any = [];

	invalidDateRange: boolean = false;
	dateRange: any;
	originalFromDate: number = 0;
	originalToDate: number = 0;
	status: number = this.statusFilterList[0].id;
	recordsPerPage: number = 100;
	selectedStudies: any = [];
	currentUser: any = {};

	requestStatus = {
		0: 'queued',
		1: 'accepted',
		queued: 0,
		accepted: 1
	};

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		fileName: this.parent == 'customer' ? this.customerName+' '+this.translateService.instant('studies.power_studies'): this.translateService.instant('studies.power_studies_list'),
		hasSelectionColumn: true,
		draggable: true,
	};

	@ViewChild("confirmDeleteStudy") confirmDeleteStudy: ModalComponent;

	constructor(
		private studiesService: StudiesService,
		private translateService: TranslateService,
		private sctToastService: NotificationMessageService,
		public usersService: UsersService,
		private router: Router,
		private commonService: CommonService,
		public commonDataService: CommonDataService,
	) {}

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();

		if(!this.currentUser.has_access_to_any_study || this.commonService.isChargeLink())
			return this.router.navigate(['/unauthorized']);

		if(window.location.hostname.includes(this.commonDataService.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.listingColumns = this.getListingColumns();
		this.prepareStyle();
		this.getData();
		this.canDeleteStudy = this.usersService.hasAccessPermission(null, 'delete_power_studies', 'write');
	}

	getListingColumns() {
		const columns: ColumnsConfig[] = [
			{ key: 'id', name: this.translateService.instant('g.id'), type: "id", hidden: true },
			{ key: 'name', name: this.translateService.instant('studies.name'), type: 'link' },
			{ key: 'is_done_fmt', name: this.translateService.instant('studies.is_done'), type: "string" },
		];

		if (['admin', 'device'].includes(this.parent))
			columns.push({ key: 'customer_name', name: this.translateService.instant('studies.customer_name'), type: "link" });

		if (['admin', 'customer', 'device'].includes(this.parent))
			columns.push({ key: 'site_name', name: this.translateService.instant('studies.site_name'), type: "link" });

		if (['customer', 'admin', 'site'].includes(this.parent))
			columns.push({ key: 'serial_number', name: this.translateService.instant('studies.serial_number'), type: "link" });

		if (this.usersService.hasAccessPermission(null, 'noc')) {
			columns.push(
				{ key: 'start_event_id', name: this.translateService.instant('studies.start_event_id'), type: "number", noValueString: "-" },
				{ key: 'end_event_id', name: this.translateService.instant('studies.end_event_id'), type: "number", noValueString: "-" },
			);
		}

		columns.push(
			{ key: 'start_study_time_fmt', name: this.translateService.instant('studies.start_study_time'), type: "date" },
			{ key: 'end_study_time_fmt', name: this.translateService.instant('studies.end_study_time'), type: "date" },
			{ key: 'total_kwhs', name: this.translateService.instant('studies.total_kwhs'), type: "number", noValueString: "-" },
			{ key: 'total_ahs', name: this.translateService.instant('studies.total_ahs'), type: "number", noValueString: "-" },
		);

		return columns;
	}

	getData() {
		const params: {parent: 'admin'|'customer'|'site'|'device', customer_id?: number, site_id?: number, from?: number, to?: number, status?: number, mac_address?: string} = { parent: this.parent };

		if (this.customerId)
			params.customer_id = this.customerId;

		if (this.siteId)
			params.site_id = this.siteId;

		if (this.device)
			params.mac_address = this.device.mac_address;

		if(this.dateRange) {
			params.from = moment(this.dateRange.fromDate).utc().unix();
			params.to = moment(this.dateRange.toDate).utc().unix();
		}

		if (this.status)
			params.status = this.status-1;

		this.studiesService.getStudies(params).subscribe((res: any) => {
			this.studies = res;
			this.originalFromDate = moment(this.dateRange.fromDate).utc().unix();
			this.originalToDate = moment(this.dateRange.toDate).utc().unix();
			this.filterResults();
		});
	}

	filterResults() {
		const params: {from?: number, to?: number} = { };
		if(this.dateRange) {
			params.from = moment(this.dateRange.fromDate).utc().unix();
			params.to = moment(this.dateRange.toDate).utc().unix();
			if(params.from < this.originalFromDate || params.to > this.originalToDate) {
				this.getData();
				return;
			}
		}

		// filter this.studies based on the params
		const filteredStudies = this.studies.filter((study: any) => {
			// Apply filters here
			if (params.from && study.start_study_time < params.from) return false; // Filter by start date
			if (params.to && study.end_study_time > params.to) return false; // Filter by end date

			return true; // Return true if the study should be included in the filtered array
		});

		const tableData: any = [];
		for (const study of filteredStudies) {
			tableData.push({
				study,
				id: { value: study.id },
				name: { value: study.name, link: ['/', study.customer_id, study.site_id, study.mac_address, 'performance', study.id] },
				is_done_fmt: { value: this.translateService.instant('studies.'+(study.request_status == this.requestStatus.queued ? 'queued' : study.is_done ? 'status_done' : 'status_not_done')) },
				customer_name: { value: study.customer_name, link: ['/', study.customer_id] },
				site_name: { value: study.site_name, link: ['/', study.customer_id, study.site_id] },
				serial_number: { value: study.serial_number, link: study.has_site_device_acces? ['/', study.device_customer_id, study.device_site_id, study.mac_address, 'performance']: null},
				start_event_id: { value: +study.start_event_id || null },
				end_event_id: { value: +study.end_event_id || null },
				start_study_time_fmt: { value: study.start_study_time ? moment(study.start_study_time * 1000).utc().format('YYYY-MM-DD') : '' },
				end_study_time_fmt: { value: study.end_study_time ? moment(study.end_study_time * 1000).utc().format('YYYY-MM-DD') : '' },
				total_kwhs: { value: +study.total_kwhs || null },
				total_ahs: { value: +study.total_ahs || null },
			})
		}

		this.filteredStudies = tableData;
	}

	ngAfterViewInit(): void {
		this.confirmDeleteStudy.onClose.subscribe((ok) => {
			if (ok) {
				this.deletePowerStudies(true);
			} else {
				this.confirmDeleteStudy.hide();
			}
		});
	}

	onDatesChanged(event: any) {
		this.dateRange = event.dates;
		this.invalidDateRange = event.invalidRange;
		if (!this.invalidDateRange)
			this.filterResults();
	}

	prepareStyle() {
		switch(this.parent) {
			case 'admin':
				this.styles = 'white-box m-2 p-4 border-radius-bottom';
				break;
			case 'site':
				this.styles = 'white-box p-4';
				break;
			default:
				break;
		}
	}

	deletePowerStudies(confirmed=false) {
		if (!confirmed) {
			const hasSelectedWrongStudy = this.selectedStudies.filter(study => !study.is_done && study.request_status != 0).length > 0;
			if (hasSelectedWrongStudy)
				return this.sctToastService.setMessage('translate|studies.selected_wrong_study', {clearOnXTimeNavigate: 1, type: 'warning'});

			return this.confirmDeleteStudy.show();
		}

		const selectedStudiesIds = this.selectedStudies.map(study => +study.id);
		this.studiesService.deletePowerStudies(selectedStudiesIds).subscribe((res: any) => {
			switch(res) {
				case 2:
					this.sctToastService.setMessage('translate|studies.selected_wrong_study', {clearOnXTimeNavigate: 1, type: 'warning'});
					break;
				default:
					this.studies = lo.filter(this.studies, study => !selectedStudiesIds.includes(study.id));
					this.getData();
					this.selectedStudies = [];
					this.sctToastService.setMessage('translate|g.deleted_successfully', {clearOnXTimeNavigate: 1, type: 'success'});
					break;
			}
		});
	}

	anyStudySelected(): boolean {
		return this.selectedStudies.length > 0;
	}

	onRowSelected(events: any) {
		this.selectedStudies = events.map(item => item.study)?? [];
	}

	onNewStudyFormClose(id: number) {
		this.showNewStudyForm = false;
		if(id > 0)
			this.getData();
	}
}
