import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { UsersService } from './users.service';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { EnterpriseService } from '../enterprise/enterprise.service';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '../shared/services/common.service';
import { ColumnsConfig, TableConfig, TableData } from '../shared/custom-table/custom-table-interface';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit, AfterViewInit {
	@ViewChild("deleteUserModal") deleteUserModal;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 20,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'first_name', name: this.translateService.instant('login_register.first_name'), type: "string"},
		{ key: 'last_name', name: this.translateService.instant('login_register.last_name'), type: 'string'},
		{ key: 'email_address', name: this.translateService.instant('users.email_address'), type: "string"},
		{ key: 'enterprise_id', name: this.translateService.instant('g.enterprise_id'), type: 'string'},
		{ key: 'create_date', name: this.translateService.instant('users.create_date'), type: "timestamp", dateFormat: "MM/DD/yyyy"},
		{ key: 'last_access_time', name: this.translateService.instant('users.last_access_time'), type: "date_with_ref" },
		{ key: 'last_access_through', name: this.translateService.instant('users.last_access_through'), type: "string"},
		{ key: 'status', name: this.translateService.instant('g.status'), type: "boolean", filterTrueText: this.translateService.instant('g.deactivated'), filterFalseText: this.translateService.instant('g.activated') },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	currentPage: number = 1;

	email_subscription_list = [
		{name: this.translateService.instant('connectivity_verification.connectivity_verification'), id: 'connectivity_verification'},
		{name: this.translateService.instant('orders.purchase_order_notifications'), id: 'purchase_order_notifications'},
		{name: this.translateService.instant('g.engineering'), id: 'engineering'},
		{name: this.translateService.instant('device_management.device_management_notifications'), id: 'device_management_notifications'},
		{name: this.translateService.instant('action_log.user_registration_request'), id: 'user_registration_request'},
	];

	users: any[] = [];
	usersToShow: any[] = [];
	enterprises: any = {};
	currentUser: any = {};
	criteria: any = {};
	noResult: boolean = false;
	enterpriseList: any[] = [];
	pageNum: number = 0;
	options: any = {};

	constructor(
		public usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private router: Router,
		private sideMenuService: SideMenuService,
		private enterpriseService: EnterpriseService,
		public commonService: CommonService,
		private datePipe: DatePipe,
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();

		if(!this.usersService.hasAccessFunction('search_users'))
			return this.router.navigate(['/unauthorized']);

		this.enterpriseService.getEnterprises({source: 'search_users'}).subscribe((data: any) => {
			this.enterpriseList = this.commonService.sortDataAlphabetically(data, 'name');
		});

		this.getUsersList();
	}

	ngAfterViewInit() {
		this.deleteUserModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.deleteUser(result[1]);
		});
	}

	deleteUser(id) {
		this.usersService.deleteUser(id).subscribe(data => {
			if(data == 1){
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getUsersList();
			}
		});
	}

	isEmptyCriteria() {
		if (this.criteria?.selected_subscriptions?.length == 0) {
			delete this.criteria.selected_subscriptions;
			delete this.criteria.email_subscription;
		}

		if(!this.criteria || Object.keys(this.criteria).length == 0)
			return true;

		for(let field in this.criteria) {
			if(this.criteria[field] !== null && this.criteria[field] !== undefined && this.criteria[field] !== "")
				return false;
		}

		return true;
	}

	getUsersList() {
		if(this.isEmptyCriteria())
			return;

		const email_subscriptionObject = {};

		if (this.criteria.selected_subscriptions?.length) {
			this.criteria.selected_subscriptions.forEach((subscription: string) => email_subscriptionObject[subscription] = true);
			this.criteria.email_subscription = email_subscriptionObject;
		} else {
			this.criteria.email_subscription = null;
			this.criteria.selected_subscriptions = null;
		}

		this.usersService.getUsersList(this.criteria, this.options).subscribe((response: any) => {
			this.users = response.users;
			this.enterprises = response.enterprises;
			this.noResult = this.users.length == 0;

			const tableData = [];
			const hasEditUserAdminAccess = this.usersService.hasAccessFunction('edit_user_admin');
			const hasDeleteUserAccess = this.usersService.hasAccessFunction('delete_user');

			for(const user of response.users) {
				const options = [];
				if (hasEditUserAdminAccess)
					options.push({text: this.translateService.instant('users.edit_user'), link: ['/user', 'edit', user.id]});

				if (this.currentUser.can_grant_access_functions)
					options.push({text: this.translateService.instant('users.edit_access_functions'), link: ['/admin-panel/users', 'edit-access-functions', user.id]});

				if (user.permission_id > 0 && hasEditUserAdminAccess)
					options.push({text: this.translateService.instant('g.edit_permissions'), link: ['/admin-panel', 'permission-edit', user.permission_id]});

				if (!user.is_admin)
					options.push({text: this.translateService.instant('action_log.become_user'), action: ()=> {this.getBecomeUserUrl(user.id)}});

				if (!user.special_user && hasDeleteUserAccess)
					options.push({text: this.translateService.instant('g.delete'), action: ()=> {this.deleteUserModal.passData = user.id; this.deleteUserModal.show()}});

				tableData.push({
					first_name: {value: user.first_name},
					last_name: {value: user.last_name},
					email_address: {value: user.email},
					enterprise_id: {value: `${user.enterprise_id} / ${this.enterprises[user.enterprise_id]?.name}`},
					create_date: {value: user.create_date},
					last_access_time: {value: this.datePipe.transform(user.last_access_time * 1000, 'yyyy-MM-dd HH:mm:ss') },
					last_access_through: { value: user.last_access_through },
					status: {value: user.account_deactivate, trueText: this.translateService.instant('g.deactivated'), falseText: this.translateService.instant('g.activated')},
					actions: {value: null, options: options}
				})
			}

			this.tableData = tableData;
		});
	}

	getBecomeUserUrl(id) {
		this.usersService.getBecomeUserToken(id).subscribe(
			response => {
				var url = decodeURIComponent(window.location.origin+"/#/become-user/") + response;
				var msg = this.translateService.instant('users.become_user_msg') + ' :<br/>';
				msg += "<input id='become-user-url' type='text' value='" + url + "' size='" + url.length + "'/>";
				this.notificationMessage.setMessage(msg, {numberOfNavigate: 1, type: 'success', timeout: 30000, safeType: 'html'});
			},
			err => {
				this.notificationMessage.setMessage(err, {clearOnXTimeNavigate: 1});
			}
		);
	}
}
