<div [class]="'main-black '+styles">
	<h3>{{ 'studies.power_studies' | translate }}</h3>
	<div class="row mb-5 mt-4">
		<div class="col">
			<h5>{{ 'studies.filter_by_start_date' | translate }}</h5>
			<app-date-range-select [withBorder]="false" [activePage]="'events'" (onDatesChanged)="onDatesChanged($event)"></app-date-range-select>
			<div class="text-danger" *ngIf="invalidDateRange"><b>{{'g.start_lg_end' | translate}}</b></div>
		</div>
	</div>
	<button (click)="showNewStudyForm = true;" class="btn btn-primary mb-2">{{'studies.create_new_study' | translate}}</button>
	<button *ngIf="anyStudySelected() && canDeleteStudy" (click)="deletePowerStudies()" class="btn btn-danger ml-2 mb-2">{{'studies.delete' | translate}}</button>

	<div [ngClass]="{'table-scroll': parent === 'customer'}">
		<div>
			<sct-custom-table
				[columnsConfig]="listingColumns"
				[data]="filteredStudies"
				[config]="tableConfig"
				(updateSelectedRows)="onRowSelected($event)"
			></sct-custom-table>
		</div>
	</div>
</div>

<app-modal #confirmDeleteStudy [title]="'g.confirm_action' | translate">
	<p>
		{{'studies.del_study_prompt' | translate}}
	</p>
</app-modal>

<app-new-study *ngIf="showNewStudyForm" (onClose)="onNewStudyFormClose($event)" [parent]="parent" [studies]="studies" [customerId]="customerId" [siteId]="siteId" [device]="device" ></app-new-study>
