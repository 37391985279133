<!--( IoTAh devices ) -->
<div class="info-cards" *ngIf="showIoTahInfo()">
	<div class="py-4">
		<img src="/sctportal/images/truck.png">
		<h1 class="main-blue font-weight-bold">{{ioTAhDevices?.count | number:'1.0-0'}}</h1>
		<b>{{'site.number_of_trucks' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/time.png">
		<h1 class="main-blue font-weight-bold">{{ioTAhDevices?.hours | number:'1.0-0'}}</h1>
		<b>{{'site.ioTAh_devices.total_hours' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/ahrs.png">
		<h1 class="main-blue font-weight-bold">{{ioTAhDevices?.ahrs}}{{ioTAhDevicesUnits.ahrs}}</h1>
		<b>{{'site.ioTAh_devices.total_ahr' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/kwhr.png">
		<h1 class="main-blue font-weight-bold h2">{{ioTAhDevices?.whrs}}{{ioTAhDevicesUnits.whrs}}</h1>
		<b>{{'site.ioTAh_devices.total_trucks_kwhr' | translate}}</b>
	</div>
	<div class="py-4" *ngIf="false">
		<img src="/sctportal/images/co2.png">
		<h1 class="main-blue font-weight-bold h2">{{CO2}}MT</h1>
		<b>{{'site.co2_saved' | translate}}</b>
	</div>
</div>
<!--( ChargLink devices ) -->
<div class="info-cards" *ngIf="showChargLinkInfo()">
	<div class="py-4">
		<img src="/sctportal/images/chargLink/charger.png">
		<h1 class="main-blue font-weight-bold">{{chargLinkDevices?.count | number:'1.0-0'}}</h1>
		<b>{{'site.number_of_charger' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/time.png">
		<h1 class="main-blue font-weight-bold">{{chargLinkDevices?.hours | number:'1.0-0'}}</h1>
		<b>{{'site.chargLink_devices.total_hours' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/ahrs.png">
		<h1 class="main-blue font-weight-bold">{{chargLinkDevices?.ahrs}}{{chargLinkDevicesUnits.ahrs}}</h1>
		<b>{{'site.chargLink_devices.total_ahr' | translate}}</b>
	</div>
	<div class="py-4">
		<img src="/sctportal/images/kwhr.png">
		<h1 class="main-blue font-weight-bold h2">{{chargLinkDevices?.whrs}}{{chargLinkDevicesUnits.whrs}}</h1>
		<b>{{'site.chargLink_devices.total_trucks_kwhr' | translate}}</b>
	</div>
</div>
