<app-modal #trackModal title="{{'config.configurations' | translate}}" [hasConfirmButton]="false" size="lg">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.property' | translate}}</th>
				<th>{{'g.value' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of trackModalData | keys">
			<ng-container *ngIf="!compareSkipFields.includes(key)">
				<td>{{'devices.'+key | translate}}</td>
				<td>
					<span *ngIf="isObject(trackModalData[key]); else elseBlock">
						<table>
							<thead>
								<tr>
									<th>{{'g.property' | translate}}</th>
									<th>{{'g.value' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let subKey of trackModalData[key] | keys">
									<ng-container *ngIf="nestedObjFields.includes(key); else config">
											<td>{{subKey | translate}}</td>
											<td>
												<tr *ngFor="let nestedKey of trackModalData[key][subKey] | keys">
													<td>{{nestedKey | translate }}</td>
													<td>{{ trackModalData[key][subKey][nestedKey] }}</td>
												</tr>
											</td>
									</ng-container>
									<ng-template #config>
										<td>{{'devices.'+subKey | translate}}</td>
										<td>{{trackModalData[key][subKey]}}</td>
									</ng-template>
								</tr>
							</tbody>
						</table>
					</span>
					<ng-template #elseBlock>
						<span *ngIf="!linksFields.includes(key)">
							{{trackModalData[key]}}
						</span>
						<ng-container *ngIf="linksFields.includes(key)">
							<ng-template *ngTemplateOutlet="link; context:{key, configs: trackModalData}"></ng-template>
						</ng-container>
					</ng-template>
				</td>
			</ng-container>
		</tr>
		</tbody>
	</table>
</app-modal>

<app-modal #compareModal title="{{'config.compare_configurations' | translate}}" [hasConfirmButton]="false" size="lg">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.property' | translate}}</th>
				<th>{{'g.old' | translate}}</th>
				<th>{{'g.new' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of compareModalData.old | keys">
			<ng-container *ngIf="!compareSkipFields.includes(key)">
				<td>{{'devices.'+key | translate}}</td>
				<td>
					<span *ngIf="isObject(compareModalData.old[key]); else compareElseBlock">
						<table>
							<thead>
								<tr>
									<th>{{'g.property' | translate}}</th>
									<th>{{'g.value' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let subKey of compareModalData.old[key] | keys">
									<td>{{'devices.'+subKey | translate}}</td>
									<td>{{compareModalData.old[key][subKey]}}</td>
								</tr>
							</tbody>
						</table>
					</span>
					<ng-template #compareElseBlock>
						<span *ngIf="!linksFields.includes(key)">
							{{compareModalData.old[key]}}
						</span>
						<ng-container *ngIf="linksFields.includes(key)">
							<ng-template *ngTemplateOutlet="link; context:{key, configs: compareModalData.old}"></ng-template>
						</ng-container>
					</ng-template>
				</td>
				<td>
					<span *ngIf="isObject(compareModalData.new[key]); else compareElseBlock2">
						<table>
							<thead>
								<tr>
									<th>{{'g.property' | translate}}</th>
									<th>{{'g.value' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let subKey of compareModalData.new[key] | keys">
									<td>{{'devices.'+subKey | translate}}</td>
									<td>{{compareModalData.new[key][subKey]}}</td>
								</tr>
							</tbody>
						</table>
					</span>
					<ng-template #compareElseBlock2>
						<span *ngIf="!linksFields.includes(key)">
							{{compareModalData.new[key]}}
						</span>
						<ng-container *ngIf="linksFields.includes(key)">
							<ng-template *ngTemplateOutlet="link; context:{key, configs: compareModalData.new}"></ng-template>
						</ng-container>
					</ng-template>
				</td>
			</ng-container>
		</tr>
		</tbody>
	</table>
</app-modal>

<div class="white-box p-4 border-radius mt-2">
	<div class="text-danger" *ngIf="invalidDateRange">
		<b>{{'g.start_lg_end' | translate}}</b>
	</div>

	<div class="panel panel-default">
		<div class="panel-body">
			<div class="col-md-12">
				<div>
					<button class="btn btn-info" (click)="compareConfigs()" [disabled]="!configsToCompare" [attr.title]="!configsToCompare ? ('config.select_msg' | translate) : ''">{{'config.compare_configurations' | translate}}</button>
					<sct-custom-table
            #sctCustomTable
						[columnsConfig]="columnConfig"
						[data]="tableData"
						[config]="tableConfig"
						(updateSelectedRows)="gridEvent($event)"
            (backEndPaginationFunc)="getDeviceConfigTrack($event)"
					></sct-custom-table>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Links Fields -->
<ng-template #link let-key="key" let-configs="configs">
	<a *ngIf="configs[key+'_link']; else link_not_valid" [routerLink]="configs[key+'_link']"
		(click)="hideModals(); ">{{configs[key]}}
	</a>
	<ng-template #link_not_valid>{{configs[key]}}</ng-template>
</ng-template>
