import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ReceivingShipmentService } from 'src/app/management/receiving-shipment/receiving-shipment.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { TableData, ColumnsConfig } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-receiving-shipment-history-result',
	templateUrl: './receiving-shipment-history-result.component.html',
	styleUrls: ['./receiving-shipment-history-result.component.css']
})
export class ReceivingShipmentHistoryResultComponent implements OnInit {

	reportId: number = 0;
	DEVICE_STATUS = this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS;
	devices: any = [];
	warningsData: any;
	routeSubscription: any;
	source: number = this.deviceManagementService.shipmentSource.receive;

	@ViewChild("confirmModal") confirmModal;

	column: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'serial_number', name: this.translateService.instant('g.serial_number'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'received_successfully', name: this.translateService.instant(this.source == this.deviceManagementService.shipmentSource.quality ? 'shipment.checked_successfully' : 'shipment.received_successfully'), type: 'boolean', hasFilter: true, hasSort: true},
		{ key: 'reason', name: this.translateService.instant('g.reason'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'details', name: this.translateService.instant('g.details'), type: 'action_link', hasFilter: true, hasSort: true},
		{ key: 'voltage', name: this.translateService.instant('g.voltage'), type: 'number', hasFilter: true, hasSort: true},
		{ key: 'current', name: this.translateService.instant('g.current'), type: 'number', hasFilter: true, hasSort: true}
	];
	constructor(
		private router: Router,
		public userService: UsersService,
		private route: ActivatedRoute,
		private commonDataService: CommonDataService,
		public deviceManagementService: DeviceManagementService,
		public receivingShipmentService: ReceivingShipmentService,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('shipment_management') && !this.userService.hasAccessFunction('cm_check_warnings'))
			this.router.navigate(['unauthorized']);

		if(this.router.url.includes('shipment-quality-history'))
			this.source = this.deviceManagementService.shipmentSource.quality;

		this.routeSubscription = this.route.params.subscribe(params => {
			this.reportId = params.reportId;
			this.getReceiveShipmentResult();
		});
	}

	getReceiveShipmentResult() {
		this.devices = [];
		const devicesData = [];
		this.deviceManagementService.getReceiveShipmentResult(this.reportId).subscribe((devices: any) => {
			[...devices].forEach((row) => {
				let rowData: TableData = {
					row,
					mac_address: {value: row.mac_address},
					serial_number: {value: row.serial_number},
					received_successfully: {value: row.status === this.DEVICE_STATUS.completed.status || row.status === this.DEVICE_STATUS.gps_not_ready.status, trueText: this.translateService.instant('g.yes'), falseText: this.translateService.instant('g.no')},
					reason: {value: this.receivingShipmentService.isRecevingProccessSuccess(row.status) ? '---' : this.translateService.instant('shipment.' + this.DEVICE_STATUS[row.status].status)},
					details: {value: row.status == this.DEVICE_STATUS.warnings.status? this.translateService.instant( 'g.warnings') : this.translateService.instant('shipment.' + this.DEVICE_STATUS[row.status].status), action: row.status == this.DEVICE_STATUS.warnings.status? ()=>{this.showWarnings(row)} : null },
					voltage: {value: this.printCurrentAndVoltageValue(row.details?.currentAndVoltage?.voltage)},
					current: {value: this.printCurrentAndVoltageValue(row.details?.currentAndVoltage?.current)}
				}
				devicesData.push(rowData);
			});

			this.devices = devicesData;
		});
	}

	showWarnings(device: any) {
		if (!device.details?.warnings)
			return;

		this.warningsData = device.details.warnings;
		this.confirmModal.show();
	}

	printCurrentAndVoltageValue(value) {
		if(value === 0)
			return value;

		if(value) {
			if(Number.isInteger(+value))
				return value;

			value = value.toFixed(1);
			return Math.abs(value % 1) > 0 ? value : Math.trunc(value);
		}

		return null;
	}
}
