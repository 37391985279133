import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CustomersService } from './customers.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { UsersService } from '../users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, TableConfig, TableData } from '../shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'name', name: this.translate.instant('g.name'), type: "string"},
		{ key: 'action', name: this.translate.instant('g.action'), type: "dropdown" },
	];

	customers: any[] = [];
	currentUser: any = {};


	constructor(
		private customersService: CustomersService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		public usersService: UsersService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
		if (!this.currentUser.isSCT || !this.usersService.hasAccessFunction('customer_management'))
			return this.router.navigate(['/unauthorized']);

		this.getCustomers();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.deleteCustomer(result[1]);
		});
	}

	confirmDeleteCustomer(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'g.del_customer_prompt';
		this.confirmModal.show();
	}

	getCustomers() {
		this.customersService.getCustomers().subscribe(
			(data: any) => {
				// sort data by customer_name
				this.customers = data.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
				const customers = data.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
				const tableData = [];
				const hasCustomerManagementAccessFunction = this.usersService.hasAccessFunction('customer_management') && this.currentUser.isSCT;

				for(const customer of customers) {
					const options = [];
					if (hasCustomerManagementAccessFunction) {
						options.push(
							{text: this.translate.instant('g.edit'), link: ['/customers', 'edit', customer.id]},
							{text: this.translate.instant('g.delete'), action: () => {this.confirmDeleteCustomer(customer.id)}}
						)
					}
					tableData.push({
						name: {value: customer.customer_name},
						action: {options: [
							...options,
							{text: this.translate.instant('site.sites'), link: ['/sites', customer.id]},
							{text: this.translate.instant('site.zombie_sites'), link: ['/zombie-sites', customer.id]},
						]}
					})
				}

				this.tableData = tableData;
			}
		);
	}

	deleteCustomer(id) {
		this.customersService.deleteCustomer(id).subscribe(
			(data: any) => {
				switch(data) {
					case 1:
						this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
						this.getCustomers();
					break;
					case 2:
						this.notificationMessage.setMessage('translate|customer.delete_failed_has_sites', {clearOnXTimeNavigate: 1});
					break;
					default:
						this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					break;
				}
			}
		);
	}
}
