<div>
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getActionsHistory($event)"
	></sct-custom-table>
	<div class="text-danger" *ngIf="invalidDateRange"><b>{{'g.start_lg_end' | translate}}</b></div>
</div>
