import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../../admin.service';
import { TableConfig, ColumnsConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-view-error',
	templateUrl: './view-error.component.html',
	styleUrls: ['./view-error.component.css']
})
export class ViewErrorComponent implements OnInit {

	log: any;
	id: number;
	errorType: string;

	column: ColumnsConfig[] = [
		{ key: 'time', name: this.translateService.instant('g.time'), type: 'date', hasFilter: true, hasSort: true},
		{ key: 'function_name', name: this.translateService.instant('error_log.function_name'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'main_caller', name: this.translateService.instant('error_log.main_caller'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'user_id', name: this.translateService.instant('users.user_id'), type: 'number', hasFilter: true, hasSort: true},
		{ key: 'error', name: this.translateService.instant('error_log.error'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'server_ip', name: this.translateService.instant('error_log.server_ip'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'user_ip', name: this.translateService.instant('error_log.user_ip'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'browser', name: this.translateService.instant('error_log.browser'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'extra_info', name: this.translateService.instant('error_log.extra_info'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'trace', name: this.translateService.instant('error_log.trace'), type: 'string', hasFilter: true, hasSort: true},
		{ key: 'is_sched', name: this.translateService.instant('error_log.is_sched'), type: 'boolean', hasFilter: true, hasSort: true},
		{ key: 'git_info', name: this.translateService.instant('g.git_info'), type: 'string', hasFilter: true, hasSort: true}
	];

	tableConfig: TableConfig = {
		draggable: true
	};

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private translateService: TranslateService
	) {
		this.id = this.route.snapshot.params.id;
		this.errorType = this.route.snapshot.data['pageName'];
	}

	ngOnInit() {
		this.log = [];
		this.adminService.getErrorLog(this.id, this.errorType).subscribe(
			(data: any) => {
				const tableData: TableData[] = [];

				[data].forEach((row) => {
					let rowData: TableData = {
						row,
						time: {value: row.error_time},
						function_name: {value: row.function_name},
						main_caller: {value: row.main_caller},
						user_id: {value: row.user_id},
						error: {value: row.error},
						server_ip: {value: row.server_ip},
						user_ip: {value: row.user_ip},
						browser: {value: row.browser},
						extra_info: {value: row.extra_field},
						trace: {value: row.trace},
						is_sched: {value: row.is_sched, trueText: this.translateService.instant('g.yes'), falseText: this.translateService.instant('g.no')},
						git_info: {value: row.git_info},
					}
					tableData.push(rowData);
				});

				this.log = tableData;
			}
		);
	}
}
