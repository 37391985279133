import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { ChartDataService } from './chart-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { SiteDashboardService } from 'src/app/home/site-dashboard/site-dashboard.service';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-chart-data-widget',
	templateUrl: './chart-data-widget.component.html',
	styleUrls: ['./chart-data-widget.component.css']
})
export class ChartDataWidgetComponent implements OnInit, OnChanges, OnDestroy {

	options = {
		hasPrint: true,
		hasDate: true,
		hideYesterdayDate: false,
		sinceInstallation: false,
		hasRegenOptions: false,
		hasBatteryCapacitiesOptions: false,
		hasBatteryVoltagesOptions: false,
		hasBatteryTypesOptions: false,
		hideTruckTypesOptions: false,
		hideTruckYearsOptions: false,
	};
	selectedRadioButton;
	selectedDropDown: any = {
		date: 'last_week',
		truck_type: 'all',
		battery_capacity: 'all',
		battery_voltage: 'all',
		truck_year: 'all',
		regen_option: 'ahr',
		battery_type: 'all',
	};
	selectedTags: number[];
	selectedCheckbox;
	radioButtonGroupList;
	checkboxList;
	tableItems: any[] = [];
	allTableItems: any = {};
	extraTableData: any = {};
	sizeColumnsToFit: boolean = true;
	itemsPerPage: number = 4;
	hideWidget: boolean = false;
	noDataForCurrentFilters: boolean = false;
	chartData: any;
	isCollapsed: boolean = false;
	dropDownGroupList: any[] = [];
	selectedTruckType: any = 'all';
	selectedTruckYear: any = 'all';
	selectedBatteryCapacity: any = 'all';
	selectedBatteryVoltage: any = 'all';
	selectedRegenOption: any = 'ahr';
	selectedBatteryType: any = 'all';
	exportFileName: any = [];
	siteMaxVPC: number = 0;

	tooltip: string;

	@Input() selectedDate: any = 'last_week';
	@Input() widgetId;
	@Input() data: any;
	@Input('options') passedOptions: {
		hasDate,
		hideYesterdayDate,
		sinceInstallation,
		hasRegenOptions,
		hasBatteryCapacitiesOptions,
		hasBatteryVoltagesOptions,
		hasBatteryTypesOptions,
		hideTruckTypesOptions,
		hideTruckYearsOptions,
	} = {
		hasDate: true,
		hideYesterdayDate: false,
		sinceInstallation: false,
		hasRegenOptions: false,
		hasBatteryCapacitiesOptions: false,
		hasBatteryVoltagesOptions: false,
		hasBatteryTypesOptions: false,
		hideTruckTypesOptions: false,
		hideTruckYearsOptions: false,
	};
	@Input() tableColumns: any[] = [];
	@Input() tableSortBy: any;
	@Input() title = '';
	@Input() config: any;
	@Input() chartType: string = 'ColumnChart';
	@Input() stackColumn: string;
	@Input() devices: any = {};
	@Input() siteName: any;
	@Input() columnNames: any = [
		this.translateService.instant('g.type'),
		this.translateService.instant('g.amount')
	];
	@Input() listsOptions: {
		truckTypes,
		truckYears,
		batteryCapacities,
		batteryVoltages,
		batteryTypes,
	};
	@Input() tags: any[];
	@Input() deviceTags: any[];
	@Input() tooltipKey: string;

	@Output() onPrintClick: EventEmitter<any> = new EventEmitter();

	@ViewChild('modal') modal;

	permissionsSub: Subscription = new Subscription();

	chartTableConfig = {
		column: [] as ColumnsConfig[],
		data: [],
		config: {
			hasExport: false,
			hasPagination: false,
			draggable: false
		} as TableConfig
	};

	constructor(
		private chartDataService: ChartDataService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private siteDashboardService: SiteDashboardService,
		private userService: UsersService,
	) { }

	ngOnInit() {
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {

			let area = this.widgetId;
			if(this.tooltipKey)
				area = this.tooltipKey;

			this.tooltip = this.commonService.getTooltipKey(area, this.userService.userHasNOCAccess(data));
		});
		this.prepareOptions();
		this.prepareData();
	}

	ngOnChanges(changes) {
		if(changes.data && changes.data.currentValue) {
			this.prepareOptions();
		}

		this.prepareData();

		if(this.tableColumns && this.tableColumns[0] && this.tableColumns[0].field == 'appearName') {
			this.tableColumns[0].onCellClicked = () => {
				if(this.modal) this.modal.hide();
			};
		}
		this.options = Object.assign(this.options, this.passedOptions);
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

	onSelect(event) {
		// @todo: handle event.column in case of stacked columns
		this.tableItems = [];
		if(!event)
			return;

		let selectedIndex = event.row;
		let tableItems = [];
		let selectedDevicesInfo = this.allTableItems[selectedIndex];
		if(this.widgetId == 'idle_duration') {
			selectedDevicesInfo = selectedDevicesInfo[event.column];
		}
		for(let item of selectedDevicesInfo) {
			if(!this.devices[item.mac])
				continue;

			let temp = {
				appearName: this.devices[item.mac].truck_sn+' ('+this.devices[item.mac].serial_number+')',
				totalValue: item.totalValue,
				avgValue: item.avgValue,
				value: item.value,
				mac_address: item.mac,
				eventId: item.eventId
			};
			tableItems.push(temp);
		}
		if(this.tableSortBy)
			tableItems = _.sortBy(tableItems, this.tableSortBy).reverse();

		this.tableItems = tableItems;
		this.prepareWidgateModalTable();
	}

	onSelectionChanged(event) {
		switch(event[0]) {
			case 'date':
				this.selectedDate = event[1];
			break;
			case 'truck_type':
				this.selectedTruckType = event[1];
			break;
			case 'truck_year':
				this.selectedTruckYear = event[1];
			break;
			case 'battery_capacity':
				this.selectedBatteryCapacity = event[1];
			break;
			case 'battery_voltage':
				this.selectedBatteryVoltage = event[1];
			break;
			case 'regen_option':
				this.selectedRegenOption = event[1];
				this.config.hAxis.title = this.translateService.instant('site_performance.regen_options.'+event[1]);
			break;
			case 'battery_type':
				this.selectedBatteryType = event[1];
			break;
		}
		this.prepareData();
	}

	onPrint() {
		if (!this.options.hasPrint) return;

		if (this.onPrintClick.observers.length > 0) {
			this.onPrintClick.emit({
				widgetId: this.widgetId,
				selectedRadioButton: this.selectedRadioButton,
				selectedDropDown: this.selectedDropDown,
				selectedCheckbox: this.selectedCheckbox,
				selectedTags: this.selectedTags
			});
			return;
		}
		let appendPrintContent = this.getPrintItems();
		return this.print({
			radioButtonGroupList: this.radioButtonGroupList,
			selectedRadioButton: this.selectedRadioButton,
			selectedDropDown: this.selectedDropDown,
			checkboxList: this.checkboxList,
			selectedTags: this.selectedTags,
			selectedCheckbox: this.selectedCheckbox,
			widgetId: this.widgetId,
			appendPrintContent: appendPrintContent
		});
	}

	private prepareOptions() {
		this.dropDownGroupList = [];
		this.checkboxList = [];

		let dropDownGroupList = [];

		if(this.options.hasDate) {
			let dateSelectOptions = [];

			if (!this.options.hideYesterdayDate) {
				dateSelectOptions.push({
					value: "yesterday",
					label: this.translateService.instant('durations.yesterday')
				});
			}

			dateSelectOptions = dateSelectOptions.concat([
				{
					value: 'last_week',
					label: this.translateService.instant('durations.last_week')
				}, {
					value: 'last_7_days',
					label: this.translateService.instant('durations.last_7_days')
				}, {
					value: 'last_30_days',
					label: this.translateService.instant('durations.last_30_days')
				}, {
					value: 'last_90_days',
					label: this.translateService.instant('durations.last_90_days')
				}
			]);

			if(this.data && this.options.sinceInstallation) {
				dateSelectOptions.push({
					value: 'site_installation',
					label: this.translateService.instant('durations.since_installation')
				});
			}

			dropDownGroupList.push({
				name: 'date',
				label: '',
				options: dateSelectOptions
			});
		}

		if(!this.options.hideTruckTypesOptions && this.listsOptions && this.listsOptions.truckTypes && this.listsOptions.truckTypes.length) {
			dropDownGroupList.push({
				name: 'truck_type',
				label: '',
				options: this.listsOptions.truckTypes
			});

			if(this.widgetId == 'relative_truck_usage_kws') {
				//Auto select the first option
				this.selectedDropDown.truck_type = this.listsOptions.truckTypes[0].value;
				this.onSelectionChanged(['truck_type', this.listsOptions.truckTypes[0].value]);
			}
		}

		if(!this.options.hideTruckYearsOptions && this.listsOptions && this.listsOptions.truckYears && this.listsOptions.truckYears.length) {
			dropDownGroupList.push({
				name: 'truck_year',
				label: '',
				options: this.listsOptions.truckYears
			});
		}

		if(this.options.hasBatteryCapacitiesOptions && this.listsOptions && this.listsOptions.batteryCapacities && this.listsOptions.batteryCapacities.length) {
			dropDownGroupList.push({
				name: 'battery_capacity',
				label: '',
				options: this.listsOptions.batteryCapacities
			});
		}
		if(this.options.hasBatteryVoltagesOptions && this.listsOptions && this.listsOptions.batteryVoltages && this.listsOptions.batteryVoltages.length) {
			dropDownGroupList.push({
				name: 'battery_voltage',
				label: '',
				options: this.listsOptions.batteryVoltages
			});
		}

		if(this.options.hasRegenOptions) {
			let regenOptions = [];
			for(let item of ['ahr', 'usage', 'capacity']) {
				regenOptions.push({value: item, label: this.translateService.instant('site_performance.regen_options.'+item)});
			}
			dropDownGroupList.push({
				name: 'regen_option',
				label: '',
				options: regenOptions
			});
		}
		if(this.tags && this.tags.length) {
			dropDownGroupList.push({
				name: 'tags'
			});
		}

		if(this.options.hasBatteryTypesOptions && this.listsOptions && this.listsOptions.batteryTypes && this.listsOptions.batteryTypes.length) {
			dropDownGroupList.push({
				name: 'battery_type',
				label: '',
				options: this.listsOptions.batteryTypes
			});
		}

		this.dropDownGroupList = dropDownGroupList;
		this.selectedDropDown.date = this.selectedDate;

		this.siteMaxVPC = _.chain(this.devices).pluck('vpc').uniq().without(0).max().value();
	}

	private prepareData() {
		if(!this.data)
			return;

		if(this.stackColumn)
			this.config.isStacked = true;

		let options: any = {
			dateRange:			this.selectedDate,
			truckType:			this.selectedTruckType,
			truckYear:			this.selectedTruckYear,
			batteryCapacity:	this.selectedBatteryCapacity,
			batteryVoltage:		this.selectedBatteryVoltage,
			regenOption:		this.selectedRegenOption,
			batteryType:		this.selectedBatteryType,
			devices:			this.devices,
			tags:				this.selectedTags,
			deviceTags:			this.deviceTags
		};
		let filters = [this.siteName, this.title];
		for(let group of this.dropDownGroupList) {
			let item = this.selectedDropDown[group.name];
			if(!group.options)
				continue;
			for(let opt of group.options) {
				if(opt.value == this.selectedDropDown[group.name]) {
					item = opt.label;
					break;
				}
			}
			filters.push(item);
		}
		this.exportFileName = filters.join(' - ');

		let chartData = [];
		this.tableItems = [];

		let widgetData = this.chartDataService.generateWidgetData(this.data, this.widgetId, options);
		if(!widgetData) {
			this.hideWidget = true;
			return;
		}
		if(!Object.keys(widgetData).length) {
			this.noDataForCurrentFilters = true;
			return;
		}
		this.hideWidget = false;
		this.noDataForCurrentFilters = false;
		let tableObjects: any = {};
		let counter = 0;
		let dataFormatter = this.chartDataService.getWidgetDataFormatter(this.widgetId);
		let groups = [];
		if(this.widgetId != 'end_of_voltages_vpc') {
			let options: any = {};
			if(this.widgetId == 'regen_report') {
				options.max			= _.max(_.chain(widgetData).pluck('avg').uniq().without(0).value());
				options.regenOption = this.selectedRegenOption;
			}

			groups = this.chartDataService.getWidgetGroups(this.widgetId, options);
		}

		if(this.widgetId == 'relative_truck_usage_kws') {
			tableObjects = [];
			for(let mac_address in widgetData) {
				if(!this.devices[mac_address])
					continue;

				let value = dataFormatter(widgetData[mac_address]);
				chartData.push([this.devices[mac_address].truck_sn, value]);
				tableObjects.push([{mac: mac_address, value: value}]);
			}
			this.chartData = chartData;
			this.allTableItems = tableObjects;
			return;
		}

		if(this.widgetId == 'idle_duration') {
			for(let dayIndex=0; dayIndex < 7; dayIndex++) {
				let chartRow;
				tableObjects[counter] = {};
				chartRow = [this.translateService.instant('short_days.'+dayIndex)];
				for(let groupIdx in groups) {
					chartRow.push(0);
					tableObjects[counter][(+groupIdx) + 1] = [];
				}

				chartData.push(chartRow);
				counter++;
			}

			// let widgetData = {
			// 	"3c71bfb1f9a8": {
			// 	  "0": 65,
			// 	  "1": 73,
			// 	  "2": 220,
			// 	  "3": 167,
			// 	  "4": 730
			//  }
			// };

			for(let mac_address in widgetData) {
				let index = 0;
				for(let dayIndex in widgetData[mac_address]) {
					let scaledValue = this.chartDataService.scaleValue(widgetData[mac_address][dayIndex], this.devices[mac_address], this.widgetId);
					for(let groupIndex in groups) {
						if(scaledValue < groups[groupIndex][1] || +groupIndex == (groups.length-1)) {
							index = (+groupIndex + 1);
							tableObjects[dayIndex][index].push({mac: mac_address, value: dataFormatter(scaledValue)});
							break;
						}
					}
					chartData[dayIndex][index]++;
				}
			}
			// [ [ "Sun", 0, 10.8, 7.04, 0 ] ]
		} else if (this.widgetId == 'end_of_voltages_vpc') {
			let vpcVals = _.chain(widgetData).pluck('vpc').uniq().without(0).value();
			let maxEventVPC = _.chain(widgetData).pluck('value').max().value();
			let minVpc = _.min(vpcVals);
			let maxVpc = _.max(vpcVals);
			this.extraTableData = {min: minVpc, max: maxVpc};
			groups = this.chartDataService.getWidgetGroups(this.widgetId, {min: minVpc, max: maxVpc, siteVPC: this.siteMaxVPC, maxIsDanger: maxEventVPC >= this.siteMaxVPC});
			for(let group of groups) {
				let val: any = 0;
				chartData.push([group[0], val, group[2]]);
				tableObjects[counter] = [];
				counter++;
			}
			for(let itemId in widgetData) {
				let eventValue = widgetData[itemId];

				eventValue = eventValue.value;
				let macAddress = widgetData[itemId].name;
				if(!this.devices[macAddress])
					continue;

				let index = 0;
				for(let groupIndex in groups) {
					let scaledValue = this.chartDataService.scaleValue(eventValue, this.devices[macAddress], this.widgetId);
					if(scaledValue < groups[groupIndex][1] || +groupIndex == (groups.length-1)) {
						index = +groupIndex;
						let eventIdValue = (itemId.split('.'))[1];
						tableObjects[index].push({mac: macAddress, value: dataFormatter(scaledValue), eventId: eventIdValue});
						break;
					}
				}
				chartData[index][1]++;
			}
		} else {
			let countOfStackedItems = 1;
			switch(this.stackColumn) {
				case 'truck_type':
					if(this.listsOptions && this.listsOptions.truckTypes && this.listsOptions.truckTypes.length)
						countOfStackedItems = this.listsOptions.truckTypes.length - 1; // exclude all option
				break;
			}
			for(let group of groups) {
				let itemp = [];

				itemp.push(group[0]);

				for(let i = 0; i < countOfStackedItems; i++) {
					itemp.push(0);
				}
				chartData.push(itemp);
				tableObjects[counter] = [];
				counter++;
			}
			for(let itemId in widgetData) {
				let totalValue	= widgetData[itemId].total,
					avgValue	= widgetData[itemId].avg;
				let macAddress = itemId;
				if(!this.devices[macAddress])
					continue;

				let index = 0;
				for(let groupIndex in groups) {
					let scaledAvgValue = this.chartDataService.scaleValue(avgValue, this.devices[macAddress], this.widgetId);
					if(scaledAvgValue < groups[groupIndex][1] || +groupIndex == (groups.length-1)) {
						index = +groupIndex;
						let scaledTotalValue = this.chartDataService.scaleValue(totalValue, this.devices[macAddress], this.widgetId);
						let scaledAvgValue = this.chartDataService.scaleValue(avgValue, this.devices[macAddress], this.widgetId);
						tableObjects[index].push({mac: macAddress, totalValue: dataFormatter(scaledTotalValue), scaledAvgValue, avgValue: dataFormatter(scaledAvgValue), eventId: itemId});
						break;
					}
				}

				let deviceStackedIndex = this.getDeviceStackedIndex(macAddress);

				chartData[index][deviceStackedIndex]++;
			}
		}
		this.chartData = chartData;
		this.allTableItems = tableObjects;
	}

	private getDeviceStackedIndex(macAddress) {
		let device = this.devices[macAddress];
		let index = 1;
		if(this.stackColumn) {
			let deviceTruckType = device[this.stackColumn];
			if(this.stackColumn == 'truck_type' && this.listsOptions && this.listsOptions.truckTypes) {
				for(let item of this.listsOptions.truckTypes) {
					if(item.value == deviceTruckType) {
						index = item.idx;
						break;
					}
				}
			}
		}
		return index;
	}

	private print(options) {
		let addPrintHeaderFn		= options.addPrintHeaderFn,
			customerName			= options.customerName,
			siteName				= options.siteName,
			radioButtonGroupList	= options.radioButtonGroupList || [],
			selectedRadioButton		= options.selectedRadioButton,
			selectedDropDown		= options.selectedDropDown,
			selectedTags			= options.selectedTags,
			checkboxList			= options.checkboxList || [],
			selectedCheckbox		= options.selectedCheckbox || {},
			addPrintContentFn		= options.addPrintContentFn,
			widgetId				= options.widgetId,
			appendPrintContent		= options.appendPrintContent;

		let htmlContent = '';
		if (addPrintHeaderFn) {
			htmlContent+= addPrintHeaderFn();
		} else {
			htmlContent += '<div id="dvCompanyFullName"><h3>';
			if (customerName) {
				htmlContent += customerName;
				if (siteName) {
					htmlContent += ' / '+ siteName;
				}
			}
			htmlContent += '</h3></div>';
			// add selected radio buttons
			for (let radioButtonGroup of radioButtonGroupList) {
				let value = selectedRadioButton[radioButtonGroup.name];
				for (let option of radioButtonGroup.options) {
					if (option.value == value) {
						htmlContent+= option.label + ' ';
						break;
					}
				}
			}
			//add selected dropdowns
			for (let dropDownGroup of this.dropDownGroupList) {
				let value = selectedDropDown[dropDownGroup.name];
				if(dropDownGroup.options) {
					for (let option of dropDownGroup.options) {
						if (option.value == value) {
							htmlContent+= option.label + '<br/>';
							break;
						}
					}
				}
			}

			// Add selected tags
			if(this.tags && selectedTags && selectedTags.length) {
				let tagNames = [];
				for(let tag of this.tags) {
					if(selectedTags.includes(tag.id)) {
						tagNames.push(tag.tag_name);
					}
				}
				if(tagNames.length) {
					htmlContent+= this.translateService.instant('tags.tags') + ': ' + tagNames.join(', ');
				}
			}
			// add selected checkboxes
			for (let checkbox of checkboxList) {
				let value = selectedCheckbox[checkbox.name];
				if (value) {
					htmlContent+= checkbox.label + ' ';
				}
			}
		}

		// additional content
		if (addPrintContentFn) {
			htmlContent+= addPrintContentFn();
		} else {
			if(widgetId) {
				const printId = widgetId.replace( /(:|\.|\[|\])/g, "\\$1" );
				htmlContent+=  document.getElementById(printId).innerHTML;
			}
		}

		if(appendPrintContent) {
			htmlContent+= appendPrintContent;
		}
		this.commonService.printContent(htmlContent);
	}

	private getRegenMaxValue() {
		let max = 0;
		for(let index in this.allTableItems) {
			for(let device of this.allTableItems[index]) {
				if(+(device.scaledAvgValue) > max)
					max = +(device.scaledAvgValue);
			}
		}
		return max;
	}

	private getPrintItems() {
		let printList: {title, list, columns?}[] = [];

		if(this.widgetId == 'relative_truck_usage_kws') {
			let printItem = {
				title: "",
				list: []
			};
			for(let index in this.allTableItems) {
				if(!this.allTableItems[index].length)
					continue;

				for(let item of this.allTableItems[index]) {
					if(!this.devices[item.mac])
						continue;

					printItem.list.push({
						appearName: this.devices[item.mac].truck_sn+' ('+this.devices[item.mac].serial_number+')',
						value: item.value,
						totalValue: item.totalValue,
						avgValue: item.avgValue,
						mac_address: item.mac
					});
				}
			}
			printList.push(printItem);
		} else {
			let groups;
			let options: any = {};
			if(this.widgetId == 'end_of_voltages_vpc') {
				options.min = this.extraTableData.min;
				options.max = this.extraTableData.max;
			} else if(this.widgetId == 'regen_report') {
				options.max			= this.getRegenMaxValue();
				options.regenOption = this.selectedRegenOption;
			}
			groups = this.chartDataService.getWidgetGroups(this.widgetId, options);

			if(this.widgetId == 'idle_duration') {
				for(let dayIndex in this.allTableItems) {
					let tempIndex = {
						title: this.translateService.instant('short_days.'+dayIndex),
						list: []
					};
					for(let index in this.allTableItems[dayIndex]) {
						if(!this.allTableItems[dayIndex][index].length)
							continue;

						let tempIndex2 = {
							title: groups[+index-1][0],
							list: []
						};

						for(let item of this.allTableItems[dayIndex][index]) {
							if(!this.devices[item.mac])
								continue;

							let temp = {
								appearName: this.devices[item.mac].truck_sn+' ('+this.devices[item.mac].serial_number+')',
								value: item.value,
								totalValue: item.totalValue,
								avgValue: item.avgValue,
								mac_address: item.mac,
								eventId: item.eventId
							};
							tempIndex2.list.push(temp);
						}

						tempIndex.list.push(tempIndex2);
					}
					if(tempIndex.list.length)
						printList.push(tempIndex);
				}
			} else {
				for(let index in this.allTableItems) {
					if(!this.allTableItems[index].length)
						continue;

					let tempIndex = {
						title: groups[index][0],
						list: []
					};

					for(let item of this.allTableItems[index]) {
						if(!this.devices[item.mac])
							continue;

						let temp = {
							appearName: this.devices[item.mac].truck_sn+' ('+this.devices[item.mac].serial_number+')',
							value: item.value,
							totalValue: item.totalValue,
							avgValue: item.avgValue,
							mac_address: item.mac,
							eventId: item.eventId
						};
						tempIndex.list.push(temp);
					}

					printList.push(tempIndex);
				}
			}
		}

		let html = '<div class="showOnPrint">';
		if(this.widgetId == 'idle_duration') {
			for(let dayIdx in printList) {
				let dayPrintItem = printList[dayIdx];
				html += '<div class="page-break-inside">';
				html += '<div style="font-weight:bold;">'+dayPrintItem.title+'</div>';
				for(let printItem of dayPrintItem.list) {
					html += '<span style="font-weight:bold;">'+printItem.title+'</span>';
					html += '<table class="table table-striped table-condensed table-bordered"> <thead>';
					let columns = this.tableColumns;
					for (let columnIndex in columns) {
						html += '<th>'+columns[columnIndex].headerName+'</th>';
					}
					html += '</thead><tbody>';
					for (let itemIndex in printItem.list) {
						let item = printItem.list[itemIndex];
						html += '<tr>';
						for (let columnIndex in columns) {
							html += '<td>';
							html += '<span>'+item[columns[columnIndex].field]+'</span>';
							html += '</td>';
						}
						html += '</tr>';
					}
					html += '</tbody></table>';
				}
				html += '</div>'
			}
		} else {
			for (let index in printList) {
				let printItem = printList[index];
				html += '<div class="page-break-inside">';
				html += '<span style="font-weight:bold;">'+printItem.title+'</span>';
				html += '<table class="table table-striped table-condensed table-bordered"><thead>';
				let columns = printItem.columns;

				if(!columns)
					columns = this.tableColumns;

				for (let columnIndex in columns) {
					html += '<th>'+columns[columnIndex].headerName+'</th>';
				}
				html += '</thead><tbody>';
				for (let itemIndex in printItem.list) {
					let item = printItem.list[itemIndex];
					html += '<tr>';
					for (let columnIndex in columns) {
						html += '<td>';
						html += '<span>'+item[columns[columnIndex].field]+'</span>';
						html += '</td>';
					}
					html += '</tr>';
				}
				html += '</tbody></table></div>';
			}
		}
		html += '</div>';
		return html;
	}

	prepareWidgateModalTable() {
		const { customerId, siteId } = this.tableColumns[0].cellRendererParams;
		const baseColumns = [
			{ key: 'appearName', name: this.translateService.instant('devices.truck_sn'), type: 'link' }
		];

		const widgetConfigs = {
			'truck_usage_duration': {
				columns: [
					...baseColumns,
					{ key: 'totalValue', name: this.translateService.instant('g.total_value'), type: 'string' },
					{ key: 'avgValue', name: this.translateService.instant('site_performance.avg_truck_hours'), type: 'string' }
				],
				dataKeys: ['totalValue', 'avgValue']
			},
			'truck_usage_as': {
				columns: [
					...baseColumns,
					{ key: 'totalValue', name: this.translateService.instant('site_performance.total_ebus'), type: 'number' },
					{ key: 'avgValue', name: this.translateService.instant('site_performance.avg_ebus'), type: 'number' }
				],
				dataKeys: ['totalValue', 'avgValue']
			},
			'truck_usage_ws': {
				columns: [
					...baseColumns,
					{ key: 'totalValue', name: this.translateService.instant('g.total_value'), type: 'number',  },
					{ key: 'avgValue', name: this.translateService.instant('site_performance.avg_truck_hours'), type: 'number' }
				],
				dataKeys: ['totalValue', 'avgValue']
			},
			'idle_duration': {
				columns: [
					...baseColumns,
					{ key: 'value', name: this.translateService.instant('g.total_value'), type: 'number' }
				],
				dataKeys: ['value']
			},
			'relative_truck_usage_kws': {
				columns: [
					...baseColumns,
					{ key: 'value', name: this.translateService.instant('site_performance.utilization_percentage'), type: 'number' }
				],
				dataKeys: ['value']
			},
			'end_of_voltages_vpc': {
				columns: [
					...baseColumns,
					{ key: 'eventId', name: this.translateService.instant('events.sequence_id'), type: 'string' },
					{ key: 'value', name: this.translateService.instant('event.vpc'), type: 'number' }
				],
				dataKeys: ['eventId', 'value']
			},
			'regen_report': {
				columns: [
					...baseColumns,
					{ key: 'totalValue', name: this.translateService.instant('g.total_value'), type: 'string' },
					{ key: 'avgValue', name: this.translateService.instant('site_performance.avg_truck_hours'), type: 'string' }
				],
				dataKeys: ['totalValue', 'avgValue']
			}
		};

		const config = widgetConfigs[this.widgetId];
		if (config) {
			this.chartTableConfig.column = config.columns;
			this.chartTableConfig.data = this.tableItems.map((row) => {
				const rowData: TableData = {
					appearName: { value: row.appearName, link: ['/', customerId, siteId, row.mac_address, 'performance'] }
				};

				config.dataKeys.forEach(key => {
					rowData[key] = { value: row[key], sortValue: +row[key] };
				});
				return rowData;
			});
		}
	}
}
