import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AdminService } from "../../admin.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-connectivity-verification-log',
	templateUrl: './connectivity-verification-log.component.html',
	styleUrls: ['./connectivity-verification-log.component.css']
})

export class ConnectivityVerificationLogComponent {
	// custom table test
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		fileName: `${this.translate.instant('connectivity_verification.file_name')} ${moment.unix(this.commonService.nowTime()).utc().format('MM/DD/YYYY')}`,
		hasPagination: true,
		pageSize: 100,
		draggable: true,
		hasActionButtons: true,
		actionButtonsList: [{
			text: this.translate.instant('connectivity_verification.statistics'),
			link: ['/admin-panel/connectivity-verification-statistics'],
			enable: true
		}]
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id'},
		{ key: 'current_customer_name', name: this.translate.instant('connectivity_verification.current_customer_name'), type: "link"},
		{ key: 'current_site_name', name: this.translate.instant('connectivity_verification.current_site_name'), type: 'link'},
		{ key: 'customer_name', name: this.translate.instant('connectivity_verification.customer_name'), type: "link", hidden: true},
		{ key: 'site_name', name: this.translate.instant('connectivity_verification.site_name'), type: "link", hidden: true},
		{ key: 'serial_number', name: this.translate.instant('g.serial_number'), type: "link"},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: "link"},
		{ key: 'user', name: this.translate.instant('g.done_by'), type: "link"},
		{ key: 'action_time', name: this.translate.instant('connectivity_verification.check_time'), type: "date_with_ref"},
		{ key: 'result', name: this.translate.instant('g.result'), type: "boolean",
				filterTrueText: this.translate.instant('connectivity_verification.pass'),
				filterFalseText: this.translate.instant('connectivity_verification.fail'),
		},
		{ key: 'last_connect_time', name: this.translate.instant('devices.last_connect_time'), type: 'date_with_ref', hidden: true},
		{ key: 'connectivity_status', name: this.translate.instant('devices.connectivity_status'), type: 'boolean', hidden: true,
				filterTrueText: this.translate.instant('g.connected'),
				filterFalseText: this.translate.instant('g.disconnected')
		},
		{ key: 'longitude', name: this.translate.instant('site_edit.longitude'), type: 'number', hidden: true },
		{ key: 'latitude', name: this.translate.instant('site_edit.latitude'), type: 'number', hidden: true },
		{ key: 'current', name: this.translate.instant('qv.current'), type: 'number', hidden: true },
		{ key: 'voltage', name: this.translate.instant('qv.voltage'), type: 'number', hidden: true },
		{ key: 'app_version', name: this.translate.instant('connectivity_verification.app_version'), type: 'number', hidden: true },
		{ key: 'warning_ids', name: this.translate.instant('g.warnings'), type: 'list', hidden: true}
	];

	connectivityDetails: any[] = [];
	warnings: any = [];
	routeSubscription: any;
	connectivityLog: any[] = [];
	reportId = 0;

	@ViewChild("connectivitVerificationModel") connectivitVerificationModel;

	constructor(
		private adminService: AdminService,
		private usersService: UsersService,
		private router: Router,
		private deviceService: DeviceService,
		public translate: TranslateService,
		public commonService: CommonService,
		private commonData: CommonDataService,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
				this.router.navigate(['/unauthorized']);

		if (window.location.hostname.includes(this.commonData.developmentDomain))
				this.tableConfig.pageSize = 4;

		this.routeSubscription = this.route.params.subscribe(params => {
			this.reportId = +params.reportId;
			this.getConnectivityVerificationLogs();
		});

	}

	getConnectivityVerificationLogs() {
		this.adminService.getConnectivityVerificationLogs(this.reportId).subscribe((res: any) => {
			this.connectivityLog = res;

			const tableData: TableData[] = [];
			[...this.connectivityLog].forEach((log: any) => {
				let rewData: TableData = {
					id: {value: log.id},
					current_customer_name: {value: log.current_customer_name, link: ['/', log.device_customer_id]},
					current_site_name: {value: log.current_site_name, link: ['/', log.device_customer_id, log.device_site_id]},
					customer_name: {
						value: log.customer_name == 'customer_deleted'? this.translate.instant("connectivity_verification.customer_deleted") : log.customer_name,
						link: log.customer_name == 'customer_deleted'? null : ['/', log.customer_id]
					},
					site_name: {
						value: log.site_name == 'site_deleted'? this.translate.instant("connectivity_verification.site_deleted") : log.site_name,
						link: log.site_name == 'site_deleted'? null : ['/', log.customer_id, log.site_id]
					},
					serial_number: {
						value: log.serial_number == 'device_deleted'? this.translate.instant("connectivity_verification.device_deleted") : log.serial_number,
						link: log.serial_number == 'device_deleted'? null : ['/', log.device_customer_id, log.device_site_id, log.mac_address, 'performance']
					},
					mac_address: {
						value: log.mac_address,
						link: log.serial_number == 'device_deleted'? null : ['/', log.device_customer_id, log.device_site_id, log.mac_address, 'performance']
					},
					user: {value: log.user ? log.user : '----', link: log.user && log.user_id ? ['/user', 'edit', log.user_id] : null},
					action_time: {value: this.formateDate(log.action_time * 1000)},
					result: {
						value: this.checkProcessStatus(log),
						trueText: this.translate.instant('connectivity_verification.pass'),
						falseText: this.translate.instant('connectivity_verification.fail')
					},
					last_connect_time: {value: this.formateDate(log.last_connect_time * 1000)},
					connectivity_status: {
						value: log.connectivity_status,
						trueText: this.translate.instant('g.connected'),
						falseText: this.translate.instant('g.disconnected')
					},
					longitude: {value: log.longitude},
					latitude: {value: log.latitude},
					current: {value: log.current},
					voltage: {value: log.voltage},
					app_version: {value: log.app_version},
					warning_ids: {value: [...this.prepareWarnings(log.warning_ids)]}
				}

				tableData.push(rewData);
			})

			this.tableData = tableData;
		});
	}

	prepareWarnings(data: any) {
		if (!data || data.length == 0)
			return [];

		return data.map((item) => {
			return {
				text: this.translate.instant(`warnings.${this.deviceService.getWarningsMabValues(item)}`),
				link: null
			}
		});
	}

	checkProcessStatus(record) {
		if (record.proccess_result == null)
			return (!record.warning_ids || !record.warning_ids?.length) && record.connectivity_status;

		return record.proccess_result;
	}

	getWarningsToFilter(warningsIds) {
		return (this.prepareWarnings(warningsIds) || [])
			.map(warning => this.translate.instant(`warnings.${warning}`));
	}

	formateDate(date: moment.MomentInput, format = 'MM/DD/YYYY hh:mm:ss a') {
		return date && date != '---' ? moment(date).utc().format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';
	}
}
