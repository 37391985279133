import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import * as _ from 'underscore';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-customer-requests',
	templateUrl: './customer-requests.component.html',
	styleUrls: ['./customer-requests.component.css']
})
export class CustomerRequestsComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("identicalCustomerModal") identicalCustomerModal;
	@ViewChild("linkedDevicesModal") linkedDevicesModal;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: "string"},
		{ key: 'identical_customer_found', name: this.translateService.instant('device_management.identical_customer_found'), type: 'icon'},
		{ key: 'request_by_user', name: this.translateService.instant('device_management.request_by_user'), type: 'link'},
		{ key: 'enterprise_name', name: this.translateService.instant('g.enterprise_name'), type: "string" },
		{ key: 'source', name: this.translateService.instant('device_management.source'), type: "string" },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	doneRequest = {
		enterprise: false,
		user: false
	};

	customersList: any;
	usersInfo: any = {};
	enterprisesInfo: any = {};
	approvedCustomerInfo: any = {};
	linkedDevices: any[] = [];

	constructor(
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		private enterpriseService: EnterpriseService,
		private translateService: TranslateService,
		private router: Router,
	) { }

	ngOnInit() {
		this.getPendingCustomers();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.rejectRequest(result[1]);
		});
		this.identicalCustomerModal.onClose.subscribe((confirm) => {
			if(confirm)
				this.mapToApprovedCustomer();
		});
	}

	confirmRejectRequest(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'device_management.reject_customer_prompt';
		this.confirmModal.show();
	}

	getPendingCustomers() {
		this.deviceManagementService.getPendingCustomers({getReqUser: true}).subscribe((res: any) => {
			this.customersList = res;
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();

			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
				this.doneRequest.user = true;

				if (this.doneRequest.enterprise)
					this.fillTableData();
			});

			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
				this.doneRequest.enterprise = true;

				if (this.doneRequest.user)
					this.fillTableData();
			});
		});
	}

	fillTableData() {
		const tableData = [];

		for(const customer of this.customersList) {
			const requestByUserCondition = this.usersService.hasAccessFunction('edit_user_admin') && this.usersInfo[customer.user_id];
			const enterpriseCondition = this.enterprisesInfo[customer.enterprise_id];

			tableData.push({
				customer_name: {value: customer.customer_name},
				identical_customer_found: {value: null, icon: customer._approved_customer_id ? 'link' : null , iconColor: 'blue', action: ()=> {this.showIdenticalCustomerModal(customer)}},
				request_by_user: {
					value: requestByUserCondition ?
						`${this.usersInfo[customer.user_id].first_name} ${this.usersInfo[customer.user_id].last_name}` :
						`${customer.user_id} ${!this.usersInfo[customer.user_id] ? this.translateService.instant('g.deleted') : ''}`,
					link: requestByUserCondition ? ['/user', 'edit', customer.user_id] : null
				},
				enterprise_name: {
					value: enterpriseCondition ?
						this.enterprisesInfo[customer.enterprise_id].name :
						`${customer.enterprise_id} (${this.translateService.instant('g.deleted')})`
				},
				source: {value: this.translateService.instant(customer.source ? 'device_management.source_options.'+[customer.source] : 'g.n/a')},
				actions: {options: [
					{text: this.translateService.instant('device_management.edit_approve'), link: ['/management/device-management-requests/customer-edit', customer.customer_id]},
					{text: this.translateService.instant('device_management.view_devices'), action: () => {this.viewDevices(customer.customer_id)}},
					{text: this.translateService.instant('g.reject'), action: () => {this.confirmRejectRequest(customer.customer_id)}},
				]}
			})
		}

		this.tableData = tableData;
	}

	rejectRequest(customerId) {
		this.deviceManagementService.rejectCustomerRequest(customerId).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingCustomers();
		});
	}

	viewDevices(customerId) {
		this.linkedDevices = [];
		this.deviceManagementService.getCustomerLinkedDevices(customerId).subscribe((devices: any) => {
			if(devices.length) {
				this.deviceManagementService.getDeviceOwners(devices, {allowEmpty: true}).subscribe((owners: any) => {
					let linkedDevicesOwners = _.chain(...(_.pluck(owners.customersAndSites, 'sites'))).union().indexBy('id').value();
					let linkedDevices = [];
					for(let device of owners.devices) {
						if(linkedDevicesOwners[device.site_id])
							linkedDevices.push({
								site_id: device.site_id,
								customer_id: linkedDevicesOwners[device.site_id].customer_id,
								mac_address: device.mac_address,
								latitude: device.latitude,
								longitude: device.longitude
							});
					}
					this.linkedDevices = _.uniq(linkedDevices, d => d.mac_address);
				});
			}
			this.linkedDevicesModal.show();
		});
	}

	showIdenticalCustomerModal(customer) {
		this.approvedCustomerInfo = Object.assign({}, customer);
		this.identicalCustomerModal.show();
	}

	mapToApprovedCustomer() {
		this.deviceManagementService.mapToApprovedCustomer(this.approvedCustomerInfo.customer_id, this.approvedCustomerInfo._approved_customer_id).subscribe((res) => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingCustomers();
		});
	}
}
