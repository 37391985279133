import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-sites-group',
	templateUrl: './sites-group.component.html',
	styleUrls: ['./sites-group.component.css']
})

export class SitesGroupComponent implements OnInit {
	sitesGroupList: TableData[] = [];
	group: any = {
		name: '',
		sites_ids: [],
		users_ids: []
	};
	usersList: any;
	sitesList: any;
	currentUser: any = {};
	isEditType: boolean = false;
	SCTEmailDomain: string = this.commonService.sctEmailDomain;
	invalidField: boolean = false;
	groupNameError: string = '';

	tableConfig: TableConfig = {
		hasRowSpan: true,
		separatedCells: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'group_name', name: this.translateService.instant('site_groups.group_name'), type: 'string', hasSort: false },
		{ key: 'users_name', name: this.translateService.instant('g.recipients'), type: 'list', hasSort: false },
		{ key: 'site_customer_name', name: this.translateService.instant('g.site_name'), type: 'link', hasSort: false },
		{ key: 'actions', name: this.translateService.instant('g.action'), type: 'dropdown' }
	];

	@ViewChild('addNewGroupModal') addNewGroupModal;
	@ViewChild('deleteGroupModal') deleteGroupModal;

	constructor(
		private usersService: UsersService,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private sitesService: SitesService,
		private commonService: CommonService
	) {}


	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		this.currentUser = this.usersService.getCurrentUser();
		return this.getAllSiteGroups();
	}

	ngAfterViewInit() {
		this.addNewGroupModal.onClose.subscribe((ok) => {
			if(!ok)
				return this.hideGroupModal();
			return this.saveSitesGroup();
		});

		this.deleteGroupModal.onClose.subscribe((ok) => {
			if (!ok)
				return this.hideGroupModal();
			return this.deleteSiteGroups();
		});
	}

	getUsersAndSitesLists() {
		this.usersService.getUsersList({ domain: this.SCTEmailDomain }, {}).subscribe((res: any) => {
			if (res?.users.length)
				res.users.map(user => user.name = `${user.first_name} ${user.last_name}`);
			this.usersList = res.users;
			this.getUserSites();
		});
	}

	getUserSites() {
		this.sitesService.getUserSitesInfo({getSitesInfoOnly: true}).subscribe((res: any) => {
			this.sitesList = res?.sitesInfo.filter(site => site.is_smart_rebates) || [];
		});
	}

	getAllSiteGroups() {
		this.sitesService.getAllSiteGroups().subscribe((res: any) => {
			let groups = [];
			[...res || []].forEach((row) => {
				let rowData: TableData = {
					group_name: { value: row.name, rowspan: row.sitesInfo.length },
					users_name: { value: this.prepareUsersNameList(row.usersInfo), rowspan: row.sitesInfo.length },
				}

				if (row.sitesInfo.length) {
					row.sitesInfo.forEach((site, index) => {
						rowData['site_customer_name'] = { value: `${site.customer_name} - ${site.name}`, link:  ['/', site.customer_id, site.id] };
						if (index == 0)
							rowData['actions'] = { value: null, options: [
								{ text: this.translateService.instant('g.edit'), action: ()=> { this.showEditGroupModal(row) } },
								{ text: this.translateService.instant('g.delete'), action: ()=> { this.showDeleteGroupModal(row) } },
							], rowspan: row.sitesInfo.length };

						groups.push(rowData);
						rowData = {};
					});

				} else {
					rowData['site_customer_name'] = { value: '-'};
					rowData['actions'] = { value: null, options: [
						{ text: this.translateService.instant('g.edit'), action: ()=> { this.showEditGroupModal(row) } },
						{ text: this.translateService.instant('g.delete'), action: ()=> { this.showDeleteGroupModal(row) } },
					], rowspan: row.sitesInfo.length };
	
					groups.push(rowData);
					rowData = {};
				}
				groups.push(rowData);
			});
			this.sitesGroupList = groups;
		});

		this.getUsersAndSitesLists();
	}

	saveSitesGroup() {
		this.groupNameError = '';
		if (!this.group.name) {
			this.invalidField = true;
			return this.groupNameError =  this.translateService.instant('g.field_is_required');
		}

		this.group.sites_ids = this.group.sites_ids ?? [];
		this.group.users_ids = this.group.users_ids ?? [];

		this.sitesService.saveSiteGroups(this.group).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					this.invalidField = true;
					this.groupNameError = this.translateService.instant('g.field_is_required');
					break;
				case 3:
					this.notificationMessage.setMessage('translate|site_groups.email_group_not_exist_messg', {timeout: 20000, clearOnXTimeNavigate: 1});
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
					this.getAllSiteGroups();
					this.group = {};
					this.addNewGroupModal.hide();
					break;
			}
		});
	}

	showDeleteGroupModal(group) {
		this.deleteGroupModal.show();
		this.group = group;
	}

	showEditGroupModal(group) {
		this.isEditType = true;
		delete group.sitesInfo;
		delete group.usersInfo;
		this.group = group;
		this.group.users_ids = group.users_ids;
		this.group.sites_ids = group.sites_ids;
		this.addNewGroupModal.show();
	}

	hideGroupModal() {
		this.group = {};
		this.groupNameError = '';
		this.invalidField = false;
		this.isEditType = false;
		this.addNewGroupModal.hide();
	}

	prepareUsersNameList(usersInfo) {
		return usersInfo.map((user) => ({ text:`${user.first_name} ${user.last_name}`, link: ['/user/edit/', user.id] }));
	}

	deleteSiteGroups() {
		this.sitesService.deleteSiteGroups(this.group.id).subscribe((res: any) => {
			switch(res) {
				case 1:
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.getAllSiteGroups();
					this.group = {};
					break;
				default:
					this.notificationMessage.setMessage('globalErrMsg');
					break
			}
		});
	}
}