import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../admin.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { TableConfig, TableData, ColumnsConfig } from 'src/app/shared/custom-table/custom-table-interface';
@Component({
	selector: 'app-unused-logs',
	templateUrl: './unused-logs.component.html',
	styleUrls: []
})
export class UnusedLogsComponent implements OnInit {
	logs: any[] = [];
	columnNames: any[];
	title: string = '';

	@ViewChild("confirmModal") confirmModal: ModalComponent;

	columnConfig: ColumnsConfig[] = [
		{ key: 'field_name', name: this.translateService.instant('unused_logs.field_name'), type: 'string'},
		{ key: 'command', name: this.translateService.instant('unused_logs.command'), type: 'string'},
		{ key: 'struct', name: this.translateService.instant('unused_logs.struct'), type: 'string'},
		{ key: 'start_position', name: this.translateService.instant('unused_logs.start_position'), type: 'number'},
		{ key: 'field_length', name: this.translateService.instant('unused_logs.field_length'), type: 'number'},
		{ key: 'fw_version', name: this.translateService.instant('unused_logs.fw_version'), type: 'number'},
		{ key: 'is_bit', name: this.translateService.instant('unused_logs.is_bit'), type: 'number'},
		{ key: 'count', name: this.translateService.instant('unused_logs.count'), type: 'number'},
		{ key: 'options', name: this.translateService.instant('g.options'), type: 'dropdown'}
	];

	tableConfig: TableConfig = {
		hasPagination: true,
		pageSize: 100,
		draggable: true,
		hasExport: true,
		fileName: this.translateService.instant('unused_logs.unused_logs_title')
	};

	constructor(
		private adminService: AdminService,
		private translateService: TranslateService,
		private router: Router,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessFunction('access_error_logs')) {
			this.router.navigate(['/unauthorized']);
			return;
		}

		this.title = this.translateService.instant('unused_logs.unused_logs_title');
		// fieldName,command,struct,startPosition,fieldLength,fwVersion,isBit,count
		this.logs = [];
		this.adminService.getUnusedLogs().subscribe(
			(response: any) => {
				const tableData: TableData[] = [];
				[...response].forEach((row) => {
					let rowData: TableData = {
						row,
						field_name: {value: row.fieldName },
						command: {value: row.command},
						struct: {value: row.struct == "-" ? null : row.struct},
						start_position: {value: row.startPosition != null ? +row.startPosition : null},
						field_length: {value: row.fieldLength},
						fw_version: {value: row.fwVersion || null},
						is_bit: {value: row.isBit },
						count: {value: row.count},
						options: {value: null, options:
									[
										{ text: this.translateService.instant('g.view'), action: (data) => {this.viewRecordDetails(data.row)}}
									]}
					}
					tableData.push(rowData);
				});
				this.logs = tableData;
			}
		);
	}

	viewRecordDetails(record) {
		this.confirmModal.passData = this.logs.find((item: any) => item.row.id == record.id).row.macList;
		this.confirmModal.show();
	}
}
