import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-device-location-mismatch-report',
	templateUrl: './device-location-mismatch-report.component.html',
	styleUrls: ['./device-location-mismatch-report.component.css']
})
export class DeviceLocationMismatchReportComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 20,
		draggable: true,
		fileName: `${this.translate.instant('nav.devices_location_mismatch')} - ${moment().format('YYYY/MM/DD')}`,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: "link"},
		{ key: 'serial_number', name: this.translate.instant('g.serial_number'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: "link"},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'last_connect_time', name: this.translate.instant('devices.last_connect_time'), type: "date"},
		{ key: 'site_longitude', name: this.translate.instant('devices.site_longitude'), type: "number" },
		{ key: 'site_latitude', name: this.translate.instant('devices.site_latitude'), type: "number" },
		{ key: 'device_longitude', name: this.translate.instant('devices.device_longitude'), type: "number" },
		{ key: 'device_latitude', name: this.translate.instant('devices.device_latitude'), type: "number" },
		{ key: 'distance_m', name: this.translate.instant('devices.distance_m'), type: "number" },
	];

	lastUpdateTime: any;

	constructor(
		private usersService: UsersService,
		private deviceService: DeviceService,
		private router: Router,
		private translate: TranslateService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessFunction('device_management', 'read'))
			this.router.navigate(['/unauthorized']);

		if(window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.getDevicesLocationMismatchReport();
	}

	getDevicesLocationMismatchReport() {
		this.deviceService.getDevicesLocationMismatchReport().subscribe((response: any) => {
			const tableData = [];

			for(const device of response) {
				tableData.push({
					mac_address: {value: device.mac_address, link: ['/', device.customer_id, device.site_id, device.mac_address, 'performance']},
					serial_number: {value: device.serial_number},
					customer_name: {value: device.customer_name, link: ['/', device.customer_id]},
					site_name: {value: device.site_name, link: ['/', device.customer_id, device.site_id]},
					last_connect_time: {value: this.formatTime(device.last_connect_time), sortValue: +device.last_connect_time},
					site_longitude: {value: device.site_longitude},
					site_latitude: {value: device.site_latitude},
					device_longitude: {value: device.device_longitude},
					device_latitude: {value: device.device_latitude},
					distance_m: {value: +device.distance_m.toFixed(0)},
				})
			}

			tableData.sort((A, B) => {
				return A.customer_name.value.localeCompare(B.customer_name.value);
			});

			this.tableData = tableData;
			this.lastUpdateTime = this.formatTime(response?.lastUpdateTime);
		})
	}

	formatTime(last_connect_time: string | number | Date) {
		const fromDate = Math.round(new Date(new Date((+last_connect_time) * 1000).getTime()).getTime());
		return moment(new Date(fromDate)).format('MM/DD/YYYY HH:mm:ss');
	}
}
