<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h2>{{'sim.sim_usage_management' | translate}}</h2>
	<div class="d-flex justify-content-start my-3" *ngIf="shouldShowSimActions">
		<nav class="navbar navbar-expand-lg topnav pt-2 pb-0 px-4 light-white navcolor">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == 'usage'}"
						(click)="setActiveTab('usage')"
					>
						<h5> {{'sim_management.sims_usage' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == 'disabled'}"
						(click)="setActiveTab('disabled')"
					>
						<h5> {{'sim_management.disabled_sims' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == 'blocked'}"
						(click)="setActiveTab('blocked')"
					>
						<h5> {{'sim_management.blocked_sims' | translate}}</h5>
					</a>
				</li>
				<li>
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activeTab == 'missingFromOnce'}"
						(click)="setActiveTab('missingFromOnce')"
					>
						<h5> {{'sim_management.missing_from_1nce' | translate}}</h5>
					</a>
				</li>
			</ul>
		</nav>
	</div>
	<div *ngIf="activeTab == 'usage'">
		<div class="header-container d-flex">
			<div class="p-3">
				<div>
					<p>{{'sim.sim_management_note_page_1' | translate}}</p>
					<p>{{'sim.sim_management_note_page_2' | translate}}</p>
					<ul>
						<li>{{'sim.sim_management_note_page_2_point_1' | translate}}</li>
						<li>{{'sim.sim_management_note_page_2_point_2' | translate}}</li>
						<li>{{'sim.sim_management_note_page_2_point_3' | translate}}</li>
						<li>{{'sim.sim_management_note_page_2_point_4' | translate}}</li>
					</ul>
				</div>
			</div>
			<div class="color-mapper">
				<div *ngFor="let u of [5, 3, 1, 140]" class="color-box">
					<div class="color color-{{u}} c-b"></div>
					<p>{{'sim.sim_over_data_usage_'+u | translate}}</p>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-end">
			<div class="d-flex flex-column">
				<button type="button" class="btn btn-primary" [disabled]="isWeeklyCalculatedDataAvailable()" (click)="calculateDailyAvgData(dailyAveragePeriod.lastWeek)">{{'sim_management.daily_avg_last_week' | translate }}</button>
				<button type="button" class="btn btn-primary mt-2" [disabled]="isMonthlyCalculatedDataAvailable()" (click)="calculateDailyAvgData(dailyAveragePeriod.lastMonth)">{{'sim_management.daily_avg_last_month' | translate }}</button>
			</div>
		</div>
	
		<div class="date-range">
			<button class="btn pt-0" [disabled]="disableBackCalender" (click)="handleCalendarButtons(false)">
				<i class="fa fa-lg pointer fa-arrow-left main-blue" title="Back"></i>
			</button>
		
			<div class="input-group p-0 mr-3 col-3">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'invalid-input': invalidStartDate}" [minDate]="minDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="selectedDate" (ngModelChange)="datesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		
			<button class="btn mr-1 pt-0" [disabled]="disableNextCalender" (click)="handleCalendarButtons(true)">
				<i class="fa fa-lg pointer fa-arrow-right main-blue" title="Next"></i>
			</button>
		</div>

		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="toShowDevices"
			[config]="tableConfig"
			(updateSelectedRows)="selectICCID($event)">
		</sct-custom-table>
	</div>
	<div *ngIf="activeTab == 'disabled'">
		<div *ngIf="disabledSims.length" class="d-flex justify-content-end my-3">
			<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDisabledICCIDs.length" (click)="showUpdateConfigPopup('enable', true)">
				{{'g.enable' | translate}}
			</button>
		</div>
	
		<sct-custom-table
			[columnsConfig]="disabledSimsColumnsModal"
			[data]="disabledSims"
			[config]="disabledSimsTableConfig"
			(updateSelectedRows)="selectDisabledICCID($event)">
		</sct-custom-table>
	</div>
	
	<div *ngIf="activeTab == 'blocked'">
		<div class="alert alert-info" role="alert">
			{{'sim_management.blocked_sims_note' | translate}}
		</div>
		<div *ngIf="blockedSims.length" class="d-flex justify-content-end my-3">
			<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedBlockedICCIDs.length" (click)="showBlockedSimsConfirmationPopup()">
				{{'g.unblock' | translate}}
			</button>
		</div>
	
		<sct-custom-table
			[config]="disabledSimsTableConfig"
			[data]="blockedSims"
			[columnsConfig]="blockedSimsColumnModal"
			(updateSelectedRows)="selectBlockedICCID($event)">
		</sct-custom-table>
	</div>
	
	<div *ngIf="activeTab == 'missingFromOnce'">
		<app-missing-sims></app-missing-sims>
	</div>
</div>

<app-modal [hasConfirmButton]="false" [closeBtnText]="'g.close' | translate" #tableDataPopup title="{{'sim_management.usage_history' | translate}}" size="xl">
	<div *ngIf="selectedDeviceData">
		<p><span><strong>{{'devices.serial_number' | translate}}: </strong></span>{{selectedDeviceData.serial_number}}<span></span></p>
		<p><span><strong>{{'qv.cellular_iccid' | translate}}: </strong></span>{{selectedDeviceData.cellular_iccid}}<span></span></p>
		<p><span><strong>{{'g.customer_name' | translate}}: </strong></span><span><a [routerLink]="['/', selectedDeviceData.customer_id]">{{selectedDeviceData.customer_name}}</a></span></p>
		<p><span><strong>{{'g.site_name' | translate}}: </strong></span><span><a [routerLink]="['/', selectedDeviceData.customer_id, selectedDeviceData.site_id]">{{selectedDeviceData.site_name}}</a></span></p>
	</div>
	<div class="row col-md-12 mb-5">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="popupFromData" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="showUsageHistory(selectedDeviceData, false)" [ngClass]="{'not_valid': notValidPopupDate}">
				<div class="input-group-append pointer" (click)="d1.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="popupToData" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="showUsageHistory(selectedDeviceData, false)" [ngClass]="{'not_valid': notValidPopupDate}">
				<div class="input-group-append pointer" (click)="d2.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<p *ngIf="notValidPopupDate" class="text-error-popup">{{'g.start_date_before_end_date' | translate}}</p>
	</div>
	<div *ngIf="chartObject.data?.length">
		<sct-charts
			[data]="chartObject.data"
			[columnNames]="chartObject.columnNames"
			[options]="chartObject.config"
			[formatter]="chartObject.formatter"
			[formatterType]="chartObject.formatterType"
			[title]="'sim_management.data_usage' | translate"
			[type]="'LineChart'"
			[height]="500"
		></sct-charts>
	</div>

	<sct-custom-table
		[columnsConfig]="historyColumnModal"
		[data]="toShowUsageHistory"
		[config]="historyTableConfig"
		(updateSelectedRows)="selectICCID($event)">
	</sct-custom-table>
</app-modal>

<app-modal [hasCloseButton]="false" [hasConfirmButton]="false" #blockedSimsConfirmationPopup title="{{'sim_management.blocked_sims' | translate}}" size="xl">
	<h5>{{'sim_management.unblock_sims_confirmation' | translate}}</h5>
	<table class="table table-striped">
		<thead>
			<th>{{'qv.cellular_iccid' | translate}}</th>
			<th>{{'g.unblock_status' | translate}}</th>
		</thead>
		<tbody>
			<tr *ngFor="let iccid of selectedBlockedICCIDs">
				<td>{{iccid}}</td>
				<td>
					<div *ngIf="blockActionDone && doneBlockICCIDs.includes(iccid)" class="done"><fa-icon icon="check-circle" size="lg"></fa-icon></div>
					<div *ngIf="blockActionDone && !doneBlockICCIDs.includes(iccid)" class="failed"><fa-icon icon="times-circle" size="lg"></fa-icon></div>
					<div *ngIf="!blockActionDone">-</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="text-area">
		<label for="reason">{{'g.reason' | translate}}*</label>
		<textarea name="reason" id="reason" rows="10" [(ngModel)]="unblockReason"></textarea>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="blockedSimsConfirmationPopup.onHide()">{{'g.close' | translate}}</button>
			<button [disabled]="unblockReason == '' || blockActionDone" class="btn btn-primary me-2" (click)="confirmUnblock()">{{'g.confirm' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	#exportFilePopup title="{{'g.export_to_csv' | translate}}"
	size="lg"
>
	<div class="row col-md-12 mb-5">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp3" [(ngModel)]="fromDate" bsDatepicker #d3="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="checkDateValidation()" [ngClass]="{'not_valid': notValidDate}">
				<div class="input-group-append pointer" (click)="d3.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp4" [(ngModel)]="toDate" bsDatepicker #d4="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="checkDateValidation()" [ngClass]="{'not_valid': notValidDate}">
				<div class="input-group-append pointer" (click)="d4.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>
	<p *ngIf="notValidDate" class="text-error">{{'g.start_date_before_end_date' | translate}}</p>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light" (click)="exportFilePopup.hide()">{{'g.close' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	#actionsConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="xl"
>
	<p>{{('sim.confirmation_massage_1' | translate)+ " " + operation + " " + ('sim.confirmation_massage_2' | translate)}}</p>
	<ul>
		<li *ngFor="let iccid of ICCIDs">{{iccid}}</li>
	</ul>
	<div class="text-area">
		<label for="reason">{{'g.reason' | translate}}*</label>
		<textarea name="reason" id="reason" rows="10" [(ngModel)]="actionReason"></textarea>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="actionsConfirmationPopup.onHide()">{{'g.close' | translate}}</button>
			<button [disabled]="actionReason == ''" class="btn btn-primary me-2" (click)="actionsConfirmationPopup.onConfirm()">{{'g.confirm' | translate}}</button>
		</div>
	</div>
</app-modal>
