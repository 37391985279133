import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from '../services/common.service';
import * as moment from 'moment';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { ColumnsConfig, TableConfig, TableData } from '../custom-table/custom-table-interface';
import { DatePipe } from '@angular/common';
import { UsersService } from 'src/app/users/users.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-warnings-history-table',
	templateUrl: './warnings-history-table.component.html',
	styleUrls: ['./warnings-history-table.component.css']
})
export class warningsHistoryTableComponent implements OnInit {
	@ViewChild("infoDialog") infoDialog: any;
	@Input() siteId?: number;
	@Input() customerId?: number;
	permissionSub: Subscription = new Subscription();

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		fileName: "warning",
		hideNoData: true,
	};

	columnConfig: ColumnsConfig[] = [];

	site_name = '';
	customer_name = '';

	// global warning filter
	doneFilter = false;
	warningsList = [];
	selectedWarnings = null;
	customerList = [];
	eventList = [];
	customerDevicesList = [];
	customersDevicesObject = {};
	selectedDevice: any = null;
	filter = {
		warnings: null,
		fromDate: null,
		toDate: null,
		status: 'current_warnings',
		customers: null,
		devices: []
	}

	infoHeader = "";
	filterOptions: {from: Date | null, to: Date | null} = {from: null, to: null};

	fromDate: Date;
	toDate: Date;
	showDateRange: string;
	invalidDateRange: boolean = false;;

	currentUser: any = {};
	enterprisePermissions: any = {};
	dateFields = ['timestamp'];
	dateTimeFields = ['trigger_time', 'clear_time'];
	clickableFields = ['triggered_qv_values', 'cleared_qv_values', 'acknowledge'];
	hiddenFields = ['id', 'site_id', 'customer_id', 'mac_address'];
	floutFields = ['voltage', 'charge_kwhr', 'inuse_kwhr', 'current', 'min_voltage', 'event_max_current', 'ext_adc_voltage', 'ext_adc_current', 'ext_adc_current_low_range'];
	booleanFields = ['low_range_is_inuse'];

	statusMapValue: any = {
		wifi_status: 'wifi_ip',
		eth_status: 'eth_ip'
	};

	triggeredQVValues: {
		customer_name: string,
		site_name: string,
		serial_number: string,
		warning_text: string,
		warning: string
	} = {
		customer_name: '',
		site_name: '',
		serial_number: '',
		warning_text: '',
		warning: ''
	};
	noQVData = false;

	qvFields: {
		[key: string]: string[];
	} = {
		main: ['hw_status', 'wifi_status', 'voltage', 'current', 'low_range_is_inuse', 'ext_adc_voltage', 'ext_adc_current', 'ext_adc_current_low_range'],
		live_event: ['sequence_id', 'timestamp', 'min_voltage', 'event_max_current', 'charge_kwhr', 'inuse_kwhr'],
		live_rt: ['sequence_id', 'voltage', 'current'],
	}

	warningJustHasLiveEvents = [
		'missing_midnight_split',
		'incorrect_events_limit_exceeded',
		'possible_inaccurate_events_thresholds',
		'long_event',
		'charge_long_event',
		'over_lapping_event',
		'abnormal_ah',
		'charge_events_exist',
		'inuse_events_exist',
		'missing_midnight_split',
	];

	warningWithoutQV = ['hall_effect_sensor_failure', 'no_kwh'];

	warningStatus = {
		open: 1,
		close: 2,
		acknowledged: 3
	}

	allColumns: { key: string }[] = [
		{
			key: 'id',
		},
		{
			key: 'serial_number'
		},
		{
			key: 'customer_name'
		},
		{
			key: 'site_name'
		},
		{
			key: 'warning_text'
		},
		{
			key: 'trigger_time'
		},
		{
			key: 'clear_time'
		},
		{
			key: 'cleared_qv_values'
		},
		{
			key: 'acknowledge'
		},
	];

	tableFilter: 'current_warnings'  | 'period' = 'current_warnings';

	tableFilterList = [
		{id: 'current_warnings', name: this.translateService.instant('warnings.current_warnings')},
		{id: 'period', name: this.translateService.instant('warnings.period')}
	];

	excludePermissionWarnings = ['lost_rtc', 'password', 'disconnected_device', 'invalid_device_zone', 'no_setup'];

	constructor(
		private customersService: CustomersService,
		private deviceService: DeviceService,
		private deviceManagementService: DeviceManagementService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private notificationMessage: NotificationMessageService,
		private datePipe: DatePipe,
		public usersService: UsersService,
	) {
		this.fromDate =  moment().subtract(6, 'day').toDate();
		this.toDate = new Date();
	}

	ngOnInit() {
		if (this.siteId) {
			this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
				this.enterprisePermissions = permissions;
				this.fillWarningTable();
			});
		} else {
			this.enterprisePermissions = this.usersService.getCurrentUser().permissions;
			this.prepareFilters();
		}
	}

	fillWarningTable() {
		this.getWarnings();
		this.buildGrid();
	}

	prepareFilters() {
		this.warningsList = this.deviceService.getWarningsList();
		this.customersService.getCustomers({}).subscribe((data: any) => {
				const customerList = [];

				for(let customer of data) {
					customerList.push({key: customer.id , value: customer.customer_name});
				}
				this.customerList = customerList;
			}
		);
	}

	selectCustomer() {
		const existCustomers = Object.keys(this.customersDevicesObject).map(value => +value);

		if (existCustomers.length > this.filter.customers.length)
			return this.removeCustomer(existCustomers)

		// add new customer
		const newCustomer = this.filter.customers.filter(customer => !existCustomers.includes(customer));
		if (!newCustomer.length)
			return;

		this.customersService.getCustomer(newCustomer, {get_devices_counts: true, without_analytics: true, include_zombie_sites: true}).subscribe((data: any) => {
				let customerDevicesList = [];

				for (let device of data.devices) {
					customerDevicesList.push({key: device.mac_address, value: device.serial_number});
				}

				this.customerDevicesList = [...this.customerDevicesList, ...customerDevicesList];
				this.customersDevicesObject[newCustomer] = customerDevicesList;
			}
		);
	}

	removeCustomer(existCustomers: any[]) {
		const removeCustomer = existCustomers.filter(customer => !this.filter.customers.includes(customer));
		if (!removeCustomer.length)
			return;

		const removeCustomerDevice = this.customersDevicesObject[removeCustomer[0]].map(device => device.key);
		delete this.customersDevicesObject[removeCustomer[0]];
		this.customerDevicesList = this.customerDevicesList.filter(device => !removeCustomerDevice.includes(device.key));
		this.selectedDevice = this.selectedDevice?.length ? this.selectedDevice.filter((device: any) => !removeCustomerDevice.includes(device)) : null;

		if (existCustomers.length == 1)
			this.filter.customers = null;

		return this.filter.devices = this.customerDevicesList.filter(device => this.selectedDevice.includes(device.key));
	}

	addDevice() {
		if (this.filter.customers)
			this.filter.devices = this.customerDevicesList.filter(device => this.selectedDevice.includes(device.key));

		else {
			this.deviceManagementService.searchDevices(this.selectedDevice, 'sn', true, false).subscribe((data: any) => {
				if(data.devices.length == 1)
					return this.filter.devices = [...this.filter.devices, {key: data.devices[0].mac_address, value: data.devices[0].serial_number}];

				this.notificationMessage.setMessage(this.translateService.instant('devices.selected_devices_not_exist') ,{clearOnXTimeNavigate: 1});
			});
		}
	}

	getWarnings() {
		let filter: any = {};
		filter.siteId = this.siteId ? this.siteId : null;
		let from = this.fromDate;
		let to = this.toDate;
		let getCurrentWarning = this.tableFilter == 'current_warnings';

		if (!filter.siteId) {
			filter = {...this.filter};
			filter.devices = filter.devices.map(device => device.key);
			from = this.filter.fromDate;
			to = this.filter.toDate;
			getCurrentWarning = this.filter.status == 'current_warnings';
		}

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(from).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(to).getTime() + (zoneDiff * 60 * 1000));
		filter.fromDate = moment(fromDate).utc().startOf('day').unix();
		filter.toDate = moment(toDate).utc().endOf('day').unix();
		filter.getCurrentWarning = getCurrentWarning;
		let ignorePermissionList = this.deviceService.excludePermissionWarnings();

		this.deviceService.getWarnings(filter).subscribe((res: any) => {
			this.site_name = res.site_name;
			this.customer_name = res.customer_name;

			this.tableConfig.fileName = this.site_name + 'warning';

			const tableData = [];
			for(const warning of res.warnings) {

			if((ignorePermissionList.includes(warning.warning)) || this.usersService.hasAccessPermission({permission:this.enterprisePermissions.permission}, 'warnings.'+warning.warning))
				tableData.push({
					id: warning.id,
					serial_number: {value: warning.serial_number, link: ['/', warning.customer_id, warning.site_id, warning.mac_address, 'performance']},
					customer_name: {value: warning.customer_name, link: ['/', warning.customer_id]},
					site_name: {value: warning.site_name, link: ['/', warning.customer_id, warning.site_id]},
					warning_text: {value: this.translateService.instant('warnings.' + warning.warning)},
					trigger_time: {value: this.datePipe.transform(warning.trigger_time, 'yyyy-MM-dd HH:mm:ss')},
					clear_time: {value: warning.clear_time ? this.datePipe.transform(warning.clear_time, 'yyyy-MM-dd HH:mm:ss') : null},
					cleared_qv_values: {value: warning.serial_number, icon: 'info', iconColor: 'blue', action: ()=> {this.prepareQVValues(warning)}},
					acknowledge: {value: warning.serial_number, icon: warning.status == this.warningStatus.close ? 'eye-slash': null, iconColor: 'blue', action: ()=> {this.acknowledgeAction(warning)}}
				})
			}

			this.tableData = tableData;
			this.doneFilter = true;
		})
	}

	buildGrid() {
		this.columnConfig = [];
		this.columnConfig.push({key: 'serial_number', name: this.translateService.instant('warnings.serial_number'), type: "link"});

		if (!this.siteId) {
			this.columnConfig.push(
				{key: 'customer_name', name: this.translateService.instant('warnings.customer_name'), type: "link"},
				{key: 'site_name', name: this.translateService.instant('warnings.site_name'), type: "link"}
			)
		}

		this.columnConfig.push(
			{key: 'warning_text', name: this.translateService.instant('warnings.warning_text'), type: "string"},
			{key: 'trigger_time', name: this.translateService.instant('warnings.trigger_time'), type: "date"},
			{key: 'clear_time', name: this.translateService.instant('warnings.clear_time'), type: "date"},
			{key: 'cleared_qv_values', name: this.translateService.instant('warnings.cleared_qv_values'), type: "icon"},
			{key: 'acknowledge', name: this.translateService.instant('warnings.acknowledge'), type: "icon"},
		)
	}

	prepareQVValues(row: any) {
		this.infoDialog.show();
		this.infoHeader = this.translateService.instant('warnings.cleared_qv_values');
		const data = _.cloneDeep(row);
		this.setDialogData(data);
	}

	setDialogData(data: any) {
		this.eventList = [];
		this.triggeredQVValues['serial_number'] = data.serial_number;
		this.triggeredQVValues['customer_name'] = data.customer_name || this.customer_name;
		this.triggeredQVValues['site_name'] = data.site_name || this.site_name;
		this.triggeredQVValues['warning_text'] = this.translateService.instant('warnings.' + data.warning);
		this.triggeredQVValues['warning'] = data.warning;

		const tempQVFields = _.cloneDeep(this.qvFields);

		if ( !data.triggered_qv_values && !data.cleared_qv_values ) {
			this.noQVData = true;
			return
		}

		// using charge_kwhr for charger device and inuse_kwhr for iotah device
		if (data.is_charglink)
			tempQVFields['live_event'].splice(tempQVFields['live_event'].indexOf('inuse_kwhr'), 1);
		else
			tempQVFields['live_event'].splice(tempQVFields['live_event'].indexOf('charge_kwhr'), 1);

		if (this.warningJustHasLiveEvents.includes(data.warning) && data?.triggered_qv_values?.live_event?.event_list)
			this.eventList = data.triggered_qv_values.live_event.event_list;

		let dataObjectArray = []

		dataObjectArray = [
			{key: 'main', trigger_object: data.triggered_qv_values, clear_object: data.cleared_qv_values},
			{key: 'live_event', trigger_object: data.triggered_qv_values?.live_event, clear_object: data.cleared_qv_values?.live_event},
			{key: 'live_rt', trigger_object: data.triggered_qv_values.live_rt, clear_object: data.cleared_qv_values?.live_rt},
		];

		for(const object of dataObjectArray) {
			this.triggeredQVValues[object.key] = [];

			if (object.key != 'live_event' && this.warningJustHasLiveEvents.includes(data.warning))
				continue;

			tempQVFields[object.key].forEach(field => {
				const fieldData = {
					field_name: field,
					trigger_value: this.formatData(object.trigger_object, field),
					clear_value: this.formatData(object.clear_object, field)
				};
				this.triggeredQVValues[object.key].push(fieldData);
			})
		}
	}

	formatData(object, field) {
		let returnValue = null;
		if (!(object && field in object))
			return returnValue;

		returnValue = object[field];

		if (['wifi_status'].includes(field))
			returnValue = this.getStaStatus(object[field], object[this.statusMapValue[field]]);

		else if (['hw_status'].includes(field))
			returnValue = this.getHwStatus(object[field]);

		else if (this.floutFields.includes(field))
			returnValue = (object[field]).toFixed(2);

		else if (this.booleanFields.includes(field))
			returnValue = !!(object[field]);

		else if (this.dateFields.includes(field))
			returnValue = this.commonService.getDateFormattedFromUnixTimeStamp(object[field]);

		return returnValue;
	}

	acknowledgeAction(row: any) {
		this.deviceService.acknowledgeWarnings(row.warning, row.mac_address).subscribe((data) => {
			if (data) {
				if(this.tableFilter == 'current_warnings') {
					this.tableData = this.tableData.filter((data) => data.id !== row.id)
					this.buildGrid();
				} else {
					this.getWarnings();
				}
			}
		})
	}

	onFilterChange() {
		if(this.tableFilter == 'current_warnings')
			this.filterOptions = {from: null, to: null};

		this.getWarnings();
	}

	datesChanged() {
		this.invalidDateRange = false;
		if(moment(this.fromDate).startOf('day').unix() > moment(this.toDate).endOf('day').unix())
			this.invalidDateRange = true;

		if (!this.invalidDateRange && this.fromDate && this.toDate)
			this.getWarnings();
		else
			this.notificationMessage.setMessage(this.translateService.instant('g.start_date_before_end_date') ,{clearOnXTimeNavigate: 1});
	}

	changeFilterDate() {
		if (this.filter.fromDate && this.filter.toDate && moment(this.filter.fromDate).startOf('day').unix() > moment(this.filter.toDate).endOf('day').unix())
			this.notificationMessage.setMessage(this.translateService.instant('g.start_date_before_end_date') ,{clearOnXTimeNavigate: 1});
	}

	getHwStatus(value: number) {
		let statuses = {
			1: 'warnings.plc',
			2: 'warnings.rtc',
			4: 'warnings.adc',
			8: 'warnings.flash_size',
			16: 'warnings.cellular_chip_hardware_failure'
		};

		let status = [];

		for(let id in statuses) {
			if((value & +id) != 0) {
				status.push(this.translateService.instant(statuses[id]))
			}
		}

		return status.join(', ');
	}

	getStaStatus(status: number, ip: string) {

		let STA_STATUS_STARTED = 0,
		STA_STATUS_STOPPED = 1,
		STA_STATUS_CONNECTTING = 2,
		STA_STATUS_CONNECTED = 3,
		STA_STATUS_GOT_IP = 4,
		STA_STATUS_DISOCNNECTED = 5,
		STA_STATUS_AFTER_IP = 6,
		STA_STATUS_DISOCNNECTING = 7,
		STA_STATUS_DIS = 8,
		STA_STATUS_MAX = 9;

		if(status == STA_STATUS_AFTER_IP && ip != "0.0.0.0"){
			return this.translateService.instant('qv.sta_connected')+' '+ip;
		}

		return this.translateService.instant('qv.sta_on');
	}

	isArray(value: any) {
		return Array.isArray(value);
	}

	ngOnDestroy() {
		this.permissionSub.unsubscribe();
	}
}
