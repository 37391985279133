import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteDashboardService } from '../site-dashboard.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceService } from '../device/device.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { TableConfig, ColumnsConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-site-info',
	templateUrl: './site-info.component.html',
	styleUrls: ['./site-info.component.css']
})
export class SiteInfoComponent implements OnInit, OnDestroy {
	siteInfo: any = {};
	permissions: any = {};

	devices = [];
	IoTAhDevices = [];
	chargeLinkDevices = [];
	dots = [];

	uniqueIPaddresses = [];
	showOnlyConnected = false;
	liveSiteTimeInterval;
	liveSiteTime;
	zoneName;
	currentUser: any = {};
	whrsUnit;
	whrsUsed;
	ahrsUnit;
	ahrsUsed;
	hoursUsed;
	infoBoxSiteAnalytics: any;

	siteDevicesAnalytics: any = {};

	isProduction: boolean = false;

	minDate!: Date;
	minDateChargersDevices!: Date;

	monthsOption: any[] = [];
	chargLinkQuartersOption: any[] = ['All-Year', 'Q1', 'Q2', 'Q3', 'Q4'];

	yearsOption: any[] = [];
	chargLinkYearsOption: any[] = [];

	// Quarterly Charge Usage Report ( IoTAh )
	quarterData: any;
	monthData: any;
	quartersList: any[] = [];
	monthsList: any[] = [];
	truckYearsList: any[] = [];

	truckUsageReportTableConfig = {
		column: [] as ColumnsConfig[],
		data: [],
		config: {
			hasExport: false,
			hasPagination: false,
			draggable: true
		} as TableConfig
	};

	installedIotahDeviceTableConfig = {
		column: [] as ColumnsConfig[],
		data: [],
		config: {
			hasExport: true,
			hasPagination: false,
			draggable: true,
			fileName: this.translateService.instant('site.site_devices')
		} as TableConfig
	};

	installedChargerDeviceTableConfig = {
		column: [] as ColumnsConfig[],
		data: [],
		config: {
			hasExport: true,
			hasPagination: true,
			draggable: true,
			pageSize: 100,
			fileName: this.translateService.instant('chargLinkDevice.site.site_devices')
		} as TableConfig
	};

	chargLinkQuarterlyTableConfig = {
		column: [] as ColumnsConfig[],
		data: [],
		config: {
			hasExport: false,
			hasPagination: true,
			draggable: true,
			pageSize: 100
		} as TableConfig
	};

	selectedMonthData: any[];
	selectedYearQ: any;
	selectedQuarter: any;
	selectedMonth: any;
	selectedTruckYear: any = 'all';

	// Quarterly Charge Usage Report ( ChargLink )
	chargLinkQuarterlyReportCols: any[] = [];
	chargLinkQuarterData: any;
	chargLinkQuartersList: any[] = [];

	charglinkSelectedQuarterData: any[];
	chargLinkSelectedQuarter: any;
	chargLinkSelectedYear: any;

	contactInfo: any[] = [];
	isChargeLink = false;
	tooltips = {
		quarterly_site_report: '',
		chargLink_quarterly_site_report: '',
		site_devices: '',
		chargLink_site_devices: '',
		site_info: '',
		chargLink_site_info: ''
	};

	tags: any[];
	deviceTags: any[];
	infoBox;

	status = {
		failed: this.translateService.instant(`g.failed`),
		passed: this.translateService.instant(`g.passed`),
		not_tested: this.translateService.instant(`g.not_tested`),
	}

	permissionsSub: Subscription = new Subscription();

	selectedDataOption = 'since_installation';

	siteId!: number;
	siteZoneId!: number;
	selectedYearUsageReport!: number;
	chargLinkSelectedYearUsageReport!: number;
	currentSiteName!: string;
	currentSiteAddress!: string;
	siteUTCDifference = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private siteDashboardService: SiteDashboardService,
		private common: CommonService,
		private commonData: CommonDataService,
		public usersService: UsersService,
		public sitesService: SitesService,
		public deviceService: DeviceService,
		private notificationMessage: NotificationMessageService,
		public sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.isProduction = this.commonData.productionDomain.includes(window.location.hostname);

		let fieldsAccess: any = {};
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data => {
			for (let key in this.tooltips) {
				this.tooltips[key] = this.common.getTooltipKey(key, this.usersService.userHasNOCAccess(data));
			}
			fieldsAccess = {
				truck_manufacturer: this.usersService.hasAccessPermission(data, 'truck_manufacturer'),
				last_pm_date: this.usersService.hasAccessPermission(data, 'last_pm_date'),
				billed_kwhr: this.usersService.hasAccessFunction('billed_kwhrs_settings'),
				charger_max_power: this.usersService.hasAccessPermission(data, 'basic_setup')
			};
		});

		this.usersService.currentUser.subscribe((user: any) => {
			if (user && user.id) {
				this.currentUser = user;
			}
		});

		const siteId = parseInt(this.route.snapshot.params.siteId);
		const customerId = parseInt(this.route.snapshot.params.customerId);

		if (!siteId || isNaN(siteId)) {
			return this.router.navigateByUrl('/404');
		}

		this.fillQuarterlyReportCols(fieldsAccess);
		this.fillChargLinkQuarterlyReportCols(fieldsAccess);

		this.truckYearsList = [];
		for (let item of ['all', 'new', 'old']) {
			this.truckYearsList.push({
				value: item,
				label: this.translateService.instant('site_performance.truck_year_' + item)
			});
		}

		this.siteDashboardService.getSiteData(siteId).subscribe((data: any) => {
			this.handleIotahUsageReportSelectDate(data.site.devices_min_installation_date || 0);
			this.handleChargersUsageReportSelectDate(data.site.devices_min_installation_date_chargLink || 0);

			this.siteInfo = this.common.decompress(data.site, 'site');
			this.currentSiteName = this.siteInfo.name;
			this.currentSiteAddress = this.siteInfo.address;
			this.siteId = this.siteInfo.id;
			this.siteZoneId = this.siteInfo.zone_id;
			this.sitesService.addSitesConnectivityStatusIcons([this.siteInfo]);
			this.devices = this.formatDeviceFields(data.device, data.permissions);
			this.siteDevicesAnalytics = data.siteDevicesAnalytics;
			this.siteInfo.devices_count = this.devices.length;
			this.permissions = this.common.revertPermissionValue(data.permissions);
			if (this.siteInfo.contact_name)
				this.contactInfo.push(this.siteInfo.contact_name);
			if (this.siteInfo.email)
				this.contactInfo.push(this.siteInfo.email);
			if (this.siteInfo.phone_num)
				this.contactInfo.push(this.siteInfo.phone_num);

			this.liveSiteTimeInterval = setInterval(() => {
				this.liveSiteTime = this.common.getDateFormattedFromUnixTimeStamp(this.common.getZoneTimestampFromUTC(this.siteInfo['zone_id'], moment().utc().unix()));
			}, 500);

			this.zoneName = this.common.getZoneName(this.siteInfo['zone_id']);
			this.showOnlyConnectedIPs(this.showOnlyConnected);

			// separate IoTAh devices from ChargLink devices
			this.filterDevices();

			this.prepareDevicesGridCols();
			this.prepareChargeLinkDevicesGridCols(fieldsAccess);

			this.infoBox = data.infoBox;
			this.setInfoBoxSiteAnalyticsData();

			this.prepareQuarterReport(this.siteDevicesAnalytics.usageData.qReport, false);

			this.prepareQuarterReport(this.siteDevicesAnalytics.usageData.qReport, true);

			this.prepareTags(data.siteTags);

			this.siteInfo.iotah_devices_count = this.infoBoxSiteAnalytics.ioTAh_devices.count;
			this.siteInfo.chargLink_devices_count = this.infoBoxSiteAnalytics.chargLink_devices.count;
			this.setMapDotes();
		});
	}

	setMapDotes() {
		this.dots.push({...this.siteInfo, type: 'site'});

		for (const device of this.devices) {
			if (device.last_update_location_time) {
				this.dots.push({
					latitude: device.last_latitude,
					longitude: device.last_longitude,
					type: 'device',
					truck_id: device.truck_id,
					truck_sn: device.truck_sn,
					mac_address: device.mac_address,
					site_id: this.siteInfo.id,
					customer_id: this.siteInfo.customer_id,
					serial_number: device.serial_number,
					is_charglink: device.is_charglink,
					color: device.is_charglink ? '#a5d662' : 'orange',
					group: true,
				})
			}
		}
	}

	handleIotahUsageReportSelectDate(minInstallationDate) {
		const currentYear = moment().year();
		this.selectedYearUsageReport = currentYear;
		const minDate = moment((minInstallationDate) * 1000)
		const startYear = minDate.year();
		this.minDate = minDate.toDate();

		const yearsOption = [];
		for (let year = currentYear; year >= startYear; year--) {
			yearsOption.push(year);
		}
		this.yearsOption = yearsOption;
	}

	handleChargersUsageReportSelectDate(minInstallationDate: any) {
		const currentYear = moment().year();
		this.chargLinkSelectedYearUsageReport = currentYear;
		const minDateChargersDevices = moment((minInstallationDate) * 1000)
		const startYear = minDateChargersDevices.year();
		this.minDateChargersDevices = minDateChargersDevices.toDate();

		const chargLinkYearsOption = [];
		for (let year = currentYear; year >= startYear; year--) {
			chargLinkYearsOption.push(year);
		}
		this.chargLinkYearsOption = chargLinkYearsOption;
	}

	getListQuarters(selectedYear: number, isChargeLink = false) {
		let minDate = this.minDate;
		if (isChargeLink)
			minDate = this.minDateChargersDevices;

		if (selectedYear === moment().year() || selectedYear === moment(minDate).year()) {
			const quarters: string[] = ['All-Year'];
			for (let quarter = 1; quarter <= 4; quarter++) {
				if ((selectedYear === moment().year() && quarter > moment().quarter()) || (quarter < moment(minDate).quarter() && selectedYear === moment(minDate).year()))
					continue;
				quarters.push(`Q${quarter}`);
			}
			return quarters;
		}
		return ['All-Year', 'Q1', 'Q2', 'Q3', 'Q4'];
	}

	getListMonths(selectedYear: number) {
		const months: any[] = [{
			value: 'All-Year',
			label: this.translateService.instant('g.all_year')
		}];
		if (+selectedYear === moment().year()) {
			for (let month = 0; month <= moment().month(); month++) {
				months.push({
					value: month + 1,
					label: moment().month(month).format('MMMM')
				});
			}
			this.selectedMonth = (months.filter(month => month.label == moment().format('MMMM')))[0].value;
			return months;
		}

		const allMonths = moment.months();
		let monthCounter = 1;
		for (const month of allMonths) {
			months.push({value: monthCounter++, label: month})
		}

		this.selectedMonth = months[1].value;
		return months;
	}

	fillChargLinkQuarterlyReportCols(fieldsAccess: any) { // to be check please
		let chargLinkQuarterCol: ColumnsConfig[] = [
			{ key: 'truck_sn', name: this.translateService.instant('chargLinkDevice.devices.truck_sn'), type: 'link' },
			{ key: 'truck_id', name: this.translateService.instant('chargLinkDevice.devices.truck_id'), type: 'string' },
			{ key: 'truck_type', name: this.translateService.instant('chargLinkDevice.devices.truck_type'), type: 'string' },
		];

		if (fieldsAccess.charger_max_power)
			chargLinkQuarterCol.push({ key: 'max_charger_kw', name: this.translateService.instant("chargLinkDevice.devices.charger_max_power"), type: 'number' });

		chargLinkQuarterCol.push(
			{	key: 'total_hours', name: this.translateService.instant("site.total_hours"), type: 'number' },
			{	key: 'total_ahr', name: this.translateService.instant("site.total_ahr"), type: 'number' },
			{	key: 'total_kwhr', name: this.translateService.instant("chargLinkDevice.site.total_kwhr"), type: 'number' })

		if (fieldsAccess.truck_manufacturer)
			chargLinkQuarterCol.push({ key: "truck_manufacturer", name: this.translateService.instant("chargLinkDevice.devices.truck_manufacturer"), type: 'string' });
		this.chargLinkQuarterlyTableConfig.column = chargLinkQuarterCol;
	}

	fillQuarterlyReportCols(fieldsAccess: any) {
		let column: ColumnsConfig[] = [];
		column = [
			{ key: 'truck_sn', name: this.translateService.instant('devices.truck_sn'), type: 'link' },
			{ key: 'truck_id', name: this.translateService.instant('devices.truck_id'), type: 'string' },
			{ key: 'truck_type', name: this.translateService.instant('devices.truck_type'), type: 'string' },
			{ key: 'total_hours', name: this.translateService.instant('site.total_hours'), type: 'number' },
			{ key: 'total_ahr', name: this.translateService.instant('site.total_ahr'), type: 'number' },
			{ key: 'total_kwhr', name: this.translateService.instant('site.total_kwhr'), type: 'number' },
			{ key: 'truck_year', name: this.translateService.instant('site.new_old'), type: 'boolean', hidden: true, filterFalseText: this.translateService.instant('g.old'), filterTrueText: this.translateService.instant('g.new') }
		];

		if (fieldsAccess.truck_manufacturer)
			column.push({ key: 'truck_manufacturer', name: this.translateService.instant("devices.truck_manufacturer"), type: 'string' });

		column.push({ key: "truck_manufacturing_year", name: this.translateService.instant("devices.truck_manufacturing_year"), type: 'date' });

		if (fieldsAccess.last_pm_date)
			column.push({ key: "last_pm_date", name: this.translateService.instant("site.last_pm_date"), type: 'date' });

		if (fieldsAccess.billed_kwhr)
			column.push({ key: "billed_kwhr", name: this.translateService.instant("site.billed_kwhr"), type: 'number' });

		this.truckUsageReportTableConfig.column = column;
	}

	prepareDevicesGridCols() {
		let devicesGridCols: ColumnsConfig[] = [
			{ key: "serial_number", name: this.translateService.instant("g.iotah_sn"), type: 'string' },
		];

		if (this.usersService.hasAccessPermission({ permission: this.permissions }, 'noc'))
			devicesGridCols.push({ key: "fw_version", name: this.translateService.instant("devices.fw_version"), type: 'number' });

		devicesGridCols.push(
			{ key: 'truck_sn', name: this.translateService.instant('devices.truck_sn'), type: 'link'},
			{ key: "truck_type", name: this.translateService.instant("devices.truck_type"), type: 'string' },
			{ key: "mac_address", name: this.translateService.instant("devices.unique_mac_address"), type: 'string', hidden: true },
			{ key: "hw_version", name: this.translateService.instant("devices.hw_version"), type: 'string', hidden: true },
			{ key: "ip_address", name: this.translateService.instant("g.ip_address"), type: 'string' },
			{ key: "_voltage", name: this.translateService.instant("rt.voltage"), type: 'number' },
			{ key: "battery_capacity", name: this.translateService.instant("devices.battery_capacity"), type: 'number' },
			{ key: "_installation_date_ms", name: this.translateService.instant("devices.installation_date"), type: 'timestamp' },
			{ key: "last_connect_time_formatted", name: this.translateService.instant("devices.last_connect_time"), type: 'date_with_ref' },
		);

		if (this.usersService.hasAccessPermission({ permission: this.permissions }, 'noc')) {
			devicesGridCols.push(
				{ key: "connectivity_verfication_status_formatted", name: this.translateService.instant("connectivity_verification.connectivity_verification_status"), type: 'link' },
				{ key: "shipment_quality_result_formatted", name: this.translateService.instant("devices.shipment_quality_checked"), type: 'link' },
			);
		}

		let filteredCols = [];
		if (this.IoTAhDevices.length > 0) {
			devicesGridCols.forEach((col) => {
				if ((col.key in this.devices[0])) {
					filteredCols.push(col);
				}
			});
		}

		this.installedIotahDeviceTableConfig.column = filteredCols;
	}

	prepareChargeLinkDevicesGridCols(fieldsAccess) {
		let devicesGridCols: ColumnsConfig[] = [
			{ key: "serial_number", name: this.translateService.instant("chargLinkDevice.g.iotah_sn"), type: 'string' },
		];

		if (this.usersService.hasAccessPermission({ permission: this.permissions }, 'noc'))
			devicesGridCols.push({ key: "fw_version", name: this.translateService.instant("devices.fw_version"), type: 'number' });

		devicesGridCols.push(
			{ key: 'truck_sn', name: this.translateService.instant('chargLinkDevice.devices.truck_sn'), type: 'link'},
			{ key: "truck_type", name: this.translateService.instant("chargLinkDevice.devices.truck_type"), type: 'string' },
		);

		if (fieldsAccess.charger_max_power)
			devicesGridCols.push({ key: 'max_charger_kw', name: this.translateService.instant("chargLinkDevice.devices.charger_max_power"), type: 'number', noValueString: '-'})

		devicesGridCols.push(
			{ key: "mac_address", name: this.translateService.instant("devices.unique_mac_address"), type: 'string', hidden: true },
			{ key: "hw_version", name: this.translateService.instant("devices.hw_version"), type: 'string', hidden: true },
			{ key: "ip_address", name: this.translateService.instant("g.ip_address"), type: 'string' },
			{ key: "_voltage", name: this.translateService.instant("rt.voltage"), type: 'number', noValueString: '-'},
			{ key: "_installation_date_ms", name: this.translateService.instant("devices.installation_date"), type: 'timestamp' },
			{ key: "last_connect_time_formatted", name: this.translateService.instant("devices.last_connect_time"), type: 'date_with_ref' },
		);

		if (this.usersService.hasAccessPermission({ permission: this.permissions }, 'noc')) {
			devicesGridCols.push(
				{ key: "connectivity_verfication_status_formatted", name: this.translateService.instant("connectivity_verification.connectivity_verification_status"), type: 'link' },
				{ key: "shipment_quality_result_formatted", name: this.translateService.instant("devices.shipment_quality_checked"), type: 'link' },
			);
		}

		let filteredCols = [];
		if (this.chargeLinkDevices.length > 0) {
			devicesGridCols.forEach((col) => {
				if ((col.key in this.devices[0])) {
					filteredCols.push(col);
				}
			});
		}

		this.installedChargerDeviceTableConfig.column = filteredCols;
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

	formatDeviceFields(devices, permission) {
		devices.forEach((device, idx) => {
			device = this.common.decompress(device, 'siteDevice');
			devices[idx] = device;
			if (this.usersService.hasAccessPermission({ permission }, 'number_of_cells')) {
				let deviceVPC = (device.vpc !== undefined) ? device.vpc : ((device.battery_type == this.deviceService.batteryTypesByName.lithuim_ion) ? 3 : 2);
				const voltage = (device.number_of_cells * deviceVPC);
				device._voltage = voltage;
			}
			if (this.usersService.hasAccessPermission({ permission }, 'installation_date'))
				device._installation_date_ms = device.installation_date * 1000;

			device.fw_version = this.common.toFixedWithoutRounding(device.fw_version, this.usersService.getCurrentUser().is_admin ? 4 : 2);
			device.last_connect_time_formatted = moment(device.last_connect_time * 1000).format('MM/DD/YYYY HH:mm:ss');
			device.connectivity_verfication_status_formatted = this.translateService.instant(`g.${this.getStatus(device, 'connectivity_verfication_result')}`);
			device.shipment_quality_result_formatted = this.translateService.instant(`g.${this.getStatus(device, 'shipment_quality_result')}`);
		});
		return devices;
	}

	getStatus (device, field) {
		const shipmentResults = {
			notTested: 0,
			passed: 1,
			failed: 2
		}

		let res = 'not_tested';
		if (device[field] && device[field].report_id){
			if (device[field]?.result == shipmentResults.passed)
				res = `passed`;

			if (device[field]?.result == shipmentResults.failed)
				res = `failed`;
		}

		return res;
	}

	setInfoBoxSiteAnalyticsData() {
		this.infoBoxSiteAnalytics = this.infoBox[this.selectedDataOption];
	}

	showOnlyConnectedIPs(flag) {
		let ips = [];
		let data = this.devices;

		if (data.length == 0) {
			this.uniqueIPaddresses = [];
			return;
		}

		if (flag) {
			let frequency = 900;
			let nowDate = Math.round(new Date().getTime() / 1000);
			data.forEach(device => {
				let disconnectTime = nowDate - device.last_connect_time; //disconnectTime in seconds
				if (device.ip_address && disconnectTime <= (2.5 * frequency)) {
					ips.push(device.ip_address);
				}
			});

		} else {
			data.forEach(device => {
				if (device.ip_address) {
					ips.push(device.ip_address);
				}
			});
		}

		ips = ips.filter(function (elem, pos) {
			return ips.indexOf(elem) == pos;
		});
		this.uniqueIPaddresses = ips;
	}

	prepareQuarterReport(data, is_charglink) {
		const qKey = is_charglink ? 'chargLink' : 'iotah';
		const qData = data[qKey];
		const periods = _.sortBy(Object.keys(qData)).reverse();

		if (periods.length) {
			if (is_charglink) {
				this.chargLinkQuarterData = qData;
				this.chargLinkSelectedYear = periods[0];
				this.chargLinkSelectedQuarter = Object.keys(qData[this.chargLinkSelectedYear])[0];
				this.chargLinkQuartersOption = this.getListQuarters(this.chargLinkSelectedYear, true);
			} else {
				this.monthData = qData;
				this.selectedYearQ = periods[0];
				this.selectedMonth = +Object.keys(qData[this.selectedYearQ])[0];
				this.monthsOption = this.getListMonths(this.selectedYearQ);
			}
			this.loadQuarterData(is_charglink);
		}
	}

	loadQuarterData(is_charglink: boolean, updateMonthsList = false) {
		if (is_charglink) {
			let chargLinkQuarterDataFormatted = [];
			this.charglinkSelectedQuarterData = [];

			this.chargLinkSelectedYearUsageReport = this.chargLinkSelectedYear;
			chargLinkQuarterDataFormatted = this.chargLinkQuarterData[this.chargLinkSelectedYear] ? this.chargLinkQuarterData[this.chargLinkSelectedYear][this.chargLinkSelectedQuarter] ?? [] : [];
			const charglinkSelectedQuarterData = [];

			[...chargLinkQuarterDataFormatted].forEach((row) => {
				let rowData: TableData = {
					truck_sn: { value: row.truck_sn, link: ['/', this.siteInfo.customer_id, this.siteInfo.id, row.mac_address, "performance"]},
					truck_id: { value: row.truck_id},
					truck_type: { value: row.truck_type},
					max_charger_kw: { value: +(row.max_charger_kw || 0).toFixed(2)},
					total_hours: { value: row.total_hours},
					total_ahr: { value: +(row.total_ahr || 0).toFixed(2)},
					total_kwhr: { value: +(row.total_kwhr || 0).toFixed(2)},
					truck_manufacturer: { value: row.truck_manufacturer},
				}
				charglinkSelectedQuarterData.push(rowData);
			});

			this.charglinkSelectedQuarterData = charglinkSelectedQuarterData;

			this.chargLinkQuarterlyTableConfig.data = this.charglinkSelectedQuarterData;
		} else {
			if (updateMonthsList) {
				this.selectedMonth = +Object.keys(this.monthData[this.selectedYearQ])[0];
				this.monthsOption = this.getListMonths(this.selectedYearQ);
			}

			const selectedMonthUsageData = [];
			this.selectedYearUsageReport = this.selectedYearQ;
			//this.selectedQuarter--;
			this.selectedMonthData = this.monthData[this.selectedYearQ] ? this.monthData[this.selectedYearQ][this.selectedMonth] ?? [] : [];
			if (this.selectedTruckYear == 'new') {
				this.selectedMonthData = _.filter(this.selectedMonthData, (device) => { return this.deviceService.isNewDevice(device.truck_manufacturing_year); });
			} else if (this.selectedTruckYear == 'old') {
				this.selectedMonthData = _.filter(this.selectedMonthData, (device) => { return !this.deviceService.isNewDevice(device.truck_manufacturing_year); });
			}

			[...this.selectedMonthData].forEach((row) => {
				let rowData: TableData = {
					row,
					truck_sn: { value: row.truck_sn, link: ["/", this.siteInfo.customer_id, this.siteInfo.id, row.mac_address, "performance"] },
					truck_id: { value: row.truck_id },
					truck_type: { value: row.truck_type },
					total_hours: { value: row.total_hours },
					total_ahr: { value: +(row.total_ahr || 0).toFixed(2) },
					total_kwhr: { value: +(row.total_kwhr || 0).toFixed(2) },
					truck_year: { value: row.truck_year, trueText: this.translateService.instant('g.new'), falseText: this.translateService.instant('g.old') },
					truck_manufacturer: { value: row.truck_manufacturer },
					truck_manufacturing_year: { value: row.truck_manufacturing_year },
					last_pm_date: { value: row.last_pm_date },
					billed_kwhr: { value: +(row.billed_kwhr || 0).toFixed(2) }
				}
				selectedMonthUsageData.push(rowData);
			});

			this.truckUsageReportTableConfig.data = selectedMonthUsageData;
		}
	}

	prepareTags(tagsData) {
		this.tags = [];
		Object.values(tagsData.siteTags).forEach((tag) => {
			this.tags.push(this.common.decompress(tag, 'tag'));
		});
		this.deviceTags = tagsData.deviceTags;
	}

	exportMonthlyData(allMonths?: boolean) {
		let name = 'Monthly';
		let data = this.monthData || [];
		if (!allMonths) {
			data = { [this.selectedMonth]: this.selectedMonthData };
			name = moment(this.selectedMonth, 'MM').format('MMMM');
		}

		if (allMonths && data) {
			data = data[this.selectedYearQ];
			delete data['All-Year'];
		}
		this.common.exportMonthsData(this.truckUsageReportTableConfig.column, data, `${name}_${this.siteInfo.name}_report`, false);
	}

	exportChargeLinkQuarterData(allQuraters?: boolean) {
		let name = 'quarterly';
		let data = this.chargLinkQuarterData;
		if (!allQuraters) {
			data = { [this.chargLinkSelectedQuarter]: this.charglinkSelectedQuarterData };
			name = this.chargLinkSelectedQuarter;
			data[name] = this.getValues(data[name]);
		}

		if (allQuraters) {
			const filteredData = {};
			for (const key1 in data) {
				if (data.hasOwnProperty(key1)) {
					for (const key2 in data[key1]) {
						const filteredArray = data[key1][key2];
						if (filteredArray.length > 0) {
							filteredData[`${key1}_${key2}`] = filteredArray;
						}
					}
				}
			}
			data = filteredData;
		}

		this.common.exportQuartersData(this.chargLinkQuarterlyTableConfig.column, data, `${name}_${this.siteInfo.name}_report`);
	}

	getValues(data) {
		const returnData = [];

		for (const row of data) {
			const returnObj = {};

			for (const key in row) {
				returnObj[key] = row[key].value;
			}

			returnData.push(returnObj);
		}

		return returnData;
	}

	private filterDevicesQuarterData(data) { //!! NOTE: This function is not used anywhere.
		const chargLinkData = { quarterData: {} };
		const IoTAhData = { quarterData: {} };
		const quarters = Object.keys(data.quarterData);

		quarters.forEach((quarter) => {
			const quarterDataArray = data.quarterData[quarter];

			quarterDataArray.forEach((qData) => {
				const device = this.common.decompress(qData.device, 'siteDevice');
				if (device.is_charglink == true) {
					if (!chargLinkData.quarterData[quarter])
						chargLinkData.quarterData[quarter] = [];
					chargLinkData.quarterData[quarter].push(qData);
				} else {
					if (!IoTAhData.quarterData[quarter])
						IoTAhData.quarterData[quarter] = [];
					IoTAhData.quarterData[quarter].push(qData);
				}
			});
		})
		return { IoTAhData, chargLinkData }
	}

	filterDevices() {
		this.chargeLinkDevices = [];
		this.IoTAhDevices = [];

		const filteredIotahDevices = [];
		const filteredCharglinkDevices = [];

		this.devices.forEach(device => {
			if (device.is_charglink == true)
				this.chargeLinkDevices.push(device);
			else
				this.IoTAhDevices.push(device);
		});

		[...this.IoTAhDevices].forEach((row) => {
			let rowData: TableData = {
				serial_number: { value: row.serial_number },
				fw_version: { value: row.fw_version },
				truck_sn: { value: row.truck_sn, link: ['/', this.siteInfo.customer_id, this.siteInfo.id, row.mac_address, 'performance'] },
				truck_type: { value: row.truck_type },
				mac_address: { value: row.mac_address },
				hw_version: { value: row.hw_version },
				ip_address: { value: row.ip_address },
				_voltage: { value: row._voltage },
				battery_capacity: { value: row.battery_capacity },
				_installation_date_ms: { value: row._installation_date_ms },
				last_connect_time_formatted: { value: row.last_connect_time_formatted },
				connectivity_verfication_status_formatted: { value: row.connectivity_verfication_status_formatted, link: row.connectivity_verfication_result && row.connectivity_verfication_result.report_id? ['/', 'admin-panel', 'connectivity-verification-log', row.connectivity_verfication_result?.report_id] : null},
				shipment_quality_result_formatted: { value: row.shipment_quality_result_formatted, link: row.shipment_quality_result && row.shipment_quality_result.report_id? ['/', 'management', 'shipment-quality-history', row.shipment_quality_result?.report_id] : null}
			}
			filteredIotahDevices.push(rowData);
		});

		this.installedIotahDeviceTableConfig.data = filteredIotahDevices;

		[...this.chargeLinkDevices].forEach((row) => {
			let rowData: TableData = {
				serial_number: { value: row.serial_number },
				fw_version: { value: row.fw_version },
				truck_sn: { value: row.truck_sn, link: ['/', this.siteInfo.customer_id, this.siteInfo.id, row.mac_address, 'performance'] },
				truck_type: { value: row.truck_type },
				max_charger_kw: { value: row.max_charger_kw },
				mac_address: { value: row.mac_address },
				hw_version: { value: row.hw_version },
				ip_address: { value: row.ip_address },
				_voltage: { value: row._voltage },
				_installation_date_ms: { value: row._installation_date_ms },
				last_connect_time_formatted: { value: row.last_connect_time_formatted },
				connectivity_verfication_status_formatted: { value: row.connectivity_verfication_status_formatted, link: row.connectivity_verfication_result && row.connectivity_verfication_result.report_id? ['/', 'admin-panel', 'connectivity-verification-log', row.connectivity_verfication_result?.report_id] : null},
				shipment_quality_result_formatted: { value: row.shipment_quality_result_formatted, link: row.shipment_quality_result && row.shipment_quality_result.report_id? ['/', 'management', 'shipment-quality-history', row.shipment_quality_result?.report_id] : null}
			}
			filteredCharglinkDevices.push(rowData);
		});

		this.installedChargerDeviceTableConfig.data = filteredCharglinkDevices;
	}

	formatQuarterReportData(period) {
		const device = this.common.decompress(period.device, 'siteDevice');
		const qData = this.common.decompress(period.data, 'devicePerformance');
		if (device.last_pm_date && device.last_pm_date > 0)
			this.deviceService.formatDeviceFields(device);

		let quarterObj: any = {}
		quarterObj.mac_address = device.mac_address;
		quarterObj.truck_type = device.truck_type;
		quarterObj.truck_sn = device.truck_sn;
		quarterObj.truck_id = device.truck_id;
		quarterObj.truck_manufacturing_year = device.truck_manufacturing_year;

		quarterObj.total_hours = Math.round(qData.accurate_inuse_time / 3600);
		quarterObj.total_ahr = (qData.total_inuse_as_from_all_events / 3600);
		quarterObj.total_kwhr = (qData.total_inuse_ws_from_all_events / 1000 / 3600);

		quarterObj.truck_year = this.translateService.instant(this.deviceService.isNewDevice(device.truck_manufacturing_year) ? 'g.new' : 'g.old');
		quarterObj.truck_manufacturer = device.truck_manufacturer;
		quarterObj.last_pm_date = device.last_pm_date_fmt || 'N/A';
		quarterObj.billed_kwhr = this.deviceService.calculateBilledKWHr(quarterObj.total_kwhr, device);

		return quarterObj;
	}
}
