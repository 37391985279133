<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h2>{{(source == deviceManagementService.shipmentSource.quality ? 'shipment.shipment_quality_result' : 'shipment.receiving_shipment_result') | translate}}</h2>
	<div class="d-flex justify-content-between">
		<div class="ml-auto">
			<div class="row">
				<h4>{{'g.date' | translate}}: {{devices[0]?.row?.created_at |date:'MM/dd/yyyy' }}</h4>
			</div>
		</div>
	</div>
	<sct-custom-table
		[data]="devices"
		[columnsConfig]="column">
	</sct-custom-table>
</div>

<app-modal #confirmModal title="{{'nav.warnings' | translate}}" [hasConfirmButton]="false" [closeBtnText]="'g.cancel' | translate">
    <div class="mb-5">
        <ul>
            <li *ngFor="let warning of warningsData">
                {{warning}}
            </li>
        </ul>
    </div>
</app-modal>
