<div>
	<div class="d-flex align-items-center justify-content-between">
		<a [routerLink]="['/admin-panel/disconnected-site']"><button class="btn btn-primary mb-3" type="button">{{'connectivity.disconnected_sites_Report' | translate}}</button></a>
		<h2>{{'nav.disconnected_devices' | translate}}</h2>
		<div class="d-flex align-items-center gap-2">
			<p class="m-0">
				<span>{{'g.last_data_update_time' | translate}}</span>
				<span class="mx-2">{{lastUpdateTime}}</span>
			</p>
			<button class="btn btn-primary"  type="button" (click)="getDisconnectedDevicesReport()">{{'g.refresh' | translate}}</button>
		</div>
	</div>

	<div class="white-box p-4">
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
</div>
