import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
		selector: 'app-upload-fw-files',
		templateUrl: './upload-fw-files.component.html',
})

export class UploadFwFilesComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'fw_files_list', name: this.translate.instant('devices.fw_files_list'), type: "string"},
		{ key: 'action', name: this.translate.instant('g.action'), type: "icon"},
	];

	file = null;
	maxAllowedFileSize: number = 15000000;
	fileName: string = '';
	fwVersion: any = 0;
	successFile: boolean;
	successSize: boolean;
	fwList: String[] = [];

	constructor (
		private router: Router,
		private userService: UsersService,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public translate: TranslateService
	) { }

	ngOnInit() {
		if(!this.userService.hasAccessFunction('upload_fw_files'))
			this.router.navigate(['unauthorized']);

		this.getTestingFWFilesList();
	}

	checkFile(file: any) {
		const uploadedFile = file.target.files[0];
		this.fileName = uploadedFile.name;

		this.successFile = uploadedFile.type.startsWith('application');
		this.successSize = uploadedFile.size <= this.maxAllowedFileSize && uploadedFile.size > 0;

		if(!this.successSize || !this.successFile)
			return;

		this.file = uploadedFile;
	}

	getTestingFWFilesList() {
		this.deviceManagementService.getTestingFWFilesList().subscribe((res: any) => {
			const tableData = [];
			for(const fw of res?.fwVersions) {
				tableData.push({
					fw_files_list: {value: fw},
					action: {icon: 'trash', iconColor: 'red', action: ()=> {this.deleteFWFile(fw)}}
				})
			}

			this.tableData = tableData;
		});
	}

	upload() {
		if(!this.file || !this.fwVersion)
			return;

		const formData = new FormData();

		formData.append('file', this.file);
		formData.append('version', this.fwVersion);

		this.adminService.uploadFWFile(formData).subscribe(res => {
			if (res == 1) {
				const fileName = (+this.fwVersion).toFixed(4);

				this.tableData.push({
					fw_files_list: {value: fileName},
					action: {icon: 'trash', iconColor: 'red', action: ()=> {this.deleteFWFile(fileName)}}
				});

				return this.notificationMessage.setMessage('globalSuccessMsg');
			}

			return this.notificationMessage.setMessage(`translate|g.${res}`);
		})
	}

	deleteFWFile(file: string) {
		this.deviceManagementService.deleteFWFile(file).subscribe((res: any) => {
			this.getTestingFWFilesList();
			return this.notificationMessage.setMessage('globalSuccessMsg');
		});
	}
}
