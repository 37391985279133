import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { keyBy } from 'lodash-es';
import { SitesService } from '../../sites.service';

@Component({
	selector: 'app-site-edit',
	templateUrl: './site-edit.component.html',
	styleUrls: ['./site-edit.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SiteEditComponent implements OnInit , OnChanges {
	@ViewChild('siteNameComponent', { static: false }) siteNameComponent: ElementRef;
	@Input() site: any = {};
	@Input() enterpriseList: Array<any> = [];
	@Input() miniForm: boolean = false;
	@Input() restrictSpecialSite: boolean = false;
	@Input() customerEnterprises: Array<any> = [];
	timeZonesMenu = <any>[];
	minInstallationDate = new Date(1970, 1, 1);

	recipientGroups: any = [];
	siteRecipientGroupsUsersList = [];
	siteRecipientGroupsUsersIds = [];

	constructor(
		private commonData: CommonDataService,
		public usersService: UsersService,
		private siteService: SitesService
	) {
	}

	ngOnInit() {
		this.timeZonesMenu = this.commonData.TimeZonesMenu;
		// set the default value of is_smart_rebates to true
		if (!Object.keys(this.site).includes('is_smart_rebates'))
			this.site.is_smart_rebates = true;

		this.siteService.getAllSiteGroups(false).subscribe((res: any) => {
			this.recipientGroups = res;
			this.fillSiteRecipientGroups();
		});
	}

	ngOnChanges(changes: { site: any; }) {
		if (changes.site) {
			this.fillSiteRecipientGroups();
		}
	}

	fillSiteRecipientGroups() {
		const recipientGroups = [];

		if (this.site.id) {
			for (const group of this.recipientGroups) {
				if (group.sites_ids && group.sites_ids.includes(this.site.id))
					recipientGroups.push(group.id);
			}
		}

		if (recipientGroups.length) {
			this.site.recipient_groups = recipientGroups;
			this.updateRecipientGroupUsers();
		}
	}

	updateRecipientGroupUsers() {
		this.siteRecipientGroupsUsersList = [];
		this.siteRecipientGroupsUsersIds = [];
		
		const recipientGroups = keyBy(this.recipientGroups, 'id');

		for (const groupId of this.site.recipient_groups) {
			const groupData = recipientGroups[groupId];

			for (const user of groupData.usersInfo) {
				if (this.siteRecipientGroupsUsersIds.includes([user.id]))
					continue;

				this.siteRecipientGroupsUsersIds.push(user.id);
				this.siteRecipientGroupsUsersList.push({ id: user.id, name: `${user.first_name} ${user.last_name}`} );
			}
		}

		this.siteRecipientGroupsUsersList = structuredClone(this.siteRecipientGroupsUsersList);
		this.siteRecipientGroupsUsersIds = structuredClone(this.siteRecipientGroupsUsersIds);
	}

	ngAfterViewInit() {
		this.siteNameComponent.nativeElement.focus();
	}
}
