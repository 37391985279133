import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-incorrect-events',
	templateUrl: './incorrect-events.component.html',
})

export class IncorrectEventsComponent implements OnInit {
	readonly devicesTypes = { iotah: 0, chargelink: 1 };

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 50,
		draggable: true,
		fileName: '',
	};

	columnConfig: ColumnsConfig[] = [];

	deviceTypeOptions = [
		{id: this.devicesTypes.iotah, name: 'IoTAh'},
		{id: this.devicesTypes.chargelink, name: 'ChargLink'},
	];
	years = [];
	quarters = [
		{id: 0, value: 'All Year'},
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];

	selectedDeviceType = this.devicesTypes.iotah;
	selectedYear: number = new Date().getFullYear();
	selectedQuarter = this.quarters[0].id;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private devicesService: DeviceService,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		return this.getAllYearsFromDate(new Date(`2015-01-01`));
	}

	getIncorrectEvent() {
		const options = {
			year: this.selectedYear,
			quarter: this.selectedQuarter,
			is_charglink: this.selectedDeviceType == this.devicesTypes.chargelink,
		};

		this.tableData = [];
		const tableData = [];
		this.devicesService.getIncorrectEvents(options).subscribe((data: any) => {

			for(const device of data) {
				tableData.push({
					serial_number: {value: device.serial_number, link: ['/', device.customer_id, device.site_id, device.mac_address, 'performance']},
					customer_name: {value: device.customer_name, link: ['/', device.customer_id]},
					site_name: {value: device.site_name, link: ['/', device.customer_id, device.site_id]},
					events_count: {value: device.events_count}
				})
			}

			this.tableData = tableData;
			this.buildGrid();
		});
	}

	buildGrid() {
		let eventsCountHeaderName = 'incorrect_events.number_of_event.charge';

		if (this.selectedDeviceType == this.devicesTypes.chargelink)
			eventsCountHeaderName = 'incorrect_events.number_of_event.inuse';

		this.columnConfig = [];
		this.columnConfig.push(
			{ key: 'serial_number', name: this.translate.instant('incorrect_events.serial_number'), type: 'link'},
			{ key: 'customer_name', name: this.translate.instant('incorrect_events.customer_name'), type: "link"},
			{ key: 'site_name', name: this.translate.instant('incorrect_events.site_name'), type: 'link'},
			{ key: 'events_count', name: this.translate.instant(eventsCountHeaderName), type: "number"}
		);

		this.tableConfig.fileName = `${this.translate.instant('incorrect_events.incorrect_events')}-${this.selectedYear}`;

		if (+this.selectedQuarter)
			this.tableConfig.fileName = `${this.translate.instant('incorrect_events.incorrect_events')}-${this.selectedYear}-Q${this.selectedQuarter}`;
	}

	sortString(valueA: string, valueB: string) {
		return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
	}

	getAllYearsFromDate(startDate) {
		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];

		for (let year = startYear; year <= currentYear; year++)
			years.push(year);

		this.selectedYear = years[years.length - 1];
		this.years = years.reverse();
	}
}
