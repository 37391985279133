import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsersService } from "src/app/users/users.service";
import { DeviceService } from "src/app/home/site-dashboard/device/device.service";
import { indexBy } from "underscore";
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-monitoring-devices-page",
	templateUrl: "./monitoring-devices-page.component.html",
	styleUrls: ["./monitoring-devices-page.component.css"],
})
export class MonitoringDevicesPageComponent implements OnInit {
	sites: {
		customer_id?: number
		customer_name?: string
		id?: number
		name?: string
		} = {};

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: "link"},
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: 'link'},
		{ key: 'power_view_sn', name: this.translateService.instant('devices.power_view_sn'), type: "link"},
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: 'link'},
		{ key: 'cellular_reconnect_time', name: this.translateService.instant('devices.cellular_reconnect_time'), type: "number"},
	];

	constructor(
		private usersService: UsersService,
		private router: Router,
		private devicesService: DeviceService,
		private translateService: TranslateService,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, "noc"))
			return this.router.navigate(["/unauthorized"]);

		this.getNocMonitoringDevices();
	}

	getNocMonitoringDevices() {
		this.devicesService.getNocMonitoringDevices().subscribe((res: any) => {
			this.sites = indexBy(res.sites, 'id');
			const tableData: TableData[] = [];

			for(const device of res.devices) {
				const site = this.sites[device.site_id];

				tableData.push({
					customer_name: {value: site?.customer_name, link: ['/', site?.customer_id]},
					site_name: {value: site?.name, link: ['/', site?.customer_id, device.site_id]},
					power_view_sn: {value: device.serial_number, link: ['/', site?.customer_id, device.site_id, device.mac_address, 'performance']},
					mac_address: {value: device.mac_address, link: ['/', site?.customer_id, device.site_id, device.mac_address, 'performance']},
					cellular_reconnect_time: {value: device.cellular_reconnect_time},
				})
			}

			this.tableData = tableData;
		});
	}
}
