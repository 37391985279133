import { Injectable } from '@angular/core';
import { Breadcrumb } from './breadcrumb.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BreadcrumbService {
  breadcrumbs: Breadcrumb[];
  breadcrumbChange: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  setBreadcrumb(breadcrumbs: Breadcrumb[]) : void {
  	this.breadcrumbs = breadcrumbs;
    this.breadcrumbChange.next(this.breadcrumbs);
  }
  addBreadcrumb(breadcrumb: Breadcrumb) : void {
  	this.breadcrumbs.push(breadcrumb);
  	this.breadcrumbChange.next(this.breadcrumbs);
  }
  hide() {
    this.setBreadcrumb([]);
  }
}