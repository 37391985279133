import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import * as _ from 'underscore';
import { SitesService } from 'src/app/sites/sites.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-device-requests',
	templateUrl: './device-requests.component.html',
	styleUrls: ['./device-requests.component.css']
})
export class DeviceRequestsComponent implements OnInit, AfterViewInit {
	@ViewChild("updateSetupDone") updateSetupDone;
	@ViewChild("updateSetupDoneMulti") updateSetupDoneMulti;
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("locationModal") locationModal;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		hasSelectionColumn: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: "link"},
		{ key: 'latitude', name: this.translateService.instant('devices.latitude'), type: 'number' },
		{ key: 'longitude', name: this.translateService.instant('devices.longitude'), type: "number" },
		{ key: 'serial_number', name: this.translateService.instant('devices.serial_number'), type: "string" },
		{ key: 'request_by', name: this.translateService.instant('device_management.request_by'), type: "link" },
		{ key: 'enterprise_name', name: this.translateService.instant('g.enterprise_name'), type: "string" },
		{ key: 'from_site', name: this.translateService.instant('g.from_site'), type: "string" },
		{ key: 'to_site', name: this.translateService.instant('g.to_site'), type: "link" },
		{ key: 'is_reset_data', name: this.translateService.instant('device_management.is_reset_data'), type: "boolean" },
		{ key: 'reset_device_password', name: this.translateService.instant('device_management.reset_device_password'), type: "boolean" },
		{ key: 'source', name: this.translateService.instant('device_management.source'), type: "string" },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	doneRequest = {
		enterprise: false,
		deviceOwners: false,
		users: false
	};

	requestsList: any;
	selectedRequest: number = 0;
	selectedRequests: any[] = [];
	deviceOwners: any;
	selectedAll: boolean = false;
	selectedCount: number = 0;
	usersInfo: any = {};
	enterprisesInfo: any = {};
	devicesInfo: any[] = [];
	locationsArr: any[] = [];


	constructor(
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		public enterpriseService: EnterpriseService,
		public sitesService: SitesService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.getMoveRequests();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			let requests;
			switch(result[1][0]) {
				case 'approveRequest':
					this.approveRequest(result[1][1]);
				break;
				case 'rejectRequest':
					this.rejectRequest(result[1][1]);
				break;
				case 'approveRequestMulti':
					requests = this.getSelectedRequests();
					this.approveRequest(requests);
				break;
				case 'rejectRequestMulti':
					requests = this.getSelectedRequests();
					this.rejectRequest(requests);
				break;
			}
		});
	}

	confirmAction(action, data?) {
		this.confirmModal.passData = [action, data];
		switch(action) {
			case 'approveRequest':
				this.confirmModal.message = 'device_management.approve_move_prompt';
			break;
			case 'rejectRequest':
				this.confirmModal.message = 'device_management.reject_move_prompt';
			break;
			case 'approveRequestMulti':
				this.confirmModal.message = 'device_management.approve_multi_move_prompt';
			break;
			case 'rejectRequestMulti':
				this.confirmModal.message = 'device_management.reject_multi_move_prompt';
			break;
		}
		this.confirmModal.show();
	}

	getMoveRequests() {
		this.selectedAll = false;
		this.selectedCount = 0;
		this.devicesInfo = [];
		this.deviceManagementService.getMoveRequests({get_sn: true}).subscribe((res) => {
			this.requestsList = res;
			let deviceIds		= _.chain(res).pluck('mac_address').uniq().value();
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();

			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
				this.fillTableData('users');
			});

			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
				this.fillTableData('enterprise');
			});

			if(deviceIds.length) {
				this.deviceManagementService.getDeviceOwners(deviceIds).subscribe((res: any) => {
					this.devicesInfo = _.indexBy(res.devices, 'mac_address');
					this.deviceOwners = _.chain(res.customersAndSites).pluck('sites').flatten(true).indexBy('id').value();
					this.fillTableData('deviceOwners');
				});
			}else {
				this.doneRequest.deviceOwners = true;
			}
		});
	}

	fillTableData(requestName: string) {
		this.doneRequest[requestName] = true;

		if (!Object.values(this.doneRequest).every(value => value))
			return;

		const tableData = [];

		for(const request of this.requestsList) {
			const hasRequestByLink = this.usersService.hasAccessFunction('edit_user_admin') && this.usersInfo[request.user_id];
			const options = [];

			if (request.source == 'device' && this.devicesInfo[request.mac_address]?.latitude && this.devicesInfo[request.mac_address]?.longitude)
				options.push({text: this.translateService.instant('device_management.view_location'), action: ()=>{this.showDeviceLocation(request)}})

			tableData.push({
				id: {value: request.id},
				mac_address: {value: request.mac_address, link: this.deviceOwners[request._current_site] ? ['/', this.deviceOwners[request._current_site].customer_id, request._current_site, request.mac_address, 'performance'] : null},
				latitude: {value: this.devicesInfo[request.mac_address]?.latitude},
				longitude: {value: this.devicesInfo[request.mac_address]?.longitude},
				serial_number: {value: request.serial_number},
				request_by: {
					value: hasRequestByLink ?
						`${this.usersInfo[request.user_id].first_name} ${this.usersInfo[request.user_id].last_name}` :
						`${request.user_id} (${this.translateService.instant('g.deleted')})`,
					link: hasRequestByLink ? ['/user', 'edit', request.user_id] : null,
				},
				enterprise_name: {value: this.enterprisesInfo[request.enterprise_id] ? this.enterprisesInfo[request.enterprise_id].name : `${request.enterprise_id} (${this.translateService.instant('g.deleted')})`},
				from_site: {value: this.deviceOwners[request._current_site] ? this.deviceOwners[request._current_site].name : request._current_site},
				to_site: {value: this.deviceOwners[request._new_site] ? this.deviceOwners[request._new_site].name : request._new_site, link: ['/', request._new_customer, request._new_site]},
				is_reset_data: {value: request.is_reset_data},
				reset_device_password: {value: request.reset_device_password ? true : false},
				source: {value: this.translateService.instant(request.source ? 'device_management.source_options.'+[request.source] : 'g.n/a')},
				actions: {value: null, options: [
					{text: this.translateService.instant('device_management.approve'), action: ()=>{this.onBeforeApproveRequest(request)}},
					{text: this.translateService.instant('g.reject'), action: ()=>{this.confirmAction('rejectRequest', request.id)}},
					...options
				]},
			})
		}

		this.tableData = tableData;
	}

	onBeforeApproveRequest(request) {
		if(!request.setup_done && !request.is_special) {
			// show modal to update setup done flag
			this.selectedRequest = request.id;
			return this.updateSetupDone.show();
		}

		this.confirmAction('approveRequest', request.id);
	}

	onBeforeApproveRequestMulti() {
		let requests = this.getSelectedRequests(true);

		this.selectedRequests = [];
		let showSetupDoneModal = false;
		requests.forEach((request) => {
			if(!request.setup_done && !request.is_special) {
				// show modal to update setup done flag
				this.selectedRequests.push({id: request.id, mac_address: request.mac_address});
				showSetupDoneModal = true;
			}
		});
		if(showSetupDoneModal)
			return this.updateSetupDoneMulti.show();
		this.confirmAction('approveRequestMulti', requests);
	}

	approveRequest(id, changeSetupDone = false) {
		this.approveMoveRequest(id, {changeSetupDone});
	}

	approveRequestMulti(changeSetupDone = false) {
		let reqIds = this.getSelectedRequests();
		let setupDoneDevices = [];
		if(changeSetupDone)
			setupDoneDevices = _.pluck(this.selectedRequests, 'mac_address');
		this.approveMoveRequest(reqIds, {setupDoneDevices});
	}

	approveMoveRequest(reqIds, options) {
		this.deviceManagementService.approveMoveRequest(reqIds, options).subscribe((res: any) => {
			if (res.api_status > 1) {
				switch (res.api_status) {
					case 2:
						return this.notificationMessageService.setMessage('translate|site.site_not_found', { clearOnXTimeNavigate: 1, type: 'danger' });
				}
			}
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getMoveRequests();
		});
	}

	rejectRequest(id) {
		this.deviceManagementService.rejectMoveRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getMoveRequests();
		});
	}

	selectAll(checked) {
		for (let itemIdx in this.requestsList) {
			let item = this.requestsList[itemIdx];
			item.selected = checked;
			this.selectRow(checked);
		}
	}

	selectRow(checked) {
		if (checked) {
			this.selectedCount++;
		} else {
			this.selectedCount--;
			this.selectedAll = false;
		}

		if (this.selectedCount <= 0) {
			this.selectedCount = 0;
		}

		if(this.selectedCount >= Object.keys(this.requestsList).length) {
			this.selectedCount = Object.keys(this.requestsList).length;
			this.selectedAll = true;
		}
	}

	getSelectedRequests(getInfo?) {
		let selectedDevices = [];
		this.requestsList.forEach((item) => {
			if(this.selectedRequests.includes(item.id)) {
				let data = getInfo ? item : item.id;
				selectedDevices.push(data);
			}
		});

		return selectedDevices;
	}

	showDeviceLocation(request) {
		this.locationsArr = [];
		this.locationModal.show();
		this.locationsArr = [{
			latitude: this.devicesInfo[request.mac_address].latitude,
			longitude: this.devicesInfo[request.mac_address].longitude
		}, {
			latitude: this.deviceOwners[request._new_site].latitude,
			longitude: this.deviceOwners[request._new_site].longitude
		}];
	}

	updateSelectedRequests(requests) {
		this.selectedRequests = requests.map(request => request.id);
	}
}
