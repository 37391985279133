import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';
import { ChangeLangComponent } from './change-lang/change-lang.component';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { SafePipe } from './pipes/safe.pipe';
import { ModalComponent } from './modal/modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableComponent } from './table/table.component';
import { warningsHistoryTableComponent } from './warnings-history-table/warnings-history-table.component';
import { MapboxComponent } from './mapbox/mapbox.component';
import { MapboxSearchComponent } from './mapbox-search/mapbox-search.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { ServerPaginationComponent } from './server-pagination/server-pagination.component';
import { KeysPipe } from './pipes/keys.pipe';
import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { DropdownDirective } from './directives/dropdown.directive';
import { DropdownHoverDirective } from './directives/dropdown-hover.directive';
import { BackButtonDirective } from './directives/back-button.directive';
import { CheckPermissionDirective } from './directives/check-permission.directive';
import { AgmCoreModule } from '@agm/core';
import { GridComponent } from './grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { DropDownFilter } from './grid/drop-down-filter.component';
import { ButtonRendererComponent } from './grid/button_renderer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { SimpleWidgetComponent } from './simple-widget/simple-widget.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { SctChartsComponent } from './sct-charts/sct-charts.component';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { FooterComponent } from './footer/footer.component';
import { ChartDataWidgetComponent } from './chart-data-widget/chart-data-widget.component';
import { NoteComponent } from './note/note.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { FormComponent } from './form/form.component';
import { CalenderDirective } from './directives/calender.directive';
import { ShowPasswordDirective } from './directives/show-password.directive';
import { TagInputComponent } from '../home/site-dashboard/tag-input/tag-input.component';
import { HelpTooltipComponent } from './help-tooltip/help-tooltip.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { GoogleMapService } from './google-map/google-map.service';
import { GaTrackerDirective } from './directives/ga-tracker.directive';
import { AttachmentsComponent } from './attachments/attachments.component';
import { StudiesListComponent } from '../study/studies-list/studies-list.component';
import { DateRangeSelectComponent } from '../home/site-dashboard/device/device-dashboard/date-range-select/date-range-select.component';
import { NocNoteComponent } from './noc-note/noc-note.component';
import { SCTCustomTable } from './custom-table/custom-table.component';
import { MatSortModule } from '@angular/material/sort';
import { NewStudyComponent } from '../study/new-study/new-study.component';

@NgModule({
	declarations: [
		ChangeLangComponent,
		LoaderComponent,
		HeaderComponent,
		NotificationMessageComponent,
		SafePipe,
		KeysPipe,
		ModalComponent,
		TableComponent,
		warningsHistoryTableComponent,
		MapboxComponent,
		MapboxSearchComponent,
		SideMenuComponent,
		ServerPaginationComponent,
		GridComponent,
		DropDownFilter,
		ButtonRendererComponent,
		DropdownDirective,
		BackButtonDirective,
		DropdownHoverDirective,
		CheckPermissionDirective,
		BreadcrumbComponent,
		SimpleWidgetComponent,
		SctChartsComponent,
		LeftPanelComponent,
		FooterComponent,
		ChartDataWidgetComponent,
		NoteComponent,
		InfoBoxComponent,
		FormComponent,
		CalenderDirective,
		ShowPasswordDirective,
		TagInputComponent,
		HelpTooltipComponent,
		PasswordStrengthComponent,
		GaTrackerDirective,
		AttachmentsComponent,
		StudiesListComponent,
		NewStudyComponent,
		DateRangeSelectComponent,
		NocNoteComponent,
		SCTCustomTable
	],
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		BsDropdownModule,
		ModalModule.forRoot(),
		AlertModule.forRoot(),
		NgSelectModule,
		MatMenuModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		AgmCoreModule,
		FontAwesomeModule,
		AgGridModule,
		GoogleChartsModule,
		NgxMapboxGLModule.withConfig({
			accessToken: environment.mapbox.accessToken,
			geocoderAccessToken: environment.mapbox.accessToken,
		}),
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot(),
		MatSortModule
	],
	exports: [
		ChangeLangComponent,
		LoaderComponent,
		HeaderComponent,
		NotificationMessageComponent,
		SafePipe,
		KeysPipe,
		ModalComponent,
		TableComponent,
		warningsHistoryTableComponent,
		MapboxComponent,
		MapboxSearchComponent,
		SideMenuComponent,
		ServerPaginationComponent,
		GridComponent,
		BreadcrumbComponent,
		DropdownDirective,
		BackButtonDirective,
		DropdownHoverDirective,
		CheckPermissionDirective,
		SimpleWidgetComponent,
		SctChartsComponent,
		LeftPanelComponent,
		FooterComponent,
		ChartDataWidgetComponent,
		NoteComponent,
		InfoBoxComponent,
		FormComponent,
		CalenderDirective,
		ShowPasswordDirective,
		TagInputComponent,
		HelpTooltipComponent,
		PasswordStrengthComponent,
		GaTrackerDirective,
		AttachmentsComponent,
		StudiesListComponent,
		NewStudyComponent,
		DateRangeSelectComponent,
		NocNoteComponent,
		SCTCustomTable
	],
	providers: [
		GoogleMapService
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		// Add an icon to the library for convenient access in other components
		library.addIconPacks(fas, far, fab);
	}
}
