<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div>
		<div class="d-flex d-flex justify-content-between">
			<h2>{{'nav.smart_rebates_sites' | translate}}</h2>
			<div *ngIf="selectedSitesToExport.length">
				<button class="btn btn-primary pointer" (click)="bulkExportConsumptionsReport()">{{'g.export_data' | translate}}</button>
			</div>
		</div>
		<sct-custom-table
			[data]="data"
			[columnsConfig]="columnConfig"
			[config]="tableConfig"
			(updateSelectedRows)="selectSite($event)">
		</sct-custom-table>
	</div>
	<app-modal
		#bulkExportConsumptionsReportDialog
		title="{{'nav.consumptions_report' | translate}}"
		[confirmBtnText]="'g.export_to_xlsx' | translate"
		[closeBtnText]="'g.cancel' | translate"
		[disableConfirm]="!isValidDate"
	>
		<div class="py-4">
			<div class="mx-2 mb-2 row">
				{{'g.period_type' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="periodTypesItems" bindLabel="value" bindValue="id"
					placeholder="{{'g.period_type' | translate}}" name="period_type" [(ngModel)]="selectedPeriod" [clearable]="false" (ngModelChange)="checkIsValidDate()">
				</ng-select>
			</div>

			<div *ngIf="[periodTypes.yearly, periodTypes.quarterly].includes(selectedPeriod)" class="mx-2 mb-2 row">
				{{'g.select_year' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="years" bindLabel="label" bindValue="id"
					placeholder="{{'g.select_year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
				</ng-select>
			</div>

			<div *ngIf="selectedPeriod == periodTypes.quarterly" class="mx-2 mb-2 row">
				{{'g.select_quarter' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="quarters" bindLabel="value" bindValue="id"
					placeholder="{{'g.select_quarter' | translate}}" name="year" [(ngModel)]="selectedQuarter" [clearable]="false">
				</ng-select>
			</div>

			<div *ngIf="selectedPeriod == periodTypes.monthly">
				<div class="mx-2 mb-2">
					{{'g.from' | translate}}

					<div class="d-flex justify-content-between">
						<ng-select class="w-100 option" [items]="years" bindLabel="label" bindValue="id" [class.not-valid]="monthlyPeriod.from.year > monthlyPeriod.to.year"
							placeholder="{{'g.select_year' | translate}}" name="from_year" [(ngModel)]="monthlyPeriod.from.year" [clearable]="false" (ngModelChange)="checkIsValidDate()">
						</ng-select>

						<ng-select class="ml-1 w-100 option" [items]="MONTHS_LIST" bindLabel="label" bindValue="value" [class.not-valid]="monthlyPeriod.from.year == monthlyPeriod.to.year && monthlyPeriod.from.month > monthlyPeriod.to.month"
							placeholder="{{'g.select_month' | translate}}" name="from_month" [(ngModel)]="monthlyPeriod.from.month" [clearable]="false" (ngModelChange)="checkIsValidDate()">
						</ng-select>
					</div>
				</div>

				<div class="mx-2 mb-2">
					{{'g.to' | translate}}

					<div class="d-flex justify-content-between">
						<ng-select class="w-100 option" [items]="years" bindLabel="label" bindValue="id" [class.not-valid]="monthlyPeriod.from.year > monthlyPeriod.to.year"
							placeholder="{{'g.select_year' | translate}}" name="to_year" [(ngModel)]="monthlyPeriod.to.year" [clearable]="false" (ngModelChange)="checkIsValidDate()">
						</ng-select>

						<ng-select class="ml-1 w-100 option" [items]="MONTHS_LIST" bindLabel="label" bindValue="value" [class.not-valid]="monthlyPeriod.from.year == monthlyPeriod.to.year && monthlyPeriod.from.month > monthlyPeriod.to.month"
							placeholder="{{'g.select_month' | translate}}" name="to_month" [(ngModel)]="monthlyPeriod.to.month" [clearable]="false" (ngModelChange)="checkIsValidDate()">
						</ng-select>
					</div>
				</div>
			</div>

		<div class="mx-2 row">
		  {{'smart_rebates_reports.sr_enable_date' | translate}}
		  <div class="input-group border-right rounded">
			<input [readOnly]="true" readonly class="form-control" id="fromDate" placeholder="mm/dd/yyyy" name="dp"
			  [(ngModel)]="selectedStartDate" bsDatepicker #fromDate="bsDatepicker"
			  [bsConfig]="{ containerClass: 'theme-dark-blue' }" />
			<span class="input-group-btn">
			  <button class="btn btn-default btn-date brder-right border-top border-bottom rounded-right"
				(click)="fromDate.toggle()" type="button">
				<i class="fa fa-calendar"></i>
			  </button>
			</span>
		  </div>
		</div>
		</div>
	</app-modal>

</div>
<app-modal #defectiveDevicesModal title="{{'smart_rebates_reports.defective_devices' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div *ngIf="selectedReport">
		<ul>
			<li *ngFor="let device of selectedReport.defective_devices_list">
				<a [routerLink]="['/', selectedReport?.customerId, selectedReport?.id, device.macAddress, 'settings']" [queryParams]="{tab: 'official-reporting'}" target="_blank">{{device.serialNumber}}</a>
			</li>
		</ul>
	</div>
</app-modal>
