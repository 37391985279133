import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { Sort } from '@angular/material/sort';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-disable-xtra-file',
	templateUrl: './disable-xtra-file.component.html',
	styleUrls: ['./disable-xtra-file.component.css']
})
export class DisableXTRAFileComponent implements AfterViewInit {
	@ViewChild("restartDeviceConfirm") restartDeviceConfirm;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 50,
		draggable: false,
		hasSelectionColumn: true,
		fileName: this.translate.instant('nav.disable_xtra_file'),
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'serial_number', name: this.translate.instant('g.serial_number'), type: "link"},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: "link"},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'user_name', name: this.translate.instant('sim.disabled_source'), type: 'string'},
		{ key: 'disable_since', name: `${this.translate.instant('g.disabled_since')} (${this.showUserTimeZoneReference()})`, type: "string"},
	];

	devices: any[] = [];
	selectedDevices: number[] = [];

	timeFields = ['disable_since'];
	stringOrNumberFields = ['serial_number', 'customer_name', 'site_name', 'user_name'];

	constructor(
		public usersService: UsersService,
		private devicesService: DeviceService,
		private router: Router,
		public translate: TranslateService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		private commonData: CommonDataService,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		if (window.location.hostname.includes(this.commonData.developmentDomain))
		this.tableConfig.pageSize = 4;

		this.sideMenuService.hide();
		this.getDevices();
	}

	ngAfterViewInit() {
		this.restartDeviceConfirm.onClose.subscribe((ok) => {
			if (ok)
				this.enableExtraFile();

			this.restartDeviceConfirm.hide();
		});
	}

	getDevices() {
		this.devicesService.getDisableXTRAFileDevices().subscribe((res: any) => {
			const tableData = [];
			const {usersInfo, devices} = res;

			for(const device of devices) {
				const userId = device.user_id;
				let userName = this.translate.instant('g.system');
				if (userId)
					userName = usersInfo[userId] ? `${usersInfo[userId]?.first_name} ${usersInfo[userId]?.last_name}` : `${this.translate.instant('users.deleted_user')} (${userId})`;

				tableData.push({
					id: {value: device.mac_address},
					serial_number: {value: device.serial_number, link: ['/', device.customer_id, device.site_id, device.mac_address, 'performance']},
					customer_name: {
						value: this.translate.instant(device.customer_name == 'customer_deleted'? "connectivity_verification." + device.customer_name  : device.customer_name),
						link: device.customer_name == 'customer_deleted'? null : ['/', device.customer_id]
					},
					site_name: {
						value: this.translate.instant(device.site_name == 'site_deleted'? "connectivity_verification." + device.site_name : device.site_name),
						link: device.site_name == 'site_deleted'? null : ['/', device.customer_id, device.site_id]
					},
					user_name: { value: userName },
					disable_since: {value: this.getDisableSince(device), sortValue: device.queued_since || device.disable_since },
				})
			}
			this.tableData = tableData;
		})
	}

	getDisableSince(device) {
		if (device.queued_since && !device.disable_since)
			return `${this.translate.instant('g.queued_since')} ${moment(device.queued_since).format('MM/DD/YYYY hh:mm:ss A')}`;

		if (device.disable_since && !device.queued_since)
			return moment(device.disable_since).format('MM/DD/YYYY hh:mm:ss A')
	}

	showUserTimeZoneReference() {
		const userTimeZone = moment().utcOffset();
		return moment().utcOffset(userTimeZone).format('UTCZ');
	}

	getDisabledSinceText(device) {
		return device.queued_since  ? `${this.translate.instant('g.queued_since')} ${this.formateDate(device.queued_since)}` : this.formateDate(device.disable_since);
	}

	formateDate(date: moment.MomentInput) {
		return date ? moment(date).utc().format('MM/DD/yyyy'): '-';
	}

	selectDevice(selectMacAddress) {
		if (this.selectedDevices.includes(selectMacAddress))
			return this.selectedDevices = this.selectedDevices.filter(macAddress => macAddress != selectMacAddress);

		return this.selectedDevices.push(selectMacAddress);
	}

	enableExtraFile() {
		this.devicesService.enableExtraFile(this.selectedDevices).subscribe((res: any) => {
			return this.notificationMessage.setMessage('translate|g.command_is_queued', {clearOnXTimeNavigate: 1, type: 'success'});
		})
	}

	updateSelectedDevices(devices) {
		this.selectedDevices = devices.map(device => device.id.value);
	}
}
