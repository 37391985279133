import { CommonService } from './../../../shared/services/common.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import * as _ from 'underscore';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';

@Component({
	selector: 'app-download-status',
	templateUrl: './download-status.component.html',
	styleUrls: ['./download-status.component.css']
})
export class DownloadStatusComponent implements OnInit, OnDestroy {
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		draggable: false,
		pageSize: 20,
		resetPaginationOnDataChange: true,
		fileName: this.translateService.instant('site.download_status'),
		tableId: 'site-download-status'
	};

	columnConfig: ColumnsConfig[] = [];
	customerId: number;
	siteId: number;
	subSite: Subscription = new Subscription();
	subPermission: Subscription = new Subscription();
	enterprisePermissions: any = {};
	devices: any[] = [];
	selectedDevices: any[] = [];
	statusFilter: string = 'all';
	statusList: any;
	intervalID: any;
	isAdmin = false;
	siteName: "";

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService,
		private sitesService: SitesService,
		public common: CommonService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		this.isAdmin = this.usersService.getCurrentUser().is_admin;

		this.statusList = {
			all: this.translateService.instant('g.all'),
			yes: this.translateService.instant('g.completed'),
			no: this.translateService.instant('g.not_completed'),
		};

		if(window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.subSite = this.sideMenuService.currentSite.subscribe((data: any) => {
			this.customerId = Number(this.route.parent.snapshot.params['customerId']);
			this.siteId = Number(this.route.parent.snapshot.params['siteId']);

			this.subPermission = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
				this.enterprisePermissions = permissions;
				this.columnConfig = [
					{ key: 'iotah_sn', name: this.translateService.instant('g.iotah_sn'), type: "link"},
					{ key: 'truck_sn', name: this.translateService.instant('devices.truck_sn'), type: "string"},
					{ key: 'truck_id', name: this.translateService.instant('devices.truck_id'), type: 'string'},
				];

				if (this.usersService.userHasNOCAccess(this.enterprisePermissions)) {
					this.columnConfig.push(
						{ key: 'available_events', name: this.translateService.instant('site.available_events'), type: 'string'},
						{ key: 'fw_version', name: this.translateService.instant('devices.fw_version'), type: 'number'},
						{ key: 'fw_version_to_push', name: this.translateService.instant('settings.fw_version_to_push'), type: 'number'},
						{ key: 'fw_update_block_reason', name: this.translateService.instant('settings.fw_update_block_reason'), type: 'string'}
					)
				}
				this.columnConfig.push({ key: 'download_status', name: this.translateService.instant('site.download_status'), type: 'progress_bar'},)
			});

			if (!data || !data['id'])
				return;

			if (data.id != this.siteId || data['customer_id'] != this.customerId)
				return;

			this.siteName = data.name;
			this.getSiteDownloadStatus();

		});

		this.intervalID = setInterval(() => {
			this.getSiteDownloadStatus();
		}, 30000); //every 30 seconds
	}

	getSiteDownloadStatus() {
		this.siteId = Number(this.route.parent.snapshot.params['siteId']);

		if(this.siteId) {
			this.sitesService.getSiteDownloadStatus(this.siteId).subscribe((res: any) => {
				this.devices = res;
				this.applyFilter();
				this.fillTableData();
			});
		}
	}

	fillTableData() {
		const tableData = [];
		this.tableData = [];

		for(const device of this.selectedDevices) {
			const deviceDownLoadStatus = this.getDeviceDownloadStatus(device);

			tableData.push({
				iotah_sn: {value: device.serial_number, link: ['/', this.customerId, this.siteId, device.mac_address, 'performance']},
				truck_sn: {value: device.truck_sn},
				truck_id: {value: device.truck_id},
				available_events: {value: `${device.total_events} ${this.translateService.instant('site.events')}`, sortValue: device.total_events},
				fw_version: {value: this.common.toFixedWithoutRounding(device.fw, this.isAdmin ? 4 : 2)},
				fw_version_to_push: {value: device.fw_to_push && device.fw_to_push > 0 && !device.testing_fw ? this.common.toFixedWithoutRounding(device.fw_to_push, this.isAdmin ? 4 : 2) : null},
				fw_update_block_reason: {value: this.translateService.instant(device.blocked_reason ? 'blocked_fw_reasons.'+device.blocked_reason : 'g.n/a')},
				download_status: {progress_bar_value: deviceDownLoadStatus.width, progress_bar_text: deviceDownLoadStatus.text, progress_bar_status: deviceDownLoadStatus.status}
			})
		}

		this.tableData = tableData;
	}

	getDeviceDownloadStatus(device): {width: number, text: string, status: 'success' | 'danger'} {
		if (!device.connected)
			return {width: 100, text: this.translateService.instant('g.disconnected'), status: 'danger'};

		if (!this.usersService.userHasNOCAccess(this.enterprisePermissions)) {
			if (device.downloaded > 0)
				return {width: device.status, text: `(${Math.round(device.status)}%)`, status: 'success'};

			return {width: device.status, text: `(${Math.round(device.status)}%)`, status: 'success'};
		}

		if (device.downloaded > 0)
			return {width: device.status, text: `${device.downloaded} ${this.translateService.instant('g.out_of')} ${device.total_events} (${Math.round(device.status)}%)`, status: 'success'};

		return {width: device.status, text: `(${Math.round(device.status)}%)`, status: 'success'};
	}

	applyFilter() {

		if(this.statusFilter == 'all')
			return this.selectedDevices = this.devices.slice();

		if(this.statusFilter == 'yes') {
			return this.selectedDevices = _.filter(this.devices, function(device) {
				return device.downloaded > 0 && device.connected && device.fw >= device.fw_to_push && device.total_events - device.downloaded <= 2;
			});
		}

		this.selectedDevices = _.filter(this.devices, function(device) {
			return !(device.downloaded > 0 && device.connected && device.fw >= device.fw_to_push && device.total_events - device.downloaded <= 2);
		});
	}

	ngOnDestroy() {
		this.subSite.unsubscribe();
		this.subPermission.unsubscribe();
		clearInterval(this.intervalID);
	}

	goBack() {
		this.router.navigate(['/', this.customerId, this.siteId]);
	}
}
