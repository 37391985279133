import { Injectable } from '@angular/core';
import { Location, DecimalPipe, DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as _ from 'underscore';
import objectMappers from './mapper.json';
import { CommonDataService } from './common-data.service';
import validationRulesModel from '../../../../../iotah-common/validations/validation.json';
import validationRulesModelChargLink from '../../../../../iotah-common/validations/validation-cl.json';
import { ValidationService } from './validation.service';
import { TranslateService } from '@ngx-translate/core';
import { isDevMode } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';


@Injectable({
	providedIn: 'root'
})
export class CommonService {

	constructor(
		private commonData: CommonDataService,
		private location: Location,
		private translate: TranslateService,
		private validationService: ValidationService,
		private datePipe: DatePipe
	) {
		this.convertHourToSeconds = this.convertHourToSeconds.bind(this);
		this.durationFormatter = this.durationFormatter.bind(this);
	}

	alphabetCharacters = "abcdefghijklmnopqrstuvwxyz".split('');
	providedFilterParams: any;

	isMobileScreen: boolean = window.innerWidth < 767;
	mobileAppLinks = {
		google_play: {
			iotah: 'https://play.google.com/store/apps/details?id=com.smartchargetech.iotah&pcampaignid=web_share&pli=1',
			chargLink: 'https://play.google.com/store/apps/details?id=com.smartchargetech.charglink&pcampaignid=web_share',
		},
		app_store: {
			iotah: 'https://apps.apple.com/us/app/iotah/id1534874730',
			chargLink: 'https://apps.apple.com/us/app/charglink/id6448758620',
		}
	}

	powerStudiesDevicesSiteName: string = 'Power Studies Devices';
	sctEmailDomain: string = 'smartchargetech.com';
	powerStudyDevicesSiteId: number = 99999;

	QV_SNAPSHOT_SOURCE = {
		1: 'define',
		2: 'detailedQV'
	};

	DEVICES_TYPE = {
		'IoTAh': 'IoTAh',
		'ChargLink': 'ChargLink',
		'IoTAh_ps': 'IoTAh_ps',
	};

	daysText  = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	compress(data, type) {
		let compressedData = {};
		let mapped = objectMappers[type];
		for (let field in data) {
			if(mapped[field])
				compressedData[mapped[field]] = data[field];
			else
				compressedData[field] = data[field];
		}
		return compressedData;
	}

	compare = (a: number | string, b: number | string, isAsc: boolean) => {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	showUserTimeZoneReference() {
		const userTimeZone = moment().utcOffset();
		return moment().utcOffset(userTimeZone).format('UTCZ');
	}

	empty(data) {
		if(typeof(data) == 'number' || typeof(data) == 'boolean')
		{
			return false;
		}
		if(typeof(data) == 'undefined' || data === null)
		{
			return true;
		}
		if(typeof(data.length) != 'undefined')
		{
			return data.length == 0;
		}
		var count = 0;
		for(var i in data)
		{
			if(data.hasOwnProperty(i))
				count ++;
		}
		return count == 0;
	}

	getZoneName(zoneId, defaults = 0) : string {
		let zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if(!zone) {
			if(!defaults)
				defaults = 14;
			zone = this.commonData.TimeZonesMenu[defaults];
		}
		return zone.display_name;
	}

	getUTCTimestampFromZone(zoneId, unixTimestamp) {
		var zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp - zone.base_utc);

		for (let i = 0; i < 50; i++) {

			if (unixTimestamp >= zone.changes_time[i])
				unixTimestamp = (unixTimestamp + ((i % 2 == 0) ? -1 : 1) * zone.changes_value);
		}

		return unixTimestamp;
	}

	getZoneTimestampFromUTC(zoneId, unixTimestamp) {

		var zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp + zone.base_utc);

		//DaylightSaving
		for (let i = 0; i < 50; i++) {

			if (unixTimestamp >= zone.changes_time[i])
				unixTimestamp = (unixTimestamp + ((i % 2 == 0) ? 1 : -1) * zone.changes_value);
		}

		return unixTimestamp;
	}

	getDateFormattedFromUnixTimeStamp(timeStamp, format = 'default') {
		if(timeStamp!=+timeStamp) return "";
		var LEAPOCH =(946684800 + 86400*(31+29));
		var DAYS_PER_400Y =(365*400 + 97);
		var DAYS_PER_100Y =(365*100 + 24);
		var DAYS_PER_4Y  = (365*4   + 1);
		var remdays, remsecs, remyears;
		var qc_cycles, c_cycles, q_cycles;
		var years, months;
		var wday, yday, leap;
		var days_in_month = [31,30,31,30,31,31,30,31,30,31,31,29];
		var secs = timeStamp - LEAPOCH;
		var days = Math.floor(secs / 86400);
		remsecs = secs % 86400;
		if (remsecs < 0) {
			remsecs += 86400;
			days--;
		}

		wday = (3+days)%7;
		if (wday < 0) wday += 7;

		qc_cycles = Math.floor(days / DAYS_PER_400Y);
		remdays = days % DAYS_PER_400Y;
		if (remdays < 0) {
			remdays += DAYS_PER_400Y;
			qc_cycles--;
		}

		c_cycles = Math.floor(remdays / DAYS_PER_100Y);
		if (c_cycles == 4) c_cycles--;
		remdays -= c_cycles * DAYS_PER_100Y;

		q_cycles = Math.floor(remdays / DAYS_PER_4Y);
		if (q_cycles == 25) q_cycles--;
		remdays -= q_cycles * DAYS_PER_4Y;

		remyears = Math.floor(remdays / 365);
		if (remyears == 4) remyears--;
		remdays -= remyears * 365;

		leap = !remyears && (q_cycles || !c_cycles);
		yday = remdays + 31 + 28 + leap;
		if (yday >= 365+leap) yday -= 365+leap;

		years = remyears + 4*q_cycles + 100*c_cycles + 400*qc_cycles;

		for (months=0; days_in_month[months] <= remdays; months++)
			remdays -= days_in_month[months];

		years+=2000;
		months+=3;//1 = January
		if(months > 12) {
			months -=12;
			years++;
		}
		function zeroPad(num, places) {
			var zero = places - num.toString().length + 1;
			return Array(+(zero > 0 && zero)).join("0") + num;
		}

		function getHourFromSeconds(timeInSeconds) {
			var hours = Math.floor(timeInSeconds / 3600);
			var minutes = Math.floor((timeInSeconds % 3600) / 60);
			var period;
			if(hours >= 12 && hours != 24) {

				if(hours != 12)
					hours -= 12;
				 period = 'PM';
			} else {

				if(hours == 0 || hours == 24)
					hours = 12;
				period = 'AM';
			}

			return {
				'hours':	hours,
				'minutes':	minutes,
				'period':	period
			};
		}

		var monthsText	= ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		var str;
		switch (format) {
			case "date":
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString();
			break;
			case "LCDprojection":
				var time = getHourFromSeconds(remsecs);
				str = zeroPad(time.hours, 2) + ":" + zeroPad(time.minutes, 2) + ' ' + time.period + ' - ' + this.daysText[wday] + ' ' + monthsText[months-1] + '/' + (remdays + 1);
			break;
			case "12h":
				let hours: any = Math.floor(remsecs / 3600);
				let amPM = 'PM';
				if(hours < 12) {
					amPM = 'AM';
					if(hours == 0) {
						hours = 12;
					}
				} else {
					hours -= 12;
				}
				hours = zeroPad(hours, 2);
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString() + " " + hours + ":" + zeroPad(Math.floor(remsecs / 60 % 60),2) + ":" + zeroPad(Math.floor(remsecs % 60),2) + " "+ amPM;
			break;
			default:
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString() + " " + zeroPad(Math.floor(remsecs / 3600),2) + ":" + zeroPad(Math.floor(remsecs / 60 % 60),2) + ":" + zeroPad(Math.floor(remsecs % 60),2);
			break;
		}
		return str;
	}

	decompress(data, type) {
		let decompressedData: any = {};
		let mapped = this.swapObjectKeyAndValue(objectMappers[type]);
		for (let field in data) {
			let mappedKey = mapped[field];
			if(mappedKey) {
				let special = {"live_event": 'events', "live_rt": 'rt'};
				let specialType = special[mappedKey];
				if(specialType)
					decompressedData[mappedKey] = this.decompress(data[field], specialType);
				else
					decompressedData[mappedKey] = data[field];
			} else
				decompressedData[field] = data[field];
		}
		return decompressedData;
	}

	genRandomString(len) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < len; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	}

	getDomainName() {
		const angularRoute = '#'+this.location.path();
		const url = window.location.href;
		const domain = url.replace(angularRoute, '');
		return domain;
	}

	getCaptchaUrl() {
		let captchaKey = this.genRandomString(10);
		return {
			'url': this.getDomainName()+"user/captchaImage/"+captchaKey+"/0",
			'key': captchaKey
		};
	}

	revertPermissionValue(permissionsObj){
		let mapper = {
			0: "noAccess",
			1: "read",
			2: "write",
		};
		let res = {};
		for (let key in permissionsObj) {
			if(['rt', 'events', 'quick_view', 'mobile_master', 'mobile_network', 'online_network', 'router_network'].includes(key))
				res[key] = this.revertPermissionValue(permissionsObj[key]);
			else
				res[key] = mapper[permissionsObj[key]];
		}
		return res;
	}

	swapObjectKeyAndValue(sourceObj) {
		return Object.keys(sourceObj).reduce((obj, key) => (obj[sourceObj[key]] = key, obj), {});
	}

	timeFormat(input, options: any = {timeSystem: 24, limitToOneDay: false}) {
		var timeSystem		= options.timeSystem || 24;
		var limitToOneDay	= options.limitToOneDay || false;
		if (limitToOneDay && input >=(1*24*60*60)) {
			return '24:00';
		}

		var format = "HH:mm";
		if (timeSystem == 12) {
			format = "hh:mm A";
		}

		return moment("2015-01-01").startOf('day').seconds(input).format(format);
	}

	arrayCompare(arr1, arr2) {
		return arr1.length == arr2.length && _.difference(arr1, arr2).length == 0;
	}

	getDropDownListData(type, options: any = {}) {
		let DayOfWeek = {
			'Sunday': 0,
			'Monday': 1,
			'Tuesday': 2,
			'Wednesday': 3,
			'Thursday': 4,
			'Friday': 5,
			'Saturday': 6
		};
		var dataList = [];
		var i, j;
		switch(type) {
			case "number":
				var num = options.start;
				for(i=0; i<=(options.end-options.start)/options.step; i++) {
					dataList.push(num);
					num += options.step;
				}
			break;
			case "clock":
				var hour, minute: any = "";
				for(j=options.start; j<=options.end; j++) {
					hour = j;
					if (j < 10) {
						hour = "0"+j;
					}
					for(i=0; i<60/options.step; i++) {

						if(j == options.start && options.ignoreFirst) {
							options.ignoreFirst--;
							continue;
						}

						minute = i * options.step;
						if (minute < 10) {
							minute = "0"+minute;
						}
						dataList.push(hour+":"+minute);
						if (j == options.end) {
							break;
						}
					}
				}
			break;
			case "week":
				for(var day in DayOfWeek) {
					if(options && options.days && options.days.indexOf(day) == -1)
						continue;
					dataList.push({id:DayOfWeek[day], text:day});
				}
			break;
		}
		return dataList;
	}

	secondsToDaysHoursMinutesAndSeconds(seconds) {
		let days	= 0,
			text	= '',
			value	= seconds;

		if(value >= 86400){
			days = Math.floor(value / 86400);
			value -= (days * 86400);
		}
		if(days)
			text += days+' '+ (days == 1 ? this.translate.instant('time.day') : this.translate.instant('time.days'));
		if(value)
			text += ' '+moment("2015-01-01").startOf('day').seconds(value).format('HH:mm:ss');
		return text;
	}

	secondsToElapsedTime(disconnectTime, options?) {
		options = options || {};
		var timespanObj = this.convertHourToSeconds(disconnectTime, 0, true, 0, options);

		var timespanTextArr = [];
		var timespanText	= "";
		if(timespanObj['day'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['day'], "day"));
		if(timespanObj['hour'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['hour'], "hour"));

		if(timespanTextArr.length > 0)
			timespanText = timespanTextArr.join(" and ");
		else if(timespanObj['min'] > 0)
			timespanText = this.singularOrPlural(timespanObj['min'], "min");

		return timespanText;
	}

	convertHourToSeconds(hour, min, viseVersa = false, sec = 0, options?) {
		options = options || {};
		let seconds: number = 0;
		if(viseVersa) {
			seconds			= +hour;
			let days		= (options.hoursOnly) ? 0 : Math.floor(seconds / 86400);
			let hourTime	= Math.floor((seconds - (days * 24 * 3600)) / 3600);
			let minTime		= Math.floor((seconds - (hourTime * 3600) - (days * 24 * 3600)) / 60);
			return ({day: days, hour: hourTime, min: minTime});
		} else {
			//Converts hour point in time to seconds passed
			//Ex: 01:55 -> 1 * 3600 + 55 * 60 = 6900 seconds
			var hourSeconds = hour * 3600;
			var minSeconds = min * 60;
			seconds = (+sec) || 0;
			return hourSeconds + minSeconds + seconds;
		}
	}

	getRemainingPeriod(date, span) {
		let expiry = moment(date).add(span, 'years');
		let years = expiry.diff(moment(), 'years');
			expiry.subtract(years, 'years');
		let months = expiry.diff(moment(), 'months');
			expiry.subtract(months, 'months');
		let days = expiry.diff(moment(), 'days');
		let vals = [];
		if(years > 0)
			vals.push(years + ' ' + this.translate.instant('g.years_value'));
		if(months > 0)
			vals.push(months + ' ' + this.translate.instant('g.months_value'));
		if(days > 0)
			vals.push(days + ' ' + this.translate.instant('g.days_value'));
		return vals.join(', ');
	}

	singularOrPlural(val, text, concat = null) {
		concat = concat == null ? true : false;
		if(val == 1) {
			return concat ? val + ' ' + text : text;
		}
		return concat ? val + ' ' + text + 's' : text + 's';
	}

	validateDeviceSettings(data){

		let ref				= this;
		let invalidFields	= [];
		var networksFields	= ['mobile_master', 'mobile_network', 'router_network', 'online_network'];

		let validationRules = this.getValidationRules();

		for(let field in data) {
			let rules = validationRules[field] || [];

			if(networksFields.indexOf(field) != -1) {

				for(let subField in data[field]) {
					if(invalidFields.indexOf(subField) == -1) {

						rules = validationRules[field][subField] || [];
						rules.forEach(function(_rule) {

							var isValid = ref.validationService.dataValidator(_rule.type, _rule, data[field][subField]);
							if(!isValid) {
								if(networksFields.indexOf(field) != -1){
									if(invalidFields.indexOf(field+'.'+subField) == -1)
										invalidFields.push(field+'.'+subField); // master.ssid or mixed_access.priority
								} else
									invalidFields.push(subField);
							}
						}, this);
					}
				}
			} else if(invalidFields.indexOf(field) == -1) {
				rules.forEach(function(rule) {

					var isValid = ref.validationService.dataValidator(rule.type, rule, data[field]);
					if(!isValid)
						invalidFields.push(field);
				}, this);
			}
		}
		return invalidFields;
	}

	getValidationRules(){
		let projectRules = {...validationRulesModel};
		// @todo: Update this line to override all objects insted of device only
		if(this.isChargeLink())
			projectRules = {...validationRulesModel, device: {...validationRulesModel.device, ...validationRulesModelChargLink.device} };

		let rules: any = projectRules.device;
		rules.mobile_master		= this.mergeObjects(projectRules.app_network_empty_pass);
		rules.mobile_network	= this.mergeObjects(projectRules.app_network_empty_pass);
		rules.router_network	= this.mergeObjects(projectRules.app_network_empty_pass);
		rules.online_network	= this.mergeObjects(projectRules.app_network_empty_pass);

		let minInstallationDate	= moment().subtract(5, 'years').utc().unix();
		let maxInstallationDate	= moment().endOf('day').utc().unix();
		rules.installation_date	= [{type: 'integer', min: minInstallationDate, max: maxInstallationDate}];
		rules.hw_version		= [{"type": "regex", "patt": /^([a-zA-Z ]{1,2})$/}];
		rules.serial_number		= [{"type": "regex", "patt": /^(T00(0[1-9]|10|11|12)[0-9][0-9]\d{5})$/}];
		rules.truck_manufacturing_year = [{type: 'integer', min: 1970, max: moment().year()}];

		return rules;
	}

	mergeObjects(obj1, obj2={}, obj3={}) {

		obj3 = obj3 || {};
		var resultObj = {};

		for(let key in obj1) {
			resultObj[key] = obj1[key];
		}

		for(let key in obj2) {
			resultObj[key] = obj2[key];
		}

		for(let key in obj3) {
			resultObj[key] = obj3[key];
		}

		return resultObj;
	}

	getCookie(name) {

		var value	= "; " + document.cookie;
		var parts	= value.split("; " + name + "=");

		if(parts.length == 2)
			return parts.pop().split(";").shift();

		return "";
	}

	lpad(str, length, padString = '0') {
		while (str.length < length)
			str = padString + str;
		return str;
	}

	fahToCel(val, reverse = false) {

		if(reverse)
			return Math.round(val * 1.8 + 32);

		return parseFloat(((val - 32 ) / 1.8).toFixed(1));
	}

	printContent(content) {
		let style = '';
		if (isDevMode()) {
			style = '<script type="text/javascript" src="styles.js"></script>';
		} else {
			let links = document.getElementsByTagName('link');
			for (let i = 0; i < links.length; i++) {
			 	let elem = links[i];
				if(/styles./.test(elem.href))
					style = '<link href="' + elem.href + '" rel="stylesheet"/>' ;
			}
		}

		let wnd = window.open('','');
		let doc = wnd.document;
		doc.open();

		doc.write([
			'<!DOCTYPE html>',
			'<html>',
			'<head>',
			style,
			'</head>',
			'<body>',
			content,
			'<script>setTimeout(function() { window.print();window.close(); } , 1000);</script>',
			'</body>'
		].join(""));
		wnd.document.close();
	}

	formatTotalDeviceNumbers(value) {
		let result, unit = '';
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		if (value < 1000) {
			result = value;

		} else if (value >= 1000 && value < 1000000) {
			result = decimalPipe.transform(value / 1000, '1.1-1');
			unit = "K";

		} else if (value >= 1000000 && value < 1000000000) {
			unit = "M";
			result = decimalPipe.transform(value / 1000000, '1.1-1');

		} else {
			unit = "G";
			result = decimalPipe.transform(value / 1000000000, '1.1-1');
		}
		return {
			result: result,
			unit: unit
		}
	}

	convertTextToDate(text) {
		const parts = text.split(' ');
		const timeValues = {
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		for (let i = 0; i < parts.length; i += 2) {
			const value = parseInt(parts[i]);
			const unit = parts[i + 1]?.toLowerCase();

			switch (unit) {
				case 'days':
					timeValues.hours += value * 24; // Convert days to hours
					break;
				case 'hours':
					timeValues.hours += value;
					break;
				case 'minutes':
					timeValues.minutes += value;
					break;
				case 'seconds':
					timeValues.seconds += value;
					break;
			}
		}

		return [timeValues.hours, timeValues.minutes, timeValues.seconds];
	}

	durationFormatter({filter: filterOption, value, filterText}) {
		if(!filterText)
			return true;

		const textTime = /(\d+)\s*(Days?|Hours?|Minutes?)/gi;
		const isTextDate = textTime.test(filterText);

		let filterTextSplitted	= isTextDate? this.convertTextToDate(filterText) : filterText.split(':');
		let filterValue: any = this.convertHourToSeconds(filterTextSplitted[0], filterTextSplitted[1] || 0, false, filterTextSplitted[2] || 0);
		let returnVal = false;
		let rowValue = value - (value % 60);
		switch (filterOption) {
			case 'lessThan':
				returnVal = rowValue < filterValue;
			break;
			case 'greaterThan':
				returnVal = rowValue > filterValue;
			break;
			default:
				returnVal = rowValue == filterValue;
			break;
		}
		return returnVal;
	}

	getTooltipKey(area, userHasNOCaccess) {
		let key;
		if(userHasNOCaccess) {
			let tempKey = area;
			tempKey += '_noc';
			if(this.translate.instant('tooltip.'+tempKey) != 'tooltip.'+tempKey)
				key = tempKey;
		}
		if(!key) {
			if(this.translate.instant('tooltip.'+area) != 'tooltip.'+area)
				key = area;
		}
		return key;
	}

	getCleanFileName(name) {
		return name.replace(/[/\\?%*:|"<>]/g, '_');
	}

	exportQuartersData(columns, data, fileName) {
		let alphabits = this.alphabetCharacters;

		let columnNames = _.pluck(columns, 'name');
		let fields = _.pluck(columns, 'key');

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});

		let dataObj = [];
		for(let quarter in data) {
			dataObj.push({a: quarter}, headerObj);
			for(let qData of data[quarter]) {
				let vals = {};
				fields.forEach((field, idx) => {
					let colId = alphabits[idx];
					vals[colId] = [null, undefined].includes(qData[field]) ? '-' : qData[field];
				});
				dataObj.push(vals);
			}
			dataObj.push({});
		}
		new AngularCsv(dataObj, fileName);
	}

	exportMonthsData(columns, data, fileName, hasYear = true) {
		let alphabits = this.alphabetCharacters;

		let columnNames = _.pluck(columns, 'name');
		let fields = _.pluck(columns, 'key');

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});

		if(!hasYear) {
			let dataObj = this.parseMonthsData(data, '', headerObj, fields);
			new AngularCsv(dataObj, fileName);
			return;
		}

		let dataObj = [];
		for(let year in data) {
			dataObj = [...dataObj, ...this.parseMonthsData(data[year], year, headerObj, fields)];
		}
		new AngularCsv(dataObj, fileName);
	}

	parseMonthsData(data, year, headerObj, fields) {
		const alphabits = this.alphabetCharacters;
		const dataObj = [];
		for(let month in data) {
			dataObj.push({a: `${moment(month, 'MM').format('MMM')} ${year}`}, headerObj);
			for(let mData of data[month]) {
				let vals = {};
				fields.forEach((field, idx) => {
					let colId = alphabits[idx];
					vals[colId] = mData[field] !== undefined ? mData[field] : 'N/A';
				});
				dataObj.push(vals);
			}
			dataObj.push({});
		}
		return dataObj;
	}

	exportTableToCsv(columnNames, elements, fileName) {
		let alphabits = this.alphabetCharacters;
		let CSVobj = [];

		let columnNamesObj = {};
		let headerObj = {};

		columnNames.forEach((column, colIdx) => {
			columnNamesObj[column.key] = column;
			let colId = alphabits[colIdx];
			columnNamesObj[column.key].colId = colId;

			if(column.key != 'options')
				headerObj[colId] = this.translate.instant(column.name);
		});

		CSVobj.push(headerObj);

		elements.forEach((element, rowIdx) => {

			let currRow = {};

			for(let key in columnNamesObj) {

				if(key == 'options')
					continue;

				let column = columnNamesObj[key]
				let value = element[column.key];

				switch(column.type) {
					case "json":
						value = JSON.stringify(value);
					break;

					case "dateTime":
						value = this.datePipe.transform(value, 'MM/dd/yyyy hh:mm:ss a');
					break;
				}

				if(column.key == 'serial_number')
					value = '*'+value;

				currRow[column.colId] = value;
			}

			CSVobj.push(currRow);
		});

		new AngularCsv(CSVobj, fileName);
	}

	exportToCsv(columnNames: string[], data: any, fileName: string) {
		const dataObj = [columnNames].slice();

		data = data.slice();
		data.forEach((column: any) => {
			const vals: any = {};
			column.forEach((col: any, idx: any) => {
				const colId = columnNames[idx];
				vals[colId] = col;
			});
			dataObj.push(vals);
		});

		new AngularCsv(dataObj, fileName);
	}

	lbToMt(value) {
		return value * 0.000453592;
	}

	extractKeysFromObjects(keysArr: string[], objectsArr: any[], unique: boolean = false, integers: boolean = false) {
		const items = [];
		const itemsValues = [];
		objectsArr.forEach(function(obj) {
			const tempObject = {};
			keysArr.forEach(function(key) {
				let val = obj[key];
				if(integers)
					val = +obj[key];

				if(val || val == 0) {
					if(unique) {
						if(itemsValues.indexOf(val) == -1)
							itemsValues.push(val);
					} else {
						itemsValues.push(val);
					}
					tempObject[key] = val;
				}
			});
			items.push(tempObject);
		});
		return items;
	}

	getUnixDateByUTCZone(date) {
		const fromDateRange = new Date(new Date(date).setHours(0, 0, 0, 0));
		const zoneDiff = new Date().getTimezoneOffset() * -1;
		return Math.round(new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000)).getTime() / 1000);
	}

	toFixedWithoutRounding(num: number, fixed: number): number {
		return Math.floor(Math.pow(10, fixed) * num) / Math.pow(10, fixed);
	}

	nowTime() {
		return moment().utc().unix();
	}

	ip2int(ip) {
		return (
			ip.split('.').reduce((ipInt, octet) => {
				return (ipInt << 8) + parseInt(octet, 10);
			}, 0) >>> 0 // to make it unsigned int
		);
	}

	int2ip(ipInt) {
		return (
			(ipInt >>> 24) +
			'.' +
			((ipInt >> 16) & 255) +
			'.' +
			((ipInt >> 8) & 255) +
			'.' +
			(ipInt & 255)
		);
	}

	getFormattedTimeByZone(time: number, zoneId: number, format = 'MM/dd/yyyy hh:mm:ss a') {
		const date = this.getDateFormattedFromUnixTimeStamp(this.getZoneTimestampFromUTC(zoneId, time));
		return this.datePipe.transform(date, format);
	}

	isChargeLink() {
		return this.commonData.chargLinkDomains.includes(window.location.hostname);
	}

	imagePacker(imageType: 'not_connected' | 'connected' | 'inuse' | 'idle', isChargeLink?: boolean, isStudyDevice?: boolean) {
		let imageLink = '/images';
		if (isChargeLink)
			imageLink += '/chargLink/charger';
		else
			imageLink += '/forklift';

		switch (imageType) {
			case "not_connected":
				imageLink += `_gray${isChargeLink ? '_v2' : '' }.png`;
				if (isStudyDevice)
					imageLink = 'images/studies/not_connected.png';
				break;
			case "connected":
				imageLink += `_connected${isChargeLink ? '_v2' : '' }.png`;
				if (isStudyDevice)
					imageLink = 'images/studies/connected.png';
				break;
			case "inuse":
				imageLink = '/images/forklift_inuse.gif';
				break;
			case "idle":
				imageLink = '/images/forklift_idle.png';
				break;
		}
		return imageLink;
	}

	exportTotalUsageWidget(data, chargLinkTranslationPath = "") {
		let alphabits = this.alphabetCharacters;
		let columnNames = [
			this.translate.instant('g.date'),
			this.translate.instant('g.time'),
			this.translate.instant('g.kw'),
			this.translate.instant('g.amp')
			];

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];
		const fromDate	= moment(data.startDate).format('YYYY-MM-DD');
		const toDate	= moment(data.endDate).format('YYYY-MM-DD');

		let fileName = this.getCleanFileName(data.siteName) +
			'_' + fromDate + '_' + toDate
			+ '_' + this.translate.instant(chargLinkTranslationPath + 'site_performance.total_truck_usage');
		let colLength = Object.keys(headerObj).length;
		let dataObj = header.slice();

		let chartData = data.usageAmpData.slice();  // AMP data
		const allWattData = _.map(data.usageWattData, (subarray) => subarray[1]);  // watt data

		 // Insert watt data into amp data array
		_.forEach(chartData, (subarray, index) => {
			const wattData = allWattData[index];
			const date = subarray[0];
			subarray[0] = moment(subarray[0]).format('YYYY-MM-DD');
			const time = moment(date).format('HH:mm:ss');
			subarray.splice(1,0, time);
			subarray.splice(2, 0, wattData);
			});

		chartData.forEach((column) => {
			let vals = {};
			column.forEach((col, idx) => {
				if(idx >= colLength)
					return;
				let colId = alphabits[idx];
				vals[colId] = col;
			});
			dataObj.push(vals);
		});
		new AngularCsv(dataObj, fileName);
	}

	getUnixTimezoneByLocaltime(timestamp: number) {
		return Math.floor(new Date().getTimezoneOffset() * 60 + timestamp);
	}

	getMotiveEnergyCustomerData() {
		const hostname = window.location.hostname;
		const productionLinks = [this.commonData.productionDomain, this.commonData.charglinkProductionDomain];
		const stagingLinks = [this.commonData.stagingDomain, this.commonData.charglinkStagingDomain];

		const isProduction = productionLinks.includes(hostname);
		const isStaging = stagingLinks.includes(hostname);

		const motiveEnergyEnterprise = isProduction ? 36 : (isStaging ? 36 : 77);
		const motiveEnergyCustomerId = isProduction ? 337 : (isStaging ? 204 : 51);
		const motiveEnergySiteId = isProduction ? 532 : (isStaging ? 278 : 79);

		return {
			motiveEnergyEnterprise: motiveEnergyEnterprise,
			motiveEnergyCustomer: motiveEnergyCustomerId,
			motiveEnergySite: motiveEnergySiteId
		};
	}

	mapCellularRssi(cellular_rssi: number) {
		switch (true) {
			case cellular_rssi == 0:
				return this.translate.instant('cellular_rssi_values.zero_cellular_rssi');
			case cellular_rssi == 1:
				return this.translate.instant('cellular_rssi_values.one_cellular_rssi');
			case cellular_rssi >= 2 && cellular_rssi <= 30:
				return ((Math.abs(cellular_rssi) - 30) * 2 - 53) + " dBm";
			case cellular_rssi == 31:
				return this.translate.instant('cellular_rssi_values.mid_cellular_rssi');
			case cellular_rssi > 31 && cellular_rssi <= 98:
				return this.translate.instant('cellular_rssi_values.unknown_cellular_rssi', { cellular_rssi });
			case cellular_rssi == 99:
				return this.translate.instant('cellular_rssi_values.cellular_rssi_unknown_or_undetectable');
			default:
				break;
		}
	}

	adjustMacSearchString(searchString: any) {
		const obj = {
			mac_address: '',
			serial_number: '',
		};

		let isValidMac = this.validationService.macRegex.test(searchString);
		if (isValidMac) {
			obj.mac_address = searchString;
			return obj;
		}

		try {
			searchString = JSON.parse(searchString);
			obj.mac_address = searchString.M || searchString.m || '';
			const serialNumberKey = Object.keys(searchString).find(key => key.toLowerCase() === 'sn'); // support the keys [sn, Sn, sN, SN]
			obj.serial_number = serialNumberKey ? searchString[serialNumberKey] || '' : '';
			return obj;
		} catch (ex) {
			if (typeof searchString == 'string' && searchString.length == 24) {
				const mac = searchString.slice(0, 12);
				const sn = searchString.slice(12, 24);
				isValidMac = this.validationService.macRegex.test(mac);
				if (isValidMac) {
					obj.mac_address = mac;
					obj.serial_number = sn;
					return obj;
				} else if (this.validationService.macRegex.test(sn)) {
					obj.mac_address = sn;
					obj.serial_number = mac;
					return obj;
				}
			}

			return searchString;
		}
	}

	asciiValue (str: string) {
		let sum = 0;
		for (let i = 0; i < str.length; i++) {
			sum += str.charCodeAt(i);
		}
		return sum;
	}

	sortDataAlphabetically(data: string[], fieldName: string) {
		return data.sort((item1, item2) => item1[fieldName].toLowerCase() > item2[fieldName].toLowerCase() ? 1 : -1);
	}

	handleLinkClickEvents(event) {
		// Allow Ctrl+Click, Cmd+Click (for macOS), or middle-click to open in a new tab
		if (event.ctrlKey || event.metaKey || event.button === 1) {
			return; // Do nothing, allow the browser to handle it
		}

		// For normal left-clicks, prevent the default behavior to avoid page reload
		event.preventDefault();
	}
}
