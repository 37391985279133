import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'app-update-device-fw',
	templateUrl: './update-device-fw.component.html',
	styleUrls: ['./update-device-fw.component.css']
})
export class UpdateDeviceFwComponent implements OnInit {
	macAddress!: string;
	fileName: string = ''
	deviceFWtoPush: number;
	fwToPush: number;
	siteSubscription:any;
	currentSite:any;
	fwList: Array<Object> = [];
	blockkedFWVerions: any [] = [];
	@Input() device: any = {};

	column: ColumnsConfig [] = [
		{ key: 'serial_number', name: this.translateService.instant('devices.serial_number'), type: 'string' },
		{ key: 'firmware_version', name: this.translateService.instant('devices.fw_version'), type: 'number' },
		{ key: 'blocked_until', name: this.translateService.instant('devices.blocked_until'), type: 'timestamp' },
		{ key: 'reason', name: this.translateService.instant('g.reason'), type: 'string' },
	];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
	};

	constructor(
		private deviceManagementService: DeviceManagementService,
		private usersService: UsersService,
		private router: Router,
		private sctToastService: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		if (!this.usersService.hasAccessFunction('test_fw')) {
			this.router.navigate(['/unauthorized']);
			return;
		}
		this.macAddress = this.device.mac_address;
		this.deviceFWtoPush = this.device.config_info.testing_fw ? this.device.config_info.firmware_version_to_push || 0 : 0;
		this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(!siteInfo['id'])
				return;

			this.currentSite = siteInfo;
		})

		this.getTestingFWFilesList();
	}

	getTestingFWFilesList() {
		const blockkedFWVerions = [];
		this.deviceManagementService.getTestingFWFilesList(this.macAddress).subscribe((res: any) => {
			const fwList = [];
			res.fwVersions?.map(fwVersion => fwList.push({label: fwVersion, value: fwVersion}));
			this.fwList = fwList;

			[...res.blockedFWData || []].forEach((row) => {
				let rowData: TableData = {
					serial_number: { value: row.serial_number },
					firmware_version: { value: row.version },
					blocked_until: { value: +row.time * 1000 },
					reason: { value: this.translateService.instant('blocked_fw_reasons.'+(row.reason || 'na')) }
				}
				blockkedFWVerions.push(rowData);
			});

			this.blockkedFWVerions = blockkedFWVerions;
		});
	}

	pushFW(){
		this.deviceManagementService.pushDeviceFW(this.macAddress, this.fwToPush).subscribe((res) => {
			if (res === 2)
				return this.sctToastService.setMessage('translate|site.fw_already_pushed_device');

			// if (res === 3)
			// 	return this.sctToastService.setMessage('translate|site.fw_already_pushed');

			if (!res)
				return this.sctToastService.setMessage('globalErrMsg');

			this.sctToastService.setMessage('globalSuccessMsg');
		});
	}

	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();
	}
}
