import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-time-lost-report',
	templateUrl: './time-lost-report.component.html',
	styleUrls: ['./time-lost-report.component.css']
})
export class TimeLostReportComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		draggable: true,
		fileName: this.translate.instant('nav.admin.time_lost_report'),
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'unique_mac_address', name: this.translate.instant('devices.unique_mac_address'), type: "link"},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: "link"},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'rtc_lost', name: this.translate.instant('devices.rtc_lost'), type: 'boolean'},
		{ key: 'last_cm_date_tested', name: `${this.translate.instant('devices.last_cm_date_tested')} (${this.translate.instant('g.utc')})`, type: 'timestamp'},
		{ key: 'lost_rtc_time', name: `${this.translate.instant('devices.lost_rtc_time')}  (${this.translate.instant('g.utc')})`, type: 'timestamp'},
	];

	devices: any[] = [];
	sites: any;
	customers: any;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private deviceService: DeviceService,
		public translate: TranslateService,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('show_warnings'))
			return this.router.navigate(['/unauthorized']);
		this.getTimeLostReport();
	}

	getTimeLostReport() {
		this.deviceService.getTimeLostReport().subscribe((res: any) => {
			this.devices = res.devices;
			this.sites = res.sites;
			this.customers = res.customers;

			const tableData = [];
			for(const device of res.devices) {
				const site = this.sites[device.site_id];
				const customer = this.customers[site?.customer_id];

				tableData.push({
					unique_mac_address: {value: device.mac_address, link: ['/', customer?.id, site?.id, device.mac_address, 'performance']},
					site_name: {value: site?.name || '', link: ['/', customer?.id, site?.id]},
					customer_name: {value: customer?.name || '', link: ['/', customer?.id]},
					rtc_lost: {value: device.lost_rtc == 1},
					last_cm_date_tested: {value: device.last_cm_date_tested},
					lost_rtc_time: {value: device.last_cm_date_tested},
				})
			}

			this.tableData = tableData;
		});
	}
}
