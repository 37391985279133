import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../site-dashboard/site-dashboard.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { TableConfig } from 'src/app/shared/custom-table/custom-table-interface';
import { DecimalPipe } from '@angular/common';

@Component({
	selector: 'app-usage-data',
	templateUrl: './usage-data.component.html'
})
export class UsageDataComponent implements OnInit, OnChanges {
	chargLinkTranslationPath: string = "";

	@Input() isChargeLinkDevices?: boolean = false;
	@Input() data: any = [];
	@Input() columns: any = [];
	@Input() devices: any[];
	@Input() sites: any[];
	@Input() parentPage: string = 'device'; // options are: customer, site, device
	@Input() options: {hasTruckTypeFilter, hasYearFilter, hasStackedColumn, hasSiteFilter} = {hasTruckTypeFilter: false, hasYearFilter: false, hasStackedColumn: false, hasSiteFilter: false};
	@Input() tags: any[] = [];
	@Input() siteInfo: any = {};

	selectedYear = moment().utc().get('year');
	selectedTruckType = '';
	selectedTruckYear = '';
	selectedSites = [];
	selectedTags = [];

	exportFileName = '';

	selectedTagNames = [];

	ddList = {
		truckTypes: [],
		truckYearsList: [],
	};
	recentData: any = [];
	recentColumns: any = [];
	recentFileName: string = '';

	hasData = false;

	tooltips = '';

	truckTypesMapper = {};

	readonly chargLinkTruckType = 'chargLink';

	tableData = [];
	tableColumns = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: false,
		fileName: '',
		draggable: true,
	};

	constructor(
		private translate: TranslateService,
		public common: CommonService,
		public commonData: CommonDataService,
		private userService: UsersService,
		private siteDashboardService: SiteDashboardService,
	){}

	ngOnInit() {
		this.chargLinkTranslationPath = this.isChargeLinkDevices ? this.commonData.chargLinkTranslationPath : "";
		this.data = this.data.slice(1);
		this.hasData = !!this.data.length;

		this.tooltips = this.common.getTooltipKey('recent_data_'+this.parentPage, this.userService.userHasNOCAccess());
		this.tooltips = this.tooltips ? this.chargLinkTranslationPath + this.tooltips : this.tooltips;

		this.ddList.truckYearsList = [];
		for(let item of ['all', 'new', 'old']) {
			this.ddList.truckYearsList.push({
				value: item == 'all' ? '' : item,
				label: this.translate.instant('site_performance.truck_year_'+item)
			});
		}

		this.formatDataAndColumns(this.data, this.columns);
	}

	ngOnChanges(changes) {
		if(!changes.data?.firstChange) {
			this.data = this.data.slice(1);
			this.formatDataAndColumns(this.data, this.columns);
		}

		this.hasData = !!this.data.length;
		this.chargLinkTranslationPath = this.isChargeLinkDevices ? this.commonData.chargLinkTranslationPath : "";

		this.tooltips = this.common.getTooltipKey('recent_data_'+this.parentPage, this.userService.userHasNOCAccess());
		this.tooltips = this.tooltips ? this.chargLinkTranslationPath + this.tooltips : this.tooltips;

		if(this.parentPage == 'device')
			this.exportFileName = `${this.siteInfo.name} - ${this.devices[0].config_info.truck_id} - ${this.isChargeLinkDevices ? 'Charger' : 'Truck'} Usage Data`;
		else {
			this.exportFileName = `${this.siteInfo.name} - ${this.isChargeLinkDevices ? 'Charger' : 'Truck'} Usage Data`;
			let truckTypesOptions: any = [
				{id: '', text: this.translate.instant(this.chargLinkTranslationPath + 'site_performance.all_truck_types')}
			];

			_.uniq(_.pluck(this.devices, 'truck_type')).forEach((truckType, idx) => {
				const truckTypeKey = truckType.replace(/[^\w]/gi, '_');
				this.truckTypesMapper[truckTypeKey] = truckType;
				if (!this.isChargeLinkDevices || this.parentPage == 'device') {
					const record: {id: number, text: string} = {id: truckTypeKey == '' ? '%' : truckTypeKey, text: truckType};
					truckTypesOptions.push(record);
				}
			});

			this.ddList.truckTypes = truckTypesOptions;

			this.recentFileName = this.translate.instant(this.chargLinkTranslationPath + 'perf_analytic.recent_data');
		}
		this.tableConfig.fileName = this.exportFileName;
	}

	onSelectedTagsChanges(event) {
		this.selectedTags = event;

		this.selectedTagNames = [];
		for(let tagId of event) {
			let tag = _.findWhere(this.tags, {id: tagId})
			if(tag)
				this.selectedTagNames.push(tag.tag_name)
		}
		this.buildSiteCharts();
	}

	private buildSiteCharts() {
		const ids = this.getUsageIds();
		this.siteDashboardService.getTruckUsageData(ids, 'recent', {truckYear: this.selectedTruckYear, truckType: this.truckTypesMapper[this.selectedTruckType], truckTag: this.selectedTags, parentPage: this.parentPage}).subscribe((res: any) => {
			const dataType = this.isChargeLinkDevices ? this.chargLinkTruckType : 'iotah';

			this.data = res[dataType].slice(1);
			this.columns = res[dataType][0];

			this.formatDataAndColumns(this.data, this.columns);
		});
	}

	getUsageIds() {
		let ids;
		switch (this.parentPage) {
			case 'customer':
				ids = (this.selectedSites.length ? this.selectedSites : this.sites.map((item) => item.id));
			break;
			case 'site':
				ids = this.siteInfo.id;
			break;
			case 'device':
				ids = this.devices[0].mac_address;
			break;
		}
		return ids;
	}

	rebuildPerformanceCharts() {
		return this.buildSiteCharts();
	}

	formatDataAndColumns(data: any, columns: any) {
		this.tableColumns = [];
		const tableData = [];
		const filterParams = {};
		const decimalPipe: DecimalPipe = new DecimalPipe("en-US");

		for (const col of columns) {
			if (col.filterParams?.format)
				filterParams[col.field] = col.filterParams?.format;

			this.tableColumns.push({ key: col.field, name: this.translate.instant(col.headerName), type: col.type || 'string' });
		}

		for (const row of data) {
			const rawData = {};
			for (const key in row) {
				let displayValue = row[key];
				if (key != 'period')
					displayValue = decimalPipe.transform(row[key], filterParams[key] || '1.0-3');

				rawData[key] = { value: row[key], displayValue };
			}

			tableData.push(rawData);
		}

		this.tableData = tableData;
	}
}
