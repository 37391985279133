<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div class="d-flex justify-content-between align-items-center mb-3">
		<h1 class="h2 mb-4">{{'shipment.shipment_history' | translate}}</h1>
		<button class="btn btn-primary mt-4" (click)="exportReceivedShipmentData.show()" >{{'g.export' | translate}}</button>
	</div>

	<sct-custom-table
		[columnsConfig]="columns"
		[data]="dataTable"
		[config]="tableConfig">
	</sct-custom-table>
</div>

<app-modal #isShipmentHistoryDialog  title="{{'g.device' | translate}}" size="lg" [hasConfirmButton]="false">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'g.serial_number' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of selectedDevices">
				<td> {{device.mac_address}} </td>
				<td> {{device.serial_number}} </td>
			</tr>
		</tbody>
	</table>
</app-modal>

<app-modal #downloadFilesDialog
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	[size]="'lg'"
	[disableConfirm]="getDownloadButtonStatus()"
	>
	<div dialog-content>
		<p class="text-danger" *ngIf="noSelectedAttachmentsFilesErrMsg">{{noSelectedAttachmentsFilesErrMsg}}</p>
		<div *ngFor="let index of orderAttachedFiles | keys">
			<div class="form-check">
				<input class="form-check-input" type="checkbox" [checked]="selectedFilesToDownload[orderAttachedFiles[index]]" id="{{index}}" (change)="selectFile($event, orderAttachedFiles[index])">
				<label class="form-check-label" for="{{index}}">
					{{orderAttachedFiles[index]}}
				</label>
			</div>
		</div>
		<div class="modal-footer" dialog-footer>
			<button class="btn btn-light" (click)="downloadFilesDialog.hide(); openAddDialog(selectedHistory)">
				<fa-icon icon="plus" size="1x" class="pointer text-success ms-3" title="attachments"></fa-icon>
				{{ 'g.add' | translate }}
			</button>
			<button type="submit" class="btn btn-primary" (click)="confirmDownloadAttachedFiles()">{{'ready_to_ship.download' | translate}}</button>
			<button class="btn btn-danger" type="button" (click)="confirmDeleteAttachedFiles()">{{'g.delete' | translate}}</button>
			<button type="button" class="btn btn-light" (click)="downloadFilesDialog.onHide()">{{'g.cancel' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal #addAttachmentsDialog
	[title]="'attachments.attachments' | translate"
	[hasConfirmButton]="false"
	[hasCloseButton]="false"
	[size]="'lg'"
	>
	<div dialog-content>
		<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
			<div *ngIf="showValidationErrors()" class="alert alert-danger" role="alert">
				<h6 class="alert-heading">{{'g.errors' | translate}}</h6>
				<ul>
					<li *ngIf="selectedFilesValidations.min_count">{{'attachments.files_min_count_err' | translate}}</li>
					<li *ngIf="selectedFilesValidations.max_count">{{'attachments.files_max_count_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFilesCount}})</li>
					<li *ngIf="selectedFilesValidations.extension">{{'attachments.extension_err' | translate}}: ({{attachmentsService.allowedUploadFilesExt.join(', ')}})</li>
					<li *ngIf="selectedFilesValidations.size">{{'attachments.size_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFileSize / attachmentsService.mbBytes}} MB)</li>
					<li *ngIf="selectedFilesValidations.note">{{'attachments.note_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxNoteCharacters}})</li>
				</ul>
			</div>
			<div class="row mb-2">
				<label class="col-md-4 col-form-label">
					{{'attachments.choose_files' | translate}}
				</label>
				<div class="col-md-6">
					<input #uploadAttachmentsBtn type="file" class="form-control" [accept]="attachmentsService.allowedUploadFilesExt.join(', ')" id="actual-btn" name="files" [(ngModel)]="selectedFiles" multiple (change)="selectFiles($event)">
				</div>
			</div>

			<div class="mt-5 d-flex flex-row-reverse">
				<button class="btn btn-light" (click)="addAttachmentsDialog.hide()">{{'g.close' | translate}}</button>
				<button class="btn btn-primary me-2" (click)="addAttachments()" [disabled]="!selectedFiles.length">{{'g.submit' | translate}}</button>
			</div>
		</form>
	</div>
</app-modal>

<app-modal #successAttachmentsDialog
	[title]="'attachments.success_attachments' | translate"
	[hasConfirmButton]="false"
	[hasCloseButton]="false"
	[size]="'lg'"
	>
	<div dialog-content>
		<table class="table table-striped">
			<thead>
				<th>{{'g.file_name' | translate}}</th>
				<th>{{'g.status' | translate}}</th>
			</thead>
			<tbody>
				<tr *ngFor="let file of selectedFiles">
					<td>{{file.name}}</td>
					<td *ngIf="!successAttachments[file.name] && !filesAlreadyUploaded[file.name]">
						<div class="spinner-border spinner-border-sm text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</td>
					<td *ngIf="successAttachments[file.name]" class="text-success"><i class="fa fa-check"></i></td>
					<td *ngIf="filesAlreadyUploaded[file.name]" class="text-danger">{{'attachments.file_already_attached' | translate}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-modal>

<app-modal #deleteConfirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate"></app-modal>

<app-modal #exportReceivedShipmentData [hasConfirmButton]="false" [hasCloseButton]="false" [title]="'g.export' | translate">
	<div class="row">
		<div class="col-md-12 mb-3">
			<label class="form-label mr-2"><span>{{'g.from'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="fromDate" placeholder="{{'g.search' | translate}}" class="form-control mr-2" required />
		</div>

		<div class="col-md-12">
			<label class="form-label mr-2"><span>{{'g.to'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="toDate" placeholder="{{'g.search' | translate}}" class="form-control" required />
		</div>
		<p *ngIf="notValidPopupDate" class="pl-3 pt-3 text-danger">{{'g.start_date_before_end_date' | translate}}</p>
	</div>
	<div class="mt-3 d-flex justify-content-end">
		<button class="btn btn-md btn-primary" (click)="generateShipmentHistoryReport()">
			{{'devices.export_csv' | translate}}
		</button>
	</div>
</app-modal>
