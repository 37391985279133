import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-contact-us-admin',
	templateUrl: './contact-us-admin.component.html',
	styleUrls: ['./contact-us-admin.component.css']
})
export class ContactUsAdminComponent implements OnInit, AfterViewInit {
	@ViewChild('dataTable') dataTable;
	@ViewChild("confirmModal") confirmModal;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 20,
		draggable: true,
		hasSelectionColumn: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: this.translateService.instant('g.id'), type: "string"},
		{ key: 'time', name: this.translateService.instant('g.time'), type: 'timestamp' },
		{ key: 'subject', name: this.translateService.instant('contact_us.subject'), type: "string" },
		{ key: 'body', name: this.translateService.instant('contact_us.body'), type: "string" },
		{ key: 'user_id', name: this.translateService.instant('users.user_id'), type: "number" },
		{ key: 'user_name', name: this.translateService.instant('login_register.user_name'), type: "string" },
		{ key: 'user_ip', name: this.translateService.instant('error_log.user_ip'), type: "string" },
		{ key: 'user_agent', name: this.translateService.instant('error_log.user_agent'), type: "string" },
		{ key: 'options', name: this.translateService.instant('g.options'), type: "dropdown" },
	];

	selectedIds: any;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public usersService: UsersService,
		private adminService: AdminService,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService
	) { }

	ngOnInit() {
		this.getContactUs();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteLogs') {
				return this.deleteLogs();
			}
			if(result[1][0] == 'delete') {
				return this.delete(result[1][1]);
			}
		});
	}

	confirmDelete(action, data?) {
		this.confirmModal.passData = [action, data];
		this.confirmModal.message = 'g.confirm_delete_message';
		this.confirmModal.show();
	}

	getContactUs() {
		this.adminService.getContactUs().subscribe(
			(data: any) => {
				const returnData = data.slice();
				const hasAccessFunction = this.usersService.hasAccessFunction('admin_contact_us', 'write')

				const tableData = [];
				for(const data of returnData) {
					const options = [{action: ()=> {this.doAction({action: "view", element: {id: data.id}})}, text: this.translateService.instant('g.view')}];
					if (hasAccessFunction)
						options.push({action: ()=> {this.doAction({action: "delete", element: {id: data.id}})}, text: this.translateService.instant('g.delete')});

					tableData.push({
						id: {value: data.id},
						time: {value: data.insertion_time},
						subject: {value: data.subject},
						body: {value: data.body},
						user_id: {value: data.user_id},
						user_name: {value: data.username},
						user_ip: {value: data.ip_address},
						user_agent: {value: data.browser},
						options: {value: data.options, options: options},
					})
				}

				this.tableData = tableData;
			}
		);
	}

	delete(id) {
		this.adminService.deleteContactUs(id).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage('translate|g.deleted_successfully',{clearOnXTimeNavigate: 1, type: 'success'});
					this.tableData = this.tableData.filter(data => !this.selectedIds.includes(data.id.value));
				}
			}
		);
	}

	deleteLogs() {
		this.adminService.deleteContactUs(this.selectedIds).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage('translate|g.deleted_successfully',{clearOnXTimeNavigate: 1, type: 'success'});
					this.tableData = this.tableData.filter(data => !this.selectedIds.includes(data.id.value));
					this.getContactUs();
				}
			}
		);
	}

	doAction(event) {
		if(event.action == 'view') {
			let url = this.router.createUrlTree([event.element.id], {relativeTo: this.route});
			return window.open('#'+url.toString(), "_blank");
		}
		if(event.action == 'delete')
			return this.confirmDelete('delete', event.element.id);
	}

	updateSelectedRows(rows) {
		this.selectedIds = rows.map(row => row.id.value);
	}
}
