<div>
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
