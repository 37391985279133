<div class="white-box p-4">
	<div *ngIf="!deviceFWtoPush">
		<div class="mb-3 d-flex">
			<label class="mr-2 mt-1 fw-bolder text-nowrap">
				{{'devices.fw_version' | translate}}
			</label>
			<div class="col-md-3">
				<ng-select
					[items]="fwList"
					bindLabel="label"
					bindValue="value"
					placeholder="{{'devices.fw_version' | translate}}"
					name="fw_version"
					[(ngModel)]="fwToPush"
					[clearable]="false"
					required
				></ng-select>
			</div>
		</div>
		
		<div class="w-100 d-flex flex-column align-items-end">
			<button
				[disabled]="!fwToPush"
				class="btn main-blue-bg text-white px-2 rounded-lg"
				(click)="pushFW()">
				{{'g.submit' | translate}}
			</button>
		</div>
	</div>
	
	<div *ngIf="deviceFWtoPush">
		<h3 class="alert-info p-3">
			{{'devices.updating_fw' | translate:{version: deviceFWtoPush} }}
		</h3>
	</div>

	<div class="mt-5" *ngIf="blockkedFWVerions.length">
		<sct-custom-table
			[columnsConfig]="column"
			[config]="tableConfig"
			[data]="blockkedFWVerions">
		</sct-custom-table>
	</div>
</div>
