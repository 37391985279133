import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'app-date-range-select',
	templateUrl: './date-range-select.component.html',
	styleUrls: ['./date-range-select.component.css']
})
export class DateRangeSelectComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() activePage: string;
	@Input() showLeftRightArrows: boolean;
	@Input() withBorder = true;
	@Input() minDate: Date;
	@Input() maxDate: Date;

	fromDate: Date;
	toDate: Date;
	showDateRange: string;
	invalidDateRange: boolean = false;
	invalidFromDateInput: boolean = false;
	invalidToDateInput: boolean = false;
	deviceInstallationDate: any = null;
	minSelectedDate!: number;
	calenderDisabled: boolean = false;
	@Output() onDatesChanged = new EventEmitter<Object>();

	readonly oneDayInSeconds = 24 * 60 * 60 * 1000;
	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges(changes) {
		if (changes.activePage)
			this.activePageChanged();

		if(changes.device && changes.device.currentValue.mac_address){
			
			this.deviceInstallationDate = null;
			if(changes.device.currentValue.config_info.installation_date > 0){
				this.deviceInstallationDate = new Date(changes.device.currentValue.config_info.installation_date * 1000);
				this.minSelectedDate = moment(this.deviceInstallationDate).startOf('day').unix();
			}
			this.activePageChanged();

			if(this.fromDate) {
				if(this.fromDate < this.deviceInstallationDate) {
					this.fromDate = this.deviceInstallationDate;
				}
			}
		}
	}

	activePageChanged() {
		
		if(['connectivity-track', 'actions-history', 'events', 'rt-records', 'daily-details', 'cellular-track', 'config-track'].indexOf(this.activePage) != -1) {
			this.showDateRange = 'both';
			if(this.activePage == "rt-records")
				this.showDateRange = 'from';
		} else {
			this.showDateRange = null;
		}

		if(this.showDateRange) {
			
			this.toDate = new Date(new Date().setHours(23,59,59,999));
			
			switch(this.activePage) {
				case 'rt-records':
					this.fromDate	= new Date(moment().utc().startOf('day').unix() * 1000);
					this.toDate		= null;
				break;
				case 'connectivity-track':
					this.fromDate	= new Date(moment().utc().subtract(1, 'days').startOf('day').unix() * 1000);
				break;
				case 'actions-history':
				case 'cellular-track':
				case 'config-track':
					this.fromDate	= new Date(moment().utc().subtract(30, 'days').startOf('day').unix() * 1000);
				break;
				case 'debug-records':
					this.fromDate	= new Date(moment().utc().subtract(6, 'days').startOf('day').unix() * 1000);
				break;
				default:
					this.fromDate	= new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0));
				break;
			}
			if(this.deviceInstallationDate) {
				if(this.fromDate < this.deviceInstallationDate) {
					this.fromDate = this.deviceInstallationDate;
				}
			}
			this.datesChanged();
		}
	}

	datesChanged() {
		this.invalidDateRange = false;
		let dates = {
			'fromDate':	this.fromDate,
			'toDate':	this.toDate
		};

		this.validateDateRange(dates);
		const selectedDate = moment(dates.fromDate).startOf('day').unix();
		this.calenderDisabled = selectedDate == this.minSelectedDate? true: false;

		if(moment(this.fromDate).unix() > moment(this.toDate).unix())
			this.invalidDateRange = true;
		this.onDatesChanged.emit({dates, invalidRange: this.invalidDateRange});
	}

	validateDateRange(dates) {
		if (isNaN(dates.fromDate))
			this.invalidFromDateInput = true;
		if (isNaN(dates.toDate))
			this.invalidToDateInput = true;

		if (this.invalidFromDateInput || this.invalidToDateInput)
			return;
	}

	handleDateChange(forward: boolean) {
		let startDate = this.fromDate;
		let endDate = this.toDate;
		if (forward && this.showDateRange == 'both') {
			endDate = new Date(endDate.getTime() + this.oneDayInSeconds);
		} else {
			if (forward)
				startDate = new Date(startDate.getTime() + this.oneDayInSeconds);
			else
				startDate = new Date(startDate.getTime() - this.oneDayInSeconds);
		}

		const selectedDate = moment(startDate).startOf('day').unix();
		this.calenderDisabled = selectedDate == this.minSelectedDate;

		this.fromDate = startDate;
		this.toDate = endDate;
		this.datesChanged();
	}
}