import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { DeviceService } from '../../device.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';
import { ColumnsConfig, CustomTableBackendData, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
@Component({
  selector: 'app-debug-records',
  templateUrl: './debug-records.component.html',
  styleUrls: ['./debug-records.component.css']
})
export class DebugRecordsComponent implements OnInit, OnChanges {
	@Input() device: any = {};
	@Output() onDeviceDataChange = new EventEmitter<Object>();
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	debugRecords: any[] = [];

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		isBackendPagination: true
	};

	columnDefs = [
		{headerName: 'events.record_id', field: 'record_id', type: 'number', filterParams: {format: 'noFormat'}, width: 150},
		{headerName: 'g.info', field: 'info', width: 1100}
	];

	maxDebugHours: number = 48;
	force_read_debug_time: number = null;
	forceReadDebugUntil: string = '';

	constructor(
		private deviceService: DeviceService,
		public usersService: UsersService,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessPermission(null, 'read_debug_records'))
			return this.router.navigate(['/unauthorized']);

		this.columnConfig = [
			{ key: 'record_id', name: this.translateService.instant('events.record_id'), type: "number"},
			{ key: 'info', name: this.translateService.instant('g.info'), type: "string"}
		];

		if(this.device.config_info && this.device.config_info.force_read_debug_time)
			this.getDebugDate();
	}

	ngAfterViewInit() {
		this.getDebugRecords({currentPage: 1, isFirstTime: true});
	}

	ngOnChanges(changes) {
		if (changes.device.previousValue && changes.device.previousValue.mac_address !=changes.device.currentValue.mac_address && this.sctCustomTable) {
			this.getDebugRecords({currentPage: 1, isFirstTime: true});
		}
	}

	getDebugRecords(paginationData) {
		if(!this.sctCustomTable || !this.device.mac_address)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime
		};

		this.deviceService.getDebugRecords(this.device.mac_address, options).subscribe((logs: CustomTableBackendData) => {
			const tableData = [];
			logs.data.forEach((log: any) => {
				tableData.push({
					record_id: { value: log.record_id },
					info: { value: log.info },
				});
			});
			this.tableData = tableData;
			this.sctCustomTable.updatePagination(logs.totalDataCount, logs.overallItemsCount);
		});
	}
	
	getDebugDate(force_read_debug_time?) {
		force_read_debug_time = force_read_debug_time || this.device.config_info.force_read_debug_time;
		if(force_read_debug_time && typeof(force_read_debug_time) == 'number')
			this.forceReadDebugUntil = moment.unix(force_read_debug_time || 0).utc().format('MM/DD/YYYY H:mm');
	}

	saveDebugDate() {
		if(this.force_read_debug_time > this.maxDebugHours || this.force_read_debug_time < 0)
			return this.notificationMessage.setMessage(this.translateService.instant('force_read_debug.select_time_more_max_time'));
		this.deviceManagementService.setForceReadDebugDate(this.device.mac_address, this.force_read_debug_time).subscribe((data: any) => {
			this.onDeviceDataChange.emit(data);
			this.getDebugDate(data.force_read_debug_time);
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		})
	}

	forceReadDebugNow() {
		this.deviceManagementService.setForceDebugRecordNow(this.device.mac_address).subscribe((data: any) => {
			this.onDeviceDataChange.emit(data);
			this.getDebugDate(data.debug_synch_time);
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}
}
