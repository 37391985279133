import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { AdminService } from '../admin.service';
import { UsersService } from '../../users/users.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsConfig, TableConfig, TableData } from '../../shared/custom-table/custom-table-interface';
import * as moment from 'moment';

@Component({
	selector: 'app-registration-requests',
	templateUrl: './registration-requests.component.html',
})
export class RegistrationRequestsComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;

	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 50,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'first_name', name: this.translateService.instant('login_register.first_name'), type: "string" },
		{ key: 'last_name', name: this.translateService.instant('login_register.last_name'), type: 'string' },
		{ key: 'company_name', name: this.translateService.instant('login_register.company_name'), type: "string" },
		{ key: 'request_date', name: this.translateService.instant('g.requested_time'), type: "date" },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
	) {}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('registration_requests'))
			return this.router.navigate(['/unauthorized']);

		this.getRegistrationRequests();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.deleteRegistrationRequest(result[1]);
		});
	}

	confirmDeleteRegistrationRequest(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'g.del_user_prompt';
		this.confirmModal.show();
	}

	getRegistrationRequests() {
		this.adminService.getRegistrationRequests().subscribe(
			(data: any) => {
				const tableData: TableData[] = [];

				[...data].forEach(row => {

					let rewData: TableData  = {
						row,
						first_name: {value: row.first_name},
						last_name: {value: row.last_name},
						company_name: {value: row.company_name},
						request_date: {value: moment.utc(row.request_date).format('MM/DD/YYYY hh:mm:ss A')},
						actions: {
							options: [
								{
									text: this.translateService.instant('g.validate'),
									link: ['/admin-panel/validate-registration-request', row.id]
								},
								{
									text: this.translateService.instant('g.reject'),
									action: (data: any)=>{
										this.confirmDeleteRegistrationRequest(data.row)
									}
								}
							]
						}
					};
					tableData.push(rewData);
				})

				this.tableData = tableData;
			}
		);
	}

	deleteRegistrationRequest(registrationRequest) {
		registrationRequest.isrejected = true;

		this.adminService.submitRegistrationRequest(registrationRequest).subscribe(() => {
			this.tableData = this.tableData.filter((data: any) => data.row.id != registrationRequest.id);
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}
}
