import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceManagementService } from '../../../device/device-management.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SitesService } from 'src/app/sites/sites.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-device-listing-admin',
	templateUrl: './device-listing-admin.component.html',
	styleUrls: ['./device-listing-admin.component.css']
})
export class DeviceListingAdminComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
		hasSelectionColumn: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: this.translateService.instant('g.id'), type: "id", hidden: true},
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: "string"},
		{ key: 'serial_number', name: this.translateService.instant('devices.serial_number'), type: 'string' },
		{ key: 'truck_sn', name: this.translateService.instant('devices.truck_sn'), type: "string" },
		{ key: 'hw_version', name: this.translateService.instant('devices.hw_version'), type: "string" },
		{ key: 'options', name: this.translateService.instant('g.options'), type: "dropdown"},
	];

	customerid: number;
	siteid: number;
	devices: any;
	selectedDevices: any[] = [];
	isCMdevicesMode: boolean = false;
	siteSpecial: boolean = false;

	columnNames: any[] = [
		{name: this.translateService.instant('g.mac_address'), key: 'mac_address', isId: true},
		{name: this.translateService.instant('devices.serial_number'), key: 'serial_number'},
		{name: this.translateService.instant('devices.truck_sn'), key: 'truck_sn'},
		// {name: this.translateService.instant('devices.installation_date'), key: 'installation_date', type: 'dateTime'},
		{name: this.translateService.instant('devices.hw_version'), key: 'hw_version'},
		{name: 'Options', key: 'options', type: 'options', optionsList: [
			{'action': 'edit', 'text': this.translateService.instant('g.edit')},
			{'action': 'delete', 'text': this.translateService.instant('g.delete')},
			{'action': 'hard_delete', 'text': this.translateService.instant('g.hard_delete_device')}
		]}
	];

	constructor(
		private route: ActivatedRoute,
		private deviceManagementService: DeviceManagementService,
		private router: Router,
		private translateService: TranslateService,
		public userService: UsersService,
		private sideMenuService: SideMenuService,
		private notificationService: NotificationMessageService,
		public deviceService: DeviceService,
		public sitesService: SitesService,
	) { }

	ngOnInit() {
		if(!this.userService.hasAccessFunction('device_management'))
			return this.router.navigate(['/unauthorized']);

		if(this.userService.hasAccessFunction('super_admin', 'write'))
			this.columnNames[this.columnNames.length - 1].optionsList.push({'action': 'reset_device', 'text': this.translateService.instant('g.reset_device_after_hard_delete')});

		this.sideMenuService.hide();
		this.isCMdevicesMode = this.route.snapshot.data['cmDevice'];
		this.route.params.subscribe( params => {
			this.customerid = params['customerId'] || 1;
			this.siteid = params['siteId'] || 1;
			// QA requirement:
			// CM devices should be listed under this page /#/admin-panel/cm-devices ONLY
			// and should not be listed here /#/devices/1/1
			if(!this.isCMdevicesMode && this.customerid == 1 && this.siteid == 1) {
				this.router.navigate(['admin-panel/cm-devices']);
			}

			this.sideMenuService.currentSite.subscribe((siteInfo:any) => {
				this.siteSpecial = siteInfo.is_special;
			});

			this.getDevices();
		});
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteDevice') {
				return this.deleteDevice(result[1][1]);
			}

			if(result[1][0] == 'hardDeleteDevice') {
				return this.hardDeleteDevice(result[1][1]);
			}

			if(result[1][0] == 'resetDevice') {
				return this.resetDevice(result[1][1]);
			}
		});
	}

	confirmAction(action, data1) {
		this.confirmModal.passData = [action, data1];
		switch(action) {
			case 'deleteDevice':
				this.confirmModal.message = 'g.del_device_prompt';
			break;
			case 'hardDeleteDevice':
				this.confirmModal.message = 'g.hard_del_device_prompt';
			break;
			case 'resetDevice':
				this.confirmModal.message = 'g.reset_device_after_hard_delete_prompt';
			break;
		}
		this.confirmModal.show();
	}

	getDevices() {
		this.deviceManagementService.getSiteDevices(this.siteid).subscribe((data: any) => {
				const tableData: TableData[] = [];

				for (const device of data) {
					const options = [
						{link: ['edit', device.mac_address], text: this.translateService.instant('g.edit')},
						{action: ()=> {this.doAction({action: 'delete', element: {mac_address: device.mac_address}})}, text: this.translateService.instant('g.delete')},
						{action: ()=> {this.doAction({action: 'hard_delete', element: {mac_address: device.mac_address}})}, text: this.translateService.instant('g.hard_delete_device')},
					];

					if (this.userService.hasAccessFunction('super_admin', 'write'))
						options.push({action: ()=> {this.doAction({action: 'reset_device', element: {mac_address: device.mac_address}})}, text: this.translateService.instant('g.reset_device_after_hard_delete')});

					tableData.push({
						id: {value: device.mac_address},
						mac_address: {value: device.mac_address},
						serial_number: {value: device.serial_number},
						truck_sn: {value: device.truck_sn},
						hw_version: {value: device.hw_version},
						options: { value: null, options },
					})
				}

				this.tableData = tableData;
			}
		);
	}

	updateSelectedDevices(devices) {
		this.selectedDevices = devices.map(device => device.mac_address.value);
	}

	doAction(event) {
		switch(event.action) {
			case 'edit':
				return this.router.navigate(['edit', event.element.mac_address], {relativeTo: this.route});
			case 'delete':
				return this.confirmAction('deleteDevice', event.element.mac_address);
			case 'hard_delete':
				return this.confirmAction('hardDeleteDevice', event.element.mac_address);
			case 'reset_device':
				return this.confirmAction('resetDevice', event.element.mac_address);
		}
	}

	deleteDevice(deviceId) {
		this.deviceManagementService.deleteDevices([deviceId], this.siteid, {cmDevice: this.isCMdevicesMode}).subscribe(
			() => {
				this.notificationService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getDevices();
			}
		);
	}

	hardDeleteDevice(deviceId) {
		this.deviceManagementService.hardDeleteDevice(deviceId, this.siteid, {cmDevice: this.isCMdevicesMode}).subscribe(
			() => {
				this.notificationService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getDevices();
			}
		);
	}

	resetDevice(deviceId) {
		this.deviceManagementService.cmResetDeviceAfterDelete(deviceId).subscribe(
			() => {
				this.notificationService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getDevices();
			}
		);
	}

	goToAddDevice() {
		if(this.isCMdevicesMode) {
			return this.router.navigate(['admin-panel', 'new-device']);
		}
		this.router.navigate(['edit', 0], {relativeTo: this.route});
	}
}
