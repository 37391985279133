<div class="my-3">
	<button type="button" class="btn btn-primary mb20" (click)="showNewPermissionModal()">{{'g.add_new' | translate}} <fa-icon icon="plus"></fa-icon></button>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal #linkedItemsModal title="{{(permissionType == 'enterprise' ? 'permissions.linked_enterprise' : 'permissions.linked_user') | translate}}" [hasConfirmButton]="false">
	<div class='col-md-12'>
		<table class='table table-striped'>
			<thead>
				<tr>
					<th>{{'g.id' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of linkedItems">
					<td>{{item.id}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-modal>
<app-modal #addPermissionModal title="{{'permissions.add_permission' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div *ngIf="newPermission">
		<div class="mb-3">
			<div class="row mb-2 align-items-center">
				<div class="col-md-4">
					<label for="new_permission_name">{{'permissions.permission_name' | translate}}</label>
				</div>
				<div class="col-md-8">
					<input class="form-control" name="new_permission_name" [(ngModel)]="newPermission.name" id="new_permission_name" required/>
				</div>
			</div>
			<div class="row mb-2 align-items-center">
				<div class="col-md-4">
					<label>{{'permissions.copy_from' | translate}}</label>
				</div>
				<div class="col-md-8">
					<ng-select
						[items]="permissions"
						bindLabel="name"
						bindValue="id"
						name="new_permission_id"
						[(ngModel)]="newPermission.id"
						[clearable]="false"
					>
					</ng-select>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-default mr-2" (click)="closeAddPermissionModal()">{{'g.cancel' | translate}}</button>
			<button class="btn btn-primary" (click)="addNewPermission()" [disabled]="!(newPermission.name.trim() && newPermission.id)">{{'g.ok' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal #deleteModal title="{{'permissions.delete_permission' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'permissions.delete_permission_prompt' | translate}}
</app-modal>
