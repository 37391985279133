import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnTypes, ColumnsConfig, TableConfig, TableData } from './custom-table-interface';
import * as moment from 'moment';
import { CommonService } from '../services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
	selector: 'sct-custom-table',
	templateUrl: './custom-table.component.html',
	styleUrls: ['./custom-table.component.scss'],
})
export class SCTCustomTable implements OnInit, OnChanges {
	@ViewChild(MatSort) matSort: MatSort;
	@Input() columnsConfig: ColumnsConfig[] = [];
	@Input() data: TableData[] = [];
	@Input() config: TableConfig = {
		hasPagination: false,
		pageSize: 20,
		hasExport: false,
		hasSelectionColumn: false,
		fitScreen: true,
		fileName: 'Exported Data'
	};
	@Output() onDatesChanged = new EventEmitter<Object>();
	@Output() updateSelectedRows = new EventEmitter<Object>();
	@Output() sortedDataToShow = new EventEmitter<any>();
	@Output() backEndPaginationFunc = new EventEmitter<any>();

	// backend pagination
	pageNum: number = 0;
	totalItemsNum: number = 0;
	overallItemsCount: number = 0;

	shownColumnsConfig: ColumnsConfig[] = [];
	draggedColumn = "";
	isHidePopupShown = false;
	selectAll = false;
	selectedRows = [];
	currentPage: number = 1;
	sort: Sort = { active: null, direction: '' };
	timeFilter: {[key: string]: {from: number | null, to: number | null}} = {};
	filters: {[key: string]: string} = {};
	numberFilters: {[key: string]: {method: string, value: number | null, showFilter: boolean, toValue: number | null}} = {};
	booleanFilter: {[key: string]: any} = {};
	sortAndFilterStorage = "table_filter_sort";

	toShowData: any[] = [];
	sortResult: any[] = [];
	filteredData: TableData[] = [];

	columnsToSort: string[] = [];
	columnsToFilter: string[] = [];

	boolFields: string[] = [];
	timeFields: string[] = [];
	progressFields: string[] = [];
	hasExportField: ColumnsConfig[] = [];
	stringOrNumberFields: string[] = [];
	hasFilterTypes: ColumnTypes[] = ['number', 'string', 'link', 'action_link' , 'date', 'timestamp', 'date_with_ref', 'boolean', 'list', 'select', 'hover', 'progress_bar', 'number_link'];
	hideFromListTypes: ColumnTypes[] = ['id', 'select', 'icon', 'dropdown'];

	numberFilterOptions = [
		{id: "equal", name: this.translate.instant("g.equal")},
		{id: "not_equal", name: this.translate.instant("g.not_equal")},
		{id: "greater_than", name: this.translate.instant("g.greater_than")},
		{id: "greater_than_equal", name: this.translate.instant("g.greater_than_equal")},
		{id: "less_than", name: this.translate.instant("g.less_than")},
		{id: "less_than_equal", name: this.translate.instant("g.less_than_equal")},
		{id: "in_range", name: this.translate.instant("g.in_range")},
		{id: "blank", name: this.translate.instant("g.blank")},
		{id: "not_blank", name: this.translate.instant("g.not_blank")},
	];
	numberNoValueFilters = ['blank', 'not_blank'];
	selectedNumberFilterMethod = "equal";

	constructor(
		public commonService: CommonService,
		public translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.sortResult = this.filteredData = this.data;
		this.prepareColumnsList();
	}

	ngOnChanges(changes) {
		if (changes.data) {
			this.sortResult = this.filteredData = changes.data.currentValue;
			if (this.config.resetPaginationOnDataChange)
				this.currentPage = 1;

			this.prepareFieldLists();
			this.updatePageData();
			this.filter();
		}

		if (changes.data && !changes.data.firstChange) {
			this.getSortAndFilter();
		}

		if (changes.columnsConfig && !changes.columnsConfig.firstChange) {
			this.columnsInit();
			this.prepareColumnsList();
		}
	}

	ngAfterViewInit() {
		this.getSortAndFilter();
		this.cdr.detectChanges();
	}

	columnsInit() {
		this.shownColumnsConfig = [];
		this.columnsToSort = [];
		this.columnsToFilter = [];
		this.boolFields = [];
		this.timeFields = [];
		this.progressFields = [];
		this.hasExportField = [];
		this.stringOrNumberFields = [];
		this.filters = {};
		this.numberFilters = {};
		this.booleanFilter = {};
	}

	prepareColumnsList() {
		if (this.config.hasSelectionColumn) {
			this.columnsConfig = structuredClone(this.columnsConfig);
			this.columnsConfig.unshift({key: "select", name: this.translate.instant("g.select"), type: "select", hasFilter: true});
		}

		const defaultTrueConfig = ['fitScreen', 'hideNoData'];
		defaultTrueConfig.forEach(field => {
			if (!(field in this.config))
				this.config[field] = true;
		});

		for (let column of this.columnsConfig) {
			if (column.hasSort != false && !['icon' , 'dropdown', 'select'].includes(column.type))
				this.columnsToSort.push(column.key);

			column.hasFilter = Object.keys(column).includes('hasFilter') ? column.hasFilter : true;

			if (column.hasFilter)
				this.columnsToFilter.push(column.key);

			if (column.type == 'boolean') {
				this.boolFields.push(column.key);
				this.booleanFilter[column.key] = [true, false];
			}

			if (['number', 'number_link'].includes(column.type))
				this.numberFilters[column.key] = {value: null, toValue: null, showFilter: false, method: 'equal'};

			if (column.type == "progress_bar")
				this.progressFields.push(column.key)

			if (['string', 'number', 'timestamp', 'link', 'hover', 'action_link', 'number_link'].includes(column.type))
				this.stringOrNumberFields.push(column.key);

			if (['date', 'date_with_ref'].includes(column.type))
				this.timeFields.push(column.key);

			if (['date', 'timestamp', 'date_with_ref'].includes(column.type))
				this.timeFilter[column.key] = { from: null, to: null };

			if (!('hidden' in column))
				column.hidden = false;

			if (!column.hidden && column.type != 'id')
				this.shownColumnsConfig.push(column);
		}
	}

	prepareFieldLists() {
		if (this.config.hasSelectionColumn) {
			this.data.map(row => {
				row.select = {};
				row.select.selected = false
			});
		}
	}

	format(data: any, columnConfig: ColumnsConfig) {
		if (!data && (columnConfig.type == 'timestamp' || columnConfig.type == 'date'))
			return '--/--/--';

		if (columnConfig.type == 'number')
			return data == null ? columnConfig.noValueString : data

		if (columnConfig.type == 'boolean')
			return data ? columnConfig.filterTrueText || this.translate.instant('g.yes') : columnConfig.filterFalseText || this.translate.instant('g.no');

		if (!data)
			return '-';

		if (columnConfig.type == 'timestamp')
			return moment(data).utc().format(columnConfig.dateFormat || 'MM/DD/YYYY HH:mm:ss');

		return data;
	}

	onPageChange(event: { pageIndex: number; }) {
		this.currentPage = event.pageIndex + 1;
		this.updatePageData();
	}

	filter(column?: ColumnsConfig, event?: any, options: any = {}) {
		if (column && event) {
			switch (column.type) {
				case 'number':
				case 'number_link':
					const filterNumberOptions = this.numberFilterOptions.map(option => option.id);
					if (filterNumberOptions.includes(event.id)) {
						this.numberFilters[column.key].method = event.id;

						if (this.config.isBackendPagination && this.numberNoValueFilters.includes(this.numberFilters[column.key].method))
							this.doBackEndPaginationPagination();

					} else {
						const updateField = options.updateTo ? "toValue" : "value";

						if (event.target?.value && !this.numberNoValueFilters.includes(this.numberFilters[column.key].method))
							this.numberFilters[column.key][updateField] =  +event.target?.value || 0;
						else
							delete this.numberFilters[column.key][updateField];
					}
					break;

				case 'boolean':
					const filteredValue = event.map(val => val.value);

					if (filteredValue)
						this.filters[column.key] = filteredValue;
					else
						delete this.filters[column.key];

					break;
				default:
					const value = event.target ? String(event.target.value) : event.map(val => val.value);

					if (value)
						this.filters[column.key] = value.trim();
					else
						delete this.filters[column.key];
					break;
			}
		}

		if (this.config.isBackendPagination)
			return;

		if (this.config.hasSelectionColumn) {
			this.selectAll = true;
			this.toggleSelectAll();
		}

		const allFilters = {...this.filters, ...this.numberFilters};
		if (!Object.keys(allFilters).length) {
			this.filteredData = this.data;
			return this.sortData({active: null, direction: "asc"}, {saveFilter: options.saveFilter});
		}

		this.filteredData = this.data;
		for(const filter in allFilters)
			this.filteredData = this.filteredData.filter(row => this.rowFilter(row, filter));
		this.sortData({active: null, direction: "asc"}, {saveFilter: options.saveFilter});
	}

	rowFilter(row: any, field: string) {
		let rowValue = row[field]?.value;
		const filterValue = this.filters[field];
		const numberFilter = this.numberFilters[field];
		const filterColumn = this.columnsConfig.filter(column => column.key == field)[0];

		if (!filterColumn)
			return true;

		if (filterColumn.type == 'progress_bar')
			rowValue = row[field].progress_bar_text;

		// number filter
		if (['number', 'number_link'].includes(filterColumn.type)) {
			rowValue = row[field].sortValue || rowValue;

			if (numberFilter.value == null && !this.numberNoValueFilters.includes(numberFilter.method))
				return true;

			switch (numberFilter.method) {
				case "blank":
					return rowValue == null;
				case "not_blank":
					return rowValue != null;
				case "not_equal":
					return +numberFilter.value != rowValue;
				case "greater_than":
					return +numberFilter.value != null && +numberFilter.value < rowValue;
				case "greater_than_equal":
					return +numberFilter.value != null && +numberFilter.value <= rowValue;
				case "less_than":
					return +numberFilter.value != null && +numberFilter.value > rowValue;
				case "less_than_equal":
					return +numberFilter.value != null && +numberFilter.value >= rowValue;
				case "in_range":
					if (numberFilter.toValue == null)
						return true;

					return +numberFilter.toValue >= rowValue && +numberFilter.value <= rowValue;
				default:
					return +numberFilter.value == rowValue
			}
		}

		// string filter
		if (['number', 'string', 'link', 'action_link', 'hover', 'progress_bar'].includes(filterColumn.type)) {
			if (filterValue == '-')
				return rowValue == null || !String(rowValue).toLowerCase().length;

			if (!String(rowValue).toLowerCase().length)
				return false;

			return String(rowValue).toLowerCase().includes(filterValue.toLowerCase());
		}

		// date filter
		if (['date', 'timestamp', 'date_with_ref'].includes(filterColumn.type)) {
			rowValue = row[field].sortValue || rowValue;
			const rowTime = moment(rowValue).unix();
			const from = this.timeFilter[field].from;
			const to = this.timeFilter[field].to;

			if (!from && !to)
				return true;

			if (!to && from && moment(this.timeFilter[field].from).unix() <= rowTime)
				return true;

			if (!from && to && moment(this.timeFilter[field].to).endOf('day').unix() >= rowTime)
				return true;

			if (to && moment(this.timeFilter[field].to).endOf('day').unix() >= rowTime && from && moment(this.timeFilter[field].from).unix() <= rowTime)
				return true;

			return false;
		}

		if (filterColumn.type == 'boolean')
			return filterValue.length && filterValue.includes(rowValue) || !filterValue.length;

		if (filterColumn.type == 'list') {
			if (!Object.keys(row).includes(field))
				return false;

			rowValue = row[field].value.length? rowValue : '';
			if (!rowValue.length && filterValue.trim().length)
				return false;

			return rowValue.filter(item => item && item.text.trim().toLowerCase().includes(filterValue.trim().toLowerCase())).length > 0;
		}

		return true;
	}

	sortData(sort: Sort = {active: null, direction: null}, options?: {saveSort?: boolean, saveFilter?: boolean}) {
		if (sort.active && sort.direction != null)
			this.sort = sort;

		if ((options.saveSort && this.sort.active && this.sort.direction != null) || (options.saveFilter))
			this.setSortAndFilter();

		if (this.config.isBackendPagination)
			return this.doBackEndPaginationPagination();

		const data = this.filteredData.slice();
		this.currentPage = 1;

		// no sort params and no last sort params
		if ((!this.sort.active || !this.sort.direction)) {
			this.sortResult = data;
			this.updatePageData();
			return;
		}

		this.sortResult = data.sort((a, b) => this.sortFunction(this.sort, a, b));
		this.updatePageData();
	}

	updatePageData() {
		const { hasPagination, pageSize, isBackendPagination } = this.config;
		let dataToShow = this.sortResult;

		if (hasPagination && !isBackendPagination) {
			const startIndex = (this.currentPage - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			dataToShow = dataToShow.slice(startIndex, endIndex);
		}

		this.toShowData = dataToShow;
		this.sortedDataToShow.emit(this.toShowData);
	}

	sortFunction(sort: any, a: any, b: any) {
		const { compare } = this.commonService;
		const isAsc = sort.direction !== 'asc';
		const field = sort.active;

		if (this.boolFields.includes(field))
			return compare(a[field].value ? 1 : 2, b[field].value ? 1 : 2, isAsc);

		if (this.timeFields.includes(field))
			return compare(a[field]?.sortValue || this.formateDateForCompare(a[field].value), b[field]?.sortValue || this.formateDateForCompare(b[field].value), isAsc);

		if (this.stringOrNumberFields.includes(field)) {
			let aValue = a[field]?.sortValue || a[field].value;
			aValue = typeof (aValue) == 'string'? aValue.toLocaleLowerCase() : +aValue;

			let bValue = b[field]?.sortValue || b[field].value;
			bValue = typeof (bValue) == 'string'? bValue.toLocaleLowerCase() : +bValue;
			return compare(aValue, bValue, isAsc);
		}

		if (this.progressFields.includes(field)) {
			let aValue = a[field]?.progress_bar_text || "";
			let bValue = b[field]?.progress_bar_text || "";

			return compare(aValue.toLocaleLowerCase(), bValue.toLocaleLowerCase(), isAsc);
		}


		return 0;
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date && date != '-' ? moment(date).utc().unix(): 0;
	}

	generateCsvFile() {
		const csvRows = [];
		const hasExportColumn = this.shownColumnsConfig.filter(column => column.hasExport !== false && !['dropdown', 'icon', 'select', 'id'].includes(column.type))
		const columnNames = hasExportColumn.map(column => column.name);
		csvRows.push(columnNames);

		(this.sortResult || []).forEach(row => {
			const rowArray: any[] = [];

			hasExportColumn.forEach(field => {
				const value = row[field.key].progress_bar_text || row[field.key].value;
				rowArray.push(this.format(value, field));
			})

			csvRows.push(rowArray);
		});

		new AngularCsv(csvRows, this.config.fileName || 'Exported Data');
	}

	drag(event: any) {
		this.draggedColumn = event.currentTarget.textContent;
		event.dataTransfer.setData('text/plain', event.currentTarget.textContent);
		event.dataTransfer.effectAllowed = 'move';
	}

	drop(event: any) {
		event.preventDefault();
		let target = event.currentTarget;

		if (!target || target.tagName != 'TH')
			return;

		const data = this.draggedColumn;
		const thElements = Array.from(target.parentElement.children);
		const currentIndex = thElements.findIndex((th: any) => th.textContent === data);
		const dropIndex = thElements.findIndex(th => th === target);

		// update columns list
		if (currentIndex > -1 && dropIndex > -1 && currentIndex != dropIndex) {
			const toMoveColumn = this.shownColumnsConfig[currentIndex];
			const columnsList = [...this.shownColumnsConfig];
			columnsList.splice(currentIndex, 1);
			columnsList.splice(dropIndex, 0, toMoveColumn);
			this.shownColumnsConfig = [...columnsList];
		}
	}

	allowDrop(event: any) {
		event.preventDefault();
	}

	toggleHidePopup() {
		this.isHidePopupShown = !this.isHidePopupShown;
	}

	toggleColumnVisibility(column) {
		column.hidden = !column.hidden;

		const shownColumnsKeys = this.shownColumnsConfig.map(column => column.key);

		if (!column.hidden)
			return this.shownColumnsConfig = this.columnsConfig.filter(columnsConfig => columnsConfig.key == column.key || shownColumnsKeys.includes(columnsConfig.key));

		this.shownColumnsConfig = this.shownColumnsConfig.filter(columnsConfig => columnsConfig.key != column.key);
	}

	toggleSelectAll() {
		if (!this.data.every(row => row.select))
			return;

		this.selectAll = !this.selectAll;

		if (this.selectAll)
			this.selectedRows = this.filteredData.filter(row => row.id?.disable_select == false);
		else
			this.selectedRows = [];

		this.data.map(row => row.select.selected = this.selectedRows.includes(row));
		this.updateSelectedRows.emit(this.selectedRows);
	}

	toggleSelectedRow(id) {
		const rows = this.data.filter(data => data.id.value == id);
		rows.map(row => row.select.selected = !row.select.selected);
		this.selectedRows = this.data.filter(data => data.select.selected == true);
		this.updateSelectedRows.emit(this.selectedRows);
	}

	unselectRows() {
		this.selectedRows = [];
	}

	disableCheckAll() {
		return this.data.every(row => row.id.disable_select);
	}

	doBackEndPaginationPagination(event?: { pageIndex: number; pageSize: number}) {
		this.config.pageSize = event && 'pageSize' in event ? event.pageSize : this.config.pageSize;
		this.currentPage = event && 'pageIndex' in event ? event.pageIndex + 1 : 1;

		this.backEndPaginationFunc.emit({
			currentPage: this.currentPage,
			filters: {...this.filters, ...this.timeFilter, ...this.booleanFilter, ...this.numberFilters},
			sort: {field: this.sort.active, isAsc: this.sort.direction !== 'asc'}
		});
	}

	updatePagination(totalItemsNum, overallItemsCount = 0) {
		this.totalItemsNum = totalItemsNum;
		if (overallItemsCount)
			this.overallItemsCount = overallItemsCount;
	}

	backendPaginationInit() {
		this.pageNum = 0;
		this.totalItemsNum = 0;
	}

	hasFilters() {
		let hasFilters = false;
		for (const filter in this.filters) {
			if (Array.isArray(this.filters[filter])) {
				if (this.filters[filter].length > 0) {
					hasFilters = true;
					break;
				}
				continue;
			}
			if(this.filters[filter]) {
				hasFilters = true;
				break;
			}
		}
		if(!hasFilters)
			for(const filter in this.timeFilter) {
				if (this.timeFilter[filter].from || this.timeFilter[filter].to) {
					hasFilters = true;
					break;
				}
			}
		if(!hasFilters)
			for(const filter in this.booleanFilter) {
				if (this.booleanFilter[filter].length) {
					hasFilters = true;
					break;
				}
			}
		return hasFilters;
	}

	toggleNumberFilter(key: string) {
		this.numberFilters[key].showFilter = !this.numberFilters[key].showFilter;
	}

	filterNotExistCell(rowData) {
		return this.shownColumnsConfig.filter(column => rowData[column.key]);
	}
	
	isExits(object, key) {
		return object && object.hasOwnProperty(key);
	}

	getSortAndFilter() {
		if(!this.config.tableId || !this.matSort)
			return;

		const filterAndSortString = sessionStorage.getItem(`${this.sortAndFilterStorage}_${this.config.tableId}`);
		if (!filterAndSortString)
			return;

		const filterAndSortObject = JSON.parse(filterAndSortString);

		this.sort = this.updateFilterData(filterAndSortObject.sort, this.sort);
		this.numberFilters = this.updateFilterData(filterAndSortObject.filter.number, this.numberFilters);
		this.filters = this.updateFilterData(filterAndSortObject.filter.string, this.filters);
		this.booleanFilter = this.updateFilterData(filterAndSortObject.filter.boolean, this.booleanFilter);
		this.timeFilter = this.updateFilterData(filterAndSortObject.filter.date, this.timeFilter);

		this.matSort.sortChange.emit(this.sort);

		this.filter();
	}

	updateFilterData(storageData, originalData) {
		return storageData && Object.keys(storageData).length ? storageData : originalData;
	}

	setSortAndFilter() {
		if(!this.config.tableId)
			return;

		const filterAndSortObject = {
			sort: this.sort,
			filter: {
				number: this.numberFilters,
				string: this.filters,
				boolean: this.booleanFilter,
				date: this.timeFilter
			}
		};

		sessionStorage.setItem(`${this.sortAndFilterStorage}_${this.config.tableId}`, JSON.stringify(filterAndSortObject));
	}
}
