import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import * as _ from 'underscore';
import { SitesService } from 'src/app/sites/sites.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-site-requests',
	templateUrl: './site-requests.component.html',
	styleUrls: ['./site-requests.component.css']
})
export class SiteRequestsComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("identicalSiteModal") identicalSiteModal;
	@ViewChild("linkedDevicesModal") linkedDevicesModal;
	@ViewChild("locationModal") locationModal;

	sitesList: any;
	usersInfo: any = {};
	enterprisesInfo: any = {};
	approvedSiteInfo: any = {};
	linkedDevices: any[] = [];
	locationsArr: any[] = [];

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "string"},
		{ key: 'identical_customer_found', name: this.translateService.instant('device_management.identical_customer_found'), type: 'icon'},
		{ key: 'customer', name: this.translateService.instant('g.customer'), type: "link"},
		{ key: 'request_by_user', name: this.translateService.instant('device_management.request_by_user'), type: 'link'},
		{ key: 'enterprise_name', name: this.translateService.instant('g.enterprise_name'), type: "string" },
		{ key: 'source', name: this.translateService.instant('device_management.source'), type: "string" },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	doneRequest = {
		enterprise: false,
		user: false
	};

	constructor(
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		private enterpriseService: EnterpriseService,
		public sitesService: SitesService,
		private translateService: TranslateService,
		private router: Router,
	) { }

	ngOnInit() {
		this.getPendingSites();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.rejectRequest(result[1]);
		});
		this.identicalSiteModal.onClose.subscribe((confirm) => {
			if(confirm)
				this.mapToApprovedSite();
		});
	}

	confrimRejectRequest(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'device_management.reject_site_prompt';
		this.confirmModal.show();
	}

	getPendingSites() {
		this.deviceManagementService.getPendingSites({getReqUser: true}).subscribe((res) => {
			this.sitesList = res;
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();

			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
				this.doneRequest.user = true;

				if (this.doneRequest.enterprise)
					this.fillTableData();
			});

			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
				this.doneRequest.enterprise = true;

				if (this.doneRequest.user)
					this.fillTableData();
			});
		});
	}

	fillTableData() {
		const tableData = [];

		for(const site of this.sitesList) {
			const requestByUserCondition = this.usersService.hasAccessFunction('edit_user_admin') && this.usersInfo[site.user_id];
			const enterpriseCondition = this.enterprisesInfo[site.enterprise_id];
			const locationAction = [];

			if (site.source == 'device' && !site._approved_site_id)
				locationAction.push({text: this.translateService.instant('device_management.view_location'), action: () => {this.getSiteLinkedDevices(site.site_id, 'location')}});

			tableData.push({
				site_name: {value: site.site_name},
				identical_customer_found: {value: null, icon: site._approved_site_id ? 'link' : null , iconColor: 'blue', action: ()=> {this.showIdenticalSiteModal(site)}},
				customer: {
					value: this.usersService.hasAccessFunction('customer_management') ? site.customer_name : site.customer_id,
					link: this.usersService.hasAccessFunction('customer_management') ? ['/customers', 'edit', site.customer_id] : null
				},
				request_by_user: {
					value: requestByUserCondition ?
						`${this.usersInfo[site.user_id].first_name} ${this.usersInfo[site.user_id].last_name}` :
						`${site.user_id} ${!this.usersInfo[site.user_id] ? this.translateService.instant('g.deleted') : ''}`,
					link: requestByUserCondition ? ['/user', 'edit', site.user_id] : null
				},
				enterprise_name: {
					value: enterpriseCondition ?
						this.enterprisesInfo[site.enterprise_id].name :
						`${site.enterprise_id} (${this.translateService.instant('g.deleted')})`
				},
				source: {value: this.translateService.instant(site.source ? 'device_management.source_options.'+[site.source] : 'g.n/a')},
				actions: {options: [
					{text: this.translateService.instant('device_management.edit_approve'), link: ['/management/device-management-requests/site-edit', site.site_id]},
					{text: this.translateService.instant('device_management.view_devices'), action: () => {this.getSiteLinkedDevices(site.site_id, 'linkedDevices')}},
					...locationAction,
					{text: this.translateService.instant('g.reject'), action: () => {this.confrimRejectRequest(site.site_id)}},
				]}
			})
		}

		this.tableData = tableData;
	}

	rejectRequest(siteId) {
		this.deviceManagementService.rejectSiteRequest(siteId).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingSites();
		});
	}

	showIdenticalSiteModal(site) {
		this.approvedSiteInfo = Object.assign({}, site);
		this.identicalSiteModal.show();
	}

	mapToApprovedSite() {
		this.deviceManagementService.mapToApprovedSite(this.approvedSiteInfo.site_id, this.approvedSiteInfo._approved_site_id).subscribe((res) => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingSites();
		});
	}

	getSiteLinkedDevices(siteId, showModal?) {
		this.linkedDevices = [];
		this.deviceManagementService.getSiteLinkedDevices(siteId).subscribe((devices: any) => {
			if(devices.length) {
				this.deviceManagementService.getDeviceOwners(devices, {allowEmpty: true}).subscribe((owners: any) => {
					let linkedDevicesOwners = _.chain(owners.customersAndSites).pluck('sites').flatten(true).indexBy('id').value();
					let linkedDevices = [];
					for(let device of owners.devices) {
						if(linkedDevicesOwners[device.site_id])
							linkedDevices.push({
								site_id: device.site_id,
								customer_id: linkedDevicesOwners[device.site_id].customer_id,
								mac_address: device.mac_address,
								latitude: device.latitude,
								longitude: device.longitude
							});
					}
					this.linkedDevices = _.uniq(linkedDevices, d => d.mac_address);
				});
			}
			switch(showModal) {
				case 'linkedDevices':
					this.linkedDevicesModal.show();
				break;
				case 'location':
					this.locationModal.show();
				break;
			}
		});
	}
}
