import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceManagementService } from '../../device-management.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-site-request-edit',
	templateUrl: './site-request-edit.component.html',
	styleUrls: ['./site-request-edit.component.css']
})
export class SiteRequestEditComponent implements OnInit {

	siteId: number;
	site: any;
	sitesList: any;
	oldCustomerId: number;
	enterpriseList: any = [];
	customersList: any = [];
	customerEnterprises: any = [];
	newCustomerSelected: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private chRef: ChangeDetectorRef,
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		private customersService: CustomersService,
		public sitesService: SitesService,
		private enterpriseService: EnterpriseService,
		public usersService: UsersService,
		private el: ElementRef,
		private renderer: Renderer2,
	) { }

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.siteId = Number(params['siteId']);
				if(!this.siteId) {
					return this.router.navigateByUrl('/404');
				}
			}
		);
		this.getCustomers();
		this.getSite();
	}
	
	getCustomers() {
		this.customersService.getCustomers({ customer_name: '', partial: false }).subscribe((customers:	any) => {
			if (customers.length) {
				let customersList: any = [{ id: 0, name: this.translateService.instant('customer.original_customer') }];
				
				customers.map((customer: any) => customersList.push({ id: customer.id, name: customer.customer_name, customer_enterprises: customer.customer_enterprises}));
				this.customersList = customersList;
			}
		});
	}

	getSite() {
		this.deviceManagementService.getPendingSites({siteId: this.siteId}).subscribe((res) => {
			if(res[0]) {
				let siteInfo = res[0];
				this.oldCustomerId = siteInfo.customer_id;
				
				this.site = {
					id: siteInfo.site_id,
					name: siteInfo.site_name,
					country_id:  siteInfo.country_id,
					state_id: siteInfo.state_id,
					address: siteInfo.address,
					latitude: siteInfo.latitude,
					longitude: siteInfo.longitude,
					zone_id: siteInfo.zone_id,
					enterprise_ids: [siteInfo.enterprise_id],
					installation_date: new Date(new Date(siteInfo.installation_date).setHours(0, 0, 0, 0)),
					contact_name: '',
					phone_num: '',
					email: '',
				};
				this.prepareSitesList(siteInfo.customer_id);
				this.prepareCustomerEnterprises(siteInfo.customer_id);
			}
		});
	}

	prepareSitesList(customerId) {
		this.sitesService.getCustomerSites(customerId, {include_zombie_sites: true}).subscribe((res: any) => {
			
			if (!this.usersService.hasAccessFunction('super_admin', 'write'))
				res.site = res.site.filter((site: any) => !site.is_special);
			
			this.sitesList = res.site;
			this.sitesList.unshift(
				{id: this.siteId, name: this.translateService.instant('device_management.none_new_site')}
			);
		});
	}

	prepareCustomerEnterprises(customerId) {
		customerId = customerId == 0? this.oldCustomerId : customerId;
		this.enterpriseService.getEnterprises({site_edit: true, source: 'site_edit'}).subscribe((res: any[]) => {
			const customerInfo = this.customersList.find(customer => customer.id == customerId)
			if (customerInfo) {
				this.site.customer_enterprises = customerInfo.customer_enterprises || [];
				this.customerEnterprises =  Array.isArray(customerInfo.customer_enterprises) 
					? res.filter((item) => customerInfo.customer_enterprises.includes(item.id)) 
					: [];
				this.enterpriseList = res.filter((item) => !this.site.customer_enterprises.includes(item.id));
			}
			this.prepareEnterpriseList();
		});
	}

	prepareEnterpriseList() {
		this.enterpriseService.getEnterprise(this.site.enterprise_ids, {source: 'manage_device'}).subscribe((res: any) => {
			this.enterpriseList = [...[res], ...this.enterpriseList];
			this.site.enterprise_ids = this.site.enterprise_ids.filter(enterprise => !this.site.customer_enterprises.includes(enterprise))
			this.enterpriseList = this.enterpriseList.filter((item) => !this.site.customer_enterprises.includes(item.id));
		});
	}

	approveSite() {
		let tempSite = Object.assign({}, this.site);
		tempSite.newCustomerId = this.newCustomerSelected;
		tempSite.enterprise_ids = [...tempSite.enterprise_ids, ...(tempSite.customer_enterprises || [])];
		let installationDate = new Date(tempSite['installation_date']);
		tempSite['installation_date'] = Math.floor((-installationDate.getTimezoneOffset() * 60000 + installationDate.getTime()) / 1000);

		this.deviceManagementService.approveSiteRequest(this.siteId, tempSite).subscribe((res: any) => {
			if ([2, 3, 4].includes(res.api_status)) { // this API status related error in site name field
				const form = this.el.nativeElement;
				const ele = form.querySelectorAll('[name=name]');
				if(ele && ele[0])
					this.renderer.addClass(ele[0], 'invalid-input');

				const statusMessages: Record<number, string> = {
					2: 'site.reserved_site_name',
					3: 'site.site_name_cannot_changed',
					4: 'site.site_name_already_exist',
				};

				const msg = statusMessages[res.api_status];
				return this.notificationMessageService.setMessage(`translate|${msg}`, { clearOnXTimeNavigate: 1 });
			}

			this.notificationMessageService.setMessage('globalSuccessMsg');
			this.goBack();
		});
	}

	updateSiteCoords(coords) {
		this.site['latitude'] = coords['lat'];
		this.site['longitude'] = coords['lng'];
		this.site['address'] = coords['address'];
		this.site['country_id'] = coords['country'];
		this.site['state_id'] = coords['state'];
		this.chRef.detectChanges();
	}

	goBack() {
		this.router.navigate(['/management/device-management-requests/sites']);
	}
}
