<div class="row">
	<div class="col-lg-6 col-md-6 col-sm-12">
		<h2>{{'nav.device_ps_configuration' | translate}}</h2>
		<form #searchDeviceForm="ngForm" (ngSubmit)="getDevices()">
			<div class="mt-4">
				<small class="text-danger">{{'shipment.csv_file_rules_msg' | translate}}</small>
				<div class="custom-file my-2">
					<input type="file" class="custom-file-input" id="importFile" (change)="prepareMultiDevicesForDevicesConfiguration($event)">
					<label class="custom-file-label" #labelImport for="importFile">{{'shipment.import_devices_csv' | translate}}</label>
				</div>
				{{ 'g.or_capital' | translate }}
				<div class="my-2">
					<input name="searchString" [(ngModel)]="searchString" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control" required/>
				</div>
				<div class="mt-3 input-group">
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
						<label class="form-check-label" for="inlineRadio1">{{'g.serial_number' | translate}}</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
						<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
					</div>
				</div>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary pointer" [disabled]="searchDeviceForm.invalid">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>

		<div class="col-md-6 my-5" *ngIf="searchResult?.length == 0 && !bulkSearchNotFoundDevices?.length">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>
		<div class="col-md-12 my-5" *ngIf="!searchResult?.length && bulkSearchNotFoundDevices?.length">
			<b>{{'shipment.field_import_csv_devices_not_found' | translate}}</b>
			<ul>
				<li *ngFor="let device of bulkSearchNotFoundDevices">{{ device }}</li>
			</ul>
		</div>

		<div class="col-md-12 my-5" *ngIf="searchResult?.length > 0">
			<h3>{{'g.results' | translate}}:</h3>
			<div class="row py-2">
				<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-6"><strong>{{'ready_to_ship.move_to_bulk' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of searchResult" class="row py-2">
				<div class="col-md-3">
					<a
						[routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'performance']"
						class="blue-hover pointer ml-1"
						target="_blank"
					>
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-md-3">{{device.serial_number}}</div>
				<div class="col-md-6">
					<div *ngIf="!device.deviceConfigurationError">
						<fa-icon icon="arrow-right" size="lg" class="pointer" (click)="prepareDevicesToPSConfiguration(device, sites[device.site_id]?.customer_id)"></fa-icon>
					</div>
					<div *ngIf="device.deviceConfigurationError">
						<span class="text-danger">{{device.deviceConfigurationError}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-lg-6 col-md-6 col-sm-12">
		<div class="col-md-12 my-5" *ngIf="selectedDevices.length > 0">
			<div class="row py-2">
				<div class="col-md-1"><strong>{{'g.number' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.remove' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of selectedDevices; let i = index" class="row py-2">
				<div class="col-md-1">{{i +1}}</div>
				<div class="col-md-3"><a [routerLink]="['/', device._customer_id, device.site_id, device.mac_address, 'performance']" class="blue-hover pointer ml-1" target="_blank">{{device.mac_address}}</a></div>
				<div class="col-md-3">{{device.serial_number}}</div>
				<div class="col-md-3">
					<fa-icon icon="window-close" size="2x" class="pointer text-danger" (click)="removeFromPsDevicesConfiguration(device)"></fa-icon>
				</div>
			</div>
			<div class="row justify-content-center m-4">
				<button class="btn btn-primary" (click)="devicesPSConfiguration()">{{'g.configure_devices'| translate }}</button>
			</div>
		</div>
	</div>
</div>