<h3 class="mb-4 font-weight-bold">
	<span class="light-grey"> {{'bulk_fill_device_info.bulk_devices_info' | translate}}</span>
</h3>

<div class="guide-container">
	<strong class="mb-3">{{'bulk_fill_device_info.page_guide' | translate}}</strong>
	<ol>
		<li>{{'bulk_fill_device_info.copy_serial' | translate}}</li>
		<li>{{'bulk_fill_device_info.copy_columns' | translate}}</li>
		<li>{{'bulk_fill_device_info.check_mandatory_fields' | translate}}</li>
	</ol>
	<p *ngIf="site.devices_password && site.is_smart_rebates">{{'bulk_fill_device_info.reset_device_password_note' | translate}} <strong>{{site.devices_password}}</strong></p>
</div>

<div class="table-container">
	<table class="table table-striped table-bordered mt-4">
		<thead>
			<tr>
				<th></th>
				<th *ngFor="let column of columns">
					{{('bulk_fill_device_info.' + column) | translate}}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td class="pointer" (click)="selectAll()">
					<fa-icon [icon]="['far', selectedSerials.length ? 'check-square': 'square']" size="sm"></fa-icon>
				</td>
				<td *ngFor="let column of columns">
					<div class="paste_container d-flex justify-content-between align-items-center">
						<input
							type="text"
							contenteditable="true"
							[(ngModel)]="pasteData[column]"
							[name]="column"
							id="column"
							[placeholder]="'bulk_fill_device_info.paste_here' | translate"
							(paste)="handleInputPaste($event, column)"
						>
					</div>
					<div class="old-new d-flex justify-content-around mt-2" *ngIf="column !== 'serial_number'">
						<span class="center">{{'g.old' | translate}}</span>
						<span class="center right">{{'g.new' | translate}}</span>
					</div>
				</td>
			</tr>
			<tr *ngFor="let row of devicesData; let i = index" [ngClass]="{'not_exist': !devicesListing[row.serial_number.value]}">
				<td (click)="toggleSelect(row.serial_number.value)" [ngClass]="{'not_valid_select': InvalidDevicesList.includes(row.serial_number.value)}">
					<fa-icon class="icon" [icon]="['far', selectedSerials.includes(row.serial_number.value) && !InvalidDevicesList.includes(row.serial_number.value)? 'check-square': 'square']" size="sm"></fa-icon>
				</td>
				<td *ngFor="let field of row | keys" [ngClass]="{'not_valid': !row[field].isValid && field != 'serial_number'}">
					<ng-container [ngSwitch]="field">
						<div *ngSwitchCase="'serial_number'" class="old-new d-flex justify-content-around">
							{{row[field].value}}
						</div>
						<div *ngSwitchCase="'max_charger_kw'" class="old-new d-flex justify-content-around">
							<!-- old value -->
							<span *ngIf="row[field].value != '-'">
								{{devicesListing[row['serial_number'].value]?.[field]}}
							</span>
							<span *ngIf="row[field].value == '-'" class="right">-</span>
							<!-- new value -->
							<span *ngIf="row[field].value != '-'" class="right">
								<input type="text" [name]="i + '_'+ field" id="i + '_' + field"  [(ngModel)]="row[field].value" (input)="updateFieldValidation(row, field)">
							</span>
							<span *ngIf="row[field].value == '-'">-</span>
						</div>
						<span *ngSwitchDefault class="old-new d-flex justify-content-around">
							<span>{{ devicesListing[row['serial_number'].value]?.[field] }}</span>
							<span class="right"><input type="text" [name]="i + '_' + field" [id]="'i_' + field" [(ngModel)]="row[field].value" (input)="updateFieldValidation(row, field)"></span>
						</span>
					</ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<button class="pointer btn mt-4 btn-primary" type="button" (click)="confirmationModal.show()">{{'g.submit' | translate}}</button>

<app-modal [hasConfirmButton]="false" #duplicatedSerialsModal title="{{'bulk_fill_device_info.duplicated_serials' | translate}}" [closeBtnText]="'g.cancel' | translate">
	<p>{{"bulk_fill_device_info.duplicated_serials_massage" | translate}}</p>
	<ul>
		<li *ngFor="let serialNumber of duplicatedSerials">{{serialNumber}}</li>
	</ul>
</app-modal>

<app-modal
	[hasConfirmButton]="true"
	#confirmationModal title="{{'g.confirmation' | translate}}"
	size="md"
>
	<p>{{'bulk_fill_device_info.confirmation' | translate}}</p>
</app-modal>
