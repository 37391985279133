import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SitesService } from 'src/app/sites/sites.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CustomersService } from 'src/app/customers/customers.service';

import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import * as moment from 'moment';

@Component({
	selector: 'app-zombie-sites',
	templateUrl: './zombie-sites.component.html',
	styleUrls: ['./zombie-sites.component.css']
})
export class ZombieSitesComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;

	customerId = 0;
	sites = <any>[];

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'name', name: this.translate.instant('g.name'), type: "string"},
		{ key: 'country', name: this.translate.instant('site_edit.country'), type: 'string' },
		{ key: 'state', name: this.translate.instant('site_edit.state'), type: "string" },
		{ key: 'address', name: this.translate.instant('site_edit.address'), type: "string" },
		{ key: 'modification_date', name: this.translate.instant('site.modification_date'), type: "timestamp", dateFormat: "MM/DD/yyyy"},
		{ key: 'action', name: this.translate.instant('g.action'), type: "dropdown" },
	];

	constructor(
		private sitesService: SitesService,
		private customersService : CustomersService,
		private router: Router,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private translate: TranslateService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService
	) { }

	ngOnInit() {
		const currentUser = this.usersService.getCurrentUser();
		if(!this.usersService.hasAccessFunction('customer_management') || !currentUser.isSCT)
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();

		this.route.params.subscribe(
			params => {
				this.customerId = Number(params['customerId']) || 0;
				if (this.customerId) {
					this.getZombieSites();
				}
			}
		);
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.deleteSite(result[1]);
		});
	}

	getZombieSites() {
		this.customersService.getCustomerZombieSites(this.customerId).subscribe(
			(data: any) => {
				this.sites = _.sortBy(_.values(data['site']), 'name');
				const sites = _.sortBy(_.values(data['site']), 'name');
				const hasSitManagement = this.usersService.hasAccessFunction('site_management');

				const tableData = [];
				for(const site of sites) {
					const options = [];

					if (hasSitManagement)
						options.push({link: this.customerId ? ['/sites', this.customerId, 'edit', site.id] : null, queryParams: {source: "z"}, text: this.translate.instant('g.edit')});

					if (hasSitManagement && !site.is_special)
						options.push({action: ()=> {this.confirmDeleteSite(site.id)}, text: this.translate.instant('g.delete')});

					options.push(
						{link: ['/devices', this.customerId, site.id], text: this.translate.instant('g.devices')},
					);

					if (site?.site_enterprise?.length) {
						for (const permission of site.site_enterprise) {
							options.push({
								link:['/admin-panel/permission-edit'],
								text: this.translate.instant('site.permission') + permission.enterprise?.name
							});
						}
					}

					tableData.push({
						name: {value: site.name},
						country: {value: site.country_id},
						state: {value: site.state_id},
						address: {value: site.address},
						modification_date: {value: moment(site.modification_date).unix() * 1000},
						action: {value: null, options: options}
					})
				}

				this.tableData = tableData;
			}
		);
	}


	confirmDeleteSite(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'g.del_site_prompt';
		this.confirmModal.show();
	}

	deleteSite(id) {
		this.sitesService.deleteSite(id).subscribe(
			(data: any) => {
				if(data) {
					switch (data.api_status) {
						case 2:
							this.notificationMessage.setMessage('translate|site.delete_site_with_devices', { clearOnXTimeNavigate: 1 });
							break;
						case 3:
							this.notificationMessage.setMessage('translate|site.delete_site_with_studies', { clearOnXTimeNavigate: 1 });
							break;
						default:
							this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
							this.getZombieSites();
							break;
					}
				} else {
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
				}
			}
		);
	}
}
