<div class="my-3" *ngIf="usersService.hasAccessFunction('customer_management') && currentUser.isSCT">
	<button type="button" [routerLink]="['edit', 0]" class="btn btn-primary mb20">{{'g.add_new' | translate}} <fa-icon icon="plus"></fa-icon></button>
</div>

<div class="mt-2 mb-2 ml-2 mr-2">
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
