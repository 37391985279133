<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h3>{{'update_firmware_log.title' | translate}}</h3>
	<hr/>

	<div class="mt-3 mb-2 ml-2 mr-2">
		<sct-custom-table
			#sctCustomTable
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
			(backEndPaginationFunc)="getFirmwareUpdateLog($event)"
		></sct-custom-table>
	</div>
</div>