import { Injectable } from '@angular/core';
import validationObj from '../../../../../iotah-common/validations/validationRules.json';

@Injectable()
export class ValidationService {
	macRegex: RegExp = /^[0-9a-fA-F]{12}$/; //accepts upper and lower cases for APIs

	empty(data) {
		if(typeof(data) == 'number' || typeof(data) == 'boolean') {
			return false;
		}
		if(typeof(data) == 'undefined' || data === null) {
			return true;
		}
		if(typeof(data.length) != 'undefined') {
			return data.length == 0;
		}
		var count = 0;
		for(var i in data) {
			if(data.hasOwnProperty(i))
				count ++;
		}
		return count == 0;
	}

	dataValidator(type, options, value) {
		var isValid = false,
			strLen: number;
		switch(type) {
			case 'integer':
				if(value == parseInt(value)) {

					value = parseInt(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;

					if(validMin && validMax && validStep)
						isValid = true;
				}
			break;

			case 'float':
				if(value == parseFloat(value)) {

					value = parseFloat(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;

					if(validMin && validMax && validStep)
						isValid = true;
				}
			break;

			case 'boolean':
				if(typeof(value) === "boolean")
					isValid = true;
			break;

			case 'string':
				if(options.allowEmpty && this.empty(value)){
					isValid = true;
					break;
				}

				if(typeof(value) === "string") {
					var validMin = true, validMax = true;
					strLen = value.length;

					if(typeof(options.min) !== "undefined" && strLen < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && strLen > options.max)
						validMax = false;

					if(validMin && validMax)
						isValid = true;
				}
			break;

			case 'hexString':
				if(options.allowEmpty && (value === null || value == undefined || value == '')) {
					isValid = true;
					break;
				}

				if(typeof(value) === "string") {
					value = value.trim().replace(/:/g, "");
					isValid = /^[a-fA-F0-9]{12}$/.test(value);
				}
			break;

			case 'hex':
				if(this.empty(value)){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				var regex = /\b[0-9A-Fa-f]+\b/gi;
				var validMin = true, validMax = true;
				strLen = value.trim().length;

				if(typeof(options.min) !== "undefined" && strLen < options.min)
					validMin = false;
				if(typeof(options.max) !== "undefined" && strLen > options.max)
					validMax = false;

				if(validMin && validMax && regex.test(value.trim()))
					isValid = true;
			break;

			case 'password':
				if(this.empty(value) || value.trim().length == 0){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				if(typeof(value) === "string") {
					// isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value); // one upper, one lower, one digit, at least 8 characters.
					isValid = value.length > 4;
				}
			break;
			case 'email':
				if(this.empty(value)){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				if(typeof(value) === "string") {
					isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim()) && value.length <= 255;
				}
			break;
			case 'nonPublicMail':
				if(typeof(value) === 'string')
					isValid = !this.isPublicMail(value);
            break;
			case 'serialNumber':
				let snValue = value.trim();
				if(this.empty(snValue)){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				if(typeof(snValue) === "string") {
					isValid = /^T(\S){2}(0[1-9]|1[0-2])\d{2}\d{5}$/.test(snValue);
				}
			break;

			case 'arrayOf':
				if(this.empty(value)){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				let validArray = true;
				switch(options.subType) {
					case 'integers':
						for (const ele of value) {
							if(typeof ele != 'number') {
								validArray = false;
								break;
							}
						}

					break;
					case 'emails':
						for (const ele of value) {
							const email = ele.email;
							if(typeof(email) === "string") {
								validArray = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()) && email.length <= 255;
							} else {
								validArray = false;
								break;
							}
						}
						break;
				}
				isValid = validArray;
			break;

			case 'inArray':
				switch(options.subType) {
					case 'integer':
						if(value == parseInt(value)) {
							value = parseInt(value);
						}
					break;
				}
				if(options.values.indexOf(value) > -1)
					isValid = true;
			break;

			case 'notInArray':
				switch(options.subType) {
					case 'float':
						if(value == parseFloat(value)) {

							value = parseFloat(value);

							if(options.values.indexOf(value) == -1)
								isValid = true;
						}
					break;
				}
			break;

			case 'phone':
				if(this.empty(value)){
					if(options.allowEmpty)
						isValid = true;
					break;
				}
				isValid = /^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
			break;

			case 'time':
				if(typeof(value) === "string") {
					var patt = /^((0|1)[0-9]|2[0-4]):[0-5][0-9]$/;
					if(patt.test(value)) {
						var time = value.split(':');
						var timeInMints = parseInt(time[0]) * 60 + parseInt(time[1]);

						var validMin = true, validMax = true, validStep = true;

						if(typeof(options.min) !== "undefined") {
							var minTime = options.min.split(':');
							var minTimeInMints = parseInt(minTime[0]) * 60 + parseInt(minTime[1]);

							if(timeInMints < minTimeInMints)
								validMin = false;
						}

						if(typeof(options.max) !== "undefined") {
							var maxTime = options.max.split(':');
							var maxTimeInMints = parseInt(maxTime[0]) * 60 + parseInt(maxTime[1]);

							if(timeInMints > maxTimeInMints)
								validMax = false;
						}

						if(typeof(options.step) !== "undefined" && (timeInMints % options.step != 0))
							validStep = false;

						if(validMin && validMax && validStep)
							isValid = true;
					}
				}
			break;

			case 'daysMask':
				var days = [0, 1, 2, 3, 4, 5, 6];
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach(function(ele) {
						if(withinDays && days.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays)
						isValid = true;
				}
			break;

			case 'dateRange':
				var validMin = true, validMax = true;

				var DateInSecs = new Date(value).getTime();

				if(typeof(options.min) !== "undefined" && DateInSecs < options.min.getTime())
					validMin = false;
				if(typeof(options.max) !== "undefined" && DateInSecs > options.max.getTime())
					validMax = false;

				if(validMin && validMax)
					isValid = true;
			break;

			case 'ipAddressRegex':
				let ipPatt = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
				if(options.allowEmpty && this.empty(value)){
					isValid = true;
					break;
				}
				if(ipPatt.test(value))
					isValid = true;
			break;

			case 'regex':
				let hwValue = value.trim();
				if(options.allowEmpty && this.empty(hwValue)){
					isValid = true;
					break;
				}
				if(options.patt && options.patt.test(hwValue))
					isValid = true;
				if(options.strPatt && new RegExp(options.strPatt).test(hwValue))
					isValid = true;
			break;

			case 'arraySubset':
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach(function(ele) {
						if(withinDays && options.values.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays)
						isValid = true;
				}
			break;

			case 'notNull':
				if(typeof(value) === 'string') {
					let trimmedValue = value.trim();
					if(trimmedValue.length > 0)
						isValid = true;
				}
			break;

			case 'preventWhiteSpaces':
				if(options.allowEmpty && this.empty(value)) {
					isValid = true;
					break;
				}
				if(typeof(value) === 'string') {
					let trimmedValue = value.trim();
					if(value.length !== 0 && trimmedValue.length === 0) {
						isValid = false; 
					} else {
						isValid = true;
					}
				}
			break;
		}

		return isValid;
	};

	getFormValidationRules(formName){
		return validationObj[formName];
	}

	validateForm(data, formName){

		let invalidFields	= [];

		let validationRules = this.getFormValidationRules(formName);
		for(let field in data){
			let rules = validationRules[field] || [];

			if(invalidFields.indexOf(field) == -1) {
				for (const rule of rules) {
					var isValid = this.dataValidator(rule.type, rule, data[field]);
					if(!isValid)
						invalidFields.push(field);
				}
			}
		}
		return invalidFields;
	}

	isPublicMail(email) {
		let mailDomain = email.split('@')[1];
		return this.publicMailDomainsList.includes(mailDomain);
	}

	private publicMailDomainsList = [
		'gmail.com',
		'yahoo.com',
		'hotmail.com',
		'aol.com',
		'hotmail.co.uk',
		'hotmail.fr',
		'msn.com',
		'yahoo.fr',
		'wanadoo.fr',
		'orange.fr',
		'comcast.net',
		'yahoo.co.uk',
		'yahoo.com.br',
		'yahoo.co.in',
		'live.com',
		'rediffmail.com',
		'free.fr',
		'gmx.de',
		'web.de',
		'yandex.ru',
		'ymail.com',
		'libero.it',
		'outlook.com',
		'uol.com.br',
		'bol.com.br',
		'mail.ru',
		'cox.net',
		'hotmail.it',
		'sbcglobal.net',
		'sfr.fr',
		'live.fr',
		'verizon.net',
		'live.co.uk',
		'googlemail.com',
		'yahoo.es',
		'ig.com.br',
		'live.nl',
		'bigpond.com',
		'terra.com.br',
		'yahoo.it',
		'neuf.fr',
		'yahoo.de',
		'alice.it',
		'rocketmail.com',
		'att.net',
		'laposte.net',
		'facebook.com',
		'bellsouth.net',
		'yahoo.in',
		'hotmail.es',
		'charter.net',
		'yahoo.ca',
		'yahoo.com.au',
		'rambler.ru',
		'hotmail.de',
		'tiscali.it',
		'shaw.ca',
		'yahoo.co.jp',
		'sky.com',
		'earthlink.net',
		'optonline.net',
		'freenet.de',
		't-online.de',
		'aliceadsl.fr',
		'virgilio.it',
		'home.nl',
		'qq.com',
		'telenet.be',
		'me.com',
		'yahoo.com.ar',
		'tiscali.co.uk',
		'yahoo.com.mx',
		'voila.fr',
		'gmx.net',
		'mail.com',
		'planet.nl',
		'tin.it',
		'live.it',
		'ntlworld.com',
		'arcor.de',
		'yahoo.co.id',
		'frontiernet.net',
		'hetnet.nl',
		'live.com.au',
		'yahoo.com.sg',
		'zonnet.nl',
		'club-internet.fr',
		'juno.com',
		'optusnet.com.au',
		'blueyonder.co.uk',
		'bluewin.ch',
		'skynet.be',
		'sympatico.ca',
		'windstream.net',
		'mac.com',
		'centurytel.net',
		'chello.nl',
		'live.ca',
		'aim.com',
		'bigpond.net.au',
	];
}
