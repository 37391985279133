import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { ColumnsConfig, TableConfig, TableData } from '../../shared/custom-table/custom-table-interface';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import * as abbreviations from '../../../../../Common/abbreviations.json';

@Component({
	selector: 'disconnected-sites-report.component',
	templateUrl: './disconnected-sites-report.component.html',
	styleUrls: ['./disconnected-sites-report.component.css']
})
export class DisconnectedSitesComponent implements OnInit, OnDestroy {
	@ViewChild("popupModal") popupModal: ModalComponent;
	percentage = 0;
	daysCount = 0;
	sitesData: any[] = [];
	selectedDisconnectedDevices: any[] = [];

	selectedState: string[] = [];
	abbreviation = abbreviations;
	stateFilterOptions:any[] = []
	isValidPercent = true;
	isValidDaysCount = true;
	isSmartRebatesSitesFilter: boolean = false;
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
	};

	sortedToShowData: any;

	columnConfig: ColumnsConfig[] = [
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link', hasSort: true },
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link", hasSort: true },
		{ key: 'disconnected_devices', name: this.translateService.instant('connectivity.num_disconnected_devices'), type: "number_link", hasSort: true },
		{ key: 'total_devices_number', name: this.translateService.instant('connectivity.devices_total_number'), type: "number", hasSort: true },
		{ key: 'percentage', name: this.translateService.instant('connectivity.percentage_col'), type: "number", hasSort: true },
	];

	constructor(
		private usersService: UsersService,
		private deviceService: DeviceService,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private commonService: CommonDataService
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		this.stateFilterOptions = [...Object.keys(this.abbreviation[this.commonService.SITE_LOCATION_STATE.usa]), this.commonService.SITE_LOCATION_STATE.Canada].sort((a, b) => a.localeCompare(b));
	}

	getDisconnectedSiteData() {
		const filterOptions = {
			percentage: this.percentage,
			daysCount: this.daysCount,
			siteStateFilter: this.selectedState,
			isSmartRebatesSitesFilter: this.isSmartRebatesSitesFilter
		};

		this.deviceService.getDisconnectedSitesReport(filterOptions).subscribe((response: any) => {
			const tableData: any = [];
			[...response].forEach(record => {
				let rowData: TableData = {
					record,
					customer_name: {value: record.customer_name == 'customer_deleted'? this.translateService.instant('g.customer_deleted') : record.customer_name, link:  record.customer_name == 'customer_deleted'? null : ['/', record.customer_id]},
					site_name: {value: record.site_name == 'site_deleted' ? this.translateService.instant('g.site_deleted') :  record.site_name, link: record.site_name == 'site_deleted' ? null : ['/', record.customer_id, record.site_id]},
					disconnected_devices: {value: record.disconnected_devices.length, action: () => {this.showDisconnectedDeviseDataPopup(record.disconnected_devices)}},
					total_devices_number: {value: record.total_devices_number},
					percentage: {value: record.percentage?.toFixed(2) + "%" , sortValue: +record.percentage?.toFixed(2) }
				}
				tableData.push(rowData);
			});
			this.sitesData = tableData;
		});
	}

	showDisconnectedDeviseDataPopup(disconnectedDevices) {
		this.popupModal.show();
		this.selectedDisconnectedDevices = disconnectedDevices;
	}

	checkValidation() {
		this.isValidPercent = this.percentage != null && this.percentage >= 0 && this.percentage <= 100;
		this.isValidDaysCount = this.daysCount != null && this.daysCount >= 0;
	}


	exportToPDF() {
		const filterOptions = {
			percentage: this.percentage,
			daysCount: this.daysCount,
			siteStateFilter: this.selectedState,
			isSmartRebatesSitesFilter: this.isSmartRebatesSitesFilter
		};

		this.deviceService.getDisconnectedSitesReportAsPDF(filterOptions).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					return this.notificationMessage.setMessage('translate|official_report.no_report_data', { clearOnXTimeNavigate: 1 });
				default:
					this.downloadReport(res);
					break;
			}
		});
	}

	exportToCsvFile() {
		const csvRows = [];
		const columnNames = [
			this.translateService.instant('g.customer_name'),
			this.translateService.instant('g.site_name'),
			this.translateService.instant('connectivity.num_disconnected_devices'),
			this.translateService.instant('connectivity.devices_total_number'),
			this.translateService.instant('connectivity.percentage_col')
		];

		csvRows.push(columnNames);

		(this.sortedToShowData || []).forEach(row => {
			csvRows.push( [
				row.customer_name.value,
				row.site_name.value,
				row.disconnected_devices.value,
				row.total_devices_number.value,
				row.percentage.value
			]);
		});

		new AngularCsv(csvRows, this.translateService.instant('connectivity.disconnected_sites_Report'));
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	getTableSortedData(data) {
		this.sortedToShowData = data;
	}

	ngOnDestroy() {
		this.popupModal.hide();
	}
}
