import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

@Injectable()
export class SideMenuService {

	private dataSubject= new BehaviorSubject<Object[]>([]);
	data = this.dataSubject.asObservable();

	private visibilitySubject = new BehaviorSubject<boolean>(false);
	visibility = this.visibilitySubject.asObservable();

	private currentCustomerSubject = new BehaviorSubject<boolean>(false);
	currentCustomer = this.currentCustomerSubject.asObservable();

	private currentSiteSubject= new BehaviorSubject<boolean>(false);
	currentSite = this.currentSiteSubject.asObservable();

	private expandedCustomersSubject = new BehaviorSubject<Object[]>([]);
	expandedCustomers = this.expandedCustomersSubject.asObservable();

	private sitesMapClickedSiteSubject = new BehaviorSubject<Object>({});
	sitesMapClickedSite = this.sitesMapClickedSiteSubject.asObservable();

	private sideMenuFiltersSubject = new BehaviorSubject<Object>({});
	sideMenuFilters = this.sideMenuFiltersSubject.asObservable();

	private filteredSitesSubject = new BehaviorSubject<Object[]>([]);
	filteredSites = this.filteredSitesSubject.asObservable();

	private hideMenuViewSubject = new BehaviorSubject<boolean>(false);
	hideMenuView = this.hideMenuViewSubject.asObservable();

	private selectedLocations = new BehaviorSubject<{country: string, state: string}[]>([]);
	locations = this.selectedLocations.asObservable();

	private showRelatedDevicesTypeBoxSubject = new BehaviorSubject<string[]>([]);
	showInfoRelatedDevicesType = this.showRelatedDevicesTypeBoxSubject.asObservable();

	siteInfoBoxVisibility = false;

	routeHasSiteInfoBox = false;

	showMap = true;

	constructor() { }

	hide() { this.visibilitySubject.next(false);}

	show() { this.visibilitySubject.next(true);}

	setData(data) {
		this.dataSubject.next(data);
	}

	setCurrentSite(site) {
		this.currentSiteSubject.next(site);
	}

	setDeviceTypeFilterOption(deviceType) {
		this.showRelatedDevicesTypeBoxSubject.next(deviceType);
	}

	setCurrentCustomer(customer){
		this.dataSubject.value.forEach(c=>{ if (c['id']== customer['id']) {c['expanded'] = true} });
		this.currentCustomerSubject.next(customer);
	}

	setExpandedCustomers(customersArr) {
		this.expandedCustomersSubject.next(customersArr);
	}

	setLocations(locations) {
		this.selectedLocations.next(locations);
	}

	addExpandedCustomer(customer) {
		let expandedCustomersArr = this.expandedCustomersSubject.value;
		let exists = _.find(expandedCustomersArr, function (item) {
			return item.id == customer.id;
		});
		if (!exists) {
			expandedCustomersArr.push(customer);
			this.expandedCustomersSubject.next(expandedCustomersArr);
		}
	}

	removeExpandedCustomer(customer) {
		let expandedCustomersArr = this.expandedCustomersSubject.value;
		expandedCustomersArr = expandedCustomersArr.filter(obj=> obj['id'] != customer.id);
		this.setExpandedCustomers(expandedCustomersArr);
	}

	setSitesMapClickedSite(site) {
		this.sitesMapClickedSiteSubject.next(site);
	}

	setSideMenuFilters(filters) {
		this.sideMenuFiltersSubject.next(filters);
	}

	getSideMenuFilters() {
		return this.sideMenuFiltersSubject.value;
	}

	getLocationFilter() {
		return this.selectedLocations.value;
	}

	getSiteInfoBoxVisibility() {
		return this.siteInfoBoxVisibility;
	}

	setSiteInfoBoxVisibility(value: boolean) {
		this.siteInfoBoxVisibility = value;
	}

	getRouteHasSiteInfoBox() {
		return this.routeHasSiteInfoBox;
	}

	setRouteHasSiteInfoBox(value: boolean) {
		this.routeHasSiteInfoBox = value;
	}

	setFilteredSites(sites) {
		this.filteredSitesSubject.next(sites);
	}

	setHideMenuView(value: boolean) {
		this.hideMenuViewSubject.next(value);
		this.reDrawMap();
	}

	getHideMenuView() {
		return this.hideMenuViewSubject.value;
	}

	reDrawMap(){
		this.showMap = false;
		setTimeout(() => {
			this.showMap = true;
		}, 500);
	}
}
