import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ServerPaginationComponent } from 'src/app/shared/server-pagination/server-pagination.component';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';
import * as moment from 'moment';

@Component({
  selector: 'app-rma-processing',
  templateUrl: './rma-processing.component.html',
  styleUrls: ['./rma-processing.component.css']
})
export class RmaProcessingComponent implements OnInit {

	selectedMacAddress: string = '';;
	selectedType: string = '';
	dialogMsg: string = '';

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 20,
		isBackendPagination: true
	};

	statuses: any  = {
		1: this.translateService.instant('rma.pending'),
		2: this.translateService.instant('rma.fix'),
		3: this.translateService.instant('rma.discard'),
	};

	@ViewChild("serverPagination", { static: true }) serverPagination!: ServerPaginationComponent;
	@ViewChild("confirmDialog") confirmDialog: ModalComponent;
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	constructor(
		public userService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService,
		private deviceManagementService: DeviceManagementService,
		private sctToastService: NotificationMessageService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('shipment_management'))
			return this.router.navigate(['/unauthorized']);

		const statuses = [];
		for (const statusId in this.statuses) {
			statuses.push({label: this.statuses[statusId], value: statusId});
		}
		this.columnConfig = [
			{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: "link"},
			{ key: 'serial_number', name: this.translateService.instant('devices.serial_number'), type: "string"},
			{ key: 'po_number', name: this.translateService.instant('g.purchase_order'), type: "string"},
			{ key: 'rma_number', name: this.translateService.instant('devices.rma_no'), type: "string"},
			{ key: 'created_at', name: this.translateService.instant('users.create_date'), type: "date"},
			{ key: 'updated_at', name: this.translateService.instant('rma.updated_at'), type: "date"},
			{ key: 'rma_status', name: this.translateService.instant('g.status'), type: "string", filterType: 'dropdown', items: statuses},
			{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown", hasFilter: false, hasSort: false},
		];
		this.sideMenuService.hide();
		this.getRmaDevices({currentPage: 1, isFirstTime: true});
	}

	resetPageVars() {
		this.getRmaDevices({currentPage: 1});
	}

	getRmaDevices(paginationData) {
		if(!this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime
		};

		if(options.filters?.user) {
			options.filters.user_id = options.filters.user;
			delete options.filters.user;
		}
		if(options.sort?.field == 'user')
			options.sort.field = 'user_id';

		this.deviceManagementService.getRmaDevices(options).subscribe((res: any) => {
			const tableData = [];
			res.data.forEach((log: any) => {
				const optionList = [];
				if (log.rma_status == 1) {
					optionList.push({text: this.translateService.instant('actions.move'), action: (data: any)=> {this.confirmMoveFromRma(data.mac_address.value, 'new')}});
					optionList.push({text: this.translateService.instant('actions.discard'), action: (data: any)=> {this.confirmMoveFromRma(data.mac_address.value, 'discard')}});
				}

				tableData.push({
					created_at: { value: log.created_at ? moment(log.created_at*1000).utc().format('MM/DD/YYYY HH:mm:ss') : '----' },
					updated_at: { value: log.updated_at ? moment(log.updated_at*1000).utc().format('MM/DD/YYYY HH:mm:ss') : '----' },
					serial_number: { value: log.serial_number },
					po_number: { value: log.po_number },
					rma_number: { value: log.rma_no },
					rma_status: { value: this.statuses[log.rma_status] },
					actions: {value: null, options: optionList},
					customer_id: { value: log.customer_id },
					site_id: { value: log.site_id },
					mac_address: { value: log.mac_address, link: ["/", log.customer_id, log.site_id, log.mac_address, 'performance'] },
				});
			});
			this.tableData = tableData;
			this.sctCustomTable.updatePagination(res.totalDataCount, res.overallItemsCount);
		});
	}

	moveFromRma(mac_address: string, type: string) {
		const data = {
			mac_address,
			discard: (type == 'discard')
		}
		this.deviceManagementService.moveFromRma(data).subscribe(() => {
			this.sctToastService.setMessage('globalSuccessMsg');
			this.confirmDialog.hide();
			this.resetPageVars();
		});
	}

	confirmMoveFromRma(mac_address, type: string) {
		this.selectedMacAddress = mac_address;
		this.selectedType = type;
		this.dialogMsg = `rma.${type == 'discard' ? 'discard_body' : 'move_body'}`;
		this.confirmDialog.title = this.translateService.instant(type == 'discard' ? 'rma.discard_header' : 'rma.move_header');
		this.confirmDialog.show();
	}

}
