<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div *ngIf="reportsHistory.length > 0; else noData">
		<div class="d-flex justify-content-between mb-2 px-2">
			<h2>{{(source == deviceManagementService.shipmentSource.quality ? 'nav.shipment_quality_history' : 'nav.receiving_shipment_history') | translate}}</h2>
		</div>

		<div class="mt-4">
			<div class="my-2 col-4">
				<input [ngClass]="{'invalid-input': invalidDevice}" name="searchString" [(ngModel)]="deviceId" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control"/>
				<p *ngIf="invalidDevice" class="text-danger"> {{'g.invalid_input' | translate}}</p>
			</div>
			<div class="my-3 d-flex justify-content-between">
				<div>
					<button type="submit" class="ml-3 btn btn-primary pointer" [disabled]="!deviceId" (click)="filterReceivingHistoryDevices()">{{'g.search' | translate}}</button>
					<button *ngIf="searchApplied" type="submit" class="btn btn-primary pointer ml-2" (click)="resetSearchFilter()">
						{{'g.clear_search' | translate}}
					</button>
				</div>
				<button *ngIf="source == deviceManagementService.shipmentSource.receive" class="btn btn-primary pointer " (click)="exportReceivedShipmentData.show()" >{{'g.export' | translate}}</button>
			</div>
		</div>
		<sct-custom-table
			[data]="reportsHistory"
			[columnsConfig]="columnConfig"
			[config]="tableConfig">
		</sct-custom-table>
	</div>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #exportReceivedShipmentData [hasConfirmButton]="false" [hasCloseButton]="false" [title]="'g.export' | translate">
	<div class="row">
		<div class="col-md-12 mb-3">
			<label class="form-label mr-2"><span>{{'g.from'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="fromDate" placeholder="{{'g.search' | translate}}" class="form-control mr-2" required />
		</div>

		<div class="col-md-12">
			<label class="form-label mr-2"><span>{{'g.to'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="toDate" placeholder="{{'g.search' | translate}}" class="form-control" required />
		</div>
		<p *ngIf="notValidPopupDate" class="pl-3 pt-3 text-danger">{{'g.start_date_before_end_date' | translate}}</p>
	</div>
	<div class="mt-3 d-flex justify-content-end">
		<button class="btn btn-md btn-primary" (click)="generateReceivedShipmentHistoryReport()">
			{{'devices.export_csv' | translate}}
		</button>
	</div>
</app-modal>

<app-modal #editCmPoNumberPopup [hasConfirmButton]="true" [hasCloseButton]="true" [title]="'shipment.edit_cm_po_numnber' | translate">
	<div class="row">
		<div class="col-md-12 mb-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'shipment.cm_po_number' | translate}}</span>
				</div>
				<input type="text" class="form-control" [(ngModel)]="newCmPoNumberValue">
			</div>
		</div>
	</div>
</app-modal>
