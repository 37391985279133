import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { DeviceService } from '../../device.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';
import { ColumnsConfig, CustomTableBackendData, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'app-actions-history',
	templateUrl: './actions-history.component.html',
})
export class ActionsHistoryComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() dateRange: any = {};
	@Input() invalidDateRange: boolean = false;
	@Input() currentSite: any;
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		isBackendPagination: true
	};

	constructor(
		private deviceService: DeviceService,
		private usersService: UsersService,
		private commonService: CommonService,
		private router: Router,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		if(!this.usersService.getCurrentUser().is_admin)
			return this.router.navigate(['/unauthorized']);

		this.columnConfig = [
			{ key: 'action_name', name: this.translateService.instant('action_history.action_name'), type: "string", filterType: 'dropdown', items: [], hasSort: false},
			{ key: 'username', name: this.translateService.instant('action_history.username'), type: "string", filterType: 'dropdown', items: []},
			{ key: 'action_time', name: this.translateService.instant('action_history.action_time'), type: "date", hasFilter: false},
		];
	}

	getActionsHistory(paginationData) {
		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;

		if(!this.device.mac_address || !fromDateRange || !toDateRange || !this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime
		};

		const zoneDiff		= new Date().getTimezoneOffset() * -1;
		let fromDate:any	= new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate:any		= new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();
		fromDate			= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), fromDate);
		toDate				= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), toDate);

		if(options.filters?.username) {
			options.filters.user_id = options.filters.username;
			delete options.filters.username;
		}
		if(options.sort?.field == 'username')
			options.sort.field = 'user_id';

		if(options.filters?.action_name) {
			options.filters.action_code = options.filters.action_name;
			delete options.filters.action_name;
		}
		if(options.sort?.field == 'action_name')
			options.sort.field = 'action_code';

		this.deviceService.getDeviceActions(this.device.mac_address, fromDate, toDate, options).subscribe(
			(logs: CustomTableBackendData) => {
				const tableData = [];
				logs.data.forEach((log: any) => {
					let actionTime = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), log.action_time);
					actionTime = moment(actionTime * 1000).utc().format('MM/DD/YYYY hh:mm:ss a');

					tableData.push({
						action_name: { value: this.deviceService.getCommandNameFromCode(log.action_code) },
						action_time: { value: actionTime },
						username: { value: log.user_id + " - "+ (log.first_name??'') + ' ' + (log.last_name??'') },
					});
				});


				this.tableData = tableData;
				this.sctCustomTable.updatePagination(logs.totalDataCount, logs.overallItemsCount);
				if(paginationData.isFirstTime) {
					const actions = [];
					logs.items.actionIds.forEach((actionId: any) => {
						actions.push({value: actionId, label: this.deviceService.getCommandNameFromCode(actionId)});
					});
					this.columnConfig[0].items = actions;
					this.columnConfig[1].items = logs.items.usersList;
				}
			}
		);
	};

	ngOnChanges() {
		this.getActionsHistory({currentPage: 1, isFirstTime: true});
	}
}
