import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { OrdersService } from '../orders.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerPaginationComponent } from 'src/app/shared/server-pagination/server-pagination.component';
import { DateRangeSelectComponent } from 'src/app/home/site-dashboard/device/device-dashboard/date-range-select/date-range-select.component';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { cloneDeep } from 'lodash-es';
import { AttachmentsService } from 'src/app/shared/attachments/attachments.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-manage-orders',
	templateUrl: './manage-orders.component.html',
})
export class ManageOrdersComponent implements OnInit, AfterViewInit, OnDestroy {
	idField: 'id' | 'shipping_date' = 'id';
	activeTable = 'unshipped';

	@ViewChild('downloadFilesDialog') downloadFilesDialog: ModalComponent;
	@ViewChild("addAttachmentsDialog") addAttachmentsDialog: ModalComponent;
	@ViewChild("successAttachmentsDialog") successAttachmentsDialog: ModalComponent;
	@ViewChild('deleteAttachmentsModal') deleteAttachmentsModal: ModalComponent;

	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: ElementRef<HTMLInputElement>;
	@ViewChild('uploadAttachmentsBtnLabel', { read: ElementRef }) uploadAttachmentsBtnLabel: ElementRef;

	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};
	selectedHistory: any;
	orderAttachedFiles: any[] = [];
	shippingAttachedFiles: any[] = [];
	selectedFilesToDownload: any = {};
	deletedAttachmentId: number = null;
	filesAlreadyUploaded: any = {};

	filterBySippingStatus: any[] = [
		{ id: (this.ordersService.getStatusesByKey()).NEW, label: this.translate.instant('orders.new') },
		{ id: (this.ordersService.getStatusesByKey()).IN_PROCESS, label: this.translate.instant('orders.in_process') },
		{ id: (this.ordersService.getStatusesByKey()).READY_TO_SHIP, label: this.translate.instant('orders.ready_to_ship') },
	];

	filterOrdersByDeviceTypes: Array<{ id: number, label: string }> = [
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.ChargLink, label: this.translate.instant('g.charglink') },
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.IoTAh, label: this.translate.instant('g.iotah') },
		{ id: this.ordersService.ORDERS_FILTER_BY_DEVICE_TYPE.IoTAh_PS, label: this.translate.instant('g.iotah_ps') }
	];

	sippingStatus = null;
	filterByDeviceType: number[] = [];

	orders: any[] = [];

	selectedCriteria = {
		field: '',
		value: null,
		startDate: null,
		endDate: null
	};

	criteria = {
		field: '',
		value: null,
		startDate: null,
		endDate: null
	};

	STATUSES = this.ordersService.STATUSES;
	SALES_CHANNEL = this.ordersService.SALES_CHANNEL;
	SEARCH_CRITERIA = this.ordersService.SEARCH_CRITERIA;

	mappedOrderStatus = this.ordersService.getStatusesByKey();
	addTrackingNumberOrderId: null;
	trackingNumber: string = '';
	shippingCompany: string = '';
	shippingDate: Date = new Date();
	trackingNumberError = '';
	shippingCompanyError = '';
	trackingNumberMaxLength = 200;
	selectedOrderToDelete: any = {};

	currentSearchType: string = '';
	nextSearchType: string = '';
	searchApplied: boolean = false;

	readonly ordersCountLimit: number = 20;

	successCounter = 0;

	@ViewChild("trackingNumberModal", {static: true}) trackingNumberModal: ModalComponent;
	@ViewChild("serverPagination", { static: true }) serverPagination!: ServerPaginationComponent;
	@ViewChild('dateRangePicker') dateRangePicker!: DateRangeSelectComponent;
	@ViewChild("searchOrdersForm") searchOrdersForm: NgForm;
	@ViewChild("deleteNewOrderModal") deleteNewOrderModal: ModalComponent;

	constructor(
		public userService: UsersService,
		private router: Router,
		private ordersService: OrdersService,
		private translate: TranslateService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		public attachmentsService: AttachmentsService,
		private route: ActivatedRoute,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		this.activeTable = this.ordersService.getActiveTab();

		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('create_orders') &&
			!this.userService.hasAccessFunction('view_orders') &&
			!this.userService.hasAccessFunction('shipment_management')
		)
			this.router.navigate(['/unauthorized']);

		this.getOrders(false, true);
	}

	ngAfterViewInit() {
		this.downloadFilesDialog.onClose.subscribe((result) => {
			if(!result)
				return this.selectedFilesToDownload = {};
			this.downloadFilesDialog.hide();
			this.downloadFiles(Object.keys(this.selectedFilesToDownload));
			return this.selectedFilesToDownload = {};
		});

		this.deleteNewOrderModal.onClose.subscribe((ok) => {
			if (ok) {
				this.deleteSelectedOrder(this.selectedOrderToDelete);
			} else {
				this.deleteNewOrderModal.hide();
				this.selectedOrderToDelete = {};
			}
		})
	}

	handleUploadedFiles() {
		const newAddedFiles = this.selectedFiles.filter(file => !(file.name in this.filesAlreadyUploaded)).map(file => file.name);
		
		if (this.selectedFiles.length && this.successCounter == this.selectedFiles.length) {
			this.selectedFiles = [];
			this.successAttachments = {};
			this.successAttachmentsDialog.hide();
		}

		if (this.successCounter && newAddedFiles.length == this.successCounter) {
			this.ordersService.sentAttachmentEmail(this.selectedHistory.id, this.selectedHistory.po_number, newAddedFiles, 'order', false).subscribe();
			this.getOrders(false, true);

			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		}
	}

	getOrders(isBack = false, firstTime = false) {
		if (firstTime)
			this.serverPagination.init();

		const criteria = this.criteria;

		const sippingStatus = this.activeTable == "shipped" ? (this.ordersService.getStatusesByKey()).SHIPPED : this.sippingStatus;
		const options: any = { isBack, criteria: JSON.stringify(criteria), shipped: this.activeTable == "shipped", sippingStatus: sippingStatus, filterByDeviceType: this.filterByDeviceType };

		options.lastId = firstTime ? 0 : this.orders[this.orders.length - 1]?.id || 0;
		options.lastShippingDate = firstTime ? 0 : this.orders[this.orders.length - 1]?.calculated_shipping_date || 0;

		options.firstId = this.serverPagination.firstId || 0;
		options.firstShippingDate = this.orders[0]?.calculated_shipping_date || 0;
		options.limit = this.serverPagination.limit;

		this.ordersService.getOrders(options).subscribe((orders: any) => {
			this.orders = [...orders];

			if (this.orders.length >  this.ordersCountLimit)
				this.orders.pop();

			this.serverPagination.updatePagination(orders, isBack, firstTime);
		});
	}

	addTrackingNumber() {
		if (this.trackingNumberValidation(this.trackingNumber, this.shippingCompany)) {
			const shippedOrderStatus = (this.mappedOrderStatus).SHIPPED;
			this.orders.find(item => item.id == this.addTrackingNumberOrderId).status = shippedOrderStatus;

			const zoneDiff = new Date().getTimezoneOffset() * -1;
			this.ordersService.addTrackingNumber(this.addTrackingNumberOrderId, this.trackingNumber, this.shippingCompany, zoneDiff, this.shippingDate).subscribe((res: any) => {
				switch (res.api_status) {
					case 2:
						this.trackingNumberError = this.translate.instant('g.max_length', { max: this.trackingNumberMaxLength });
						break;
					default:
						this.trackingNumber = '';
						this.trackingNumberModal.hide();
						this.notificationMessage.setMessage('globalSuccessMsg');
						this.getOrders(false, true);
					break;
				}
			});
		}
	}

	trackingNumberValidation(trackingNumber: string, shippingCompany: string) {
		trackingNumber = trackingNumber.trim();
		shippingCompany = shippingCompany.trim();
		
		if (!trackingNumber || !shippingCompany) {
			if (!trackingNumber)
				this.trackingNumberError = this.translate.instant('g.field_is_required');

			if (!shippingCompany)
				this.shippingCompanyError  = this.translate.instant('g.field_is_required');

			return false;
		}

		if (trackingNumber.length > this.trackingNumberMaxLength) {
			this.trackingNumberError = this.translate.instant('g.max_length', { max: this.trackingNumberMaxLength });
			return false;
		}

		return true;
	}

	showAddTrackingNumberOption(status: any) {
		return status == (this.ordersService.getStatusesByKey()).READY_TO_SHIP && (this.userService.hasAccessFunction('create_orders') || this.userService.hasAccessFunction('shipment_management'));
	}

	onDatesChanged(date: any) {
		this.selectedCriteria.value = date;
	}

	toLowerCase(text: string) {
		return text.toLocaleLowerCase();
	}

	selectCriteria(searchType: string) {
		this.currentSearchType = searchType;

		if(this.nextSearchType == 'date' || this.currentSearchType == 'date')
			this.selectedCriteria.value = null;

		this.nextSearchType = searchType;
	}

	showEditOrderOption(status: any) {
		return this.userService.hasAccessFunction('create_orders') && status != (this.mappedOrderStatus).SHIPPED;
	}

	setActiveTable(table: string) {
		this.activeTable = table;
		this.ordersService.setActiveTab(this.activeTable);

		if (this.activeTable == 'shipped')
			this.idField = 'shipping_date';
		else
			this.idField = 'id';
		
		this.getOrders(false, true);
	}

	getOrdersBySearchCriteria() {
		this.criteria = { ...this.selectedCriteria };
		this.getOrders(false, true);
		this.searchApplied = true;
	}

	shouldEnableSearchBtn(criteria) {
		const areDatesMissing = !criteria.startDate || !criteria.endDate;
		return !criteria.field || (!criteria.value && areDatesMissing);
	}

	resetFormData() {
		this.searchOrdersForm.reset();
		this.criteria = {
			field: '',
			value: null,
			startDate: null,
			endDate: null
		}
		this.getOrders(false, true);
		this.searchApplied = false;
	}

	openDownloadDialog(order: any) {
		this.selectedHistory = order;
		this.orderAttachedFiles = order.attachments;
		this.shippingAttachedFiles = order.shipping_attachments;

		let counter = 1;
		const shipmentAttachedFilesCount = Object.keys(this.shippingAttachedFiles).length;
		for (const id in this.shippingAttachedFiles) {
			this.shippingAttachedFiles[id].counter = counter;
			this.shippingAttachedFiles[id].is_last_shipment = counter == shipmentAttachedFilesCount;
			counter++;
		}
		
		this.downloadFilesDialog.title = this.translate.instant('orders.order') + ' ' + order.po_number + ' ' + this.translate.instant('ready_to_ship.attachments');
		this.selectedFilesToDownload = {};
		this.successAttachments = {};
		this.successCounter = 0;
		this.downloadFilesDialog.show();
	}

	openAddDialog(order: any) {
		this.addAttachmentsDialog.title = order.po_number + ' ' + this.translate.instant('ready_to_ship.attachments');
		this.selectedFiles = [];
		this.selectedHistory = order;
		this.uploadAttachmentsBtn.nativeElement.value = '';
		this.selectedFilesValidations = [];
		this.selectedFilesToDownload = {};
		this.addAttachmentsDialog.show()
	}

	getDownloadButtonStatus() {
		return !Object.keys(this.selectedFilesToDownload).length;
	}

	selectFiles(events: any) {
		this.selectedFiles = Object.values(events.target.files);
		//this.uploadAttachmentsBtnLabel.innerText = this.uploadAttachmentsBtn.value;
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}

	selectFile(event: any, file: any, isShipping=false) {
		const isChecked = event.target.checked;

		let fileId = 0;
		if (isShipping) {
			fileId = file.id;
			file = file.file;
		} else {
			file = file.file_name;
		}
		
		if (isChecked)
			return this.selectedFilesToDownload[`${file}%%${fileId}`] = isChecked;
		
		delete this.selectedFilesToDownload[`${file}%%${fileId}`];
	}

	addAttachments() {
		if (!this.selectedFiles.length)
			return this.selectedFilesValidations = { ...this.selectedFilesValidations, min_count: true };

		this.selectedFilesValidations = this.attachmentsService.validate({ files: this.selectedFiles, note: '' });
		if (Object.keys(this.selectedFilesValidations).length)
			return;

		this.filesAlreadyUploaded = {};
		this.addAttachmentsDialog.hide();
		this.successAttachmentsDialog.show();

		let counter = 0;

		for (const file of this.selectedFiles) {
			let formData = new FormData();

			formData.append("file", file);
			formData.append('sourceId', this.selectedHistory.id.toString());
			formData.append('source', 'order');
			formData.append('note', '');

			this.attachmentsService.addAttachments(formData).subscribe((attachment: any) => {
				switch (attachment.api_status) {
					case 3:
						this.filesAlreadyUploaded = cloneDeep({...this.filesAlreadyUploaded, [file.name]: true});
						break;
					default:
						this.successAttachments = cloneDeep({...this.successAttachments, [attachment.file_name]: true});
						this.successCounter++;
						break;
				}

				counter++;
				if (counter == this.selectedFiles.length)
					this.handleUploadedFiles();
			});
		}
	}

	downloadFiles(files?: string[]) {
		const downloadFiles = [];
		for(const file of files) {
			const fileParts = file.split('%%');
			const shipmentFilesPath = `shipping-out-reports/${this.selectedHistory.po_number}/${+fileParts[1]}`;
			downloadFiles.push({file: fileParts[0], source: +fileParts[1] > 0 ? shipmentFilesPath : 'order_attachments/' + this.selectedHistory.id });
		}
		this.attachmentsService.downloadAttachments('order', this.selectedHistory.id, downloadFiles, true);
	}

	showDeleteAttachmentModel(attachmentId: number) {
		this.deletedAttachmentId = attachmentId;
		this.deleteAttachmentsModal.show();
	}

	deleteAttachment() {
		if(!this.deletedAttachmentId)
			return;

		this.attachmentsService.deleteAttachment('order', this.selectedHistory.id, this.deletedAttachmentId).subscribe((data: any) => {
			let filename = '';
			delete this.selectedHistory.attachments.filter((item: any) => {
				if(this.deletedAttachmentId == item.id)
					filename = item.file_name;
				return this.deletedAttachmentId == item.id
			})[0];
			this.deletedAttachmentId = null;
			this.deleteAttachmentsModal.hide();
			this.downloadFilesDialog.hide();
			this.ordersService.sentAttachmentEmail(this.selectedHistory.id, this.selectedHistory.po_number, [filename], 'order', true).subscribe();
			this.getOrders(false, true);
			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	formateShippingDate(shipping_date: any){
		return shipping_date ? moment.unix(shipping_date).utc().format('MM/DD/YYYY') : '-';
	}

	showAttachmentsButton(order: any) {
		return order.attachments.length || Object.keys(order.shipping_attachments).length;
	}

	showDeleteOrder(status: any) {
		return this.userService.hasAccessFunction('create_orders') && status == (this.mappedOrderStatus).NEW;
	}

	deleteSelectedOrder(order: any) {
		this.ordersService.deleteOrder(order.id).subscribe((response: any) => {
			switch(response) {
				case 1:
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.selectedOrderToDelete = {};
					this.getOrders(false, true);
					break;
				default:
					this.notificationMessage.setMessage('globalErrMsg');
					break
			}
		});
	}
	
	resetAddTrackingModal() {
		this.trackingNumber = this.shippingCompany = '';
		this.shippingDate = new Date();
		this.trackingNumberModal.hide();
	}

	ngOnDestroy(): void {
		this.ordersService.shouldBackToSelectedTab = false;
	}
}
