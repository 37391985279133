<div class="d-flex justify-content-between mb-2 px-2">
	<h2>{{'connectivity_verification.title' | translate}}</h2>
</div>
<sct-custom-table
	[columnsConfig]="columnConfig"
	[data]="tableData"
	[config]="tableConfig"
></sct-custom-table>

<ng-template #noWarning>
	{{'---'}}
</ng-template>
