import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../users/users.service';
import { DeviceService } from '../home/site-dashboard/device/device.service';
import { ColumnsConfig, TableConfig, TableData } from '../shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-iccid-changes-report',
	templateUrl: './iccid-changes-report.component.html',
	styleUrls: ['./iccid-changes-report.component.css']
})
export class IccidChangesReportComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		draggable: false,
		pageSize: 1000,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translateService.instant('devices.unique_mac_address'), type: "link"},
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link"},
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link'},
		{ key: 'oem_iccid', name: this.translateService.instant('devices.oem_iccid'), type: 'string'},
		{ key: 'current_iccid', name: this.translateService.instant('devices.current_iccid'), type: 'string'},
	];

	constructor(
		private router: Router,
		private usersService: UsersService,
		private devicesService: DeviceService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		let currentUser = this.usersService.getCurrentUser();
		if(!currentUser.is_admin)
			return this.router.navigate(['/unauthorized']);
		return this.getChangesReportDevices();

	}

	getChangesReportDevices() {
		this.devicesService.getChangesReportDevices().subscribe((data: any) => {
			const devices = data.devices;
			const sites = data.sites;
			const customers = data.customers;

			const tableData = [];
			for(const device of devices) {
				const site = sites[device.site_id];
				const customer = customers[site?.customer_id];

				tableData.push({
					mac_address: {value: device.mac_address, link: ['/', customer?.id, site?.id, device.mac_address, 'performance']},
					site_name: {value: site?.name, link: ['/', customer?.id, site?.id]},
					customer_name: {value: customer?.name, link: ['/', customer?.id]},
					oem_iccid: {value: device.oem_cellular_iccid},
					current_iccid: {value: device.cellular_iccid},
				})
			}
			this.tableData = tableData;
		})
	}
}
