import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DeviceService } from '../../device.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { CommonDataService } from 'src/app/shared/services/common-data.service';


@Component({
	selector: 'app-connectivity-track',
	templateUrl: './connectivity-track.component.html',
})
export class ConnectivityTrackComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() dateRange: any = {};
	@Input() invalidDateRange: boolean = false;
	@Input() currentSite: any;

	emptyResponse = false;

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		fileName: 'connectivity-track',
		hasPagination: true,
		pageSize: 100
	};

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private commonData: CommonDataService
	) { }

	ngOnInit() {
		if (window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.columnConfig = [
			{ key: 'last_connect_time', name: this.translateService.instant('devices.last_connect_time'), type: "date" },
			{ key: 'log_time', name: this.translateService.instant('devices.log_time'), type: "date" },
			{ key: 'connection_type', name: this.translateService.instant('devices.connectivity_type'), type: "string" },
		];
	}

	getConnectivityTrack() {
		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;

		if(!this.device.mac_address || !fromDateRange || !toDateRange)
			return;

		let zoneDiff		= new Date().getTimezoneOffset() * -1;
		let fromDate:any	= new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate:any		= new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();
		fromDate			= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), fromDate);
		toDate				= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), toDate);

		this.deviceService.getConnectivityTrack(this.device.mac_address, fromDate, toDate).subscribe((response: any) => {
			if(response.length)
				this.emptyResponse = false;
			else
				this.emptyResponse = true;

			const tableData = [];

			response.forEach((record) => {
				record.last_connect_time	= this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record.last_connect_time);
				record.log_time				= this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record.log_time);

				record.last_connect_time	= moment(record.last_connect_time * 1000).utc().format('MM/DD/YYYY HH:mm:ss');
				record.log_time				= moment(record.log_time * 1000).utc().format('MM/DD/YYYY HH:mm:ss');

				if(record.metered)
					record.connection_type = `${this.translateService.instant('client_socket_type.' + record.client_socket_type)} (${this.translateService.instant('g.metered')})`;
				else
					record.connection_type = `${this.translateService.instant('client_socket_type.' + record.client_socket_type)} (${this.translateService.instant('g.non_metered')})`;

				tableData.push({
					last_connect_time: { value: record.last_connect_time },
					log_time: { value: record.log_time },
					connection_type: { value: record.connection_type },
				});
			});

			this.tableData = tableData;
		});
	};

	ngOnChanges() {
		this.getConnectivityTrack();
	}
}
