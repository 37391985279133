import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from '../shared/side-menu/side-menu.service';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { Subscription } from 'rxjs';
import { SitesService } from 'src/app/sites/sites.service';
import { saveAs } from 'file-saver';
import { ColumnsConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-registration-reports',
	templateUrl: './registration-reports.component.html'
})
export class RegistrationReportsComponent implements OnInit {

	sites: any = [];
	getSitesSubscribe!: Subscription;

	columnConfig: ColumnsConfig[] = [
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link', hasFilter: true, hasSort: true},
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: 'link', hasFilter: true, hasSort: true},
		{ key: 'generate_report', name: this.translateService.instant('registration_purposes.generate_report'), type: 'icon'}
	];

	constructor(
		public usersService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		private sitesService: SitesService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		if(!this.usersService.hasAccessFunction('registration_reports'))
			this.router.navigate(['unauthorized']);

		this.sideMenuService.hide();
		this.getSitesData();
	}

	getSitesData() {
		this.sites = [];
		this.getSitesSubscribe = this.sitesService.getSitesWithCustomerInfo().subscribe((results: any) => {
			const sites = [];
			[...results].forEach((row) => {
				let rowData: TableData = {
					row,
					customer_name: {value: row.customer_name, link: ['/', row.customer_id]},
					site_name: {value: row.site_name, link: ['/', row.customer_id, row.site_id]},
					generate_report: {icon: 'download', action: () => this.downloadRegistrationPurposes(row.site_id)}
				}
				sites.push(rowData);
			});

			this.sites = sites;
		});
	}

	downloadRegistrationPurposes(siteId: number) {
		this.sitesService.registrationPurposes(siteId).subscribe((res: any) => {
			switch (res.api_status) {
				case 1:
					return this.notificationMessage.setMessage('translate|official_report.no_report_data', {clearOnXTimeNavigate: 1});
				case 2:
					return this.notificationMessage.setMessage('translate|registration_purposes.no_devices_founded', {clearOnXTimeNavigate: 1});
			}
			this.downloadReport(res);
		});
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	ngOnDestroy(): void {
		if (this.getSitesSubscribe)
			this.getSitesSubscribe.unsubscribe();
	}
}
