import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-info-box',
	templateUrl: './info-box.component.html',
	styleUrls: ['./info-box.component.css']
})
export class InfoBoxComponent implements OnInit, OnChanges {
	@Input() chargLinkDevices?: any;
	@Input() ioTAhDevices?: any;
	@Input() hours: number;
	@Input() ahrs: number;
	@Input() whrs: any;
	@Input() billedWhrs: number;
	@Input() deviceTypeFilterSelected: string = '';

	chargLinkDevicesUnits= {
		ahrs: "",
		whrs: "",
		billed_whrs: ""
	}
	ioTAhDevicesUnits= {
		ahrs: "",
		whrs: "",
		billed_whrs: ""
	}
	CO2: string;
	numberOfDevicesIcon: string;

	constructor(
		private commonService: CommonService,
		private deviceService: DeviceService
	) { }

	ngOnInit() {
	}

	ngOnChanges() {
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		this.format(decimalPipe);
		this.format(decimalPipe, true);
	}

	format(decimalPipe: DecimalPipe, isChargLink=false) {
		const key = isChargLink ? 'chargLinkDevices' : 'ioTAhDevices';
		const {billedWhrs, billedWhrsUnit} = this.formatBilledWhrs(decimalPipe,this[key].billed_whrs);
		this[key].billed_whrs = billedWhrs;
		this[`${key}Units`].billed_whrs = billedWhrsUnit;
		const {ahrs, ahrsUnit} = this.formatAhrs(this[key].ahrs);
		this[key].ahrs = ahrs;
		this[`${key}Units`].ahrs = ahrsUnit;
		const {whrs, whrsUnit} = this.formatWhrs(decimalPipe,this[key].whrs);
		this[key].whrs = whrs;
		this[`${key}Units`].whrs = whrsUnit;
		if(!isChargLink)
			this.formatCO2data(decimalPipe);
		this[key].hours = this[key].hours / 3600;
	}

	formatBilledWhrs(decimalPipe: DecimalPipe, billedWhrsData) {
		let billedWhrs = billedWhrsData / 3600;
		let billedWhrsObj: any = {result: decimalPipe.transform(Math.round(billedWhrs) / 1000, '1.0-0')};
		billedWhrs = billedWhrsObj.result;
		const billedWhrsUnit = billedWhrsObj.unit ? billedWhrsObj.unit : '';
		return {billedWhrs, billedWhrsUnit}
	}

	formatCO2data(decimalPipe: DecimalPipe) {
		let co2 = this.deviceService.calculateCO2(this.billedWhrs/1000);
		this.CO2 = decimalPipe.transform(co2, '1.0-2');
	}

	formatAhrs(ahrsData) {
		let ahrsObj = this.commonService.formatTotalDeviceNumbers(Math.round(ahrsData / 3600));
		const ahrs = ahrsObj.result;
		const ahrsUnit = ahrsObj.unit ? ahrsObj.unit : '';
		return {ahrs, ahrsUnit}
	}

	formatWhrs(decimalPipe: DecimalPipe, whrsData) {
		let whrsObj: any = {result: decimalPipe.transform(Math.round(whrsData / 3600) / 1000, '1.0-0')};
		const whrs = whrsObj.result;
		const whrsUnit = whrsObj.unit ? whrsObj.unit : '';
		return {whrs, whrsUnit}
	}

	showIoTahInfo(): boolean {
		return [this.commonService.DEVICES_TYPE.IoTAh, ''].includes(this.deviceTypeFilterSelected) && this.ioTAhDevices?.count > 0;
	}

	showChargLinkInfo(): boolean {
		return [this.commonService.DEVICES_TYPE.ChargLink, ''].includes(this.deviceTypeFilterSelected) && this.chargLinkDevices?.count > 0;
	}
}
