import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-configure-study-devices',
	templateUrl: './configure-study-devices.component.html',
	styleUrls: ['./configure-study-devices.component.css']
})

export class ConfigureStudyDevicesComponent {
	searchString: string = '';
	searchBy: string = 'mac'; // Or SN option
	searchResult: any = null;
	sites: any = [];
	multiSearchDeviceSubscription: any = [];
	selectedDevices: any = [];
	isDeviceNotFound: boolean = false;
	errorMessage: string = '';
	maxDevicesCountPerFile = 50;
	bulkSearchNotFoundDevices: string[] = [];

	@ViewChild('labelImport')
	labelImport: ElementRef;

	constructor(
		private translateService: TranslateService,
		public usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private commonService: CommonService,
		private deviceManagementService: DeviceManagementService,
		private router: Router,
		private sideMenuService: SideMenuService
	) {}

	ngOnInit(): void {
		if(!this.usersService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);
		this.sideMenuService.hide();
	}


	async prepareMultiDevicesForDevicesConfiguration(event: any) {
		this.selectedDevices = [];
		this.bulkSearchNotFoundDevices = [];
		const file = event?.target?.files[0];
		if (!file)
			return this.notificationMessage.setMessage('translate|ready_to_ship.no_selected_files', { clearOnXTimeNavigate: 1 });

		const fileExtension = file.name.split('.').pop();
		const allowedFilesExt = ['csv'];
		if (!allowedFilesExt.includes(fileExtension.toLowerCase())) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_extension', { clearOnXTimeNavigate: 1 });
		}

		this.labelImport.nativeElement.innerText = file.name;

		const fileAsText = (await file.text()).trim();
		let csvData: string[] = fileAsText.split("\n").map((item: string) => item.replace('\r', '').trim()).filter((item: string) => item && item != '');
		csvData = [...new Set(csvData)];

		if (csvData.length > this.maxDevicesCountPerFile) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage(this.translateService.instant('shipment.csv_file_more_50_records'), { clearOnXTimeNavigate: 1 });
		}

		const deviceIds = [];
		for (const row of csvData) {
			const macSerialObj = this.commonService.adjustMacSearchString(row);
			if (macSerialObj.mac_address)
				deviceIds.push(macSerialObj.mac_address);
			else
				deviceIds.push(macSerialObj);
		}

		this.multiSearchDeviceSubscription = this.deviceManagementService.searchDevices(deviceIds, this.searchBy, false, false, 'normal-search', true).subscribe((data: any) => {
			const devicesInfo = data.devices || [];

			if (devicesInfo.length != csvData.length) {
				const macAddresses: string[] = [];
				const serialNumbers: string[]= [];
				const mixMacsAndSerials: string[]= [];
				const mixSerialsAndMacs: string[]= [];

				for (const device of devicesInfo) {
					macAddresses.push(device.mac_address);
					serialNumbers.push(device.serial_number);
					mixMacsAndSerials.push(`${device.mac_address}${device.serial_number}`);
					mixSerialsAndMacs.push(`${device.serial_number}${device.mac_address}`);
				}

				const notFoundDevices = csvData.filter((deviceId: string) => {
					return !macAddresses.includes(deviceId) && !serialNumbers.includes(deviceId) && !mixMacsAndSerials.includes(deviceId) && !mixSerialsAndMacs.includes(deviceId);
				});

				if (notFoundDevices.length) {
					event.target.value = '';
					this.bulkSearchNotFoundDevices = notFoundDevices;
					this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
					this.searchResult = [];
					return;
				}
			}

			this.searchResult = devicesInfo;
			this.sites = _.indexBy(data.sites || [], 'id');

			if (this.searchResult.length > 0) {
				this.checkDeviceConfiguration(this.searchResult);
				event.target.value = '';
			}
		});
	}

	checkDeviceConfiguration(devices) {
		for (const device of devices) {
			if (device.is_study_device) {
				device.deviceConfigurationError = this.translateService.instant('g.device_already_ps');
				continue;
			}
			if (device.is_charglink) {
				device.deviceConfigurationError = this.translateService.instant('g.not_iotah_device');
				continue;
			}
			if (!device.is_study_device && !device.connected) {
				device.deviceConfigurationError = this.translateService.instant('g.device_offline');
				continue;
			}

			if (this.selectedDevices.some((item) => item.mac_address == device.mac_address)) {
				device.deviceConfigurationError = this.translateService.instant('g.duplicate_mac_for_cm');
				continue;
			}

			this.prepareDevicesToPSConfiguration(device, this.sites[device.site_id]?.customer_id);
		}
	}

	getDevices() {
		this.notificationMessage.closeNotification();
		this.deviceManagementService.searchDevices(this.searchString, this.searchBy, false, false, 'normal-search').subscribe((data: any) => {
			this.searchResult = data.devices || [];
			this.sites = _.indexBy(data.sites || [], 'id');
			this.checkDeviceConfiguration(this.searchResult);
			this.bulkSearchNotFoundDevices = [];
		});
	}

	prepareDevicesToPSConfiguration(device, customerId) {
		device._customer_id = customerId;
		this.selectedDevices.push(device);

		this.searchString = '';
		this.searchResult = this.searchResult.filter((item) => item.mac_address != device.mac_address);
		this.bulkSearchNotFoundDevices = [];
	}

	removeFromPsDevicesConfiguration(device: any) {
		const macAddress = device.mac_address;
		this.selectedDevices = this.selectedDevices.filter((device) => {
			return device.mac_address != macAddress;
		});
	}

	devicesPSConfiguration() {
		const macAddresses = _.map(this.selectedDevices, 'mac_address');
		this.deviceManagementService.configurePSDevices(macAddresses).subscribe((res: any) => {
			if (res?.api_status) {
				let messageRes = 'globalErrMsg';
				switch(res.api_status) {
					case 2:
						messageRes = 'translate|ready_to_ship.disconnected_devices';
						break;
				}

				return this.notificationMessage.setMessage(messageRes, { clearOnXTimeNavigate: 1 });
			}

			this.selectedDevices = [];
			this.searchResult = [];
			return this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
		});
	}
}