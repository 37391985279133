import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SitesService } from 'src/app/sites/sites.service';
import { ReceivingShipmentService } from 'src/app/management/receiving-shipment/receiving-shipment.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-receive-shipments',
	templateUrl: './receiving-shipment.component.html',
})
export class ReceivingShipmentComponent implements OnInit, OnDestroy, AfterViewInit {

	currentUser:any = [];
	searchString: string = '';
	searchBy: string = 'mac'; // sn: serial number, mac: mac address
	searchResult: any = null;
	sites: any = [];
	intervalId: any;
	macAddress: string;
	fwUpdateStatus: string = this.translate.instant('g.n/a');
	devices: any = {};
	selectedDevice: any = {};
	DEVICE_STATUS = this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS;
	fwDevicesStatus: any = {};

	isGpsNotReady: any = {};
	skipShipmentQuality = false;
	hasFailures: boolean = false;
	showUpload = true;
	csvDataLimit = 50;
	shipmentCounter = 0;
	notFoundDevices: string[];
	searchedDevices: string[];
	cmPoNumberError = '';
	resetRTCDevice = '';

	@ViewChild("warningDialog") warningDialog: any;
	@ViewChild('labelImport') labelImport: ElementRef;
	@ViewChild("resetRTCLostDetectionModal") resetRTCLostDetectionModal;

	private shipmentCounterSubject= new BehaviorSubject<number>(0);
	processCounter = this.shipmentCounterSubject.asObservable();
	private doMoveToShipmentSubject = new BehaviorSubject<number>(0);
	doMoveToShipmen = this.doMoveToShipmentSubject.asObservable();

	shipmentOptions = {
		historyReportId: null,
		skipShipmentQuality: false,
		source: this.devicesService.shipmentSource.receive,
		cm_po_number: '',
		time_zone_diff: -new Date().getTimezoneOffset(),
	};

	constructor(
		private router: Router,
		public userService: UsersService,
		private devicesService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private translate : TranslateService,
		private sideMenuService: SideMenuService,
		private deviceService: DeviceService,
		private loader: LoaderInterceptorService,
		public commonDataService: CommonDataService,
		private sitesService: SitesService,
		public receivingShipmentService: ReceivingShipmentService,
		public common: CommonService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		if(!this.userService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);

		this.intervalId = setInterval(() => {
			this.getFWUpdateStatus();
		}, 30000); //every 30 seconds

		this.sideMenuService.hide();

		this.processCounter.subscribe((counter: number) => {
			if(this.shipmentOptions.skipShipmentQuality && (this.devicesHaveFWUpdate() || this.shouldAllowSubmitShipment()) && counter == Object.values(this.devices).length) {
				this.submitShipment();
				this.shipmentCounter = Object.values(this.devices).filter((item: any) => this.receivingShipmentService.isRecevingProccessSuccess(item.status)).length;
			}
		});
	}

	ngAfterViewInit() {
		this.resetRTCLostDetectionModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.resetRTCLostDetection();
		});
	}

	showResetRTCPopup(mac_address) {
		this.resetRTCLostDetectionModal.passData = [{'reset': true}];
		this.resetRTCLostDetectionModal.show();
		this.resetRTCDevice = mac_address;
	}

	getDevices(){
		this.notificationMessage.closeNotification();
		this.devicesService.searchDevices(this.searchString, this.searchBy, false, false, 'receive-shipments').subscribe((data: any) => {
			this.searchResult = data.devices || [];
			this.sites = _.indexBy(data.sites || [], 'id');

			// Status is currently supported for a single device only
			this.macAddress = this.searchResult[0]?.mac_address;
			this.searchedDevices = this.searchResult.map((device: any) => device.mac_address);
			this.getFWUpdateStatus();
		});
	}

	doReceiveShipment(device: any) {
		if (!this.checkCmPONumberExist())
			return;
		if (this.shipmentOptions.skipShipmentQuality)
			return this.moveDeviceToInventory(device);

		return this.checkFWUpdateStatus(device);
	}

	checkFWUpdateStatus(device) {
		if(!this.shipmentOptions.historyReportId)
			return;

		this.devicesService.checkDeviceFWStatus([device.mac_address], { receivingShipmentStarted: true, ...this.shipmentOptions }).subscribe((res: any) => {
			this.fwDevicesStatus[device.mac_address] = res.status[device.mac_address];
			this.shipmentOptions.historyReportId = res.reportId;

			if (!this.fwDevicesStatus[device.mac_address].step){
				this.hideReCheckFailureButton();
				this.checkShipmentQuality(device);
			} else if(!this.hasFailures)
				this.hasFailures = true;
		});
	}

	checkShipmentQuality(device) {
		this.devicesService.checkShipmentQuality(device.mac_address, true, this.shipmentOptions).subscribe((data: any) => {
			if(this.manageResponseMessage(device.mac_address, data)) {
				this.checkCellularTestMode(device);
			}
		});
	}

	checkCellularTestMode(device)  {
		this.devicesService.checkCellularTestMode(device.mac_address, this.shipmentOptions).subscribe((data: any) => {
			if(this.manageResponseMessage(device.mac_address, data)) {
				this.checkCellularGpsStatus(device);
			}
		});
	}

	checkCellularGpsStatus(device) {
		this.devicesService.checkCellularGpsStatus(device.mac_address, this.shipmentOptions).subscribe((data: any) => {
			if(this.manageResponseMessage(device.mac_address, data)) {
				this.moveDeviceToInventory(device);
			}
		});
	}

	moveDeviceToInventory(device) {
		this.devicesService.receiveDeviceShipment(device.mac_address, this.shipmentOptions).subscribe((res: any) => {
			this.shipmentOptions.historyReportId = res.reportId || res.response?.reportId || this.shipmentOptions.historyReportId;

			this.manageResponseMessage(device.mac_address, res, true);
			this.shipmentCounterSubject.next(++this.shipmentCounter);
		});
	}

	getFWUpdateStatus() {
		const deviceIds = this.searchedDevices.concat(Object.keys(this.devices));
		if (this.shipmentOptions.skipShipmentQuality || !deviceIds.length)
			return;

		this.devicesService.checkDeviceFWStatus(deviceIds, { receivingShipmentStarted: false, ...this.shipmentOptions }).subscribe((res: any) => {
			this.fwDevicesStatus = res.status;
		});
	}

	sendCommand() {
		this.deviceService.sendCommand(this.macAddress, 'indicatorLight', {}).subscribe((data: any) => {
			// if the api timed-out OR if wait sent and the device is disconnected.
			if (data.response_status == this.loader.resStatus.api_timeout || data.api_status == this.deviceService.deviceCommandsResponse)
				this.notificationMessage.setMessage('translate|warnings.disconnected_device', {type: 'danger', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
			else
				this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	ngOnDestroy() {
		clearInterval(this.intervalId);
		this.shipmentCounterSubject.unsubscribe();
	}

	manageResponseMessage(mac_address: string, response: any, isFinalStep=false) {
		this.devices[mac_address].status = this.DEVICE_STATUS.in_progress.status;
		this.devices[mac_address].warnings = [];
		const currentVoltageCheck = response.api_status && response.api_status != 1 ? response?.response?.currentVoltageCheck : response?.currentVoltageCheck;
		if(currentVoltageCheck) {
			delete currentVoltageCheck.err;
			this.devices[mac_address].currentVoltageCheck = currentVoltageCheck;
		}

		switch(response.api_status) {
			case 2:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_connected.status;
			break;
			case 3:
				let warnings = response.response.warnings;
				for (let warning of warnings)
					this.devices[mac_address].warnings.push(this.translate.instant('warnings.'+ warning));
			break;
			case 4:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cmd_cell_test_mode_failed'));
			break;
			case 5:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cellular_and_gps_not_ready'));
			break;
			case 6:
				this.devices[mac_address].status = this.DEVICE_STATUS.cellular_not_connected.status;
			break;
			case 7:
				//this.devices[mac_address].status = this.DEVICE_STATUS.gps_not_ready.status;
				this.isGpsNotReady[mac_address] = true;
				return true;
			break;
			case 8:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.factory_reset_fails'));
			break;
			case 9:
				this.devices[mac_address].warnings.push(this.translate.instant('shipment.new_site_fw_upgrade'));
			break;
			case 10:
				this.devices[mac_address].status = this.DEVICE_STATUS.no_cellular_iccid.status;
			break;
			case 11:
				this.devices[mac_address].warnings.push(this.translate.instant('shipment.cellular_iccid_does_not_exist'));
			break;
			case 12:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cellular_not_ready'));
			break;
			case 13:
				this.devices[mac_address].status = this.DEVICE_STATUS.device_not_cm_devices.status;
			break;
			case 14:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_reachable.status;
			break;
			case 15:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_authorized.status;
			break
			case 16:
				this.devices[mac_address].status = this.DEVICE_STATUS.voltage_error.status;
			break;
			case 17:
				this.devices[mac_address].status = this.DEVICE_STATUS.current_error.status;
			break;
			case 18:
				return this.notificationMessage.setMessage('translate|shipment.device_wifi_connected_promt', {type: 'danger', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
			break;
			case 19:
				return this.notificationMessage.setMessage('translate|shipment.not_passed_shipment_quality', {type: 'danger', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
			break;
			default:
				if(isFinalStep) {
					this.devices[mac_address].status = this.isGpsNotReady[mac_address] ?? false ? this.DEVICE_STATUS.completed_gps_not_ready.status : this.DEVICE_STATUS.completed.status;
					this.searchResult = null;
					this.searchString = '';
					this.devices[mac_address].customer_id = this.sitesService.sctCustomerId;
					this.devices[mac_address].site_id = this.sitesService.sctInventorySiteId;
				} else
					return true;
			break;
		}
		if (this.devices[mac_address].warnings.length)
			this.devices[mac_address].status = this.DEVICE_STATUS.warnings.status;

		this.hideReCheckFailureButton();

		if(response.api_status && ![1, 7].includes(response.api_status))
			this.hasFailures = true;

		return false;
	}

	checkVoltageCurrentRanges(device) {
		if(this.shipmentOptions.skipShipmentQuality) {
			this.notificationMessage.closeNotification();
			return this.addToShipment(device);
		}
		this.devicesService.getLiveQuickView(device.mac_address).subscribe((res: any) => {
			let msg: string;
			if(res.api_status && res.api_status != 1) {
				msg = 'g.global_err';
				switch (res.api_status) {
					case 2:
						msg = 'shipment.not_connected'
						break;
					case 14:
						msg = 'shipment.not_reachable'
						break;
				}
			} else {
				const qv = this.common.decompress(res || {}, 'quick_view');

				const voltage = +qv.voltage.toFixed(1);
				const current = +qv.current.toFixed(1);
				if(voltage < this.devicesService.currentAndVoltageRanges.lowVoltageRange || voltage > this.devicesService.currentAndVoltageRanges.highVoltageRange)
					msg = 'shipment.voltage_error';
				else if((current < this.devicesService.currentAndVoltageRanges.lowCurrentRange || current > this.devicesService.currentAndVoltageRanges.highCurrentRange))
					msg = 'shipment.current_error';
			}
			if(msg) {
				this.notificationMessage.setMessage(`translate|${msg}`, { clearOnXTimeNavigate: 1, timeout: 20000 });
				return;
			}
			this.addToShipment(device);
		});
	}

	addToShipment(device: any) {
		if (this.devices[device.mac_address])
			return;

		device = {
			mac_address: device.mac_address,
			serial_number: device.serial_number,
			site_id: device.site_id,
			customer_id: this.sites[device.site_id].customer_id,
			status: this.DEVICE_STATUS.not_start.status,
			time_lost: device.time_lost,
			is_device_in_cm_devices: device.is_device_in_cm_devices,
		};

		this.devices = cloneDeep({ ...this.devices, [device.mac_address]: device });
		this.searchResult = this.searchResult.filter(item => item.mac_address != device.mac_address);
		this.searchString = '';
	}

	shouldShowShipmentDevices() {
		return Object.keys(this.devices).length;
	}

	showWarnings(mac_address: any) {
		if (!this.devices[mac_address].warnings)
			return;

		this.selectedDevice = this.devices[mac_address];
		this.warningDialog.show();
	}

	removeFromShipment(mac_address: string) {
		delete this.devices[mac_address];
		this.devices = cloneDeep(this.devices);
		this.hideReCheckFailureButton();
	}

	shouldAllowSubmitShipment() {
		const notAllowedSubmitStatus = [this.DEVICE_STATUS.not_start.status, this.DEVICE_STATUS.in_progress.status];
		return !Object.values(this.devices).some((device: any) => notAllowedSubmitStatus.includes(device.status));
	}

	devicesHaveFWUpdate() {
		return Object.values(this.fwDevicesStatus).some((item: any) => item?.step);
	}

	moveToBulk(device: {is_sct_inventory: boolean, is_device_in_cm_devices: any; time_lost: any; mac_address: string, has_mismatched_sn: boolean, client_socket_type: number, shipment_quality_passed: boolean }) {
		return (
			device.is_device_in_cm_devices &&
			(!device.time_lost || this.shipmentOptions.skipShipmentQuality) &&
			(!this.fwDevicesStatus[device.mac_address]?.step || this.shipmentOptions.skipShipmentQuality) &&
			!this.devices[device.mac_address] &&
			(device.client_socket_type == this.commonDataService.CLIENT_SOCKET_TYPE.WIFI || this.shipmentOptions.skipShipmentQuality) &&
			(!this.checkDeviceFwIsPrevented(device.mac_address) || this.shipmentOptions.skipShipmentQuality) &&
			this.checkDeviceShipmetQuality(device.shipment_quality_passed)
		)
	}

	createInitialHistory(device?: any) {
		if (!this.checkCmPONumberExist())
			return;
		let devices = Object.values(this.devices);
		if (!devices.length)
			return;

		if(this.shipmentOptions.historyReportId) {
			if(device)
				return this.doReceiveShipment(device);
			return this.bulkReceive();
		}


		this.devicesService.createInitialHistory(this.shipmentOptions).subscribe((res: any) => {
			const reportId = res;
			this.shipmentOptions.historyReportId = reportId;

			if(device)
				return this.doReceiveShipment(device);

			this.bulkReceive();
		});
	}

	submitShipment(forceSubmit=false) {
		let devices = Object.values(this.devices);
		if (!devices.length)
			return;

		if(this.hasFailures && !forceSubmit)
			return;

		this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
		return this.router.navigate(['/management/receiving-shipment-history/', this.shipmentOptions.historyReportId]);

	}

	receiveButtonDisabled(macAddress: string) {
		return this.devices[macAddress].status == this.DEVICE_STATUS.in_progress.status
		|| this.devices[macAddress].status == this.DEVICE_STATUS.completed.status
		|| this.devices[macAddress].status == this.DEVICE_STATUS.completed_gps_not_ready.status
			|| (this.fwDevicesStatus[macAddress]?.step && !this.shipmentOptions.skipShipmentQuality);
	}

	enableDisableSkipShipmentQuality() {
		return !!Object.values(this.devices).length;
	}

	checkDeviceFwIsPrevented(macAddress) {
		return this.fwDevicesStatus[macAddress]?.status == this.DEVICE_STATUS.fw_update_prevented.status;
	}

	async reCheckFailures() {
		if (!this.checkCmPONumberExist())
			return;

		for(const macAddress in this.devices) {
			if(this.devices[macAddress].status != this.DEVICE_STATUS.not_start.status && !this.receiveButtonDisabled(macAddress) && this.devices[macAddress].status != this.DEVICE_STATUS.completed_gps_not_ready.status) {
				this.devices[macAddress].status = this.DEVICE_STATUS.in_progress.status;
				this.doReceiveShipment(this.devices[macAddress]);
				await this.delay(100);
			}
		}
		this.hasFailures = false;
	}

	async bulkReceive(onlyRedirect=false) {
		this.showUpload = false;
		if(onlyRedirect)
			return this.router.navigate(['/management/receiving-shipment-history/', this.shipmentOptions.historyReportId]);
		for(const macAddress in this.devices)
			if(this.devices[macAddress].status == this.DEVICE_STATUS.not_start.status) {
				this.doReceiveShipment(this.devices[macAddress]);
				await this.delay(100);
			}
	}

	hideReCheckFailureButton() {
		let hide = true;
		for(const macAddress in this.devices) {
			if(this.devices[macAddress].status != this.DEVICE_STATUS.not_start.status && !this.receiveButtonDisabled(macAddress) && this.devices[macAddress].status != this.DEVICE_STATUS.completed_gps_not_ready.status) {
				hide = false;
				break;
			}
		}
		this.hasFailures = !hide;
	}

	async handleUploadCSVFile(event) {
		const file = event.target.files[0];
		if (!file)
			return this.notificationMessage.setMessage('translate|ready_to_ship.no_selected_files', { clearOnXTimeNavigate: 1 });

		if(file.type  != 'text/csv') {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_extension', {clearOnXTimeNavigate: 1});
		}

		this.labelImport.nativeElement.innerText = file.name;

		const text = (await file.text()).trim();
		let csvData: string[] = text.split("\n").map(item => item.replace('\r', '').trim()).filter(item => item && item != '');
		csvData = [...new Set(csvData)];

		if(!csvData.length) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_length', {clearOnXTimeNavigate: 1});
		}

		if(csvData.length > this.csvDataLimit) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.csv_file_more_50_records', {clearOnXTimeNavigate: 1});
		}

		const deviceIds = [];
		for (const row of csvData) {
			const macSerialObj = this.common.adjustMacSearchString(row);
			if (macSerialObj.mac_address)
				deviceIds.push(macSerialObj.mac_address);
			else
				deviceIds.push(macSerialObj);
		}

		this.devicesService.searchDevices(deviceIds, this.searchBy, false, false, 'receive-shipments', true).subscribe((data: any) => {

			const devicesInfo = data.devices || [];

			if (devicesInfo.length != csvData.length) {
				const macAddresses: string[] = [];
				const serialNumbers: string[]= [];
				const mixMacsAndSerials: string[]= [];
				const mixSerialsAndMacs: string[]= [];

				for (const device of devicesInfo) {
					macAddresses.push(device.mac_address);
					serialNumbers.push(device.serial_number);
					mixMacsAndSerials.push(`${device.mac_address}${device.serial_number}`);
					mixSerialsAndMacs.push(`${device.serial_number}${device.mac_address}`);
				}

				const notFoundDevices = csvData.filter((deviceId: string) => {
					return !macAddresses.includes(deviceId) && !serialNumbers.includes(deviceId) && !mixMacsAndSerials.includes(deviceId) && !mixSerialsAndMacs.includes(deviceId);
				});

				if(notFoundDevices.length) {
					event.target.value = '';
					this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
					this.notFoundDevices = notFoundDevices;
					return;
				}
			}

			this.showUpload = false;
			this.searchResult = data.devices || [];
			this.searchedDevices = this.searchResult.map((device: any) => device.mac_address);
			this.sites = _.indexBy(data.sites || [], 'id');

			this.doBulkProcess();
		});
	}

	doBulkProcess() {
		if (!this.searchResult.length)
			return;

		for (const device of this.searchResult) {
			if (this.moveToBulk(device))
				this.checkVoltageCurrentRanges(device);
		}
	}

	checkDisabledInputs() {
		return this.shipmentOptions.skipShipmentQuality && !this.showUpload;
	}

	getStatuses() {
		let hasInprogress = false;
		let hasNotStart = false;
		let hasFWUpdate = false;
		let hasOtherStatus = false;
		for(const deviceId in this.devices) {
			const device = this.devices[deviceId];
			if(!hasInprogress && device.status == this.DEVICE_STATUS.in_progress.status)
				hasInprogress = true;
			if(!hasNotStart && device.status == this.DEVICE_STATUS.not_start.status)
				hasNotStart = true;
			if(!hasFWUpdate && this.fwDevicesStatus[deviceId]?.step)
				hasFWUpdate = true;
			if(!hasOtherStatus && ![this.DEVICE_STATUS.in_progress.status, this.DEVICE_STATUS.not_start.status].includes(device.status) && !this.fwDevicesStatus[deviceId]?.step)
				hasOtherStatus = true;
		}
		return { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus };
	}

	isButtonDisabled() {
		const { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus } = this.getStatuses();
		let flag = true;
		switch (true) {
			case (!hasInprogress && hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = this.shipmentOptions.skipShipmentQuality ? false : true;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
		}
		return flag;
	}

	toggleButtons() {
		const { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus } = this.getStatuses();
		let flag = true;
		switch (true) {
			case (!hasInprogress && hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
		}
		return flag;
	}

	checkCmPONumberExist() {
		this.cmPoNumberError = '';
		if (!this.shipmentOptions.cm_po_number) {
			this.cmPoNumberError =  this.translate.instant('g.field_is_required');
			return false;
		}
		return true;
	}

	checkDeviceShipmetQuality(passedShipmentQuality: boolean) {
		return !this.shipmentOptions.skipShipmentQuality || passedShipmentQuality;
	}

	resetRTCLostDetection() {
		this.deviceService.resetRTCLostDetection(this.resetRTCDevice).subscribe((response: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getDevices();
		});
	}

	delay(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
