import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import * as _ from 'underscore';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-device-replacement-requests',
	templateUrl: './device-replacement-requests.component.html',
	styleUrls: ['./device-replacement-requests.component.css']
})
export class DeviceReplacementRequestsComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'from', name: this.translateService.instant('g.from'), type: "link"},
		{ key: 'to', name: this.translateService.instant('g.to'), type: 'link' },
		{ key: 'request_by', name: this.translateService.instant('device_management.request_by'), type: "link" },
		{ key: 'enterprise_name', name: this.translateService.instant('g.enterprise_name'), type: "string" },
		{ key: 'is_reset_data', name: this.translateService.instant('device_management.is_reset_data'), type: "boolean" },
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: "dropdown" },
	];

	requestsList: any;
	usersInfo: any = {};
	enterprisesInfo: any = {};

	doneRequest = {
		enterprise: false,
		user: false
	};

	constructor(
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		public enterpriseService: EnterpriseService,
		private router: Router
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('replace_device'))
			return this.router.navigate(['/unauthorized']);
		this.getReplacementRequests();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'approveRequest') {
				return this.approveRequest(result[1][1]);
			}

			if(result[1][0] == 'rejectRequest') {
				return this.rejectRequest(result[1][1]);
			}
		});
	}

	confirmAction(action, data) {
		this.confirmModal.passData = [action, data];
		switch(action) {
			case 'approveRequest':
				this.confirmModal.message = 'device_management.approve_replacement_prompt';
			break;
			case 'rejectRequest':
				this.confirmModal.message = 'device_management.reject_replacement_prompt';
			break;
		}
		this.confirmModal.show();
	}

	getReplacementRequests() {
		this.deviceManagementService.getReplacementRequests({get_sn: true}).subscribe((res) => {
			this.requestsList = res;
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();
			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
				this.doneRequest.user = true;

				if (this.doneRequest.enterprise)
					this.fillTableData();
			});
			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
				this.doneRequest.enterprise = true;

				if (this.doneRequest.user)
					this.fillTableData();
			});
		});
	}

	fillTableData() {
		const tableData = [];

		for(const request of this.requestsList) {
			const requestByCondition = this.usersService.hasAccessFunction('edit_user_admin') && this.usersInfo[request.user_id];
			tableData.push({
				from: {value: request.serial_number, link: ['/', request._current_customer, request._current_site, request.mac_address]},
				to: {value: request.new_serial_number, link: ['/', request._new_customer, request._new_site, request.new_mac_address]},
				request_by: {
					value: requestByCondition ?
						`${this.usersInfo[request.user_id].first_name} ${this.usersInfo[request.user_id].last_name}` :
						`${request.user_id} ${!this.usersInfo[request.user_id] ? this.translateService.instant('g.deleted') : ""}`,
					link: requestByCondition ? ['/user', 'edit', request.user_id] : null
				},
				enterprise_name: {
					value: this.enterprisesInfo[request.enterprise_id] ?
						this.enterprisesInfo[request.enterprise_id].name :
						`${request.enterprise_id} (${this.translateService.instant('g.deleted')})`,
				},
				is_reset_data: {value: request.is_reset_data},
				actions: {value: null, options: [
					{text: this.translateService.instant('device_management.approve'), action: ()=> {this.confirmAction('approveRequest', request.id)}},
					{text: this.translateService.instant('g.reject'), action: ()=> {this.confirmAction('rejectRequest', request.id)}}
				]}
			})
		}

		this.tableData = tableData;
	}

	approveRequest(id) {
		this.deviceManagementService.approveReplacementRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getReplacementRequests();
		});
	}

	rejectRequest(id) {
		this.deviceManagementService.rejectReplacementRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getReplacementRequests();
		});
	}
}
