import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import * as _ from 'underscore';

import { Subscription } from 'rxjs';
import { SitesService } from 'src/app/sites/sites.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-sites-map',
	templateUrl: './sites-map.component.html',
	styleUrls: ['./sites-map.component.css']
})
export class SitesMapComponent implements OnInit, OnDestroy {

	tags = [];
	data = {};
	sites = [];
	verifiedSites = [];
	allSites = [];
	filteredSites = [];
	expandedCustomersSub: Subscription = new Subscription();
	dataSub: Subscription = new Subscription();
	filteredSitesSub: Subscription = new Subscription();
	deviceFilterType: Subscription = new Subscription();
	sitesAnalytics: any;
	deviceTypeFilterSelected: string = '';
	initLat: any;
	initLong: any;
	activeDataSub: any;

	constructor(
		private sideMenuService: SideMenuService,
		public sitesService: SitesService,
	) { }

	ngOnInit() {
		this.initLat = this.sitesService.defaultMapLocation.latitude;
		this.initLong = this.sitesService.defaultMapLocation.longitude;

		this.reset(true);
		this.dataSub = this.sideMenuService.data.subscribe(data => {
			this.data = data;
			this.sites = [];
			_.pluck(data, "sites").forEach(item=>{
				this.sites = this.sites.concat(item);
			});
			this.allSites = this.sites;
			this.filterVerifiedSites();
		});

		this.deviceFilterType = this.sideMenuService.showInfoRelatedDevicesType.subscribe((data) => {
			this.deviceTypeFilterSelected = data;
		});

		this.expandedCustomersSub = this.sideMenuService.expandedCustomers.subscribe(data => {
			let sites = [];
			this.tags = data;

			_.pluck(data, "sites").forEach(item=>{
				sites = sites.concat(item);
			});

			this.sites = sites;
			let all = false;

			if (this.tags.length == 0 && this.filteredSites.length ==0)
				this.sites = this.allSites;

			if (!data.length)
				all = true;

			this.filterVerifiedSites(all);
		});

		this.filteredSitesSub = this.sideMenuService.filteredSites.subscribe(data => {
			this.sites = [];
			this.filteredSites = data;

			if (data.length > 0) {
				this.sites = data;
			} else {
				this.sites = this.allSites;
			}
			this.filterVerifiedSites();
			this.prepareSitesAnalytics();
		});
		this.prepareSitesAnalytics();
	}

	closeExpandedCustomer(customerId) {
		this.sideMenuService.removeExpandedCustomer(customerId);
	}

	reset(withResetFilters = false) {
		if(withResetFilters)
			this.sideMenuService.setSideMenuFilters({});

		this.sideMenuService.setSitesMapClickedSite({});
		this.sideMenuService.setExpandedCustomers([]);
		this.sideMenuService.setCurrentSite({});
		this.sideMenuService.setCurrentCustomer({});
	}

	ngOnDestroy() {
		this.reset();
		this.expandedCustomersSub.unsubscribe();
		this.dataSub.unsubscribe();
		this.filteredSitesSub.unsubscribe();
		this.sideMenuService.setRouteHasSiteInfoBox(false);
		if(this.activeDataSub)
			this.activeDataSub.unsubscribe();
	}

	prepareSitesAnalytics() {
		const siteIds = this.filteredSites.map(site => site.id);
		if(siteIds.length === 0) {
			this.sitesAnalytics = {
				ioTAhDevices: {
					hours: 0,
					ahrs: 0,
					whrs: 0,
					count: 0,
					billed_whrs: 0
				},
				chargLinkDevices: {
					hours: 0,
					ahrs: 0,
					whrs: 0,
					count: 0,
					billed_whrs: 0
				},
			};
			return;
		}
		this.activeDataSub = this.sitesService.activeSiteAnalyticsData.subscribe(sitesAnalytics => {
			const result = {
				ioTAhDevices: {
					hours: 0,
					ahrs: 0,
					whrs: 0,
					count: 0,
					billed_whrs: 0
				},
				chargLinkDevices: {
					hours: 0,
					ahrs: 0,
					whrs: 0,
					count: 0,
					billed_whrs: 0
				},
			};
			for (const siteAnalytics of sitesAnalytics) {
				result.ioTAhDevices.hours += (siteAnalytics.ioTAh_devices?.hours || 0);
				result.ioTAhDevices.ahrs += (siteAnalytics.ioTAh_devices?.ahrs || 0);
				result.ioTAhDevices.whrs += (siteAnalytics.ioTAh_devices?.whrs || 0);
				result.ioTAhDevices.count += (siteAnalytics.ioTAh_devices?.count || 0);
				result.ioTAhDevices.billed_whrs += (siteAnalytics.ioTAh_devices?.billed_whrs || 0);

				result.chargLinkDevices.hours += (siteAnalytics.chargLink_devices?.hours || 0);
				result.chargLinkDevices.ahrs += (siteAnalytics.chargLink_devices?.ahrs || 0);
				result.chargLinkDevices.whrs += (siteAnalytics.chargLink_devices?.whrs || 0);
				result.chargLinkDevices.count += (siteAnalytics.chargLink_devices?.count || 0);
				result.chargLinkDevices.billed_whrs += (siteAnalytics.chargLink_devices?.billed_whrs || 0);
			}

			this.sitesAnalytics = result;
		});

		this.sitesService.getSitesAnalyticsDataCached(siteIds);
	}

	filterVerifiedSites(all: boolean = false) {
		const sites = all ? this.allSites : this.sites;

		this.verifiedSites = _.where(sites, {is_verified: true});
	}
}
