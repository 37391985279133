<div class="bg-white shadow border-radius">
	<div *ngIf="tags.length" class="px-4 py-2">
		<div class="row">
			<span *ngFor="let tag of tags; let last = last" class="d-table-cell align-middle my-3 ml-3">
				<span class="main-green-bg py-2 pl-2 pr-1 rounded text-white">
					<span class="ml-1 mr-3">{{tag.customer_name}}</span>
					<a class="px-2 py-1 light-green-bg pointer rounded" (click)="closeExpandedCustomer(tag)">
						<i class="fa fa-times font-size-12 glyphicon-white d-table-cell align-middle" style="font-size: 0.9rem;"></i>
					</a> 
				</span>
				<span *ngIf="!last" class="vl ml-3"></span>
			</span>
		</div>
	</div>
	<div class="row m-0 px-4 py-5">
		<div class="col-md-6 col-sm-12">
			<app-info-box *ngIf="sitesAnalytics"
				[ioTAhDevices]="sitesAnalytics.ioTAhDevices"
				[chargLinkDevices]="sitesAnalytics.chargLinkDevices"
				[deviceTypeFilterSelected]="deviceTypeFilterSelected"
			></app-info-box>
		</div>
		<div class="col-md-6 col-sm-12">
			<div class="mapbox-widget">
				<app-mapbox
					class="border-radius main-blue-border"
					dotsType="sites"
					[latitude]="initLat"
					[longitude]="initLong"
					zoom="2.5"
					[dots]="verifiedSites"
				></app-mapbox>
			</div>
		</div>
	</div>
</div>