import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { CustomersService } from 'src/app/customers/customers.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-devices-without-location',
	templateUrl: './devices-without-location.component.html',
	styleUrls: ['./devices-without-location.component.css']
})
export class DevicesWithoutLocationComponent implements AfterViewInit, OnDestroy {

	@ViewChild("confirmModal") confirmModal;
	getCustomersSubscription!: Subscription;
	getCustomerSubscription!: Subscription;
	searchDevicesSubscription!: Subscription;
	getDevicesSubscription!: Subscription;
	disableXtraFileSubscription!: Subscription;

	addDeviceManually = false;
	customerList = [];
	customersDevicesObject = {};
	customerDevicesList = [];
	searchedDevices: any = null;
	filter = {
		customers: null,
		devices: []
	}

	devices: any[] = [];
	selectedDevices: any[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 50,
		fileName: this.translateService.instant('devices_without_location.title'),
		hasSelectionColumn: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'serial_number', name: this.translateService.instant('devices.device_sn'), type: 'link'},
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: "link"},
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link"},
		{ key: 'site_installation_date', name: this.translateService.instant('site.site_installation_date'), type: "date"},
		{ key: 'last_connect_time', name: `${this.translateService.instant('devices.last_connect_time')} (${this.showUserTimeZoneReference()})`, type: "date"},
		{ key: 'fw_version', name: this.translateService.instant('devices.fw_version'), type: "number"},
		{ key: 'xtra_file_status', name: this.translateService.instant('devices_without_location.xtra_file_status'), type: "string" },
	];

	constructor(
		public usersService: UsersService,
		private deviceManagementService: DeviceManagementService,
		private router: Router,
		private translateService: TranslateService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		private customersService: CustomersService,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		this.getCustomersList();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((ok: boolean) => {
			if (ok)
				this.disableXtraFile();

			this.confirmModal.hide();
		});
	}

	getCustomersList() {
		this.getCustomersSubscription = this.customersService.getCustomers({}).subscribe((data: any) => {
				const customerList = [];
				for (let customer of data) {
					customerList.push({key: customer.id , value: customer.customer_name});
				}
				this.customerList = customerList;
			}
		);
	}

	selectCustomer() {
		const existCustomers = Object.keys(this.customersDevicesObject).map(value => +value);

		if (existCustomers.length > this.filter.customers.length)
			return this.removeCustomer(existCustomers)

		// add new customer
		const newCustomer = this.filter.customers.filter(customer => !existCustomers.includes(customer));
		if (!newCustomer.length)
			return;

		this.getCustomerSubscription = this.customersService.getCustomer(newCustomer, {get_devices_counts: true, without_analytics: true, include_zombie_sites: true}).subscribe((data: any) => {
				let customerDevicesList = [];

				for (let device of data.devices) {
					customerDevicesList.push({key: device.mac_address, value: device.serial_number});
				}

				this.customerDevicesList = [...this.customerDevicesList, ...customerDevicesList];
				this.customersDevicesObject[newCustomer] = customerDevicesList;

				if (this.addDeviceManually) {
					this.addDeviceManually = false;
					this.filter.devices = [];
				}
			}
		);
	}

	removeCustomer(existCustomers: any[]) {
		const removeCustomer = existCustomers.filter(customer => !this.filter.customers.includes(customer));
		if (!removeCustomer.length)
			return;

		const removeCustomerDevice = this.customersDevicesObject[removeCustomer[0]].map(device => device.key);
		delete this.customersDevicesObject[removeCustomer[0]];
		this.customerDevicesList = this.customerDevicesList.filter(device => !removeCustomerDevice.includes(device.key));
		this.searchedDevices = this.searchedDevices?.length ? this.searchedDevices.filter((device: any) => !removeCustomerDevice.includes(device)) : null;

		if (existCustomers.length == 1)
			this.filter.customers = null;

		return this.filter.devices = this.customerDevicesList.filter(device => this.searchedDevices.includes(device.key));
	}

	addDevice() {
		if (this.filter.customers) {
			this.filter.devices = this.customerDevicesList.filter(device => this.searchedDevices.includes(device.key));
		} else {
			this.searchedDevices = this.searchedDevices.trim();
			const isAddedBefore = this.filter.devices.some((row: { value: string; }) => row.value === this.searchedDevices);
			if (isAddedBefore)
				return this.notificationMessage.setMessage(this.translateService.instant('devices_without_location.device_already_added') ,{ clearOnXTimeNavigate: 1 });

			this.searchDevicesSubscription = this.deviceManagementService.searchDevices(this.searchedDevices, 'sn', true, false).subscribe((data: any) => {
				if (!data.devices.length)
					return this.notificationMessage.setMessage(this.translateService.instant('devices.selected_devices_not_exist') ,{clearOnXTimeNavigate: 1});

				this.filter.devices = [...this.filter.devices, {key: data.devices[0].mac_address, value: data.devices[0].serial_number}];
				this.searchedDevices = null;
				this.addDeviceManually = true;
			});
		}
	}

	getDevicesWithoutLocation() {
		let macAddress = [];
		if (this.filter.devices.length)
			macAddress = this.filter.devices.map(row => row.key);

		this.devices = [];
		this.getDevicesSubscription = this.deviceManagementService.getDevicesWithoutLocation(this.filter.customers, macAddress).subscribe((devices: any) => {
			const tableData: any[] = [];

			for (const device of devices) {
				const lastConnectTime = device.last_connect_time + (-new Date().getTimezoneOffset() * 60);
				const lastConnectTimeFormatted = moment(lastConnectTime*1000).utc().format('MM/DD/YYYY HH:mm:ss');
				const siteInstallationDate = moment(device.site_installation_date*1000).utc().format('MM/DD/YYYY');
				const disableXTRA = device.disable_xtra ? this.translateService.instant('sim.disable') : this.translateService.instant('sim.enable');

				const rowData: TableData = {
					id: { value: device.mac_address },
					mac_address: { value: device.mac_address },
					serial_number: { value: device.serial_number, link: ['/', device.customer_id, device.site_id, device.mac_address, 'performance'] },
					customer_name: { value: device.customer_name, link: ['/', device.customer_id] },
					site_name: { value: device.site_name, link: ['/', device.customer_id, device.site_id] },
					site_installation_date: { value: siteInstallationDate },
					last_connect_time: { value: lastConnectTimeFormatted },
					fw_version: { value: device.fw_version || null},
					xtra_file_status: { value: disableXTRA },
				}
				tableData.push(rowData);
			}

			this.devices = tableData;
		});
	}

	getSelectedDevices(event) {
		this.selectedDevices = event;
	}

	showConfirmationModal() {
		this.confirmModal.show();
	}

	disableXtraFile() {
		if (!this.selectedDevices.length)
			return;

		const macAddresses = this.selectedDevices.map(row => row.mac_address.value);
		this.disableXtraFileSubscription = this.deviceManagementService.disableXtraFile(macAddresses).subscribe(() => {
			this.devices = this.devices.filter(device => !macAddresses.includes(device.mac_address.value));
			this.selectedDevices = [];
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	showUserTimeZoneReference() {
		const userTimeZone = moment().utcOffset();
		return moment().utcOffset(userTimeZone).format('UTCZ');
	}

	ngOnDestroy() {
		this.getCustomersSubscription?.unsubscribe();
		this.getCustomerSubscription?.unsubscribe();
		this.searchDevicesSubscription?.unsubscribe();
		this.getDevicesSubscription?.unsubscribe();
		this.disableXtraFileSubscription?.unsubscribe();
	}
}
