<h2>{{'apis_logs.title' | translate}}</h2>

<details open class="white-box p-1 filter-box">
	<summary>{{'g.filter_box' | translate}}</summary>
		<!-- filters -->
	<div class="white-field col-12">
		<div class="custom-row">
			<!-- year -->
			<div class="filter">
				<label for="warning">{{'g.year' | translate}}</label>
				<ng-select
					[(ngModel)]="filter.year"
					[items]="yearsList"
					name="tags_filter"
					bindValue="id"
					bindLabel="name"
					[clearable]="false"
					[ngStyle]="{'width': '300px'}"
					(ngModelChange)="selectYear()"
				></ng-select>
			</div>
			<!-- month -->
			<div class="filter">
				<label for="warning">{{'connectivity_verification.month' | translate}}</label>
				<ng-select
					[(ngModel)]="filter.month"
					[items]="monthsList"
					name="tags_filter"
					bindValue="id"
					bindLabel="name"
					[clearable]="false"
					[ngStyle]="{'width': '300px'}"
					(ngModelChange)="selectMonth()"
				></ng-select>
			</div>
			<!-- from time -->
			<div class="filter">
				<label for="warning">{{'g.from_time' | translate}}</label>
				<input type="datetime-local" class="form-control" [(ngModel)]="filter.fromTimeLocal" (ngModelChange)="formatTime('fromTime', $event)" [min]="minTime" [max]="filter.toTimeLocal">
			</div>
			<!-- to time -->
			<div class="filter">
				<label for="warning">{{'g.to_time' | translate}}</label>
				<input type="datetime-local" class="form-control" [(ngModel)]="filter.toTimeLocal" (ngModelChange)="formatTime('toTime', $event)" [max]="maxTime" [min]="filter.fromTimeLocal">
			</div>
		</div>
		<div class="custom-row">
			<!-- user -->
			<div class="filter" *ngIf="usersList.length">
				<label for="user">{{'g.user' | translate}}</label>
				<ng-select
					[(ngModel)]="filter.user_id"
					[items]="usersList"
					name="user"
					bindValue="value"
					bindLabel="label"
					[clearable]="true"
					[multiple]="true"
					[ngStyle]="{'width': '300px'}"
				></ng-select>
			</div>
			<!-- end point -->
			<div class="filter">
				<label for="endPoint">{{'apis_logs.endpoint' | translate}}</label>
				<ng-select
					[(ngModel)]="filter.end_point"
					[items]="endPointList"
					name="endPoint"
					bindValue="id"
					bindLabel="name"
					[clearable]="true"
					[ngStyle]="{'width': '300px'}"
				></ng-select>
			</div>
			<!-- success -->
			<div class="filter">
				<label for="status">{{'apis_logs.api_status' | translate}}</label>
				<ng-select
					[(ngModel)]="filter.success"
					[items]="apiStatusList"
					name="status"
					bindValue="id"
					bindLabel="name"
					[clearable]="true"
					[ngStyle]="{'width': '300px'}"
				></ng-select>
			</div>
		</div>
		<div class="custom-row">
			<div class="filter">
				<button class="btn btn-primary" (click)="applyFilter()">{{"g.search" | translate}}</button>
			</div>
		</div>
	</div>
</details>

<div class="white-box p-1 mt-3 filter-box">
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getLogs($event)"
	></sct-custom-table>
</div>
