import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceService } from '../device.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as lo from 'lodash-es';
import * as validator from '../../../../../../../Common/validator';
import { UsersService } from 'src/app/users/users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as lodash from 'lodash-es';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SiteDashboardService } from '../../site-dashboard.service';

interface DeviceData {
	serial_number: {value: string},
	truck_sn: {value: string, isValid: boolean},
	truck_id: {value: string, isValid: boolean},
	truck_manufacturer?: {value: string | null, isValid: boolean},
	truck_type?: {value: string, isValid: boolean},
	longitude?: {value: number, isValid: boolean},
	latitude?: {value: number, isValid: boolean},
	max_charger_kw?: {value: number | string, isValid: boolean}
}

@Component({
	selector: 'app-bulk_fill_device_info',
	templateUrl: './bulk_fill_device_info.component.html',
	styleUrls: ['./bulk_fill_device_info.component.css']
})
export class BulkFillDeviceInfoComponent implements OnDestroy, OnInit {
	@ViewChild("duplicatedSerialsModal") duplicatedSerialsModal;
	@ViewChild("confirmationModal") confirmationModal;
	permissionsSub: Subscription = new Subscription();
	site: any = {};

	subListing: Subscription = new Subscription();
	devicesListing: any[] = [];
	devicesByMacAddresses: any[] = [];
	InvalidDevicesList = [];

	siteId = 0;
	columns = ['serial_number', 'truck_sn', 'truck_id','truck_manufacturer', 'truck_type', 'longitude', 'latitude', 'max_charger_kw'];
	validator = validator;

	readonly deviceInitData = {
		serial_number: {value: ''},
		truck_sn: {value: '', isValid: true},
		truck_manufacturer: {value: null, isValid: true},
		truck_type: {value: '', isValid: true},
		longitude: {value: 0, isValid: true},
		latitude: {value: 0, isValid: true},
		max_charger_kw: {value: '-', isValid: true},
	}

	devicesData: DeviceData[] = [];
	duplicatedSerials: string[] = [];

	readonly pasteDataInit = {
		serial_number: '',
		truck_sn: '',
		truck_manufacturer: null,
		truck_type: '',
		longitude: '',
		latitude: '',
		max_charger_kw: null,
	}

	pasteData: {[key: string]: string} = this.pasteDataInit;

	selectedSerials = [];

	constructor(
		private deviceService: DeviceService,
		private route: ActivatedRoute,
		private usersService: UsersService,
		private router: Router,
		private notificationMessageService: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private siteDashboardService: SiteDashboardService,
	) {}

	ngOnInit(): void {
		this.sideMenuService.currentSite.subscribe((data:any) => {
			this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
				const hasPermission = this.usersService.hasAccessPermission(data, 'noc', 'write');

				if (!hasPermission)
					this.router.navigate(['/unauthorized']);
			});
		});

		this.route.params.subscribe(
			params => {
				this.siteId = Number(params['siteId']) || 0;

				this.subListing = this.deviceService.getSiteDevicesListing(this.siteId, null, true).subscribe( (response: any) => {
					this.devicesListing = response.devices;
					this.site = response.siteInfo;
					this.devicesByMacAddresses  = lo.keyBy(this.devicesListing, 'mac_address');
					this.devicesListing = lo.keyBy(this.devicesListing, 'serial_number');
				});
			}
		);
	}

	ngAfterViewInit() {
		this.confirmationModal.onClose.subscribe((ok) => {
			if(!ok)
				return;

			this.submitPastData();
		});
	}

	handleInputPaste(event: ClipboardEvent, column: string) {
		event.preventDefault();

		const clipboardData = event.clipboardData || (window as any).clipboardData;
		const pastedText = clipboardData.getData('text');

		this.pasteColumnData(column, pastedText.trim());
	}


	pasteColumnData(column: string, pastText = '') {
		if (pastText === '' && this.pasteData[column] === '')
			return;

		let dataArray = pastText !== '' ? pastText.split(/\r\n/g) : (this.pasteData[column]).split(/\r\n/g);

		if (!dataArray.length)
			return;

		if(!this.devicesData[0] && column != 'serial_number') //the user should choose devices first
			return

		if(column == 'serial_number') {
			this.duplicatedSerials = [];
			const notDuplicatedSerials = [];

			dataArray.forEach(serial => {
				serial = serial.trim();

				if (!notDuplicatedSerials.includes(serial))
					notDuplicatedSerials.push(serial);

				else if (!this.duplicatedSerials.includes(serial))
					this.duplicatedSerials.push(serial);
			})

			if (this.duplicatedSerials.length)
				return this.duplicatedSerialsModal.show();
		}

		dataArray.forEach((value, index) => {
			this.fillDeviceData(column, value.trim(), index);
		})

		this.pasteData = this.pasteDataInit;
	}

	fillDeviceData(column: string, value: string, index: number) {
		const serial_number = column == 'serial_number' ? value : (this.devicesData[index] ? this.devicesData[index].serial_number.value : null);
		const device = serial_number ? this.devicesListing[serial_number] : null;
		const isCharglink = !!device?.is_charglink;

		if(!this.devicesData[index]) {
			this.devicesData[index] = lodash.cloneDeep(this.deviceInitData);

			if (device) {
				this.devicesData[index] = {
					serial_number: {value: device.serial_number},
					truck_sn: {value: device.truck_sn || '', isValid: true},
					truck_id: {value: device.truck_id || '', isValid: true},
					truck_manufacturer: {value: device.truck_manufacturer || null, isValid: true},
					truck_type: {value: device.truck_type || '', isValid: true},
					longitude: {value: device.longitude || 0, isValid: true},
					latitude: {value: device.latitude || 0, isValid: true},
					max_charger_kw: {value: isCharglink ? device.max_charger_kw || 0 : '-', isValid: true}
				}

				this.selectedSerials.push(device.serial_number);
			}
		}
		if(column == 'max_charger_kw' && !isCharglink)
			value = '-';

		this.devicesData[index][column].value = value;

		if(column != 'serial_number') {
			this.devicesData[index][column].isValid = this.checkValidation(column, value);
			this.updateInvalidDevices(this.devicesData[index]);
		}
	}

	checkValidation(column: string, value: string | number) {
		let isValid = false;

		switch (column) {
		case 'truck_sn':
		case 'truck_manufacturer':
		case 'truck_type':
		case 'truck_id':
			isValid = this.validator.dataValidator('string', {"min": 1, "max": 31}, value) && this.validator.dataValidator('notNull', {}, value);
			break;

		case 'longitude':
		case 'latitude':
			isValid = this.validator.dataValidator('float', {}, value);
			break;

		case 'max_charger_kw':
			if (value != '-')
			isValid = this.validator.dataValidator('float', {"allowEmpty": true}, value);
			else
			isValid = true;
			break;

		default:
			break;
		}

		return isValid;
	}

	updateFieldValidation(device: DeviceData, field: string) {
		device[field].isValid = this.checkValidation(field, device[field].value);
		this.updateInvalidDevices(device);
	}

	submitPastData() {
		const toUpdateDevicesData = this.devicesData
			.filter(device => this.selectedSerials.includes(device.serial_number.value) && !this.InvalidDevicesList.includes(device.serial_number.value));

		if (!toUpdateDevicesData.length)
			return;

		const {macAddresses, formattedDevisesData} = this.formatDataBeforeSubmit(toUpdateDevicesData);

		const hasChanges = this.checkDataChanges(formattedDevisesData);

		if(!hasChanges)
			return this.notificationMessageService.setMessage('translate|bulk_fill_device_info.no_data_changes', {clearOnXTimeNavigate: 1, type: 'warning'});

		this.deviceService.saveMultiDevicesSettings(macAddresses, null, formattedDevisesData).subscribe((response: any) => {
			if (response.config_info)
				this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});

			if (response.invalid_fields)
				this.notificationMessageService.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
		});
	}

	checkDataChanges(newData) {
		let hasChanges = false;
		for (let macAddress of Object.keys(newData)) {
			for (let field of Object.keys(newData[macAddress])){

				if (newData[macAddress][field] == this.devicesByMacAddresses[macAddress][field])
					delete newData[macAddress][field];
				else
					hasChanges = true;
			}
		}

		return hasChanges;
	}

	formatDataBeforeSubmit(toUpdateDevicesData: DeviceData[]) {
		const formattedDevisesData = {};
		const macAddresses = [];

		toUpdateDevicesData.forEach(deviceData => {
			const serial_number = deviceData.serial_number.value;
			const device = this.devicesListing[serial_number];

			const mac_address = device.mac_address;
			macAddresses.push(mac_address);

			if (mac_address)
				formattedDevisesData[mac_address] = {};

				(['truck_sn', 'truck_id', 'truck_manufacturer', 'truck_type', 'longitude', 'latitude']).forEach(field => {
					if (deviceData[field].value != device[field])
						formattedDevisesData[mac_address][field] = deviceData[field].value;
				})

				if (deviceData.max_charger_kw.value != device.max_charger_kw && deviceData.max_charger_kw.value != '-')
					formattedDevisesData[mac_address].max_charger_kw = deviceData.max_charger_kw.value || null;
		});

		return { macAddresses, formattedDevisesData };
	}

	selectAll() {
		if (this.selectedSerials.length)
			this.selectedSerials = [];
		 else
			this.selectedSerials = this.devicesData
				.filter(device => Object.keys(this.devicesListing).includes(device.serial_number.value))
				.map(device => device.serial_number.value);
	}

	toggleSelect(serial_number) {
		if (!this.devicesListing[serial_number])
			return;

		if (this.selectedSerials.includes(serial_number))
			this.selectedSerials = this.selectedSerials.filter(sn => sn != serial_number);
		else
			this.selectedSerials.push(serial_number);
	}

	updateInvalidDevices(device: DeviceData) {
		const serial_number = device.serial_number.value;
		const hasInvalidFields = this.hasInvalidFields(device);

		if (hasInvalidFields && !this.InvalidDevicesList.includes(serial_number))
			this.InvalidDevicesList.push(serial_number);

		else if (!hasInvalidFields)
			this.InvalidDevicesList = this.InvalidDevicesList.filter(sn => sn != serial_number);
	}

	hasInvalidFields(device: DeviceData) {
		for(let field of Object.keys(device)){
			if (field != 'serial_number' && device[field].isValid == false)
				return true;
		}

		return false;
	}

	ngOnDestroy() {
		this.subListing.unsubscribe();
		this.permissionsSub.unsubscribe();
	}
}
