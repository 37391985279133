<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3">
			<h2>{{'devices.edit_device_owners_request' | translate}}</h2>
			<p>{{'g.serial_number' | translate}}: {{serialNumbers.join(' , ')}}</p>
		</div>
		<div class="px-5 py-4">
			<form (ngSubmit)="showResetDevicePasswordConfirmationPopup()" validate-form="manageDevice" [innerModelGroups]="['customerInputs', 'site']">
				<div class="form-group" *ngIf="customers.length > 0">
					<div class="row mt-2 align-items-center">
						<div class="col-md-2">
							<strong>{{'g.customer' | translate}}</strong>
						</div>
						<div class="col-md-10">
							<ng-select
								[items]="customers"
								bindLabel="customer_name"
								bindValue="id"
								placeholder="{{'customer.select_customer' | translate}}"
								name="customer_id"
								[(ngModel)]="deviceInfo.customer_id"
								[clearable]="false"
								(ngModelChange)="getCustomerSites($event)"
								required
							></ng-select>
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-2"></div>
					<div class="col-md-10">
						<fieldset *ngIf="deviceInfo.id && !deviceInfo.customer_id">
							<legend><b>{{'g.customer' | translate}}</b></legend>
							<app-customer-edit [customer]="customer" [miniForm]="true"></app-customer-edit>
						</fieldset>
					</div>
				</div>
				<div class="form-group" *ngIf="sites.length > 0 && deviceInfo.customer_id">
					<div class="row mt-2 align-items-center">
						<div class="col-md-2">
							<strong>{{'g.site' | translate}}</strong>
						</div>
						<div class="col-md-10">
							<ng-select
								[items]="sites"
								bindLabel="name"
								bindValue="id"
								placeholder="{{'site.select_site' | translate}}"
								name="site_id"
								[(ngModel)]="deviceInfo.site_id"
								[clearable]="false"
								[disabled]="deviceInfo.is_study_device"
								required
							></ng-select>
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-2"></div>
					<div class="col-md-10">
						<fieldset *ngIf="deviceInfo.id && !deviceInfo.site_id && !deviceInfo.is_study_device">
							<legend><b>{{'g.site' | translate}}</b></legend>
							<app-site-edit [site]="site" [miniForm]="true"></app-site-edit>
							<app-mapbox
								class="border-radius main-blue-border"
								[latitude]="site.latitude||sitesService.defaultMapLocation.latitude"
								[longitude]="site.longitude||sitesService.defaultMapLocation.longitude"
								[zoom]="[3]"
								[zoomIn]="true"
								[showPopup]="false"
								[dots]="[{
									latitude: site.latitude||sitesService.defaultMapLocation.latitude,
									longitude: site.longitude||sitesService.defaultMapLocation.longitude
								}]"
								[clickEnabled]="true"
								(onAddressChanged)="updateSiteCoords($event)"
							></app-mapbox>
						</fieldset>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-2">
					</div>
					<div class="col-md-10">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button type="submit" class="btn text-white main-blue-bg btn-lg col-md-5 mb-4 mr-4" [disabled]="deviceInfo.site_id == deviceInfo.oldSiteId">
									{{'g.submit' | translate}}
								</button>
								<button type="button" class="btn text-white main-grey-bg btn-lg col-md-5 mb-4" (click)="goBack()">{{'g.cancel' | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<app-modal
	[hasConfirmButton]="true"
	#resetDevicePasswordConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="md"
>
	<p>{{'devices.reset_device_password_confirmation_popup' | translate}}</p>
</app-modal>
