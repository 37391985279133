import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { SCTCustomTable } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/sct-table.component';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-update-firmware-logs',
	templateUrl: './update-firmware-logs.component.html',
	styleUrls: ['./update-firmware-logs.component.css']
})
export class UpdateFirmwareLogsComponent implements AfterViewInit {

	firmwareUpdateStatusesOptions = [
		{ label: this.translateService.instant('update_firmware_log.start'), value: 1 },
		{ label: this.translateService.instant('update_firmware_log.ongoing'), value: 2 },
		{ label: this.translateService.instant('update_firmware_log.done'), value: 3 },
	];

	firstTime = true;
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;
	columnConfig: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translateService.instant('update_firmware_log.mac_address'), type: 'string' },
		{ key: 'old_version', name: this.translateService.instant('update_firmware_log.old_version'), type: 'number' },
		{ key: 'new_version', name: this.translateService.instant('update_firmware_log.new_version'), type: 'number' },
		{ key: 'status', name: this.translateService.instant('g.status'), type: "string", filterType: 'dropdown', items: this.firmwareUpdateStatusesOptions },
		{ key: 'step', name: this.translateService.instant('g.step'), type: "number" },
		{ key: 'created_at', name: `${this.translateService.instant('update_firmware_log.time')} (${this.commonService.showUserTimeZoneReference()})`, type: "date" },
	];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		fileName: 'firmware-logs',
		hasPagination: true,
		pageSize: 20,
		isBackendPagination: true,
		draggable: true
	};

	firmwareUpdateStatuses = {
		1: 'start',
		2: 'ongoing',
		3: 'done'
	}

	constructor(
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		private translateService: TranslateService,
		private commonService: CommonService,
	) { }

	ngAfterViewInit() {
		this.getFirmwareUpdateLog({currentPage: 1, isFirstTime: true});
	}

	getFirmwareUpdateLog(paginationData) {
		if (!this.sctCustomTable)
			return;

		if (paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime
		};


		this.deviceManagementService.getUpdateFirmwareLog(options).subscribe((response: any) => {
			const tableData = [];
			for (const log of response.data) {

				log.status = this.translateService.instant(log.status ? `update_firmware_log.${this.firmwareUpdateStatuses[log.status]}` : 'g.n/a');
				log.step = log.step || this.translateService.instant('g.n/a');
				log.created_at = moment(log.created_at).format('MM/DD/YYYY HH:mm:ss');

				tableData.push({
					mac_address: { value: log.mac_address },
					old_version: { value: log.old_version },
					new_version: { value: log.new_version },
					status: { value: log.status },
					step: { value: log.step },
					created_at: { value: log.created_at }
				});
			}

			this.tableData = tableData;
			this.sctCustomTable.updatePagination(response.totalDataCount, response.overallItemsCount);
		});
	}
}
