<div>
	<h2>{{'nav.device_queue_changes' | translate}}</h2>
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="queueChanges"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal #deleteChangeStackModal [title]="'g.confirm_action' | translate">
	<p>
		{{(confirmDeleteStack? 'settings.remove_stack_non_related_user_msg' : 'settings.remove_changes_stack_title_msg')| translate }}
	</p>
</app-modal>

<app-modal #devicesQueueFieldsModal [title]="'devices.device_queued_fields' | translate" [hasCloseButton]="false" [hasConfirmButton]="false" [size]="'xl'">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.field_name' | translate}}</th>
				<th>{{'g.value' |translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of changesToSow">
				<td> {{'devices.'+item.key |translate}} </td>
				<td> {{item.value}} </td>
				<td> <fa-icon class="pointer" icon="trash" (click)="deleteChangeStack({user_id: queueFields?.user_id, changes: item.key, id: queueFields?.id})"></fa-icon> </td>
			</tr>
		</tbody>
	</table>
</app-modal>
