<app-modal #updateSetupDone [title]="'devices.change_is_done' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'devices.change_is_done_msg' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();saveDeviceOwners(true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();saveDeviceOwners(false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="updateSetupDone.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>

<div [ngSwitch]="stepsCount">
	<div *ngSwitchCase="processSteps.selectDevicesOwner">
		<div class="row justify-content-md-center">
			<div class="col-md-8 bg-white">
				<div class="border-bottom p-3">
					<h2>{{'devices.edit_device_owners' | translate}}</h2>
					<p *ngIf="!fromAdminPanel">{{'g.serial_number' | translate}}: {{serialNumbers.join(' , ')}}</p>
				</div>
				<div class="px-5 py-4">
					<form (ngSubmit)="onBeforeSaveDeviceOwners()" validate-form="manageDevice" [innerModelGroups]="['customerInputs', 'site']">
						<div class="form-group" *ngIf="fromAdminPanel && customers.length > 0">
							<div class="row mt-2 align-items-center">
								<div class="col-md-2">
									<strong>{{'g.serial_number' | translate}}</strong>
								</div>
								<div class="col-md-10">
									<textarea name="devicesIds" class="form-control" [(ngModel)]="tmpDevicesIds" [required]=""></textarea>
								</div>
							</div>
						</div>
						<div class="form-group" *ngIf="customers.length > 0">
							<div class="row mt-2 align-items-center">
								<div class="col-md-2">
									<strong>{{'g.customer' | translate}}</strong>
								</div>
								<div class="col-md-10">
									<ng-select
										[items]="customers"
										bindLabel="customer_name"
										bindValue="id"
										placeholder="{{'customer.select_customer' | translate}}"
										name="customer_id"
										[(ngModel)]="deviceInfo.customer_id"
										[clearable]="false"
										(ngModelChange)="getCustomerSites($event)"
										required
									></ng-select>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-2"></div>
							<div class="col-md-10">
								<fieldset *ngIf="(fromAdminPanel || deviceInfo.id) && !deviceInfo.customer_id">
									<legend><b>{{'g.customer' | translate}}</b></legend>
									<app-customer-edit [customer]="customer" [enterpriseList]="enterpriseList"></app-customer-edit>
								</fieldset>
							</div>
						</div>
						<div class="form-group" *ngIf="sites.length > 0 && deviceInfo.customer_id">
							<div class="row mt-2 align-items-center">
								<div class="col-md-2">
									<strong>{{'g.site' | translate}}</strong>
								</div>
								<div class="col-md-10">
									<ng-select
										[items]="sites"
										bindLabel="name"
										bindValue="id"
										placeholder="{{'site.select_site' | translate}}"
										name="site_id"
										[(ngModel)]="deviceInfo.site_id"
										[clearable]="false"
										[disabled]="deviceInfo.is_study_device"
										required
									></ng-select>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-2"></div>
							<div class="col-md-10">
								<fieldset *ngIf="hasAccessToNewSite()">
									<legend><b>{{'g.site' | translate}}</b></legend>
									<app-site-edit [site]="site" [enterpriseList]="siteEnterprises" [customerEnterprises]="customerEnterprises" [restrictSpecialSite]="!usersService.hasAccessFunction('super_admin', 'write')"></app-site-edit>
									<app-mapbox
										class="border-radius main-blue-border"
										[latitude]="site.latitude||sitesService.defaultMapLocation.latitude"
										[longitude]="site.longitude||sitesService.defaultMapLocation.longitude"
										[zoom]="[3]"
										[zoomIn]="true"
										[showPopup]="false"
										[dots]="[{
											latitude: site.latitude||sitesService.defaultMapLocation.latitude,
											longitude: site.longitude||sitesService.defaultMapLocation.longitude
										}]"
										[clickEnabled]="true"
										(onAddressChanged)="updateSiteCoords($event)"
									></app-mapbox>
								</fieldset>
							</div>
						</div>
						<div class="row my-4 align-items-center">
							<div class="col-md-2">
							</div>
							<div class="col-md-10">
								<div class="row align-items-center">
									<div class="col-md-8">
										<button type="submit" class="btn text-white main-blue-bg btn-lg col-md-5 mb-4 mr-4">{{'g.submit' | translate}}</button>
										<button type="button" class="btn text-white main-grey-bg btn-lg col-md-5 mb-4" (click)="goBack()">{{'g.cancel' | translate}}</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div *ngSwitchCase="processSteps.confirmation">
		<div class="border-bottom p-3">
			<h2>{{('device_management.moving_devices' | translate) + ': '}}</h2>
			<h6>{{'g.customer' | translate}} {{': ' + getSelectedCustomerName()}}</h6>
			<h6>{{'g.site' | translate}} {{': ' + (deviceInfo.site_id ? sitesById[deviceInfo.site_id]?.name : site.name)}}</h6>
		</div>
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
			(updateSelectedRows)="updateSelectedDevice($event)"
		></sct-custom-table>
		<div class="col-md-12">
			<div class="row align-items-center justify-content-center">
				<button type="submit" [disabled]="!confirmedDevicesSerials.length" class="btn text-white main-blue-bg btn-lg col-md-2 mb-4 mr-4" (click)="showSetupPopup()">{{'g.confirm' | translate}}</button>
				<button type="button" class="btn text-white main-grey-bg btn-lg col-md-2 mb-4" (click)="goBack()">{{'g.back' | translate}}</button>
			</div>
		</div>
	</div>
</div>

