<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3">
			<h2 *ngIf="isAddNewUser">{{'g.add' | translate}} {{'g.user' | translate}}</h2>
			<h2 *ngIf="!isAddNewUser">{{'g.update' | translate}} {{'g.user' | translate}}</h2>
		</div>
		<nav *ngIf="isAdminEdit" class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activePage == 'user_data'}"
						(click)="setActivePage('user_data')"
					>
						<h5> {{'users.user_data' | translate}}</h5>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small pointer"
						[ngClass]="{'active': activePage == 'email_subscription'}"
						(click)="setActivePage('email_subscription')"
					>
						<h5> {{'users.user_subscriptions' | translate}}</h5>
					</a>
				</li>
			</ul>
		</nav>
		<div class="px-5 py-4">
			<form #editUserFrm="ngForm" (ngSubmit)="editUser()" [validate-form]="isAddNewUser ? 'addUser':'editUser'">
				<div *ngIf="activePage == 'user_data'">
					<div class="row mt-2 align-items-center">
						<div class="col-md-4">
							<strong>{{'login_register.first_name' | translate}}</strong>
						</div>
						<div class="col-md-8">
							<input class="form-control" name="first_name" [(ngModel)]="user.first_name" required/>
						</div>
					</div>
					<div class="row mt-2 align-items-center">
						<div class="col-md-4">
							<strong>{{'login_register.last_name' | translate}}</strong>
						</div>
						<div class="col-md-8">
							<input class="form-control" name="last_name" [(ngModel)]="user.last_name" required/>
						</div>
					</div>
					<div class="row mt-2 align-items-center" *ngIf="isAdminEdit">
						<div class="col-md-4">
							<strong>{{'g.language' | translate}}</strong>
						</div>
						<div class="col-md-8">
							<ng-select
								[items]="langList"
								bindLabel="label"
								bindValue="value"
								name="lang"
								[(ngModel)]="user.lang"
								[clearable]="false"
							></ng-select>
						</div>
					</div>
					<div class="row mt-2 align-items-center">
						<div class="col-md-4">
							<strong>{{'login_register.email' | translate}}</strong>
						</div>
						<div class="col-md-8">
							<input type="email" class="form-control" name="email" [(ngModel)]="user.email" [disabled]="!isAdminEdit" email required/>
						</div>
					</div>
					<div class="row mt-2 align-items-center">
						<div class="col-md-4">
							<strong>{{'login_register.phone_no' | translate}}</strong>
						</div>
						<div class="col-md-8">
							<input class="form-control" name="phone_number" [(ngModel)]="user.phone_number" email required/>
						</div>
					</div>
				</div>
				<div *ngIf="isAdminEdit">
					<div *ngIf="activePage == 'user_data'">
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'g.password' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<input type="password" class="form-control" name="password" [(ngModel)]="user.password" [required]="isAddNewUser" show-password/>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'ent.enterprise' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<ng-select
									[items]="enterpriseList"
									bindLabel="name"
									bindValue="id"
									name="enterprise_id"
									placeholder="{{'ent.select_enterprise' | translate}}"
									[(ngModel)]="user.enterprise_id"
									[clearable]="false"
									required
								>
								</ng-select>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'permissions.permission' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<ng-select
									[items]="permissionList"
									bindLabel="name"
									bindValue="id"
									name="default_permission_id"
									placeholder="{{'permissions.permission' | translate}}"
									[(ngModel)]="user.default_permission_id"
									[clearable]="false"
									required
								>
								</ng-select>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.is_admin' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.is_admin" name="is_admin"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.email_is_confirmed' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_confirmed" name="email_confirmed"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.phone_is_confirmed' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.phone_confirmed" name="phone_confirmed"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.is_test_user' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.test_user" name="test_user"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.user_activated' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.account_deactivate" name="account_deactivate"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.has_auth_code' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="hasAuthCode" name="has_auth_code" (change)="onChangeHasAuthCode($event)"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'users.force_password_change' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.force_password_change" name="force_password_change"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center" *ngIf="usersService.hasAccessFunction('super_admin', 'write')">
							<div class="col-md-4">
								<strong>{{'users.special_user' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.special_user" name="special_user"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center" *ngIf="hasAuthCode">
							<div class="col-md-4">
								<strong>{{'users.auth_code' | translate}}</strong>
							</div>
							<div class="col-md-8 input-group">
								<input class="d-none" name="auth_code" [(ngModel)]="user.auth_code" maxlength="8" />
								<input class="form-control" name="auth_code1" [(ngModel)]="user.auth_code" disabled />
								<mat-icon class="pointer blue-hover input-group-text" (click)="generateRandomAuthCode()" style="height: 100%;padding-right: 25px;background-color: white;">
									refresh
								</mat-icon>
							</div>
						</div>
					</div>
					<div *ngIf="activePage == 'email_subscription'">
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'connectivity_verification.connectivity_verification' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_subscription_list.connectivity_verification" name="email_subscription_list_connectivity_verification"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'orders.purchase_order_notifications' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_subscription_list.purchase_order_notifications" name="email_subscription_list_purchase_order_notifications"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'g.engineering' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_subscription_list.engineering" name="email_subscription_list_engineering"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'device_management.device_management_notifications' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_subscription_list.device_management_notifications" name="email_subscription_list_device_management_notifications"></mat-slide-toggle>
								</div>
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-md-4">
								<strong>{{'action_log.user_registration_request' | translate}}</strong>
							</div>
							<div class="col-md-8">
								<div class="form-check form-check-inline slide-toggle-div">
									<mat-slide-toggle [(ngModel)]="user.email_subscription_list.user_registration_request" name="email_subscription_list_user_registration_request"></mat-slide-toggle>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p *ngIf="isAdminEdit && user.is_blocked">{{"users.the_user_is_blocked" | translate }}
					<span (click)="RemoveBlockModal.show(); $event.preventDefault();">
						<a href="#">{{"users.remove_block" | translate}}</a>
					</span>
				</p>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button type="submit" class="btn text-white main-blue-bg btn-lg col-5 mr-4">{{'g.submit' | translate}}</button>
								<button type="button" class="btn text-white main-grey-bg btn-lg px-5" (click)="goBack()">{{'g.back' | translate}}</button>
							</div>
							<div class="col-md-8 mt-4" *ngIf="!isAdminEdit">
								<a class="pointer text-primary" (click)="deleteAccountModal.show()"><u>{{'login_register.delete_my_account' | translate}}</u></a>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<app-modal #deleteAccountModal title="{{'login_register.delete_account_confirm' | translate}}">
	<p>{{'login_register.delete_account_confirm_msg' | translate}}</p>
	<label for="verificationPassword">{{'login_register.verification_password' | translate}}</label>
	<input type="password" show-password class="form-control" [(ngModel)]="verificationPassword" name="verificationPassword" placeholder="{{'g.password' | translate}}"/>
</app-modal>

<app-modal #RemoveBlockModal title="{{'Unblock User Confirmation'}}">
	<p>{{"users.remove_block_confirm" | translate}}</p>
</app-modal>
