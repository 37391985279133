import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceService } from '../../device.service';

interface Events {
	timestamp: number,
	end_timestamp: number,
	inuse_as: number,
	event_type: number,
}

@Component({
	selector: 'app-charger-prediction',
	templateUrl: './charger-prediction.component.html',
	styleUrls: ['./charger-prediction.component.css']
})

export class ChargerPredictionComponent implements OnInit {
	@Input() device: any = {};
	@Input() studyInfo: any = {};
	@ViewChild("ShiftsModal") ShiftsModal;

	fromDate: Date = null;;
	toDate: Date = null;
	invalidStartDate: boolean = false;
	dailyDetails: any[] = [];
	events: any[] = [];
	predictionObject: any = {};
	workingDays = [false,true,true,true,true,true,false]; // satarday + sunday --> Not working days
	predictionSettings = {
		minChargeOppurtinityTime: 10,
		showShiftsPeriods: true,
		overrideChargeRateEnabled: false,
		overrideChargeRate: 25,
		shiftAutoDetect: false,
		maxSOC: 80,
		minSOC: 30,
		chargeEfficiency: 95,
		minDailyEBU: 0.25,
		minChargeOpportunityTime: 50
	};

	minInuseAhr = 10;
	showPredictionResult = false;
	hasConventionalCharts: boolean = false;
	recommendedChargerImage: string = '';
	recommendedCharger: any = '-';
	chartObject: any = {
		data: {},
		config: {},
	};

	shifts = [];
	shift: any = {};
	isEditMode = false;
	shiftIndex: number;
	oneDayInSeconds: number = 24*60*60;
	maxStudyDate: Date;
	minStudyDate: Date;

	eventTypes = {
		'charge': 1,
		'idle': 2,
		'inuse': 3,
	};

	constructor(
		public commonService: CommonService,
		private sctToastService: NotificationMessageService,
		private deviceService: DeviceService
	) {};

	ngOnInit() {
	}

	deleteShift(index) {
		this.shifts.splice(index, 1);
	}

	ngAfterViewInit() {
		this.ShiftsModal.onClose.subscribe((ok: boolean) => {
			if (ok)
				return this.addShift();
			this.isEditMode = false;
		});
	}

	addShift() {
		if (!this.shift.startTime || !this.shift.endTime || !this.shift.overrideShiftAHr)
			return this.sctToastService.setMessage('translate|operation_analysis.manual_shift_validation_error_message');

		this.shift.startTimeFormatted	= moment(this.shift.startTime, 'HH:mm').format('hh:mm A');
		this.shift.endTimeFormatted		= moment(this.shift.endTime, 'HH:mm').format('hh:mm A');
		const startTimeObj				= this.shift.startTime.split(":");
		const endTimeObj				= this.shift.endTime.split(":");
		this.shift.start_hour			= startTimeObj[0];
		this.shift.start_minute			= startTimeObj[1];
		this.shift.end_hour				= endTimeObj[0];
		this.shift.end_minute			= endTimeObj[1];
		this.shift.start_time_seconds	= this.commonService.convertHourToSeconds(this.shift.start_hour, this.shift.start_minute);
		this.shift.end_time_seconds		= this.commonService.convertHourToSeconds(this.shift.end_hour, this.shift.end_minute);

		let validPeriod = true;
		for (const k in this.shifts) {
			if (this.isEditMode && (+k) == this.shiftIndex)
				continue;

			const item = this.shifts[k];
			if(item.start_time_seconds <= this.shift.end_time_seconds && item.end_time_seconds >= this.shift.start_time_seconds) {
				validPeriod = false;
				this.sctToastService.setMessage('translate|operation_analysis.shift_overlapping_message', {clearOnXTimeNavigate: 1});
				break;
			}
		}

		if(validPeriod) {
			if(this.isEditMode) {
				this.shifts[this.shiftIndex] = this.shift;
			} else {
				this.shifts.push(this.shift);
			}
		}

		if(!this.isEditMode) {
			this.shift = {overrideShiftAHr: (Math.ceil(0.1*this.device.config_info.battery_capacity/5)*5)};
		}
		this.isEditMode = false;
		this.ShiftsModal.hide();
	}

	editShift(index) {
		this.shift = {};
		this.shift = _.extend(this.shift, this.shifts[index]);
		this.isEditMode = true;
		this.shiftIndex = index;
		this.ShiftsModal.show();
	}

	ngOnChanges() {
		this.shift = {
			overrideShiftAHr: (Math.ceil(0.1*this.device.config_info.battery_capacity/5)*5)
		};

		if (Object.keys(this.studyInfo).length) {
			this.maxStudyDate = this.studyInfo.is_done? new Date(this.studyInfo.end_study_time*1000) : new Date();
			this.minStudyDate = new Date(this.studyInfo.start_study_time*1000);
		}
	}

	validateFormFields() {
		this.invalidStartDate = false;
		if (!this.fromDate || !this.toDate) {
			this.invalidStartDate = true;
			return this.sctToastService.setMessage('translate|g.field_is_required');
		}

		const startTimeStamp = moment(this.fromDate).utc().startOf('day').unix();
		const endTimeStamp = moment(this.toDate).utc().endOf('day').unix();

		if(startTimeStamp > endTimeStamp) {
			this.invalidStartDate = true;
			return this.sctToastService.setMessage('translate|g.start_lg_end');
		}

		if (!this.predictionSettings.shiftAutoDetect && this.shifts.length == 0)
			return this.sctToastService.setMessage("translate|operation_analysis.manual_shift_validation_error_message");

		this.deviceService.getDailyDetails(this.device.mac_address, startTimeStamp, endTimeStamp).subscribe(
			(dailyDetails: any) => {
				this.dailyDetails = dailyDetails;

				this.deviceService.getEvents(this.device.mac_address, startTimeStamp, endTimeStamp).subscribe(
					(events: any) => {
						this.events = events.events;
		  
						if (!this.events.length || !this.dailyDetails.length)
							return this.sctToastService.setMessage('translate|operation_analysis.no_event_daily_details_error_msg');
						this.predictionCalculation();
				});
			});
	}

	predictionInuseAHRcalculations() {
		let previousDayTimeStamp: any = 0;
		let predictionDaysObject = {};
		let predictionEventsPerDay = {};

		if(!this.predictionSettings.shiftAutoDetect) {
			this.predictionObject.avg_daily_inuse_ahr = 0;
			this.shifts.forEach((shift) => {
				if(shift.overrideShiftAHr)
					this.predictionObject.avg_daily_inuse_ahr += +(shift.overrideShiftAHr);
			});
			return predictionEventsPerDay;
		}

		for(let dailyDetails of this.dailyDetails) {
			predictionDaysObject[dailyDetails.date] = dailyDetails;
		}

		let allEvents = {};
		allEvents = _.extend(allEvents, this.events);

		// Only count the days and events where the check boxes in the configurations selected.
		// And we have > 0.25 EBU in that day. (dailyDetailsJson[day].inuse_as/3600) < (battview.ahrcapacity * 0.8 * 0.25)
		for (const eventId in allEvents) {
			const event: Events	= this.commonService.compress(allEvents[eventId], 'events') as Events;
			if (!event || Object.keys(event).length == 0)
				continue;

			let eventDayTime	= moment(event.timestamp*1000).utc().startOf('day').format("X");
			let eventDayNumber	= moment(event.end_timestamp*1000).utc().day();

			if (!predictionDaysObject[eventDayTime])
				continue;

			const thisDayEBU = +(predictionDaysObject[eventDayTime].EBU); // @@ TODO: CHECK this value: alwyes NAN
			if (!this.workingDays[eventDayNumber] || thisDayEBU < this.predictionSettings.minDailyEBU)
				continue;

			if (!predictionEventsPerDay[eventDayTime])
				predictionEventsPerDay[eventDayTime] = [];

			predictionEventsPerDay[eventDayTime].push(event);

			if (event.event_type != this.eventTypes.inuse)
				continue;

			if (this.predictionObject.max_inuse_ahr < event.inuse_as)
				this.predictionObject.max_inuse_ahr = event.inuse_as;

			this.predictionObject.total_inuse_as += event.inuse_as;
			this.predictionObject.count_inuse_events++;

			if (previousDayTimeStamp != eventDayTime) {
				const inuseAsValue = predictionDaysObject[eventDayTime].inuse_as;
				this.predictionObject.total_daily_inuse_as += +(inuseAsValue);
				this.predictionObject.count_daily_inuse_events++;
				previousDayTimeStamp = eventDayTime;
			}
		}

		this.predictionObject.max_inuse_ahr /= 3600;
		if (this.predictionObject.count_inuse_events > 0)
			this.predictionObject.avg_inuse_ahr = this.predictionObject.total_inuse_as / (this.predictionObject.count_inuse_events * 3600);

		if (this.predictionObject.count_daily_inuse_events > 0)
			this.predictionObject.avg_daily_inuse_ahr = this.predictionObject.total_daily_inuse_as / (this.predictionObject.count_daily_inuse_events * 3600);

		return predictionEventsPerDay;
	}

	predictionCalculation() {
		this.predictionObject.total_inuse_as = 0;
		this.predictionObject.count_inuse_events = 0;
		this.predictionObject.avg_inuse_ahr = 0;
		this.predictionObject.total_daily_inuse_as = 0;
		this.predictionObject.count_daily_inuse_events = 0;
		this.predictionObject.avg_daily_inuse_ahr = 0;
		this.predictionObject.max_inuse_ahr = 0;
		this.predictionObject.shifts = [];
		let minChargeRate = 17;
	
		const predictionInuseAHRcalculations = () => {
			// Calculate the Average Inuse events AHR and the Max.
			let previousDayTimeStamp: any = 0;
			let predictionDaysObject = {};
			let predictionEventsPerDay = {};
	
			if (!this.predictionSettings.shiftAutoDetect) {
				this.predictionObject.avg_daily_inuse_ahr = 0;
				this.shifts.forEach((shift) => {
					if (shift.overrideShiftAHr)
						this.predictionObject.avg_daily_inuse_ahr += +(shift.overrideShiftAHr);
				});
				return predictionEventsPerDay;
			}
	
			for (let dailyDetails of this.dailyDetails) {
				predictionDaysObject[dailyDetails.date] = dailyDetails;
			}
	
			let allEvents = {};
			allEvents = _.extend(allEvents, this.events);
	
			// Only count the days and events where the check boxes in the configurations selected.
			// And we have > 0.25 EBU in that day. (dailyDetailsJson[day].inuse_as/3600) < (battview.ahrcapacity * 0.8 * 0.25)
			for (const eventId in allEvents) {
				const event			= this.commonService.decompress(allEvents[eventId], 'events');
				const eventDayTime	= moment(event.timestamp*1000).utc().startOf('day').format("X");
				const eventDayNumber	= moment(event.timestamp*1000).utc().day();
				if (!predictionDaysObject[eventDayTime])
					continue;
	
				const thisDayEBU	= +(predictionDaysObject[eventDayTime].EBU);
				if (!this.workingDays[eventDayNumber] || thisDayEBU < this.predictionSettings.minDailyEBU)
					continue;

				if (!predictionEventsPerDay[eventDayTime])
					predictionEventsPerDay[eventDayTime] = [];

				predictionEventsPerDay[eventDayTime].push(event);
	
				if (event.event_type != this.eventTypes.inuse)
					continue;
	
				if (this.predictionObject.max_inuse_ahr < event.inuse_as)
					this.predictionObject.max_inuse_ahr = event.inuse_as;
	
				this.predictionObject.total_inuse_as += event.inuse_as;
				this.predictionObject.count_inuse_events++;

				if (previousDayTimeStamp != eventDayTime) {	
					const inuseAsValue = predictionDaysObject[eventDayTime].inuse_as;

					this.predictionObject.total_daily_inuse_as += +(inuseAsValue);
					this.predictionObject.count_daily_inuse_events++;
					previousDayTimeStamp = eventDayTime;
				}
			}
	
			this.predictionObject.max_inuse_ahr /= 3600;
			if (this.predictionObject.count_inuse_events > 0)
				this.predictionObject.avg_inuse_ahr = this.predictionObject.total_inuse_as / (this.predictionObject.count_inuse_events * 3600);
	
			if(this.predictionObject.count_daily_inuse_events > 0)
				this.predictionObject.avg_daily_inuse_ahr = this.predictionObject.total_daily_inuse_as / (this.predictionObject.count_daily_inuse_events * 3600);
	
			return predictionEventsPerDay;
		};
	
		const predictionShiftsCalculations = (predictionEventsPerDay) => {
	
			let shiftsArray = [];
			if (!this.predictionSettings.shiftAutoDetect) {
				this.shifts.forEach((shift) => {
					if (Object.keys(shift).length > 0) {
						const tempObject: any	= {};
						tempObject.start	= shift.start_time_seconds;
						tempObject.end		= shift.end_time_seconds;
						tempObject.overrideShiftAHr	= shift.overrideShiftAHr;
						shiftsArray.push(tempObject);
					}
				});
	
				// if no shift enabled we need to calculate shifts
				if (shiftsArray.length > 0) {
					shiftsArray.sort((a, b) => {
						return a.start - b.start;
					});
	
					this.predictionObject.shifts = shiftsArray;
					return;
				}
			}
	
			let daysShiftsObject = [];
			const minInuseAs = (+this.minInuseAhr) * 3600;
			for(let day in predictionEventsPerDay) {
				const currentDayEvents = predictionEventsPerDay[day];
	
				for (let i in currentDayEvents) {
					const currEvent = currentDayEvents[i];
					if (currEvent.event_type != this.eventTypes.inuse)
						continue;
	
					if (currEvent.inuse_as >= minInuseAs) {
						const eventStartTime	= currEvent.timestamp - (+day),
							eventEndTime	= eventStartTime + currEvent.calc_duration
							;
						
						daysShiftsObject.push({start: eventStartTime, end: eventEndTime});
					}
				}
			}
	
			// sort shifts by start time
			daysShiftsObject.sort((a, b) => {
				return a.start - b.start;
			});
	
			for (let k = 0; k < daysShiftsObject.length; k++) {
				const item = daysShiftsObject[k];
				let intersectIndex = -1;
				for (let j = shiftsArray.length -1 ; j >= 0; j--) {
					if (item.start <= (shiftsArray[j].end + minChargeOppurtinitySeconds) && item.end >= (shiftsArray[j].start - minChargeOppurtinitySeconds)) {
						intersectIndex = j;
						if (item.start < shiftsArray[j].start)
							shiftsArray[j].start = item.start;

						if (item.end > shiftsArray[j].end)
							shiftsArray[j].end = item.end;

						break;
					}
				}

				if(intersectIndex === -1)
					shiftsArray.push(item);
			}
	
			if (shiftsArray.length > 1) {
				const lastShift = shiftsArray[shiftsArray.length -1];
				const oneDaySeconds = 60*60*24;
				if(shiftsArray[0].start <= (lastShift.end + minChargeOppurtinitySeconds - oneDaySeconds)) {
					// merge last shift with first shift
					shiftsArray[shiftsArray.length -1].end = shiftsArray[0].end;
					delete shiftsArray[0];
				}
			}
	
			// to fix indexes; index with no object in case of merge
			shiftsArray.forEach((shift) => {
				if(shift)
					this.predictionObject.shifts.push(shift);
			});
		};
	
		const predictionCalculateShiftAHR = () => {
			let hasError = false;
			for(const shiftIndex in this.predictionObject.shifts) {
				const shift = this.predictionObject.shifts[shiftIndex];
				if(shift.overrideShiftAHr) {
					shift.ahr = shift.overrideShiftAHr;
				} else {
					let shiftPeriod = (shift.end - shift.start);
					if(shiftPeriod < 0) shiftPeriod += (24*3600);
					shift.ahr = this.predictionObject.avg_daily_inuse_ahr * shiftPeriod / (3600 * 24);
				}
	
				// If the Shift AHR (regardless if the user enter it OR calculated) > 100% – Min SOC  * battery capacity
				// show Error “Shift AHRs is more than (100- $ min SOC) % of battery capacity.“
				if(shift.ahr > (100 - this.predictionSettings.minSOC)/100 * this.device.config_info.battery_capacity) {
					this.sctToastService.setMessage("Shift AHRs is more than "+(100 - this.predictionSettings.minSOC)+"% of battery capacity.");
					hasError = true;
					break;
				}
			}
			return hasError;
		};
	
		const predictionChargeRateCalculations = () => {
			// If the user entered the charge rate, no need to calculate
			if(this.predictionSettings.overrideChargeRateEnabled && this.predictionSettings.overrideChargeRate > 0)
				return this.predictionObject.minDailyChargeRate = this.predictionSettings.overrideChargeRate;
	
			// Charge Time Per day: time available between shifts that at least more than 10 mins (as in the settings).
			let chargeTimePerDay		= 0;
			let previousShiftEndTime	= -1;
			this.predictionObject.shifts.forEach((shift) => {
				if (previousShiftEndTime > -1)
					chargeTimePerDay += (shift.start - previousShiftEndTime);

				previousShiftEndTime = shift.end;
			});
	
			if (previousShiftEndTime > -1) {
				let lastVsFirstShift = this.predictionObject.shifts[0].start - previousShiftEndTime;
				if(lastVsFirstShift < 0) lastVsFirstShift += (24*3600);
				chargeTimePerDay += lastVsFirstShift;
			}
	
			chargeTimePerDay /= 3600;
	
			this.predictionObject.minDailyChargeRate = 0;
			if (chargeTimePerDay > 0) {
				// Min Daily Charge Rate = Average AHR / (charge Hours * battery capacity)
				this.predictionObject.minDailyChargeRate = (this.predictionObject.avg_daily_inuse_ahr / (chargeTimePerDay * this.device.config_info.battery_capacity)) * 100;
			}

			if (this.predictionObject.minDailyChargeRate < minChargeRate)
				this.predictionObject.minDailyChargeRate = minChargeRate;

		};
	
		const predictionShiftChargeRateCalculations = () => {
			// Shift Charge Rate Calculations:
			// Calculate the recommended Shift charger rate
			let hasError = false;
			let firstShiftAfterLongestIdleIndex	= 0,
				chargeRate = this.predictionObject.minDailyChargeRate;
	
			const idleTiming = this.predictionCalcualteLongestIdleTime();
			const idleTimes = idleTiming[0],
				longestIdleTimeIndex = idleTiming[1];
	
			if(this.predictionObject.shifts.length === 1) {
				// If the 1 shift AHR + Min SOC * battery capacity is less than 100%
				// then use the average daily AHR else make an exception daily AHR as above
				if(this.predictionObject.shifts[0].ahr + ((this.predictionSettings.minSOC / 100) * this.device.config_info.battery_capacity) < this.device.config_info.battery_capacity) {
					this.predictionObject.shiftChargeRate = this.predictionObject.avg_daily_inuse_ahr;
				} else {
					this.predictionObject.shiftChargeRate = this.predictionObject.minDailyChargeRate;
				}
			} else {
				// Select the longest idle time, Find the shift followed by the longest idle
				firstShiftAfterLongestIdleIndex = (+longestIdleTimeIndex) + 1;
				if(firstShiftAfterLongestIdleIndex === this.predictionObject.shifts.length) {
					firstShiftAfterLongestIdleIndex = 0;
				}
			}
			let loopIdx = firstShiftAfterLongestIdleIndex;
	
			if(chargeRate < minChargeRate) {
				chargeRate = minChargeRate;
			}
	
			let ovverrideChargeRate = false;
			if(this.predictionSettings.overrideChargeRateEnabled && this.predictionSettings.overrideChargeRate > 0) {
				this.predictionObject.shiftChargeRate = this.predictionSettings.overrideChargeRate;
				if(this.predictionObject.shiftChargeRate < minChargeRate) {
					this.predictionObject.shiftChargeRate = minChargeRate;
				}
				chargeRate = this.predictionObject.shiftChargeRate;
				ovverrideChargeRate = true;
			}
	
			// Loop over shifts and idles: with different CR values
			let isShift = true;
			this.predictionObject.activeAHR = this.predictionSettings.maxSOC * this.device.config_info.battery_capacity / 100;
			const maxActiveAHR = this.predictionObject.activeAHR;
			let firstloop = true;
	
			do {
				let changeIsShift = true;
	
				if(isShift) {
					// Set  AAHR = AAHR – average shift AHR, if the result < Min SOC *  capacity , stop calculations and set CR to be CR+1% and start over.
					this.predictionObject.shifts[loopIdx].aahr_start = this.predictionObject.activeAHR;
					this.predictionObject.activeAHR = this.predictionObject.activeAHR - this.predictionObject.shifts[loopIdx].ahr;
					this.predictionObject.shifts[loopIdx].aahr_end = this.predictionObject.activeAHR;
					if(this.predictionObject.activeAHR < (this.predictionSettings.minSOC * this.device.config_info.battery_capacity)/100) {
						// NOTE: IF CR reaches 40% Show Error
						if(chargeRate >= 40 || ovverrideChargeRate) {
							this.sctToastService.setMessage("There is no enough Charge Time available");
							hasError = true;
							break;
						}
						chargeRate = chargeRate + 1;
						this.predictionObject.minDailyChargeRate = chargeRate;
						loopIdx = firstShiftAfterLongestIdleIndex;
						changeIsShift = false;
						this.predictionObject.activeAHR = maxActiveAHR;
						firstloop = true;
					}
				} else {
					let chargeAHR = 0;
					if (idleTimes[loopIdx] >= minChargeOppurtinitySeconds) 
						chargeAHR = (chargeRate / 100) * this.device.config_info.battery_capacity * (idleTimes[loopIdx]/3600) * (this.predictionSettings.chargeEfficiency / 100);

					this.predictionObject.activeAHR += chargeAHR;
	
					if (this.predictionObject.activeAHR > maxActiveAHR)
						this.predictionObject.activeAHR = maxActiveAHR;
	
					if (this.predictionObject.activeAHR > this.device.config_info.battery_capacity)
						this.predictionObject.activeAHR = this.device.config_info.battery_capacity;
				}

				if (changeIsShift) {
					isShift = !isShift;
					if(isShift) {
						loopIdx++;
						firstloop = false;
					}
				}

				if(loopIdx >= this.predictionObject.shifts.length)
					loopIdx = 0;

			} while(loopIdx !== firstShiftAfterLongestIdleIndex || firstloop);
			return hasError;
		};
	
		const predictionGetChargerType = () => {
			let chargerType;
			let idleTiming: any[] = this.predictionCalcualteLongestIdleTime();
			const idleTimes = idleTiming[0];
			let longestIdleTimeIndex: number = idleTiming[1];

			const mostIdleTime = idleTimes[longestIdleTimeIndex] / 3600;
			// conventional:
			// 	- ((100 - min soc )/100) * battery ahr > avg daily
			// 	- idel > 3 hours
			// 	- left time (most idle time - 3 hours) -> use for generate charge rate as following:
			// ((90 - min soc) / time in hour) -> if that number less than 20% then conventional
			if(
				((100 - this.predictionSettings.minSOC) / 100) * this.device.config_info.battery_capacity > this.predictionObject.avg_daily_inuse_ahr 
			&& (mostIdleTime > 3)
			&& ((90 - this.predictionSettings.minSOC) / (mostIdleTime-3) < 20)
			) {
				chargerType = 'Conventional';
			} else {
				// if not conventional:
				// 	check charge rate calculated (which is already uploaded to prod):
				// 	> 30 -> fast
				// 	avg daily EBU > 1.6 => fast
				if(
					(this.predictionObject.minDailyChargeRate > 30
					&& this.predictionObject.avg_daily_inuse_ahr / this.device.config_info.battery_capacity > 1.6
					)
				|| this.predictionObject.minDailyChargeRate > 35
				) {
					chargerType = 'fast';
				} else {
					// 	else opportunity
					chargerType = 'Opportunity';
				}
			}
	
			this.predictionObject.chargerType = chargerType;
		};
	
		const getRecommendedCharger = () => {
			let currentRating;
			if (this.device.NominalVoltage <= 36) // @@ TODO: check no nominalVoltage in device Info
				currentRating = 50;
			else if (this.device.NominalVoltage <= 48)
				currentRating = 40;
			else
				currentRating = 25;
	
			const totalCurrent = this.device.config_info.battery_capacity * this.predictionObject.minDailyChargeRate/100;
			let count_of_PMs = Math.ceil(totalCurrent / currentRating);
			if (count_of_PMs > 12) 
				count_of_PMs = 12;
			let modelType;
			this.recommendedChargerImage = '/sctportal/images/chargLink/charger_connected_v2.png';
			if (count_of_PMs <= 4){
				modelType = 'Q4';
			} else if (count_of_PMs <= 6) {
				modelType = 'Q6';
				// this.recommendedChargerImage = '6x_Idle.png'; 
			} else {
				modelType = 'Q12';
				// this.recommendedChargerImage = '12x_Idle.png';
			}
			// @@ Anas TODO: return back to check charger Image and modal for each charger (new requirments)
			// this.recommendedCharger = modelType + "-" + (count_of_PMs * currentRating) + "-XXX";
		};
	
		this.showPredictionResult = false;
		const minChargeOppurtinitySeconds = (+this.predictionSettings.minChargeOppurtinityTime) * 60;

		const predictionEventsPerDay = predictionInuseAHRcalculations();
		predictionShiftsCalculations(predictionEventsPerDay);

		if(this.predictionObject.shifts.length == 0)
			return this.sctToastService.setMessage("translate|operation_analysis.manual_shift_validation_error_message");

		if (predictionCalculateShiftAHR()) return; // return in case of error happen
		predictionChargeRateCalculations();
		if (predictionShiftChargeRateCalculations()) return; // return in case of error happen
		this.drawSOCpredictionChart();
		this.drawEventsSOCpredictionChart();
		predictionGetChargerType();
		getRecommendedCharger();
	
		this.hasConventionalCharts = false;
		if (this.predictionObject.chargerType == 'Conventional') {
			this.hasConventionalCharts = true;
	
			this.drawSOCpredictionChart(true);
			this.drawEventsSOCpredictionChart();
		}
		
		this.showPredictionResult = true;
	}

	calculatePeriod(start, end) {
		let period = 0;
		if(start > end) {
			end += 24*3600;
		}
		period = end - start;
		return period;
	}

	prepareConventionalChartData() {
		let data = [];
	
		const idleTiming: any[] = this.predictionCalcualteLongestIdleTime();
		let idleTimes = idleTiming[0],
			longestIdleTimeIndex = idleTiming[1];
	
		const mostIdleTime = idleTimes[longestIdleTimeIndex] / 3600;
	
		let conventionalShifts = [];
		conventionalShifts = _.extend(conventionalShifts, this.predictionObject.shifts)
	
		let firstShiftAfterLongestIdleIndex = longestIdleTimeIndex +1;
		if (firstShiftAfterLongestIdleIndex === conventionalShifts.length) firstShiftAfterLongestIdleIndex = 0;
		for(let i = 0 ; i < firstShiftAfterLongestIdleIndex; i++) {
			const temp = conventionalShifts.shift();
			conventionalShifts.push(temp);
		}
	
		let currentAHR = this.device.config_info.battery_capacity;
		conventionalShifts.forEach((shift) => {
			data.push([shift.start, currentAHR]);
			if (shift.start > shift.end) {
				const period = ((this.oneDayInSeconds) - shift.start) / 3600;
				const endOfDayAHR = currentAHR - Math.round(this.predictionObject.avg_daily_inuse_ahr * period / 24);
				data.push([(this.oneDayInSeconds) - 1, endOfDayAHR]);
				data.push([0, endOfDayAHR]);
			}
			currentAHR -= shift.ahr;
			data.push([shift.end, currentAHR]);
		});
	
		const maxSOCPeriod = mostIdleTime - 3;
		const lastShift = conventionalShifts[conventionalShifts.length -1];
		let maxSOCEndTime = lastShift.end + (maxSOCPeriod * 3600);
		let maxSOCvalue = this.predictionSettings.maxSOC;

		if ((currentAHR / this.device.config_info.battery_capacity * 100) > maxSOCvalue)
			maxSOCvalue = currentAHR / this.device.config_info.battery_capacity * 100;

		let period = ((this.oneDayInSeconds) - lastShift.end) / 3600;
		if (maxSOCEndTime === this.oneDayInSeconds) {
			const endOfDayAHR = maxSOCvalue * this.device.config_info.battery_capacity / 100;
			data.push([(this.oneDayInSeconds) - 1, endOfDayAHR]);
			data.push([0, endOfDayAHR]);
		} else {
			if (maxSOCEndTime > this.oneDayInSeconds) {
				let period = ((this.oneDayInSeconds) - lastShift.end) / 3600;
				let endOfDayAHR: number = currentAHR;
				if(currentAHR < this.device.config_info.battery_capacity * this.predictionSettings.maxSOC / 100) {
					endOfDayAHR += Math.round(this.predictionObject.avg_daily_inuse_ahr * period / 24);
				}
				data.push([(this.oneDayInSeconds) - 1, endOfDayAHR]);
				data.push([0, endOfDayAHR]);
				maxSOCEndTime -= this.oneDayInSeconds;
				if(maxSOCvalue < (endOfDayAHR / this.device.config_info.battery_capacity * 100)) {
					maxSOCvalue = endOfDayAHR / this.device.config_info.battery_capacity * 100;
				}
			} 
			data.push([maxSOCEndTime, maxSOCvalue * this.device.config_info.battery_capacity / 100]);
		}
	
		// sort data to be the start of day first
		data = data.sort((a, b) => {return a[0] - b[0];});
	
		let conventionalData = [];
		data.forEach((element) => {
			const timeObj: any	= this.commonService.convertHourToSeconds(element[0], null, true);
			conventionalData.push([
				new Date(2017, 11, 11, timeObj.hour, timeObj.min, 0, 0),
				Math.round((element[1] / this.device.config_info.battery_capacity) * 100),
				this.predictionSettings.maxSOC,
				this.predictionSettings.minSOC
			]);
		});
	
		return conventionalData;
	}

	predictionCalcualteLongestIdleTime() {
		let idleTimes = [];
		let longestIdleTimeIndex = 0;
	
		if(this.predictionObject.shifts.length === 1) {
			idleTimes.push((24*3600) - this.calculatePeriod(this.predictionObject.shifts[0].start, this.predictionObject.shifts[0].end));
		} else {
			// Get the idle times
			let prevShiftEndTime = -1;
			this.predictionObject.shifts.forEach((shift) => {
				if(prevShiftEndTime > -1) {
					idleTimes.push(shift.start - prevShiftEndTime);
				}
				prevShiftEndTime = shift.end;
			});

			if(prevShiftEndTime > -1) {
				let lastIdleTime = this.predictionObject.shifts[0].start - prevShiftEndTime;
				if(lastIdleTime < 0) lastIdleTime += (24*3600);
				idleTimes.push(lastIdleTime);
			}
	
			// Select the longest idle time, if we had multiple with the same time, select the first one in the day.
			longestIdleTimeIndex = idleTimes.indexOf(Math.max.apply(null, idleTimes));
		}
		return [idleTimes, longestIdleTimeIndex];
	}

	drawSOCpredictionChart(isConventional = false){
		let prepareChartData = () => {
			let data = [];
			let startOfDayIsIdle = true,
				lastEventOfDayWithinShift = false;
	
			data.push([new Date(2017, 11, 11, 0, 0, 0, 0), 0, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			this.predictionObject.shifts.forEach((shift) => {
				let endTime = shift.end;
				if (shift.start > shift.end) {
					startOfDayIsIdle = false;
					endTime = (this.oneDayInSeconds) - 1;
				}

				const startTimeObj: any	= this.commonService.convertHourToSeconds(shift.start, null, true);
				const endTimeObj: any		= this.commonService.convertHourToSeconds(endTime, null, true);

				if((endTimeObj.hour == 0 && endTimeObj.min == 0) || (startTimeObj.hour == 0 && startTimeObj.min == 0))
					lastEventOfDayWithinShift = true;

				data.push([
					new Date(2017, 11, 11, startTimeObj.hour, startTimeObj.min, 0, 0),
					Math.round((shift.aahr_start / this.device.config_info.battery_capacity) * 100),
					this.predictionSettings.maxSOC,
					this.predictionSettings.minSOC
				]);
				data.push([
					new Date(2017, 11, 11, endTimeObj.hour, endTimeObj.min, 0, 0),
					Math.round((shift.aahr_end / this.device.config_info.battery_capacity) * 100),
					this.predictionSettings.maxSOC,
					this.predictionSettings.minSOC
				]);
			});

			const lastShift = this.predictionObject.shifts[this.predictionObject.shifts.length-1];
			let period, startOfDaySOC;
			if(startOfDayIsIdle) {
				if(!lastEventOfDayWithinShift)
					data.push([new Date(2017, 11, 11, 23, 59, 0, 0), 0, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
				
				const lastShiftEndSOC = (lastShift.aahr_end / this.device.config_info.battery_capacity) * 100;
				period = (this.oneDayInSeconds) - lastShift.end;
				startOfDaySOC = Math.round(lastShiftEndSOC + (this.predictionObject.avg_daily_inuse_ahr * period / (3600 * 24)));
				if (startOfDaySOC>this.predictionSettings.maxSOC) startOfDaySOC = this.predictionSettings.maxSOC;
				data[0][1] = startOfDaySOC;
				data[data.length-1][1] = startOfDaySOC;
			} else {
				period = (this.oneDayInSeconds) - lastShift.start;
				const avgHourlyShiftAHR = lastShift.ahr / ((lastShift.end - lastShift.start + (this.oneDayInSeconds)) / 3600);
				const startOfDayAHR = Math.round(lastShift.aahr_start - (avgHourlyShiftAHR * period / 3600));
				startOfDaySOC = (startOfDayAHR / this.device.config_info.battery_capacity) * 100;
				data[data.length-1][1] = startOfDaySOC;
				data[0][1] = startOfDaySOC;
				const endTimeObj: any		= this.commonService.convertHourToSeconds(lastShift.end, null, true);
				data.splice(1, 0, [new Date(2017, 11, 11, endTimeObj.hour, endTimeObj.min, 0, 0), Math.round((lastShift.aahr_end / this.device.config_info.battery_capacity) * 100), this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			}
			return data;
		};
	
		if(this.predictionObject.shifts.length == 0)
			return;

		let key = 'shiftSOC';        
		if(isConventional)
			key += '-conventional';
		
		let data;
		if(isConventional) {
			data = this.prepareConventionalChartData();
		} else {
			data = prepareChartData();
		}

		this.chartObject.data[key] = data;
		this.chartObject.columnNames = ['Date', 'SOC', 'Max SOC', 'Min SOC'];
		let config = {
			LegendPosition: 'top', 
			hAxis: {
				title: 'Day Of Time',
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxis: {
				title: '%SOC',
			},
			explorer: {
				"actions": ['dragToZoom', 'rightClickToReset'],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400,
			dataIsDataTable: true,
		};
	
		this.chartObject.config[key] = config;
	}
	
	drawEventsSOCpredictionChart() {
		let prepareChartData = () => {
			let data = [];
			let previousSOCvalue = this.predictionSettings.maxSOC;
			const minChargeOppurtinitySeconds = (+this.predictionSettings.minChargeOppurtinityTime) * 60;
			const maxSOC = this.predictionSettings.maxSOC;
			data.push([new Date(this.commonService.decompress(this.events[0].timestamp*1000, 'events')), previousSOCvalue, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			this.events.forEach((eventData) => {
				const event = this.commonService.decompress(eventData, 'events');
				let endSOC = previousSOCvalue;
				if(event.event_type == this.eventTypes.inuse) {
					// inuse
					const eventSOC = Math.round((event.inuse_as / (this.device.config_info.battery_capacity * 3600)) * 100);
					endSOC-= eventSOC;
					if(endSOC < 0) {
						endSOC = 0;
					}
				} else {
					// other
					if(event.calc_duration >= minChargeOppurtinitySeconds) {
						const chargeSOC = Math.round((this.predictionObject.minDailyChargeRate / 100) * this.device.config_info.battery_capacity * (event.calc_duration/3600) * (this.predictionSettings.chargeEfficiency / 100));
						endSOC += chargeSOC;

						if (endSOC > maxSOC)
							endSOC = maxSOC;
					}
				}
	
				data.push([new Date((event.timestamp + event.calc_duration)*1000), endSOC, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
				previousSOCvalue = endSOC;
			});
			return data;
		};

		if(this.events.length == 0)
			return;

		let key = 'eventSOC';
		
		let data = prepareChartData();
		this.chartObject.data[key] = data;
	
		let config = {
			LegendPosition: 'top', 
			hAxis: {
				title: 'Date',
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			colors: ["#3799db", "green", "red"],
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxis: {
				title: '%SOC',
				titleTextStyle: {color: 'black', fontSize: 18},
				minValue: 0, 
				maxValue: 100,
				gridlines: {'count': 10},
				viewWindow: { min: 0, max: 100},
				viewWindowMode: "explicit"
			},
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400
		};
	
		this.chartObject.config[key] = config;
	}

	ngOnDestroy() {
		this.ShiftsModal.onClose.unsubscribe();
	}
}