
<div class="main-black border-radius-bottom white-box">
	<div class="row m-0 px-4" [ngClass]="{'py-5': !commonService.isMobileScreen, 'pt-5 pb-3': commonService.isMobileScreen}">
		<div class="col-md-6 col-sm-12">
			<div class="row m-0 mb-5">
				<h3 class="font-weight-bold">{{customer.customer_name}}</h3>
				<a *ngIf="userService.hasAccessFunction('customer_management') && currentUser.isSCT"
					class="btn main-green-bg text-white ml-auto px-4 my-auto rounded-lg"
					[routerLink]="['/customers', 'edit', customer.id]">
					{{'customer.edit_customer' | translate}}
				</a>
				<div class="w-100" *ngIf="currentUser.isSCT && customer.original_enterprise">
					<p class="badge text-white bg-main-black-color">
						{{'customer.this_customer_is_generated' | translate}}
						<span *ngIf="!userService.hasAccessFunction('enterprise_management')">
							{{customer.original_enterprise_name}}
						</span>
						<a *ngIf="userService.hasAccessFunction('enterprise_management')"  routerLink="/enterprise/edit/{{customer.original_enterprise}}"> {{customer.original_enterprise_name}} </a>
					</p>
				</div>
			</div>

			<ng-container *ngIf="customer.customer_contact_name || customer.customer_phone_num">
				<h5 class="font-weight-bold mt-4">
					{{'g.contact_info' | translate}}
					<app-help-tooltip *ngIf="tooltips.customer_contact_info" [field]="tooltips.customer_contact_info"></app-help-tooltip>
				</h5>
				<p class="light-grey">{{customer.customer_contact_name}}</p>
				<p class="light-grey">{{customer.customer_phone_num}}</p>
			</ng-container>


			<app-info-box *ngIf="infoBoxSiteAnalytics"
				[ioTAhDevices]="infoBoxSiteAnalytics.ioTAhDevices"
				[chargLinkDevices]="infoBoxSiteAnalytics.chargLinkDevices"
			></app-info-box>
		</div>
		<div class="col-md-6 col-sm-12">
			<div class="mapbox-widget">
				<app-mapbox
					class="border-radius main-blue-border"
					dotsType="sites"
					[latitude]="sitesService.defaultMapLocation.latitude"
					[longitude]="sitesService.defaultMapLocation.longitude"
					zoom="2.8"
					[dots]="sites"
				></app-mapbox>
			</div>
		</div>
	</div>
</div>

<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="shouldShowStudiesSection()">
	<app-studies-list parent="customer" [customerId]="customerId" [customerName]="customer.customer_name"></app-studies-list>
</div>
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="selectedMonthData && iotahDevices.length > 0">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.monthly_customer_report' | translate:{customer: customer.customer_name} }}
				<app-help-tooltip *ngIf="tooltips.monthly_customer_report" [field]="tooltips.monthly_customer_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="sites" bindLabel="name" bindValue="id" placeholder="{{'site.all_sites' | translate}}" name="site" [(ngModel)]="monthReportFilters.site" (ngModelChange)="loadMonthData(true)" [multiple]="true">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="yearList" bindLabel="label" bindValue="id" placeholder="{{'site.year' | translate}}" name="year" [(ngModel)]="monthReportFilters.year" (ngModelChange)="loadMonthData(true)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="monthList" bindLabel="label" bindValue="id" placeholder="{{'site.month' | translate}}" name="quarter" [(ngModel)]="monthReportFilters.month" (ngModelChange)="loadMonthData(true)" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto pl-5 d-flex flex-row-reverse" [ngClass]="{'pt-2': commonService.isMobileScreen}">
			<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportMonthData()"></button>
		</div>
	</div>
	<div>
		<sct-custom-table
			[columnsConfig]="trucksReportConfig.columns"
			[data]="trucksReportConfig.data"
			[config]="trucksReportConfig.table"
		></sct-custom-table>
	</div>
</div>
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="selectedQuarterData && chargLinkDevices.length > 0">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.quarterly_customer_report' | translate:{customer: customer.customer_name} }}
				<app-help-tooltip *ngIf="tooltips.quarterly_customer_report" [field]="tooltips.quarterly_customer_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="sites" bindLabel="name" bindValue="id" placeholder="{{'site.all_sites' | translate}}" name="site" [(ngModel)]="quarterReportFilters.site" (ngModelChange)="loadQuarterData()" [multiple]="true">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-3 d-inline-flex">
			<ng-select class="mr-auto w-100" [items]="quartersList" bindLabel="label" bindValue="id" placeholder="{{'site.quarter' | translate}}" name="quarter" [(ngModel)]="quarterReportFilters.quarter" (ngModelChange)="loadQuarterData()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-1 ml-auto pl-5 d-flex flex-row-reverse" [ngClass]="{'pt-2': commonService.isMobileScreen}">
			<button class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportQuarterData()"></button>
		</div>
	</div>
	<div>
		<sct-custom-table
			[columnsConfig]="chargersReportConfig.columns"
			[data]="chargersReportConfig.data"
			[config]="chargersReportConfig.table"
		></sct-custom-table>
	</div>
</div>
<div>
	<!-- Iotah devices usage data-->
	<app-usage-data *ngIf="iotahDevices.length > 0"
		[data]="sitesPerformanceData.recent.iotah"
		[columns]="sitesPerformanceData.recent.iotah[0]"
		[devices]="iotahDevices"
		[isChargeLinkDevices]="false"
		[sites]="sites"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
		[siteInfo]="{id: customer.id, name: customer.customer_name}"
		parentPage="customer"
	></app-usage-data>
	<!-- chargLink devices usage data-->
	<app-usage-data *ngIf="chargLinkDevices.length > 0"
		[data]="sitesPerformanceData.recent.chargLink"
		[columns]="sitesPerformanceData.recent.chargLink[0]"
		[devices]="chargLinkDevices"
		[isChargeLinkDevices]="true"
		[sites]="sites"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
		[siteInfo]="{id: customer.id, name: customer.customer_name}"
		parentPage="customer"
	></app-usage-data>

	<!-- Iotah devices quarterly usage data-->
	<app-quarterly-usage-data *ngIf="iotahDevices.length > 0"
		[data]="sitesPerformanceData.months.iotah"
		[devices]="iotahDevices"
		[isChargeLinkDevices]="false"
		[sites]="sites"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
		[siteInfo]="{id: customer.id, name: customer.customer_name}"
		parentPage="customer"
		[truckTypes]="customer.sitesAnalytics.trucksIndexes.iotah"
	></app-quarterly-usage-data>
	<!-- chargLink devices quarterly usage data-->
	<app-quarterly-usage-data *ngIf="chargLinkDevices.length > 0"
		[data]="sitesPerformanceData.quarter.chargLink"
		[devices]="chargLinkDevices"
		[isChargeLinkDevices]="true"
		[sites]="sites"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true, hasSiteFilter: true}"
		[siteInfo]="{id: customer.id, name: customer.customer_name}"
		parentPage="customer"
		[truckTypes]="customer.sitesAnalytics.trucksIndexes.chargLink"
	></app-quarterly-usage-data>

</div>
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="customer.id">
	<app-note
		[title]="'note.customer_notes' | translate"
		[modelType]="'customer'"
		[modelId]="customer.id"
		[limit]="3"
		[tooltipKey]="tooltips.customer_notes"
	></app-note>
</div>
