import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { keyBy } from 'lodash-es'
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { SCTCustomTable } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/sct-table.component';

@Component({
	selector: 'app-suggested-installation-date-report',
	templateUrl: './suggested-installation-date-report.component.html'
})
export class SuggestedInstallationDateReportComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		hasSelectionColumn: true,
		fileName: this.translate.instant('suggested_installation_date_report.title'),
		hasActionButtons: true,
		hideNoData: true,
		actionButtonsList: [
			{
				text: this.translate.instant('suggested_installation_date_report.use_suggested_installation_date'),
				action: ()=> { this.setInstallationDate() },
			}
		]
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: "id", hidden: true},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: "link"},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'string' },
		{ key: 'suggested_installation_date', name: this.translate.instant('suggested_installation_date_report.suggested_installation_date'), type: "date" },
		{ key: 'actual_installation_date', name: this.translate.instant('suggested_installation_date_report.actual_installation_date'), type: "date" },
	];

	customersAndSites: any[] = [];
	customersList: any[] = [];
	sitesList: any[] = [];

	selectedCustomerId: number = null;
	selectedSiteId: number = null;
	selectedDate = 0;
	selectedDevices = {};
	selectedGapPeriod: number = 14;
	newSuggestedDateSelected: Date = new Date();
	maxDate: Date = new Date();
	setInstallationSubscription!: Subscription;
	generateReportSubscription!: Subscription;
	invalidSelectedDate: boolean = false;
	@ViewChild('editSuggestedInstallationDateModal') editSuggestedInstallationDateModal;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private deviceService: DeviceService,
		private siteService: SitesService,
		private commonService: CommonService,
		public translate: TranslateService,
		private notificationMessage: NotificationMessageService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		if(window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.sideMenuService.hide();

		this.getCustomersSiteList();
	}

	ngAfterViewInit() {
		this.editSuggestedInstallationDateModal.onClose.subscribe((res) => {
			if (res && this.newSuggestedDateSelected)
				return this.suggestNewInstallationDate(this.editSuggestedInstallationDateModal.data);
			return this.newSuggestedDateSelected = new Date();
		});
	}

	getCustomersSiteList() {
		this.siteService.getUserSitesInfo({ get_customers: true }).subscribe((res: any) => {
			this.customersList = res.customers;
			this.customersList = this.commonService.sortDataAlphabetically(this.customersList, 'customer_name');
			this.customersAndSites = keyBy(this.customersList || [], 'id');
		});
	}

	getCustomerSites(customerId: number) {
		this.sitesList = this.customersAndSites[customerId].sites || [];
		this.sitesList = this.commonService.sortDataAlphabetically(this.sitesList, 'name');
		this.selectedSiteId = this.sitesList[0]?.id;
	}

	disableEnableGenerateButton() {
		return  !this.selectedSiteId ||
				!this.selectedDate ||
				!Number.isInteger(this.selectedGapPeriod) ||
				this.selectedGapPeriod < 7 ||
				this.selectedGapPeriod > 60;
	}

	generateReport() {
		if (this.disableEnableGenerateButton())
			return;

		this.generateReportSubscription = this.deviceService.generateSuggestedInstallationReport(this.selectedSiteId, this.selectedDate, this.selectedGapPeriod).subscribe((res: any) => {
			this.selectedDevices = {};
			const tableData = [];
			for(const row of (res || [])) {
				tableData.push({
					id: {value: row.mac_address, disable_select: !row.suggested_installation_date},
					not_formatted_suggested_installation_date: {value: row.suggested_installation_date},
					serial_number: {value: row.serial_number, link: ['/', this.selectedCustomerId, this.selectedSiteId, row.mac_address,'performance']},
					mac_address: {value: row.mac_address},
					suggested_installation_date: {value: this.formateInstallationDate(row.suggested_installation_date), iconList: [{name: 'pen', action: () => this.openNewSuggestionInstallationDate(row.mac_address), iconTitle: this.translate.instant('g.edit')}]},
					actual_installation_date: {value: this.formateInstallationDate(row.actual_installation_date)},
				})
			}

			this.newSuggestedDateSelected = new Date();
			this.tableData = tableData;
		});
	}

	setInstallationDate(data = {}) {
		this.setInstallationSubscription = this.deviceService.setDevicesInstallationDate(this.selectedDevices).subscribe((res: any) => {
			switch (res?.api_status) {
				case 2:
					this.notificationMessage.setMessage(this.translate.instant('g.invalid_fields'));
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
					break;
			}
		});
	}

	openNewSuggestionInstallationDate(macAddress: string) {
		this.invalidSelectedDate = false;
		this.editSuggestedInstallationDateModal.data = macAddress;
		this.editSuggestedInstallationDateModal.show();
	}

	suggestNewInstallationDate(macAddress: string) {
		if (moment(this.newSuggestedDateSelected).utc().startOf('day').unix() > moment(this.maxDate).utc().startOf('day').unix()) {
			this.invalidSelectedDate = true;
			this.editSuggestedInstallationDateModal.closeOnConfirm = false;
			return;
		}
		const selectedRow = this.tableData.find(row => row.id?.value == macAddress);
		if (selectedRow) {
			selectedRow.suggested_installation_date = selectedRow.suggested_installation_date || {};
			selectedRow.suggested_installation_date.backGroundColor = 'yellow';

			this.selectedDevices[macAddress] = this.newSuggestedDateSelected;
			const selectedUnixDate = moment(this.selectedDevices[macAddress]).utc().startOf('day').unix();
			selectedRow.not_formatted_suggested_installation_date.value = selectedUnixDate;

			selectedRow.old_suggestion_installation_date = selectedRow.suggested_installation_date.value;
			selectedRow.suggested_installation_date.value = moment(this.selectedDevices[macAddress]).format('MM/DD/YYYY');

			this.tableConfig.actionButtonsList[0].enable = false;
			selectedRow.id.disable_select = false;
			if (!selectedRow.suggested_installation_date.iconList.some(value => value.name == 'sync-alt')) {
				selectedRow.suggested_installation_date.iconList.push({
					name: 'sync-alt',
					action: () => this.resetInstallationDateToDefault(macAddress),
					iconTitle: this.translate.instant('g.reset')
				});
			}

			this.editSuggestedInstallationDateModal.hide();
		}
	}

	resetInstallationDateToDefault(macAddress: string) {
		const selectedRow = this.tableData.find(row => row.id?.value === macAddress);
		if (selectedRow) {
			selectedRow.suggested_installation_date.backGroundColor = null;
			delete this.selectedDevices[macAddress];
			selectedRow.select.selected = false;
			selectedRow.id.disable_select = true;

			selectedRow.not_formatted_suggested_installation_date.value = 0;
			selectedRow.suggested_installation_date.value = selectedRow.old_suggestion_installation_date;

			if (selectedRow.suggested_installation_date.iconList)
				selectedRow.suggested_installation_date.iconList.pop();

			if (this.sctCustomTable && !Object.keys(this.selectedDevices).length)
				this.sctCustomTable.unselectRows();

			this.newSuggestedDateSelected = new Date();
		}
	}

	formateInstallationDate(date: number) {
		if (!date)
			return 'N/A';

		return moment(date * 1000).utc().format('MM/DD/YYYY');
	}

	ngOnDestroy() {
		if (this.setInstallationSubscription)
			this.setInstallationSubscription.unsubscribe();

		if (this.generateReportSubscription)
			this.generateReportSubscription.unsubscribe();
	}

 	updateSelectedDevices(devices) {
		for (const device of devices) {
			this.selectedDevices[device.mac_address.value] = device.not_formatted_suggested_installation_date.value;
		}
	}
}
