<div *ngIf="(config.hideNoData && data.length) || !config.hideNoData; else noData">
	<div class="table-control-container d-flex justify-content-end align-items-center my-2">
		<div *ngIf="config.hasActionButtons">
			<div *ngFor="let btn of config.actionButtonsList">
				<!-- link -->
				<a *ngIf="btn.link" [routerLink]="btn.link">
					<button class="btn btn-primary btn-sm mx-2">
						{{btn.text}}
					</button>
				</a>

				<!-- action -->
				<button *ngIf="!btn.link" class="btn btn-primary btn-sm mx-2" [disabled]="!(this.selectedRows.length || btn.enable)" (click)="btn.action ? btn.action() : null">
					{{btn.text}}
				</button>
			</div>
		</div>
		<fa-icon *ngIf="config.hasExport" class="icon mr-2" icon="download" size="lg" (click)="generateCsvFile()"></fa-icon>
		<fa-icon *ngIf="!config.hideColumnControl" class="icon" icon="table" size="lg" (click)="toggleHidePopup()"></fa-icon>
		<div class="hide-columns-popup" *ngIf="isHidePopupShown">
			<ul>
				<li *ngFor="let column of columnsConfig" (click)="toggleColumnVisibility(column)">
					<fa-icon *ngIf="!hideFromListTypes.includes(column.type)" [icon]="['far', column.hidden ? 'square' :'check-square']" size="sm" class="mr-2"></fa-icon>
					<span *ngIf="!hideFromListTypes.includes(column.type)">{{column.name}}</span>
				</li>
			</ul>
		</div>
	</div>
	<div *ngIf="config.isBackendPagination">
		<span *ngIf="totalItemsNum && hasFilters()" class="mr-1">{{'g.showing_items' | translate}}: {{totalItemsNum}}</span>
		<span *ngIf="overallItemsCount">{{'g.total_items' | translate}}: {{overallItemsCount}}</span>
	</div>

	<div class="hide-columns-popup-shadow" *ngIf="isHidePopupShown" (click)="toggleHidePopup()"></div>

	<div class="table_container" [class]="{'fit_screen': config.fitScreen}">
		<table class="table table-striped"
			[ngClass]="config.separatedCells ? '' : 'no-vertical-lines'"
			matSort (matSortChange)="sortData($event, {saveSort: true})"
			[matSortActive]="sort.active"
			[matSortDirection]="sort.direction"
		>
			<thead class="sticky">
				<tr>
					<th *ngFor="let column of shownColumnsConfig"
							[draggable]="config.draggable"
							(dragstart)="config.draggable && drag($event)"
							(dragenter)="config.draggable && drop($event)"
							(dragover)="config.draggable && allowDrop($event)" [class]="{'small-column': hideFromListTypes.includes(column.type)}">

						<!-- Sort -->
						<div class="header-container" *ngIf="columnsToSort.includes(column.key); else noSort" [mat-sort-header]=" column.key">
							<ng-container *ngTemplateOutlet="columnTemplate; context: { column: column }"></ng-container>
						</div>

						<ng-template #noSort class="header-container">
							<ng-container *ngTemplateOutlet="columnTemplate; context: { column: column }"></ng-container>
						</ng-template>
					</th>
				</tr>
				<tr *ngIf="columnsToFilter.length">
					<th *ngFor="let column of shownColumnsConfig" [class]="{'small-column': hideFromListTypes.includes(column.type)}">
						<div class="filter-container" *ngIf="hasFilterTypes.includes(column.type) && columnsToFilter.includes(column.key); else noFilter">
							<div [ngSwitch]="column.filterType || column.type">
								<!-- Date Filter -->
								<div *ngSwitchCase="'date_with_ref'">
									<ng-container *ngTemplateOutlet="timeRangeTemplate;
										context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
									></ng-container>
								</div>

								<div *ngSwitchCase="'date'">
									<ng-container *ngTemplateOutlet="timeRangeTemplate;
										context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
									></ng-container>
								</div>

								<div *ngSwitchCase="'timestamp'">
									<ng-container *ngTemplateOutlet="timeRangeTemplate;
										context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
									></ng-container>
								</div>

								<!-- Boolean Filter -->
								<div *ngSwitchCase="'boolean'">
									<ng-select
										[items]="[{value: true, result: column.filterTrueText || ('g.yes' | translate)}, {value: false, result: column.filterFalseText || ('g.no' | translate)}]"
										bindLabel="result"
										bindValue="value"
										[name]="column.key"
										[(ngModel)]="booleanFilter[column.key]"
										multiple="true"
										(change)="config.isBackendPagination ? doBackEndPaginationPagination() : filter(column, $event, {saveFilter: true})"
									>
									</ng-select>
								</div>

								<!-- DropDown Filter -->
								<div *ngSwitchCase="'dropdown'">
									<ng-select
										[items]="column.items"
										bindLabel="label"
										bindValue="value"
										[name]="column.key"
										[(ngModel)]="filters[column.key]"
										multiple="false"
										(change)="config.isBackendPagination ? doBackEndPaginationPagination() : filter(column, $event, {saveFilter: true})"
									>
									</ng-select>
								</div>

								<!-- Select -->
								<div *ngSwitchCase="'select'" class="form-check pointer">
									<input class="form-check-input" type="checkbox" [checked]="selectAll" (click)="toggleSelectAll()" [disabled]="this.disableCheckAll()">
								</div>

								<!-- Number -->
								<div *ngSwitchCase="'number'" class="number-filter-container d-flex align-items-center justify-content-between">
									<ng-container *ngTemplateOutlet="numberFilterTemplate; context: {column: column}"></ng-container>
								</div>

								<div *ngSwitchCase="'number_link'" class="number-filter-container d-flex align-items-center justify-content-between">
									<ng-container *ngTemplateOutlet="numberFilterTemplate; context: {column: column}"></ng-container>
								</div>

								<!-- Text Filter -->
								<div *ngSwitchDefault>
									<input type="text" [name]="column.key" [id]="column.key"
										(input)="filter(column, $event, {saveFilter: true})" class="filter_field"
										[placeholder]="'g.filter' | translate"
										(keydown.enter)="config.isBackendPagination && doBackEndPaginationPagination()"
										[(ngModel)]="filters[column.key]"
									>
									<!-- (blur)="config.isBackendPagination && doBackEndPaginationPagination()" -->
								</div>
							</div>

						</div>
						<ng-template #noFilter><div class="filter_shadow"></div></ng-template>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let rowData of toShowData" [class]="{'hasRowSpan': config.hasRowSpan}">
					<td [attr.rowspan]="rowData[column.key]?.rowspan || 1" class="data-cell" *ngFor="let column of filterNotExistCell(rowData)" [class]="{'hover-effect': column.type == 'hover', 'small-column': hideFromListTypes.includes(column.type)}" [style.--color]="rowData[column.key]?.backGroundColor || 'transparent'">
						<div class="d-flex justify-content-between align-items-center">
							<div [ngSwitch]="column.type" [class]="{'select_box': column.type == 'select'}">
								<!-- Icon -->
								<div *ngSwitchCase="'icon'">
									<fa-icon class="icon"
										*ngIf="rowData[column.key].icon"
										[style.--color]="rowData[column.key]?.iconColor || '#007bff'"
										[icon]="rowData[column.key].icon" size="lg"
										(click)="rowData[column.key].action?.(rowData)"
									></fa-icon>
									<p *ngIf="!rowData[column.key].icon">-</p>
								</div>

								<!-- Links -->
								<ng-container *ngSwitchCase="'link'">
									<span *ngIf="!rowData[column.key].link">
										{{ format(rowData[column.key].value, column) }}
									</span>
									<a *ngIf="rowData[column.key].link" [routerLink]="rowData[column.key].link" [target]="rowData[column.key].target? rowData[column.key].target : '_self'">
										{{ format(rowData[column.key].value, column) }}
									</a>
								</ng-container>

								<ng-container *ngSwitchCase="'action_link'">
									<span *ngIf="!rowData[column.key].action">
										{{ format(rowData[column.key].value, column) }}
									</span>

									<a *ngIf="rowData[column.key].action" class="pointer" (click)="rowData[column.key].action?.(rowData)">
										{{ format(rowData[column.key].value, column) }}
									</a>
								</ng-container>

								<ng-container *ngSwitchCase="'number_link'">
									<span *ngIf="!rowData[column.key].action && !rowData[column.key].link">
										{{ format(rowData[column.key].value, column) }}
									</span>

									<a *ngIf="rowData[column.key].action || rowData[column.key].link" class="pointer"
										(click)="rowData[column.key].action ? rowData[column.key].action?.(rowData) : null"
										[routerLink]="rowData[column.key].link ? rowData[column.key].link : null"
									>
										{{ format(rowData[column.key].value, column) }}
									</a>
								</ng-container>

								<!-- DropDown -->
								<div *ngSwitchCase="'dropdown'">
									<div class="btn-group mx-3" dropdown placement="left" *ngIf="rowData[column.key]?.options.length">
										<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
										<ul *dropdownMenu class="dropdown-menu" role="menu">
											<li role="menuitem">
												<a
												*ngFor="let option of rowData[column.key]?.options"
												class="dropdown-item pointer"
												(click)="option.action ? option.action?.(rowData) : null"
												[routerLink]="option.link ? option.link : null"
												[queryParams]="option.queryParams ? option.queryParams : null"
												>
												{{option.text}}
												</a>
											</li>
										</ul>
									</div>
								</div>

								<!-- Boolean -->
								<div *ngSwitchCase="'boolean'">{{rowData[column.key].value ? rowData[column.key].trueText || ('g.yes' | translate) : rowData[column.key].falseText || ('g.no' | translate)}}</div>

								<!-- List -->
								<div *ngSwitchCase="'list'">
								<ul [ngClass]="config.separatedCells? 'no-list-style' : ''" *ngIf="rowData[column.key].value.length;else emptyList">
									<li *ngFor="let value of rowData[column.key].value">
										<a *ngIf="value.link; else nolink" [routerLink]="value.link ? value.link : null">{{value.text}}</a>
										<ng-template #nolink> {{value.text}} </ng-template>
									</li>
								</ul>
								</div>

								<div *ngSwitchCase="'hover'" class="pointer" (click)="rowData[column.key].action?.(rowData)">{{rowData[column.key].value}}</div>

								<!-- Select Field -->
								<div *ngSwitchCase="'select'" class="form-check pointer">
									<input class="form-check-input" type="checkbox" [checked]="rowData[column.key].selected" (click)="toggleSelectedRow(rowData.id.value)" [disabled]="rowData.id.disable_select">
								</div>

								<!-- Progress Bar -->
								<div *ngSwitchCase="'progress_bar'" class="form-check pointer">
									<div role="progressbar" aria-valuemin="0" aria-valuemax="100"
										[ngStyle]="{ 'width': rowData[column.key].progress_bar_value+'%'}"
										[ngClass]="{
										'progress-bar progress-bar-striped progress-bar-animated bg-success': rowData[column.key].progress_bar_status == 'success',
										'progress-bar progress-bar-striped bg-danger': rowData[column.key].progress_bar_status == 'danger'
										}"
									>
										<span>{{rowData[column.key].progress_bar_text}}</span>
									</div>
								</div>

								<!-- Another -->
								<div class="data" *ngSwitchDefault>{{isExits(rowData[column.key], 'displayValue') ? rowData[column.key].displayValue : format(rowData[column.key]?.value, column)}}</div>
							</div>

							<div *ngIf="rowData[column.key].iconList">
								<span *ngFor="let icon of rowData[column.key].iconList" (click)="icon.action()">
								<fa-icon *ngIf="icon.name" [icon]="icon.name" size="1x" class="pointer text-primary ms-3 ml-2" [title]="icon.iconTitle"></fa-icon>
								</span>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<!-- front end pagination -->
	<mat-paginator
		*ngIf="data?.length && config.hasPagination && !config.isBackendPagination"
		[length]="filteredData.length"
		[pageSize]="config.pageSize"
		[pageIndex]="currentPage - 1"
		[pageSizeOptions]="config.pageSizeOptions || [config.pageSize || 20]"
		[showFirstLastButtons]="true"
		(page)="onPageChange($event)"
	>

	</mat-paginator>

	<!-- back end pagination -->
	<mat-paginator
		*ngIf="data?.length && config.hasPagination && config.isBackendPagination"
		[length]="totalItemsNum"
		[pageSize]="config.pageSize"
		[pageIndex]="currentPage - 1"
		[pageSizeOptions]="config.pageSizeOptions || [config.pageSize || 20]"
		[showFirstLastButtons]="true"
		(page)="doBackEndPaginationPagination($event)">
	</mat-paginator>
</div>

<ng-template #noData>
	<div class="alert alert-info mt-2">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<!-- column name -->
<ng-template #columnTemplate let-column="column">
	<div [ngSwitch]="column.type" class="d-flex">
		<div *ngSwitchCase="'date_with_ref'" [class]="{'wide': config.draggable}">
			{{ column.name }} ({{commonService.showUserTimeZoneReference()}})
		</div>
		<div *ngSwitchCase="'select'" class="select">
			{{ column.name }}
		</div>
		<div *ngSwitchDefault [class]="{'wide': config.draggable}">{{ column.name }}</div>
		<fa-icon *ngIf="column.hasTooltip" icon="question" [tooltip]="column.hasTooltip? column.hasTooltip: ''" placement="auto" [ngClass]="{'small-icon': true}"></fa-icon>
	</div>
</ng-template>

<!-- Time Filter -->
<ng-template #timeRangeTemplate let-fromName="fromName" let-toName="toName" let-fromId="fromId" let-toId="toId" let-range="range" let-column="column">
	<div class="time-container d-flex justify-content-around">
		<input
			type="date"
			[name]="fromName"
			[id]="fromId"
			placeholder="from"
			[(ngModel)]="range.from"
			(input)="config.isBackendPagination ? null : filter(column, $event, {saveFilter: true})"
			(keydown.enter)="config.isBackendPagination ? doBackEndPaginationPagination() : filter(column, $event, {saveFilter: true})"
			[max]="timeFilter[column.key].to"
			class="form-control"
			[ngStyle]="{'border': formateDateForCompare(range.from) && formateDateForCompare(range.to) && (formateDateForCompare(range.from) > formateDateForCompare(range.to)) ? '1px solid red' : '1px solid #ced4da'}"
		>
		<input
			type="date"
			[name]="toName"
			[id]="toId"
			placeholder="to"
			[(ngModel)]="range.to"
			[min]="timeFilter[column.key].from"
			(input)="config.isBackendPagination ? null : filter(column, $event, {saveFilter: true})"
			(keydown.enter)="config.isBackendPagination ? doBackEndPaginationPagination() : filter(column, $event, {saveFilter: true})"
			class="form-control"
			[ngStyle]="{'border': formateDateForCompare(range.from) && formateDateForCompare(range.to) && (formateDateForCompare(range.from) > formateDateForCompare(range.to)) ? '1px solid red' : '1px solid #ced4da'}"
		>
	</div>
</ng-template>

<!-- Number Filter -->
<ng-template #numberFilterTemplate let-column="column">
	<input type="number" [name]="column.key" [id]="column.key"
		(input)="filter(column, $event, {saveFilter: true})" class="filter_field mr-1"
		[(ngModel)]="numberFilters[column.key].value"
		[placeholder]="'g.filter' | translate"
		(keydown.enter)="config.isBackendPagination && doBackEndPaginationPagination()"
	>
	<fa-icon icon="filter" class="pointer number-filter-icon" size="sm" (click)="toggleNumberFilter(column.key)" [class.active]="numberFilters[column.key].value != null || numberNoValueFilters.includes(numberFilters[column.key].method)"></fa-icon>
	<div class="number-filter hide-columns-popup no_max_length" *ngIf="numberFilters[column.key].showFilter">
		<ng-select
			[items]="numberFilterOptions"
			bindLabel="name"
			bindValue="id"
			name="enterprise_id"
			[(ngModel)]="numberFilters[column.key].method"
			(change)="filter(column, $event, {saveFilter: true})"
			[clearable]="false"
		>
		</ng-select>
		<input type="number" [name]="column.key" [id]="column.key"
			(input)="filter(column, $event, {saveFilter: true})" class="filter_field"
			[placeholder]="(numberFilters[column.key].method == 'in_range' ? 'g.from' : 'g.filter') | translate"
			(keydown.enter)="config.isBackendPagination && doBackEndPaginationPagination()"
			[(ngModel)]="numberFilters[column.key].value"
		>
		<input type="number" [name]="column.key" [id]="column.key"
			*ngIf="numberFilters[column.key].method == 'in_range'"
			(input)="filter(column, $event, {updateTo: true, saveFilter: true})" class="filter_field"
			[placeholder]="'g.to' | translate"
			(keydown.enter)="config.isBackendPagination && doBackEndPaginationPagination()"
			[(ngModel)]="numberFilters[column.key].toValue"
		>
	</div>
	<div class="hide-columns-popup-shadow" *ngIf="numberFilters[column.key].showFilter" (click)="toggleNumberFilter(column.key)"></div>
</ng-template>

<ng-template #emptyList>
	-
</ng-template>
