import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DeviceService } from '../../device.service';
import { UsersService } from 'src/app/users/users.service';
import { ActivatedRoute , Router } from '@angular/router';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-official-report',
	templateUrl: './official-report.component.html',
	styleUrls: ['./official-report.component.css']
})
export class OfficialReportComponent implements OnInit {
	@ViewChild("addDefectivePeriod") addDefectivePeriod: ModalComponent;
	@ViewChild("deleteDefectivePeriod") deleteDefectivePeriod: ModalComponent;
	@Input() config_info: any = {};
	@Input() device: any = {};

	readonly periodLimit = 2;
	tableData: TableData[] = [];
	periodStatusOptions = [
		{id: 1, name: this.translateService.instant('studies.status_done')},
		{id: 2, name: this.translateService.instant('studies.status_not_done')},
	];
	fieldsError = {
		start_date: false,
		end_date: false,
		reason: false
	}
	popupAction: 'add' | 'edit' = 'add';
	toEditPeriod = {id: 0, index: 0};
	toDeletePeriod: any = {index: {value: null}, from_date: {value: null}, to_date: {value: null}};
	periodOptions = { completed: 1, ongoing: 2}
	popupWarning = {viable: false, text: ''};
	selectedPeriodStatus = this.periodStatusOptions[0].id;

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		fileName: this.translateService.instant('devices.defective_periods'),
		hideColumnControl: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'period_status', name: this.translateService.instant('devices.ongoing_period'), type: "string", hasFilter: true, hasSort: true },
		{ key: 'from_date', name: this.translateService.instant('g.start_date'), type: 'date', hasFilter: true, hasSort: true},
		{ key: 'to_date', name: this.translateService.instant('g.end_date'), type: 'date'},
		{ key: 'reason', name: this.translateService.instant('g.reason'), type: "string"},
		{ key: 'edit', name: this.translateService.instant('g.edit'), type: "icon"},
		{ key: 'delete', name: this.translateService.instant('g.delete'), type: "icon"},
	];

	macAddress: string = '';
	included_in_reports: boolean;
	ongoingPeriod: boolean;

	deviceInstallationDate: string = '';
	readonly today: string = moment().utc().format('YYYY-MM-DD');

	defective = {
		startDate: this.today,
		endDate: this.today,
		reason: ''
	};

	defectiveValidationError: any = {
		date: false,
		reason: false
	};

	constructor(
		private deviceService: DeviceService,
		private usersService: UsersService,
		private route: ActivatedRoute,
		private router: Router,
		private sctToastService: NotificationMessageService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessFunction('official_reporting'))
			return this.router.navigate(['/unauthorized']);

		this.route.params.subscribe( params => {
			this.macAddress = params['deviceId'] || 0;
		});
		this.included_in_reports = this.config_info.included_in_reports;

		this.deviceInstallationDate = moment(this.config_info.installation_date * 1000).utc().format('YYYY-MM-DD');
		this.getDefectiveDevice();
	}

	ngAfterViewInit() {
		this.addDefectivePeriod.onClose.subscribe((submit) => {
			const errors = { start_date: false, end_date: false, reason: false };
			if (submit) {
				if (!this.defective.reason)
					errors.reason = true;

				if (!this.defective.startDate)
					errors.start_date = true;

				if (this.selectedPeriodStatus == this.periodOptions.completed && !this.defective.endDate)
					errors.end_date = true;

				if(errors.end_date || errors.start_date || errors.reason) {
					this.fieldsError = errors;
					return this.popupWarning = {viable: true, text: this.translateService.instant('g.fill_required_fields')};
				}

				if (this.selectedPeriodStatus == this.periodOptions.completed && moment(this.defective.startDate).utc().unix() > moment(this.defective.endDate).utc().unix())
					return this.popupWarning = {viable: true, text: this.translateService.instant('g.start_date_before_end_date')};

				if (moment(this.defective.startDate).utc().unix() < moment(this.deviceInstallationDate).utc().unix()) {
					errors.start_date = true;
					return this.popupWarning = {viable: true, text: this.translateService.instant('g.start_date_before_installation_date')};
				}

				if (moment(this.defective.endDate).utc().unix() > moment(this.today).utc().unix()) {
					errors.end_date = true;
					return this.popupWarning = {viable: true, text: this.translateService.instant('g.end_date_after_today')};
				}

				if (this.popupAction == 'add' && this.tableData.length < this.periodLimit) {
					const periodIndex = this.tableData.length;
					let newTableData = [ ...this.tableData , this.fillReasonObject(0, periodIndex + 1) ];

					newTableData = this.sortPeriods(newTableData);
					if (this.validateDefectivePeriod(newTableData)) {
						this.tableData = newTableData;
						this.addDefectivePeriod.hide();
					}

					return;
				}

				else if (this.popupAction == 'edit') {
					let data = [];

					[...this.tableData].forEach(tData => {
						if (tData.index.value != this.toEditPeriod.index)
							return data.push(tData);

						data.push(this.fillReasonObject( this.toEditPeriod.id, this.toEditPeriod.index));
					});

					data = this.sortPeriods(data);
					if (this.validateDefectivePeriod(data)) {
						this.tableData = data;
						this.addDefectivePeriod.hide();
					}

					return;
				}

			}

			this.addDefectivePeriod.hide();
		})


		this.deleteDefectivePeriod.onClose.subscribe((submit) => {
			if (submit)
				this.tableData = this.tableData.filter(data => data.index.value != this.toDeletePeriod.index.value);

			this.deleteDefectivePeriod.hide();
		})
	}

	validateDefectivePeriod(periodObject: TableData[]) {
		const isValid = true;
		this.popupWarning = {viable: false, text: ''};

		if (periodObject.length == 1)
			return isValid;

		for (let periodIndex = 0; periodIndex < periodObject.length - 1; periodIndex++) {
			const currentPeriodStatus = periodObject[periodIndex].period_status.value;
			const nextPeriodStart = moment(periodObject[periodIndex + 1].from_date.value).utc().unix();
			const currentPeriodEnd = moment(periodObject[periodIndex].to_date.value).utc().unix();

			if (currentPeriodEnd >= nextPeriodStart || currentPeriodStatus == this.translateService.instant('studies.status_not_done')) {
				this.popupWarning = {viable: true, text: this.translateService.instant('g.overlapped_period')};
				return false
			}
		}

		return isValid;
	}

	sortPeriods(periodObject: TableData[]) {
		periodObject.sort((a, b) => {
			const dateA = moment(a.from_date.value, 'YYYY-MM-DD');
			const dateB = moment(b.from_date.value, 'YYYY-MM-DD');

			if (dateA.isBefore(dateB)) return -1;
			if (dateA.isAfter(dateB)) return 1;
			return 0;
		});

		return periodObject;
	}

	fillReasonObject(id, index) {
		const isCompleted = this.selectedPeriodStatus == this.periodOptions.completed;

		return {
			id: {value: id},
			index: {value: index},
			period_status: {value: isCompleted ? this.translateService.instant('studies.status_done') : this.translateService.instant('studies.status_not_done')},
			from_date: {value: moment(this.defective.startDate).format('YYYY-MM-DD')},
			to_date: {value: isCompleted ? moment(this.defective.endDate).format('YYYY-MM-DD') : null},
			reason: {value: this.defective.reason},
			edit: {icon: 'edit', iconColor: 'green', action: (data: any)=> this.openEditPeriodPopup(data)},
			delete: {icon: 'trash', iconColor: 'red', action: (data: any)=> this.deletePeriod(index)}
		}
	}

	openEditPeriodPopup(data) {
		const isCompleted = data.period_status.value == this.translateService.instant('studies.status_done');
		this.defective = {
			endDate: isCompleted ? data.to_date.value : this.defective.endDate,
			startDate: data.from_date.value,
			reason: data.reason.value
		}

		this.selectedPeriodStatus = isCompleted ? this.periodOptions.completed : this.periodOptions.ongoing;
		this.toEditPeriod = {id: data.id.value, index: data.index.value};
		this.updatePeriodStatus();

		this.popupAction = 'edit';
		this.popupWarning = {viable: false, text: ''};
		this.fieldsError = { start_date: false, end_date: false, reason: false };
		this.addDefectivePeriod.show();
	}

	deletePeriod(index) {
		this.toDeletePeriod = this.tableData.filter(data => data.index.value == index)[0];

		if (this.toDeletePeriod)
			this.deleteDefectivePeriod.show();
	}

	saveData() {
		const defectiveDevice = [];
		this.tableData.forEach((period) => {
			defectiveDevice.push({
				id: period.id.value,
				start_date: period.from_date.value,
				end_date: period.to_date.value,
				reason: period.reason.value,
			})
		})

		const data = {
			included_in_reports: this.included_in_reports,
			defective_device: defectiveDevice,
		}

		this.deviceService.officialReporting(this.macAddress, data).subscribe((response: any) => {
			let msg = '';
			switch (response.status) {
				case 1:
					this.device.included_in_reports = !!this.included_in_reports;
					this.deviceService.updateDeviceConfig({included_in_reports : !!this.included_in_reports})
					this.defectiveValidationError = {};
					this.getDefectiveDevice();
					msg = 'globalSuccessMsg';
				break;
				default:
					msg = 'globalErrMsg';
					break;
			}
			return this.sctToastService.setMessage(msg);
		})
	}

	getDefectiveDevice() {
		this.deviceService.getDefectiveDevice(this.macAddress).subscribe((response: any) => {
			let data = [];

			if (!response.length)
				return;

			response.forEach((period) => {
				this.defective.startDate = moment.unix(period.start_date).utc().format('YYYY-MM-DD');
				this.defective.endDate = period.end_date ? moment.unix(period.end_date).utc().format('YYYY-MM-DD') : null;
				this.selectedPeriodStatus = this.defective.endDate ? this.periodOptions.completed : this.periodOptions.ongoing;
				this.defective.reason = period.reason;

				if (data.length < this.periodLimit)
					data.push(this.fillReasonObject(period.id, period.id));
			});

			data = this.sortPeriods(data);
			this.tableData = data;
		});
	}

	showDefectivePeriodPopup(event: Event) {
		event.preventDefault();
		this.popupAction = 'add';
		this.defective = {
			startDate: this.today,
			endDate: this.today,
			reason: ''
		};

		this.ongoingPeriod = false;
		this.selectedPeriodStatus = this.periodOptions.completed;

		this.popupWarning = {viable: false, text: ''};
		this.fieldsError = { start_date: false, end_date: false, reason: false };
		this.addDefectivePeriod.show();
	}

	updatePeriodStatus() {
		this.ongoingPeriod = this.selectedPeriodStatus == this.periodOptions.ongoing;
	}
}
