<div [ngClass]="{'mat-elevation-z8': withShadow}">
	<div class="text-right p-1">
		<button *ngIf="showExport" class="fa fa-upload btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="exportCsv()"></button>
	</div>

	<mat-table #table [dataSource]="dataSource" [ngClass]="{'table-lg': size == 'lg', 'table-md': size == 'md'}">
		<ng-container matColumnDef="select" *ngIf="hasCheckboxColumn">
			<mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? toggleSelection(true) : null"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? toggleSelection(false, row) : null"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</mat-cell>
		</ng-container>

		<div *ngFor="let column of columnNames">
			<ng-container [matColumnDef]="column.key">
				<mat-header-cell *matHeaderCellDef [ngClass]="{'text-center': column.type == 'options'}">{{column.name}}</mat-header-cell>
				<mat-cell *matCellDef="let element">
					<ng-container *ngTemplateOutlet="dataCell; context:{element: element, column: column}"></ng-container>
				</mat-cell>
			</ng-container>
		</div>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>

	<mat-paginator *ngIf="pagination"
					#paginator
					[pageSize]="pageSize"
					[pageSizeOptions]="pageSizeOptions"
					[showFirstLastButtons]="showFirstLastButtons"
					(page)="clearSelection()">
	</mat-paginator>
</div>

<ng-template #dataCell let-element="element" let-column="column">
	<div [ngSwitch]="column.type" [ngClass]="{'text-center': column.type == 'options'}">
		<span *ngSwitchCase="'dateTime'">{{element[column.key] | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
		<span *ngSwitchCase="'json'">{{element[column.key] | json}}</span>
		<span *ngSwitchCase="'options'">
			<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="optionsMenu" 
				[matMenuTriggerData]="{element: element, options: column.optionsList}">
				settings
			</mat-icon>
		</span>
		<span *ngSwitchCase="'truncate'">{{element[column.key] | slice:0:column.maxLength}}{{element[column.key].length > column.maxLength ? '...' : ''}}</span>
		<span *ngSwitchCase="'link'"><a [routerLink]="element[column.linkId]">{{element[column.key]}}</a></span>
		<span *ngSwitchDefault>{{element[column.key]}}</span>
	</div>
</ng-template>

<mat-menu #optionsMenu="matMenu" [overlapTrigger]="false">
	<ng-template matMenuContent let-options="options" let-element="element">
		<button *ngFor="let option of options"
			mat-menu-item
			(click)="emitAction(option.action, element)">
			<span>{{option.text}}</span>
		</button>
	</ng-template>
</mat-menu>