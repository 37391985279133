<div *ngIf="confirmed" class="row justify-content-center">
	<div class="col-10 mt-5">
		<div>
			<div class="row">
				<h4>{{'g.thank_you' | translate}}</h4>
			</div>
			<div class="row">
				<p class="h6">{{'login_register.email_has_been_confirmed' | translate}}</p>
			</div>
		</div>
	</div>
</div>