import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SitesService } from './sites.service';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

import * as _ from 'underscore';
import { UsersService } from '../users/users.service';
import { CommonService } from '../shared/services/common.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-sites',
	templateUrl: './sites.component.html',
	styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit, AfterViewInit {
	@ViewChild("deleteModal") deleteModal;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'name', name: this.translate.instant('g.name'), type: "string"},
		{ key: 'enterprise_name', name: this.translate.instant('g.enterprise_name'), type: 'string'},
		{ key: 'country', name: this.translate.instant('site_edit.country'), type: "link"},
		{ key: 'state', name: this.translate.instant('site_edit.state'), type: 'link'},
		{ key: 'address', name: this.translate.instant('site_edit.address'), type: "string" },
		{ key: 'modification_date', name: this.translate.instant('site.modification_date'), type: "timestamp" },
		{ key: 'actions', name: this.translate.instant('g.actions'), type: "dropdown" },
	];

	customerId = 0;
	sites = <any>[];
	currentUser: any = {};
	confirmedField: string = '';
	siteInfoById = {};

	constructor(
		public sitesService: SitesService,
		private router: Router,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private translate: TranslateService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if (!this.currentUser.isSCT || !this.usersService.hasAccessFunction('site_management'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		this.getCustomerSites();
	}

	ngAfterViewInit() {
		this.deleteModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.deleteSite(result[1]);
		});
	}

	getCustomerSites() {
		this.route.params.subscribe(
			params => {
				this.customerId = Number(params['customerId']) || 0;
				if (this.customerId) {
					this.sitesService.getCustomerSites(this.customerId, { get_enterprise_list: true, check_has_studies: true }).subscribe(
						(data: any) => {
							this.sites = _.sortBy(_.values(data['site']), 'name');
							this.sites = this.commonService.sortDataAlphabetically(this.sites, 'name');

							const tableData = [];
							const hasSiteManagementAccess = this.usersService.hasAccessFunction('site_management');
							const hasDeviceManagementAccess = this.usersService.hasAccessFunction('device_management');

							for(const site of this.sites) {
								const options = [];
								if (hasSiteManagementAccess)
									options.push({text: this.translate.instant('g.edit'), link: this.customerId ? ['/sites', this.customerId, 'edit', site.id] : null});

								if (this.canDeleteSite(site))
									options.push({text: this.translate.instant('g.delete'), action: ()=> {this.deleteModal.passData = site.id; this.deleteModal.show();}});

								if (hasDeviceManagementAccess)
									options.push({text: this.translate.instant('g.devices'), link: ['/devices', this.customerId, site.id]});

								for (const permission of site.site_enterprise) {
									options.push({
										text: `${this.translate.instant('site.permission')} (${permission.enterprise?.name})`,
										link: ['/admin-panel/permission-edit', permission.permission_id],
										queryParams: { customerId: this.customerId }
									});
								}

								tableData.push({
									name: {value: site.name},
									enterprise_name: {value: this.getEnterpriseNameList(site.site_enterprise)},
									country: {value: site.country_id},
									state: {value: site.state_id},
									address: {value: site.address},
									modification_date: {value: site.modification_date},
									actions: {value: null, options: options}
								})
							}

							this.tableData = tableData;
						},
					);
				}
			}
		);

	}

	deleteSite(id) {
		this.sitesService.deleteSite(id).subscribe(
			(data: any) => {
				if(data) {
					switch (data.api_status) {
						case 2:
							this.notificationMessage.setMessage('translate|site.delete_site_with_devices', { clearOnXTimeNavigate: 1 });
							break;
						case 3:
							this.notificationMessage.setMessage('translate|site.delete_site_with_studies', { clearOnXTimeNavigate: 1 });
							break;
						default:
							this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
							this.getCustomerSites();
							break;
					}
				} else {
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
				}
			}
		);
	}

	getEnterpriseNameList(enterpriseList) {
		if (!enterpriseList) return;
		let names = [];

		enterpriseList.forEach(item => {
			if (item['enterprise']) {
				names.push(item['enterprise']['name']);
			}
		});

		return names.join(', ');
	}

	canDeleteSite(site: any) {
		return site.id != this.sitesService.specialFolderId
			&& site.id != this.sitesService.sctInventorySiteId
			&& !site.is_special
			&& this.usersService.hasAccessFunction('site_management');
	}

}
