import { Component, OnInit, ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from 'underscore';
import { SitesService } from '../sites.service';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
  selector: 'app-site-edit-page',
  templateUrl: './site-edit-page.component.html',
  styleUrls: ['./site-edit-page.component.css']
})
export class SiteEditPageComponent implements OnInit {

	enterpriseList = <any>[];
	customerEnterprises = <any>[];
	enterprise_ids = [];
	customerId = 0;
	site: any = {
		id: 0,
		siteid: 0,
		customerid: 0,
		enterprise_ids: []
	}
	map: any = {
		latitude: this.sitesService.defaultMapLocation.latitude,
		longitude: this.sitesService.defaultMapLocation.longitude
	};
	mapZoom = 3;
	isSiteLoaded = false;
	customerInfo: any;
	currentUser: any;

	constructor(
		private chRef: ChangeDetectorRef,
		private route: ActivatedRoute,
		private sitesService: SitesService,
		private router: Router,
		private enterpriseService: EnterpriseService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private navigation: NavigationService,
		private el: ElementRef,
		private renderer: Renderer2,
		private usersService: UsersService
	) {}

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if (!this.currentUser.isSCT || !this.usersService.hasAccessFunction('site_management'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		this.route.params.subscribe(
			params => {
				this.site.id = Number(params['siteId']) || 0;
				this.customerId = Number(params['customerId']) || 0;

				if (this.site.id) {
					this.getSite();
				} else {
					this.getCustomerInfo();
					this.site['installation_date'] = new Date(new Date().setHours(0, 0, 0, 0));
					this.site['is_smart_rebates'] = true;
					this.map.latitude = this.sitesService.defaultMapLocation.latitude;
					this.map.longitude = this.sitesService.defaultMapLocation.longitude;
					this.isSiteLoaded = true;
				}
			}
		);
	}


	getSite() {
		this.sitesService.getSite(this.site.id, {get_enterprise_list: true}).subscribe(
			data => {
				if(!data['site'].length)
					return this.goBack();

				this.site = data['site'][0];
				this.customerInfo = data['customerInfo'][0];
				this.map['latitude'] = this.site['latitude'];
				this.map['longitude'] = this.site['longitude'];
				this.mapZoom = 15;
				this.site['enterprise_ids'] = _.pluck(this.site['site_enterprise'], "enterprise_id");
				this.site['installation_date'] = new Date(new Date(this.site['installation_date']).setHours(0, 0, 0, 0));
				this.handleSiteEnterprises(data['enterprise_list']);
				//Only keep usable enterprises
				let enterpriseIdsList = _.pluck(this.enterpriseList, 'id');
				this.site['enterprise_ids'] = _.intersection(this.site['enterprise_ids'], enterpriseIdsList);
				this.isSiteLoaded = true;
			}
		);
	}

	getEnterpriseList() {
		 this.enterpriseService.getEnterprises({site_edit: true, source: 'site_edit'}).subscribe((enterpriseData: any)=> {
			this.handleSiteEnterprises(enterpriseData);
		});
	}

	handleSiteEnterprises(enterpriseData) {
		if (this.customerInfo.customer_enterprises) {
			this.customerEnterprises = enterpriseData.filter((item) => this.customerInfo.customer_enterprises.includes(item.id));
			this.enterpriseList = enterpriseData.filter((item) => !this.customerInfo.customer_enterprises.includes(item.id));
			this.site.customer_enterprises = this.customerInfo.customer_enterprises || [];
		} else {
			this.enterpriseList = enterpriseData;
		}
	}

	getCustomerInfo() {
		this.sitesService.getCustomerSites(this.customerId, {get_customers: true}).subscribe((response: any) => {
			this.customerInfo = response.customerInfo[0] || {};
			this.customerEnterprises = this.customerInfo.customer_enterprises;
			this.getEnterpriseList();
		});
	}

	saveSite() {
		let tempSite = Object.assign({}, this.site);
		let insDate = new Date(tempSite['installation_date']);
		tempSite['installation_date'] = Math.floor((-insDate.getTimezoneOffset() * 60000 + insDate.getTime()) / 1000);
		tempSite['customer_id'] = this.customerId;
		tempSite.enterprise_ids = [...tempSite.enterprise_ids, ...(tempSite.customer_enterprises || [])];
		delete tempSite.customer_enterprises;
		if (!tempSite['customer_id']) {
			return;
		}

		this.sitesService.saveSite(tempSite).subscribe((data: any) => {
			if (data) {
				if(data.api_status) {
					let msg = 'globalErrMsg';

					switch (data.api_status) {
						case 4:
							msg = 'translate|site.rename_special_site_err';
							break;
						case 5:
						case 6:
							const form = this.el.nativeElement;
							const ele = form.querySelectorAll('[name=name]');
							if (ele && ele[0])
								this.renderer.addClass(ele[0], 'invalid-input');

							msg = 'translate|site.reserved_site_name';
							if (data.api_status == 6)
								msg = 'translate|site.site_name_cannot_changed';

							break;
						case 7:
							msg = 'translate|site.site_name_already_exist';
						default:
							break;
					}
					this.notificationMessage.setMessage(msg, { clearOnXTimeNavigate: 1 });
				} else {
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.goBack();
				}
			} else {
				this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			}
		});
	}

	updateSiteCoords(coords) {
		this.site['latitude'] = coords['lat'];
		this.site['longitude'] = coords['lng'];
		this.site['address'] = coords['address'];
		this.site['country_id'] = coords['country'];
		this.site['state_id'] = coords['state'];
		this.chRef.detectChanges();
	}

	goBack() {
		this.navigation.goBack('/');
	}
}
