import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomersService } from 'src/app/customers/customers.service';
import { ActivatedRoute, Router } from "@angular/router";
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import * as _ from 'underscore';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { SitesService } from 'src/app/sites/sites.service';
import * as moment from 'moment';
import { TableConfig } from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-customer-info',
	templateUrl: './customer-info.component.html',
	styleUrls: ['./customer-info.component.css']
})
export class CustomerInfoComponent implements OnInit, OnDestroy {

	customerId: number = 0;
	customer: any = {};
	dataSub: any;
	sites = [];
	devices = [];
	sitesById: any = {};
	sitesPerformanceData;
	allDevicesCount = 0;
	infoBoxSiteAnalytics: any;
	siteMapClickedSiteSub: any = {};
	quarterlyReportCols: any[] = [];
	monthlyReportCols: any[] = [];
	quarterData: any = [];
	monthData: any = [];
	quartersList: any[] = [];
	yearList: any[] = [];
	monthList: any[] = [];
	selectedQuarterData: any[];
	selectedMonthData: any[];
	quarterReportFilters: any = {
		quarter: '',
		site: []
	};
	monthReportFilters: any = {
		year: '',
		month: '',
		site: []
	};
	tooltips = {
		customer_contact_info: '',
		quarterly_customer_report: '',
		monthly_customer_report: '',
		customer_notes: ''
	};
	currentMonth = moment().format('MMMM');
	currentYear = moment().format('YYYY');
	maintenanceReport: any = {};
	maintenanceConfig: any = {};
	siteDeviceMaintenance: any = {};
	hasMaintenanceAccess: boolean = false;
	currentUser: any = {};
	chargLinkDevicesMacAddresses = [];
	chargLinkDevices = [];
	iotahDevices = [];
	isChargLinkDomain: boolean = false;

	trucksReportConfig: any = {
		table: {
			hasExport: true,
			hasPagination: false,
			fileName: '',
			draggable: true,
		},
		columns: [
			{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link" },
			{ key: 'site_address', name: this.translateService.instant('site_edit.address'), type: 'string' },
			{ key: 'trucks_count', name: this.translateService.instant('site.number_of_trucks'), type: 'number' },
			{ key: 'total_ahr', name: this.translateService.instant('site.ioTAh_devices.total_ahr'), type: "number"},
			{ key: 'total_kwhr', name: this.translateService.instant('site.ioTAh_devices.total_trucks_kwhr'), type: "number" },
		],
		data: []
	}

	chargersReportConfig: any = {
		table: {
			hasExport: true,
			hasPagination: false,
			fileName: '',
			draggable: true,
		},
		columns: [
			{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link" },
			{ key: 'site_address', name: this.translateService.instant('site_edit.address'), type: 'string' },
			{ key: 'chargers_count', name: this.translateService.instant('site.number_of_charger'), type: 'number' },
			{ key: 'chargers_total_ahr', name: this.translateService.instant('site.chargLink_devices.total_ahr'), type: "number"},
			{ key: 'chargers_total_kwhr', name: this.translateService.instant('site.chargLink_devices.total_trucks_kwhr'), type: "number" },
		],
		data: []
	}

	tableConfigChargersReport: TableConfig = {
		hasExport: true,
		hasPagination: false,
		fileName: '',
		draggable: true,
	};

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private customersService: CustomersService,
		private sideMenuService: SideMenuService,
		public userService: UsersService,
		public commonService: CommonService,
		private translateService: TranslateService,
		public sitesService: SitesService
	) {}

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.isChargLinkDomain = this.commonService.isChargeLink();

		for(let key in this.tooltips) {
			this.tooltips[key] = this.commonService.getTooltipKey(key, this.userService.userHasNOCAccess());
		}

		this.sideMenuService.setRouteHasSiteInfoBox(true);
		this.hasMaintenanceAccess = this.userService.hasAccessPermission({}, 'last_pm_date');

		this.reset();

		let customerId = parseInt(this.route.snapshot.params.customerId);
		if (customerId) {
			this.customerId = customerId;
			this.getCustomer(customerId);

			this.quarterlyReportCols = [
				{
					headerName: "g.site_name", field: "site_name",
					cellRendererSelector:function (params) {
						params['label'] = params.data.site_name;
						params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.data.site_id].join('/');
						return {
							component: 'linkCellRenderer',
							params: params
						};
					},
					cellRendererParams: {customerId: customerId},
				},
				{headerName: "site_edit.address", field: "site_address"},
				{headerName: "site.number_of_charger", field: "chargers_count"},
				{headerName: "site.chargLink_devices.total_ahr", field: "chargers_total_ahr", type: 'number', filterParams: {format: '1.0-2'}},
				{headerName: "site.chargLink_devices.total_trucks_kwhr", field: "chargers_total_kwhr", type: 'number', filterParams: {format: '1.0-2'}}

			];

			this.monthlyReportCols = [
				{
					headerName: "g.site_name", field: "site_name",
					cellRendererSelector:function (params) {
						params['label'] = params.data.site_name;
						params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.data.site_id].join('/');
						return {
							component: 'linkCellRenderer',
							params: params
						};
					},
					cellRendererParams: {customerId: customerId},
				},
				{headerName: "site_edit.address", field: "site_address"},
				{headerName: "site.number_of_trucks", field: "trucks_count"},
				{headerName: "site.ioTAh_devices.total_ahr", field: "total_ahr", type: 'number', filterParams: {format: '1.0-2'}},
				{headerName: "site.ioTAh_devices.total_trucks_kwhr", field: "total_kwhr", type: 'number', filterParams: {format: '1.0-2'}},
			];
		}

		this.siteMapClickedSiteSub = this.sideMenuService.sitesMapClickedSite.subscribe((data: any) => {
			if (data && data.id) {
				this.sideMenuService.setSiteInfoBoxVisibility(true);
			}
		});

	}

	prepareQuarterlyReport() {
		if(!this.customer.sitesAnalytics)
			return;

		this.quarterData = [];
		const sitesAnalytics = this.customer.sitesAnalytics;
		this.quarterData = sitesAnalytics.usageData.qReport;
		if(!Object.keys(this.quarterData).length)
			return;

		this.monthData = this.quarterData.iotah;
		this.quarterData = this.quarterData.chargLink;

		let sorterQ = _.sortBy(Object.keys(this.quarterData)).reverse();
		for(let q of sorterQ) {
			this.quartersList.push({
				id: q,
				label: q.replace('_', ' ')
			});
		}
		if(sorterQ.length) {
			this.quarterReportFilters.quarter = sorterQ[0];
			this.loadQuarterData();
		}

		let sorterY = _.sortBy(Object.keys(this.monthData)).reverse();
		for(let y of sorterY) {
			this.yearList.push({
				id: y,
				label: y
			});
		}

		if(this.yearList.length) {
			this.monthReportFilters.year = this.yearList[0].id;

			this.setMonthList();
			if(this.monthList.length) {
				this.loadMonthData();
			}
		}
	}

	setMonthList() {
		let sorterM = _.sortBy(Object.keys(this.monthData[this.monthReportFilters.year]).map(key => +key));
		this.monthList = [];
		for(let m of sorterM) {
			this.monthList.push({
				id: m,
				label: moment(m, 'MM').format('MMMM')
			});
		}
		this.monthReportFilters.month = this.monthReportFilters.year == this.currentYear ? (this.monthList.filter(month => month.label == this.currentMonth))[0].id : this.monthList[0].id;
	}

	getCustomer(customerId) {
		this.customersService.getCustomer(customerId, { get_devices_counts: true, check_has_studies: true }).subscribe(
			(data: any) => {
				if(data == 'not_found')
					return this.router.navigateByUrl('/404');

				this.customer = data;
				for (const siteId in data.sites_devices_counts) {
					this.allDevicesCount += +data.sites_devices_counts[siteId];
				}

				if(this.customer.devices) {
					this.devices = this.customer.devices;
					this.chargLinkDevices = [];
					this.iotahDevices = [];
					this.devices.forEach(device => {
						if (device.is_charglink) {
							this.chargLinkDevices.push(device);
							this.chargLinkDevicesMacAddresses.push(device.mac_address);
						} else {
							this.iotahDevices.push(device);
						}
					})
				}

				if(this.customer.sitesAnalytics) {
					this.sitesPerformanceData = this.customer.sitesAnalytics.usageData;
					this.aggregateSitesAnalytics(this.customer.sitesAnalytics);
				}

				this.dataSub = this.sideMenuService.data.subscribe((d:any)=> {
					if(d.length !=0) {
						const customers = _.indexBy(d, "id");
						this.sideMenuService.setCurrentCustomer(customers[customerId]);
						let customer = customers[customerId];
						this.sites = customer["sites"];
						this.sitesById = _.indexBy(this.sites, 'id');
						this.prepareQuarterlyReport();
					}
				});

				this.trucksReportConfig.table.fileName = this.translateService.instant('site.monthly_customer_report', { customer: this.customer.customer_name });
				this.chargersReportConfig.table.fileName = this.translateService.instant('site.quarterly_customer_report', { customer: this.customer.customer_name });
			}
		);
	}

	getDevicesPerformanceData(sitesAnalytics) {
		let sitesData:any = Object.values(sitesAnalytics);
		let result = [];
		for(let i in sitesData)
			result.push(...sitesData[i]);

		return result;
	}

	reset() {
		this.sideMenuService.setSitesMapClickedSite({});
		this.sideMenuService.setCurrentSite({});
	}

	ngOnDestroy() {
		if(this.dataSub)
			this.dataSub.unsubscribe();
		this.reset();
		this.siteMapClickedSiteSub.unsubscribe();
		this.sideMenuService.setRouteHasSiteInfoBox(false);
	}

	loadQuarterData() {
		this.selectedQuarterData = _.filter(this.quarterData[this.quarterReportFilters.quarter], (site) => {
			return !this.quarterReportFilters.site.length || this.quarterReportFilters.site.includes(+site.site_id);
		});

		const tableData = [];
		for (const row of this.selectedQuarterData) {
			tableData.push({
				site_name: { value: row.site_name, link: ['/', this.customerId, row.site_id] },
				site_address: { value: row.site_address },
				chargers_count: { value: row.chargers_count },
				chargers_total_ahr: { value: +parseFloat(row.chargers_total_ahr.toFixed(2)).toString() },
				chargers_total_kwhr: { value: +parseFloat(row.chargers_total_kwhr.toFixed(2)).toString() },
			});
		}
		this.chargersReportConfig.data = tableData;
	}

	loadMonthData(fromHtml=false) {
		if(fromHtml)
			this.setMonthList();
		this.selectedMonthData = _.filter(this.monthData[this.monthReportFilters.year][this.monthReportFilters.month], (site) => {
			return !this.monthReportFilters.site.length || this.monthReportFilters.site.includes(+site.site_id);
		});

		const tableData = [];
		for (const row of this.selectedMonthData) {
			tableData.push({
				site_name: { value: row.site_name, link: ['/', this.customerId, row.site_id] },
				site_address: { value: row.site_address },
				trucks_count: { value: row.trucks_count },
				total_ahr: { value: +parseFloat(row.total_ahr.toFixed(2)).toString() },
				total_kwhr: { value: +parseFloat(row.total_kwhr.toFixed(2)).toString() },
			});
		}
		this.trucksReportConfig.data = tableData;
	}

	private aggregateSitesAnalytics(sitesAnalytics) {
		this.infoBoxSiteAnalytics = {
			ioTAhDevices: sitesAnalytics.infoBox.since_installation.ioTAh_devices,
			chargLinkDevices: sitesAnalytics.infoBox.since_installation.chargLink_devices,
		}
	}

	exportQuarterData() {
		this.commonService.exportQuartersData(
			this.chargersReportConfig.columns,
			this.quarterData,
			this.translateService.instant('site.quarterly_customer_report', {customer: this.customer.customer_name})
		);
	}

	exportMonthData() {
		this.commonService.exportMonthsData(
			this.trucksReportConfig.columns,
			this.monthData,
			this.translateService.instant('site.monthly_customer_report', {customer: this.customer.customer_name})
		);
	}

	shouldShowStudiesSection() {
		return !this.isChargLinkDomain && this.currentUser.has_access_to_any_study && this.customer.has_studies
	}
}
