import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import { DecimalPipe } from '@angular/common';

import * as moment from 'moment';

import * as _ from 'underscore';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceService } from '../device/device.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-maintenance-report',
	templateUrl: './maintenance-report.component.html',
	styleUrls: ['./maintenance-report.component.css']
})
export class MaintenanceReportComponent implements OnInit, OnDestroy {
	@ViewChild("lastPMModal") lastPMModal;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: false,
		hasSelectionColumn: true,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'serial_number', name: this.translateService.instant('g.iotah_sn'), type: "link"},
		{ key: 'truck_sn', name: this.translateService.instant('devices.truck_sn_short'), type: "string"},
		{ key: 'report_ahr', name: this.translateService.instant('maintenance_schedule.report_ahr'), type: 'number'},
		{ key: 'report_kwhr', name: this.translateService.instant('maintenance_schedule.report_kwhr'), type: 'number'},
		{ key: 'report_usage_hours', name: this.translateService.instant('maintenance_schedule.report_usage_hours'), type: 'number'},
		{ key: 'report_period', name: this.translateService.instant('maintenance_schedule.report_period'), type: 'number'},
	];

	colorMapper: {
		red:'#ffcccb',
		orange : '#ffe6cb',
		green: '#d2ffcb',
	}

	hasIotahDevices = false;
	hideSetMaintenanceDateButton: boolean = true;
	siteId: Number = 0;
	customerId: any;
	currentSite: any = {};
	siteNameClean: string;
	permissions: any = {};
	data: any = {};

	devices: any = {};
	selectedDevices: any[] = [];

	subSite: Subscription = new Subscription();
	permissionSub: Subscription = new Subscription();
	siteSubscription: Subscription = new Subscription();

	lastPMModalVars: any = {};

	constructor(
		private sideMenuService: SideMenuService,
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private SitesService: SitesService,
		private commonService: CommonService,
		private notificationMessage: NotificationMessageService,
		private deviceService: DeviceService,
		private translateService: TranslateService,
	) { }

	ngOnInit(): void {
		this.subSite = this.sideMenuService.currentSite.subscribe((data: any) => {
			if (!data || !data['id'])
				return;
			let siteId = Number(this.route.parent.snapshot.params['siteId']);
			let customerId = Number(this.route.parent.snapshot.params['customerId']);

			if (data.id != siteId || data['customer_id'] != customerId)
				return;

			this.siteId = siteId;
			this.customerId = customerId;

			this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
				// this.enterprisePermissions = permissions;
				this.permissions = permissions.permission;

				if(!this.usersService.hasAccessPermission(permissions, 'basic_setup')) {
					return this.router.navigate(['/unauthorized']);
				}
			});
			this.getReport();
		});

		this.siteSubscription = this.sideMenuService.currentSite.subscribe((siteInfo: any) => {
			if(!siteInfo.id)
				return;

			this.siteNameClean = this.commonService.getCleanFileName(siteInfo.name);

			this.currentSite = siteInfo;
		});
	}

	ngOnDestroy() {
		this.subSite.unsubscribe();
		this.permissionSub.unsubscribe();
		this.siteSubscription.unsubscribe();
	}

	getReport() {
		this.SitesService.getMaintenanceReport(this.siteId).subscribe((response: any) => {
			this.data = response;
			// sort data by date then value

			for (let device of response.devices) {
				if (!device.is_charglink) {
					this.hideSetMaintenanceDateButton = false;
					this.hasIotahDevices = true;
					break;
				}
			}

			if(!this.hasIotahDevices)
				return this.router.navigate(['/404']);

			let reportData = [];
			for(let mac in response.data) {
				reportData.push({...response.data[mac], ...{mac_address: mac}});
			}

			reportData = _.chain(reportData).sortBy((item) => {
				return item['0'];
			}).sortBy((item) => {
				return item['1'];
			}).sortBy((item) => {
				return item['2'];
			}).sortBy((item) => {
				return item['3'];
			}).value();

			this.devices = _.indexBy(response.devices, 'mac_address');
			let maintenanceConfig = _.indexBy(response.maintenanceConfig, 'mac_address');
			const reports = this.formatReport(reportData, maintenanceConfig);
			const tableData = [];

			for(const report of reports) {
				tableData.push({
					id: {value: report.mac_address},
					serial_number: {value: report.serial_number, link: report._device_link},
					truck_sn: {value: report.truck_sn},
					report_ahr: {
						value: report.report_ahr,
						backGroundColor: report.ahr_color && this.colorMapper[report.ahr_color] ? this.colorMapper[report.ahr_color] : ''
					},
					report_kwhr: {
						value: report.report_kwhr,
						backGroundColor: report.kwhr_color && this.colorMapper[report.kwhr_color] ? this.colorMapper[report.kwhr_color] : ''
					},
					report_usage_hours: {
						value: report.report_usage_hours,
						backGroundColor: report.usage_days_color && this.colorMapper[report.usage_days_color] ? this.colorMapper[report.usage_days_color] : ''
					},
					report_period: {
						value: report.report_period,
						backGroundColor: report.left_days_color && this.colorMapper[report.left_days_color] ? this.colorMapper[report.left_days_color] : ''
					},
				})
			}

			this.tableData = tableData;
		});
	}

	formatReport(reportData, maintenanceConfig) {
		let data = [];
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
		for(let record of reportData) {
			let mac_address = record.mac_address;
			record = this.commonService.decompress(record, 'siteMaintenanceReport');
			let tempRecord = JSON.parse(JSON.stringify(record));
			tempRecord.report_ahr			= 'N/A';
			tempRecord.report_kwhr			= 'N/A';
			tempRecord.report_usage_hours	= 'N/A';
			tempRecord.report_period		= 'N/A';
			tempRecord.ahr_color			= '';
			tempRecord.kwhr_color			= '';
			tempRecord.left_days_color		= '';
			tempRecord.usage_days_color		= '';

			if(maintenanceConfig[mac_address]) {
				if(maintenanceConfig[mac_address].report_ahr_opt) {
					let unit = " AHrs";
					if(tempRecord.left_ahrs < 0) {
						unit += ' exceeded';
						tempRecord.ahr_color = 'red';
					} else {
						tempRecord.ahr_color = (tempRecord.left_ahrs / maintenanceConfig[mac_address].report_ahr) < 0.2 ? 'orange' : 'green';
					}
					tempRecord.report_ahr	= decimalPipe.transform(Math.abs(tempRecord.left_ahrs), '1.0-2') + unit;
				}
				if(maintenanceConfig[mac_address].report_kwhr_opt) {
					let unit = " KWHrs";
					if(tempRecord.left_kwhrs < 0) {
						unit += ' exceeded';
						tempRecord.kwhr_color = 'red';
					} else {
						tempRecord.kwhr_color = (tempRecord.left_kwhrs / maintenanceConfig[mac_address].report_kwhr) < 0.2 ? 'orange' : 'green';
					}
					tempRecord.report_kwhr	= decimalPipe.transform(Math.abs(tempRecord.left_kwhrs), '1.0-2') + unit;
				}
				if(maintenanceConfig[mac_address].report_usage_hours_opt) {
					let unit = " hours";
					if(tempRecord.left_truck_usage_hours < 0) {
						unit += ' exceeded';
						tempRecord.usage_days_color = 'red';
					} else {
						tempRecord.usage_days_color = ((tempRecord.left_truck_usage_hours/24) / (maintenanceConfig[mac_address].report_usage_hours/24)) < 0.2 ? 'orange' : 'green';
					}
					tempRecord.report_usage_hours	= Math.round(Math.abs(tempRecord.left_truck_usage_hours)) + unit;
				}
				if(maintenanceConfig[mac_address].report_period_opt) {
					let unit = " days";
					if(tempRecord.left_time_period_days < 0) {
						unit += ' exceeded';
						tempRecord.left_days_color = 'red';
					} else {
						tempRecord.left_days_color = (tempRecord.left_time_period_days / maintenanceConfig[mac_address].report_period) < 0.2 ? 'orange' : 'green';
					}
					tempRecord.report_period	= Math.round(Math.abs(tempRecord.left_time_period_days)) + unit;
				}
			}

			let device = this.devices[mac_address];
			tempRecord.mac_address = mac_address;
			tempRecord.truck_id = device.truck_id || 'N/A';
			tempRecord.truck_sn = device.truck_sn || 'N/A';
			tempRecord.serial_number = device.serial_number;
			tempRecord._device_link = ["/#", this.customerId, this.siteId, device.mac_address, "performance"];

			data.push(tempRecord);
		}
		return data;
	}

	openLastPMModal() {
		Object.assign(this.lastPMModalVars, {
			lastPMDate: new Date(),
			isValid: true,
			max: new Date()
		});
		this.lastPMModal.show();
	}

	setLastPMDate() {
		let lastPMDate = moment(this.lastPMModalVars.lastPMDate).startOf('day').unix();
		if(!this.deviceService.validateLastPMDate(lastPMDate, this.lastPMModalVars.installationDate)) {
			this.lastPMModalVars.isValid = false;
			return;
		}

		//  local time -> utc
		let zoneDiff = new Date().getTimezoneOffset();
		lastPMDate -= (zoneDiff * 60);

		this.lastPMModalVars.isValid = true;

		this.deviceService.setLastMaintenanceDate(this.selectedDevices, lastPMDate).subscribe((res: any) => {
			if(res.api_status) {
				this.lastPMModalVars.isValid = false;
				return;
			}
			this.notificationMessage.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			this.lastPMModal.hide();
		});
	}

	updateSelectedDevices(devices) {
		this.selectedDevices = devices.map(device => device.id.value);
		let maxDate = 0;
		for(let device of this.selectedDevices)
			maxDate = _.max([maxDate, this.devices[device].installation_date]);

		this.lastPMModalVars.min = new Date(maxDate * 1000);
		this.lastPMModalVars.installationDate = maxDate;
	}
}
