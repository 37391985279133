import { AfterViewInit, Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { saveAs } from 'file-saver';
import { AttachmentsService } from 'src/app/shared/attachments/attachments.service';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { OrdersService } from '../orders/orders.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ReceivingShipmentService } from '../receiving-shipment/receiving-shipment.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-shipment-history',
	templateUrl: './shipment-history.component.html',
	styleUrls: ['./shipment-history.component.css']
})
export class ShipmentHistoryComponent implements OnInit, AfterViewInit {
	@ViewChild("isShipmentHistoryDialog") isShipmentHistoryDialog: any;
	@ViewChild('downloadFilesDialog') downloadFilesDialog: ModalComponent;
	@ViewChild("addAttachmentsDialog") addAttachmentsDialog: ModalComponent;
	@ViewChild("successAttachmentsDialog") successAttachmentsDialog: ModalComponent;
	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: HTMLInputElement;
	@ViewChild("deleteConfirmModal") deleteConfirmModal: ModalComponent;
	@ViewChild("exportReceivedShipmentData") exportReceivedShipmentData;

	data: any;
	selectedDevices: any[] = [];
	poNumber: number;
	noResult: boolean = false;
	currentPage: number = 1;
	dataToShow: any[] = [];
	fromDate: Date = new Date();
	toDate: Date = new Date();
	notValidPopupDate = false;
	dataTable: any[] = [];

	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};
	selectedHistory: any;

	orderAttachedFiles: any[] = [];
	selectedFilesToDownload: any = {};
	noSelectedAttachmentsFilesErrMsg = '';
	deleteAttachmentsSub!: Subscription;
	filesAlreadyUploaded: any = {};

	successCounter = 0;
	columns: ColumnsConfig[] = [
		{ key: 'purchase_order', name: this.translateService.instant('shipments_history.purchase_order'), type: 'string' },
		{ key: 'shipment_tracking_number', name: this.translateService.instant('shipments_history.shipment_tracking_number'), type: 'string' },
		{ key: 'shipping_date', name: this.translateService.instant('shipments_history.shipping_date'), type: 'date' },
		{ key: 'customer', name: this.translateService.instant('shipments_history.customer'), type: 'link' },
		{ key: 'site', name: this.translateService.instant('shipments_history.site'), type: 'link' },
		{ key: 'devices', name: this.translateService.instant('shipments_history.devices'), type: 'icon' },
		{ key: 'user_name', name: this.translateService.instant('shipments_history.user_name'), type: 'string' },
		{ key: 'attachments', name: this.translateService.instant('shipments_history.attachments'), type: 'icon' }
	];

	tableConfig: TableConfig = {
		hasPagination: true,
		pageSize: 20,
		hasExport: true,
		fileName:  this.translateService.instant('shipment.shipment_history')
	};

	constructor(
		private router: Router,
		private usersService: UsersService,
		private deviceManagement: DeviceManagementService,
		private ordersService: OrdersService,
		private translateService: TranslateService,
		public attachmentsService: AttachmentsService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private receivingShipmentService: ReceivingShipmentService
	) { }

	ngOnInit(): void {
		if (!this.usersService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);

		this.getShipmentsHistory();

		this.sideMenuService.hide();
	}

	ngAfterViewInit() {
		this.downloadFilesDialog.onClose.subscribe((result) => {
			if(!result)
				return this.selectedFilesToDownload = {};
			this.downloadFilesDialog.hide();
			this.downloadFiles(Object.keys(this.selectedFilesToDownload), { id: this.selectedHistory.id, po_number: this.selectedHistory.purchase_order });
			return this.selectedFilesToDownload = {};
		});

		this.addAttachmentsDialog.onClose.subscribe((result) => {
			this.selectedFiles = [];
			this.addAttachmentsDialog.hide();
			this.uploadAttachmentsBtn.files = null;
		});

		this.addAttachmentsDialog.onClose.subscribe((result) => {
			this.selectedFiles = [];
			this.addAttachmentsDialog.hide();
			this.uploadAttachmentsBtn.files = null;
		});

		this.deleteConfirmModal.onClose.subscribe((result) => {
			if(!result)
				return;
			return this.deleteAttachmentFiles(this.selectedHistory.id, Object.keys(this.selectedFilesToDownload));
		});
	}

	handleUploadedFiles() {
		const newAddedFiles = this.selectedFiles.filter(file => !(file.name in this.filesAlreadyUploaded)).map(file => file.name);

		if (this.selectedFiles.length && this.successCounter == this.selectedFiles.length) {
			this.selectedFiles = [];
			this.successAttachments = {};
			this.successAttachmentsDialog.hide();
		}

		if (this.successCounter && newAddedFiles.length == this.successCounter) {
			this.ordersService.sentAttachmentEmail(this.selectedHistory.order_id, this.selectedHistory.purchase_order, newAddedFiles, 'shipping', false).subscribe();
			this.getShipmentsHistory();
		}
	}

	getShipmentsHistory() {
		this.deviceManagement.getShipmentsHistory().subscribe((res: any) => {
			this.data = res;
			this.data.map((order: any) => order.shipping_date = order.shipping_date ? moment.unix(order.shipping_date).utc().format('MM/DD/YYYY') : '-');
			const tableData: any[] = [];
			this.data.forEach((row: any) => {
				let rowData: TableData = {
					row,
					purchase_order: { value: row.purchase_order },
					shipment_tracking_number: { value: row.shipment_tracking_number },
					shipping_date: { value: row.shipping_date != '-' ? row.shipping_date : null },
					customer: { value: row.customer_name? row.customer_name: '---', link: row.customer_name? ['/', row.customer_id] : null },
					site: { value: row.site_name? row.site_name : '---', link: row.site_name? ['/', row.customer_id, row.site_id]: null },
					devices: { icon: 'eye', iconColor: '#007bff', action: () => {this.showShipmentsHistoryDevices(row.devices) } },
					user_name: { value: row.user_name? row.user_name : '---'},
					attachments: { icon: row.attached_files?.length ? 'paperclip' : 'plus', iconColor: row.attached_files?.length ? '#007bff' : '#28a745',  action: row.attached_files?.length? () => {this.openDownloadDialog(row)} : () => {this.openAddDialog(row) } }
				};
				tableData.push(rowData);
			});

			this.dataTable = tableData;
		});
	}

	capitalizeFirstLetter(string) {
		if (!string)
			return string;

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	showShipmentsHistoryDevices(devices) {
		this.selectedDevices = devices;
		this.isShipmentHistoryDialog.show();
	}

	openDownloadDialog(order: any) {
		this.selectedHistory = order;
		this.orderAttachedFiles = order.attached_files;
		this.downloadFilesDialog.title = order.purchase_order + ' ' + this.translateService.instant('ready_to_ship.attachments');
		this.downloadFilesDialog.show();
		this.selectedFilesToDownload = {};
		this.selectedFiles = [];
		this.successCounter = 0;
		this.noSelectedAttachmentsFilesErrMsg = '';
	}

	openAddDialog(order: any) {
		this.addAttachmentsDialog.title = order.purchase_order + ' ' + this.translateService.instant('ready_to_ship.attachments');
		this.selectedFiles = [];
		this.successAttachments = {};
		this.selectedFilesToDownload = {};
		this.selectedHistory = order;
		this.addAttachmentsDialog.show();
		this.successCounter = 0;
		this.noSelectedAttachmentsFilesErrMsg = '';
	}

	selectFile(event: any, file: any) {
		const isChecked = event.target.checked;
		if (isChecked) {
			if (this.noSelectedAttachmentsFilesErrMsg)
				this.noSelectedAttachmentsFilesErrMsg = '';

			return this.selectedFilesToDownload[file] = isChecked;
		}
		delete this.selectedFilesToDownload[file];
	}

	getDownloadButtonStatus() {
		return !Object.keys(this.selectedFilesToDownload).length;
	}

	downloadFiles(files: string[], orderInfo: { id: number, po_number: string }) {
		this.deviceManagement.downloadAttachment(files, orderInfo).subscribe((res: any) => {
			if (!res)
				return;
			const arr = new Uint8Array(res.content.data);
			const blob = new Blob([arr]);
			if (blob)
				saveAs(blob, res.name);
		});
	}

	addAttachments() {
		if (!this.selectedFiles.length)
			return this.selectedFilesValidations = { ...this.selectedFilesValidations, min_count: true };

		this.selectedFilesValidations = this.attachmentsService.validate({ files: this.selectedFiles, note: '' });
		if (Object.keys(this.selectedFilesValidations).length)
			return;

		this.filesAlreadyUploaded = {};
		this.addAttachmentsDialog.hide();
		this.successAttachmentsDialog.show();

		let counter = 0;
		for (const file of this.selectedFiles) {
			let formData = new FormData();

			formData.append("file", file);
			formData.append('id', this.selectedHistory.id.toString());

			this.deviceManagement.addAttachmentToShipmentHistory(formData).subscribe((attachment: any) => {
				switch (attachment.api_status) {
					case 2:
						this.notificationMessage.setMessage('globalErrMsg', { clearOnXTimeNavigate: 1 });
						break;
					case 3:
						this.filesAlreadyUploaded = cloneDeep({...this.filesAlreadyUploaded, [file.name]: true});
						break;
					default:
						this.successAttachments = cloneDeep({ ...this.successAttachments, [attachment.file_name]: true });
						this.successCounter++;
						break;
				}

				counter++;
				if (counter == this.selectedFiles.length)
					this.handleUploadedFiles();
			});
		}
	}

	selectFiles(events: any) {
		this.selectedFiles = Object.values(events.target.files);
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}

	confirmDownloadAttachedFiles() {
		if (!Object.values(this.selectedFilesToDownload).length)
			return this.noSelectedAttachmentsFilesErrMsg = this.translateService.instant('ready_to_ship.no_selected_files');

		this.downloadFilesDialog.onConfirm();
	}

	confirmDeleteAttachedFiles() {
		if (!Object.values(this.selectedFilesToDownload).length)
			return this.noSelectedAttachmentsFilesErrMsg = this.translateService.instant('ready_to_ship.no_selected_files');

		this.deleteConfirmModal.message = 'attachments.delete_attachments';
		this.deleteConfirmModal.show();
	}

	deleteAttachmentFiles(id: number, files: string[]) {
		this.deleteAttachmentsSub = this.deviceManagement.deleteAttachments(id, files).subscribe((res: any) => {
			if (!res)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			const index = this.data.map((order: any) => order.id).indexOf(id);
			const attachments = this.data[index].attached_files.filter((file: string) => !files.includes(file));
			this.data[index] = { ...this.data[index], attached_files: attachments };

			this.getShipmentsHistory();
			return this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});

		this.downloadFilesDialog.hide();
	}

	generateShipmentHistoryReport() {
		let zoneDiff = new Date().getTimezoneOffset() * -1;
		const dateRange = {
			from: moment(this.fromDate).utc().startOf('day').unix() + (zoneDiff*60),
			to: moment(this.toDate).utc().endOf('day').unix()
		};

		if (this.fromDate && dateRange.from > dateRange.to)
			return this.notValidPopupDate = true;
		this.notValidPopupDate = false;

		const reportData = this.data.filter(record => {
			const shippingDate = moment(record.shipping_date).utc().unix();
			return shippingDate >= dateRange.from && shippingDate <= dateRange.to
		});

		const sortedData = reportData.sort((a, b) => moment(b.shipping_date).utc().unix() - moment(a.shipping_date).utc().unix());
		this.exportReceivedShipmentData.hide();
		if (!sortedData.length)
			return this.notificationMessage.setMessage('translate|g.date_range_has_no_records', { type: 'warning' });
		return this.receivingShipmentService.generateHistoryCsvFile(sortedData, this.translateService.instant('shipment.shipment_history'), 'shipment_history');
	}

	ngOnDestroy() {
		if (this.deleteAttachmentsSub)
			this.deleteAttachmentsSub.unsubscribe();
	}

}
