<div  class="border-radius-bottom m-2 main-black p-4 white-box">
	<h3>{{'site_groups.title' | translate}}</h3>
	<div>
		<button type="button" class="btn btn-primary mt-4 ml-2 mb-2" (click)="addNewGroupModal.show(); isEditType=false">{{'site_groups.add_group' | translate}}</button>
		<sct-custom-table
			*ngIf="sitesGroupList.length; else noData"
			[config]="tableConfig"
			[columnsConfig]="columnConfig"
			[data]="sitesGroupList">
		</sct-custom-table>
	</div>
</div>

<ng-template #noData class="mt-4">
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #addNewGroupModal [hasConfirmButton]="false" [hasCloseButton]="false" title="{{(!isEditType? 'site_groups.add_group' : 'site_groups.edit_group') | translate}}" size="xl">
	<div class="col-sm-12 card-body">
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_groups.group_name' | translate}}</strong>
			</div>
			<div class="col-md-6">
				<input class="form-control" [ngClass]="{'invalid-input' : invalidField}" name="group_name" placeholder="{{'site_groups.group_name' | translate}}" [(ngModel)]="group.name" required/>
				<p class="text-danger" *ngIf="invalidField" (ngModelChange)="groupNameError=''"><small>{{groupNameError}}</small></p>
			</div>
		</div>

		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong> {{'g.recipients' |translate}} </strong>
			</div>
			<div class="col-md-6">
				<ng-select
					[multiple]="true"
					id="users_list"
					[items]="usersList"
					bindLabel="name"
					bindValue="id"
					placeholder="{{'g.recipients' | translate}}"
					name="users_list"
					[(ngModel)]="group.users_ids"
					[clearable]="true"
					[searchable]="true">
				</ng-select>
			</div>
		</div>

		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong> {{'site.sites' |translate}} </strong>
			</div>
			<div class="col-md-6">
				<ng-select
					[multiple]="true"
					id="sites_list"
					[items]="sitesList"
					bindLabel="name"
					bindValue="id"
					placeholder="{{'site.sites' | translate}}"
					name="sites_list"
					[(ngModel)]="group.sites_ids"
					[clearable]="true"
					[searchable]="true">
				</ng-select>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="saveSitesGroup()" >{{'g.confirm' | translate}}</button>
		<button type="button" class="btn btn-light" (click)="hideGroupModal()">{{'g.close' | translate}}</button>
	</div>
</app-modal>

<!-- Delete Group-->
<app-modal #deleteGroupModal title="{{'g.confirm_action' | translate}}" closeBtnText="{{'g.no' | translate}}" confirmBtnText="{{'g.yes' | translate}}">
	<div>
		<p>{{'site_groups.delete_site_group' |translate:{"group": group.name} }}</p>
	</div>
</app-modal>
