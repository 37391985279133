import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { CommonService } from 'src/app/shared/services/common.service';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';

@Component({
	selector: 'app-missing-sims',
	templateUrl: './missing-sims.component.html',
	styleUrls: ['./missing-sims.component.css']
})
export class MissingSimsComponent {
	@ViewChild("actionsConfirmationPopup") actionsConfirmationPopup: ModalComponent;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		draggable: false,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: "link"},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: "link"},
		{ key: 'iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link'},
	];

	confirmationMassage = '';
	sims: any[] = [];

	isUpdatingSims: boolean = false;
	lastUpdateSimsTime: number;

	constructor(
		private router: Router,
		private userService: UsersService,
		private deviceService: DeviceService,
		public commonService: CommonService,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
	) {}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc', 'write'))
			this.router.navigate(['/unauthorized']);

		this.getMissingSims();
	}

	ngAfterViewInit() {
		this.actionsConfirmationPopup.onClose.subscribe((ok) => {
			if(!ok)
				return;

			this.refreshMissingSims();
		});
	}

	getMissingSims() {
		this.deviceService.getMissingSims().subscribe((response: any) => {
			this.isUpdatingSims = response.isUpdatingSims;
			this.lastUpdateSimsTime = response.lastUpdateSimsTime;
			const tableData: TableData[] = [];

			for(const device of response.data) {
				tableData.push({
					serial_number: {value: device.serial_number, link: ['/', device.customer_id, device.site_id, device.mac_address,'performance']},
					customer_name: {value: device.customer_name, link: ['/', device.customer_id]},
					site_name: {value: device.site_name, link: ['/', device.customer_id, device.site_id]},
					iccid: {value: device.iccid, link: ['/', device.customer_id, device.site_id, device.mac_address,'cellular-settings']},
				})
			}

			this.tableData = tableData;
		})
	}

	refreshMissingSims() {
		this.deviceService.refreshMissingSims().subscribe((response: any) => {
			this.lastUpdateSimsTime = response?.response?.lastUpdateSimsTime;

			switch (response.api_status) {
				case 2:
					this.notificationMessage.setMessage('translate|g.in_progress_action', {clearOnXTimeNavigate: 1, type: 'warning'});
					this.isUpdatingSims = true;
					break;
				case 3:
					this.notificationMessage.setMessage('translate|g.done_before_less_than_three_hours', {clearOnXTimeNavigate: 1, type: 'warning'});
					this.getMissingSims();
					break;
				default:
					this.notificationMessage.setMessage('translate|g.action_will_applied_later', {clearOnXTimeNavigate: 1, type: 'success'});
					this.isUpdatingSims = true;
					break;
			}
		})
	}

	sortFunction(sort: any, a:any, b:any) {
		const { compare } = this.commonService;
		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (["customer_name", "site_name"].includes(field))
			return compare(a[field].toUpperCase(), b[field].toUpperCase(), isAsc);

		return compare(a[field], b[field], isAsc);
	}

	formateDate(date: moment.MomentInput) {
		return date && date != '---' ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : '---';
	}

	showRefreshPopup() {
		if (this.isUpdatingSims)
			return this.refreshMissingSims();

		this.confirmationMassage = this.translate.instant('sim.update_sims_confirm');
		this.actionsConfirmationPopup.show();
	}
}
