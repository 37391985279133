import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'disconnected_devices',
	templateUrl: './disconnected-device.component.html',
	styleUrls: ['./disconnected-device.component.css']
})
export class DisconnectedDevicesComponent implements OnInit {
	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 20,
		draggable: true,
		fileName: `${this.translate.instant('nav.disconnected_devices')} - ${moment().format('YYYY/MM/DD')}`,
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: "link"},
		{ key: 'serial_number', name: this.translate.instant('g.serial_number'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: "link"},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'last_connect_time', name: this.translate.instant('devices.last_connect_time'), type: "date"},
		{ key: 'last_connected_in', name: this.translate.instant('devices.last_connected_in'), type: "string"},
		{ key: 'connection_type', name: this.translate.instant('devices.connection_type'), type: "string"},
		{ key: 'ip_address', name: this.translate.instant('g.ip_address'), type: "string"},
	];

	lastUpdateTime: any;

	constructor(
		private usersService: UsersService,
		private deviceService: DeviceService,
		private router: Router,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);
		this.getDisconnectedDevicesReport();
	}

	getDisconnectedDevicesReport() {
		this.deviceService.getDisconnectedDevicesReport().subscribe((response: any) => {
			const tableData: TableData[] = [];

			for(const device of response.devicesList) {
				tableData.push({
					mac_address: {value: device.mac_address, link: ['/', device.customer_id, device.site_id, device.mac_address, 'performance']},
					serial_number: {value: device.serial_number},
					customer_name: {value: device.customer_name, link: ['/', device.customer_id]},
					site_name: {value: device.site_name, link: ['/', device.customer_id, device.site_id]},
					last_connect_time: {value: this.formatTime(device.last_connect_time)},
					last_connected_in: {value: this.getLastConnectedIn(device.last_connect_time), sortValue: moment().utc().unix() - +device.last_connect_time},
					connection_type: {value: this.getConnectionType(device.metered, device.client_socket_type)},
					ip_address: {value: device.ip_address}
				})
			}

			this.tableData = tableData;

			this.lastUpdateTime = this.formatTime(response?.lastUpdateTime);
		})
	}

	lastConnectedInComparator(valueCompareA: string, valueCompareB: string, dataCompareA: any, dataCompareB: any) {
		const numValueA = dataCompareA.data.last_connected_in;
		const numValueB = dataCompareB.data.last_connected_in;
		return numValueA - numValueB;
	}

	getConnectionType(metered: boolean, client_socket_type: number) {
		let connectionType = '';
		if (metered)
			connectionType = `${this.translate.instant('client_socket_type.' + client_socket_type)} (${this.translate.instant('g.metered')})`;
		else
			connectionType = `${this.translate.instant('client_socket_type.' + client_socket_type)} (${this.translate.instant('g.non_metered')})`;
		return connectionType;
	}

	formatTime(last_connect_time: string | number | Date) {
		const fromDate = Math.round(new Date(new Date((+last_connect_time) * 1000).getTime()).getTime());
		return moment(new Date(fromDate)).format('MM/DD/YYYY hh:mm:ss A');
	}

	getLastConnectedIn(last_connect_time: any) {
		const formattedLastConnectTime = this.formatTime(last_connect_time);
		const currentTime = moment();
		const lastConnectTime = moment(formattedLastConnectTime, 'MM/DD/YYYY hh:mm:ss A');
		const duration = moment.duration(currentTime.diff(lastConnectTime));
		const days = Math.floor(duration.asDays()) ? `${Math.floor(duration.asDays())} Days` : "";
		const hours = duration.hours() ? `${duration.hours()} Hours` : "";
		const minute = duration.minutes() ? `${duration.minutes()} Minutes` : "" ;
		const result = days != "" ? `${days} ${hours}` : `${hours} ${minute}`;
		return result;
	}
}
