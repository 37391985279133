import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { TranslateService } from '@ngx-translate/core';
import { TableConfig, TableData, ColumnsConfig } from 'src/app/shared/custom-table/custom-table-interface';
import * as moment from 'moment';
@Component({
	selector: 'app-rejected-device-connection',
	templateUrl: './rejected-device-connection.component.html',
	styleUrls: ['./rejected-device-connection.component.css']
})
export class RejectedDeviceConnectionComponent implements OnInit {

	logOptions: {
		getBy: string,
		value?: string,
		reason?: string,
		execlude?: boolean,
		ipAddress?: string,
	};
	filterOptions: Object[];
	rejectionReasons: any = {
		'deviceNotFound': 'rejection_log.device_not_found',
		'serialNumbersMissMatch': 'rejection_log.serial_number_mismatch',
		'hugeQueueSize': 'rejection_log.huge_queue_size',
		'encCodeMissMatch': 'rejection_log.enc_code_miss_match'
	};
	fromDateFmt: Date;
	toDateFmt: Date;
	logInfo: any[] = [];

	@ViewChild('ipAddress') ipAddress:NgModel;

	columnConfig: ColumnsConfig[] = [
		{key: 'time', name: this.translateService.instant('g.time'), type: 'date', hasSort: true, hasFilter: true},
		{key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: 'string', hasSort: true, hasFilter: true},
		{key: 'serial_number', name: this.translateService.instant('g.serial_number'), type: 'string', hasSort: true, hasFilter: true},
		{key: 'reason', name: this.translateService.instant('g.reason'), type: 'string', hasSort: true, hasFilter: true},
		{key: 'ip_address', name: this.translateService.instant('g.ip_address'), type: 'string', hasSort: true, hasFilter: true},
		{key: 'extra_info', name: this.translateService.instant('error_log.extra_info'), type: 'string', hasSort: true, hasFilter: true},
		{key: 'options', name: this.translateService.instant('g.options'), type: 'dropdown'},
	];

	tableConfig: TableConfig = {
		hasPagination: true,
		pageSize: 10,
		draggable: true,
		hasExport: true,
		fileName: this.translateService.instant('nav.admin.rej_device_conn'),
	};

	constructor(
		private adminService: AdminService,
		private translateService: TranslateService,
	) {
	}

	ngOnInit() {
		this.filterOptions	= [
			{label: 'g.date', value:'date'},
			{label: 'g.mac_address', value: 'mac_address'},
			{label: 'g.serial_number', value: 'serial_number'},
			{label: 'g.ip_address', value: 'ip_address'},
			{label: 'rejection_log.rejection_reason', value: 'rejection_reason'}
		];
		this.logOptions = {
			getBy: 'date',
			execlude: false
		};
		this.getRejectedDeviceConnections();
	}

	resetSelects(){
		this.logOptions.value = '';
		this.logOptions.reason = '';
		this.logOptions.execlude = false;
		this.logOptions.ipAddress ='';
	}

	getRejectedDeviceConnections(options?){
		this.logInfo = [];
		options = options || this.logOptions;

		if(options.getBy == 'date') {
			let fromDate, toDate;

			if(!this.fromDateFmt || !this.toDateFmt) {
				this.fromDateFmt = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
				fromDate = Math.floor(new Date(this.fromDateFmt).getTime() / 1000);

				this.toDateFmt = new Date(new Date().setHours(23,59,59,999));
				toDate = Math.floor(new Date(new Date(this.toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
			} else {

				var fromDateFmt	= this.fromDateFmt;
				var toDateFmt	= this.toDateFmt;
				if(options.passedDates) {
					fromDateFmt	= options.fromDate;
					toDateFmt	= options.toDate;
				}

				fromDate	= Math.floor(new Date(fromDateFmt).getTime() / 1000);
				toDate 		= Math.floor(new Date(new Date(toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
			}

			options.fromDate	= fromDate;
			options.toDate		= toDate;
		}

		this.adminService.getRejectedDeviceConnections(options).subscribe(
			(response: any) => {
				const tableData: TableData[] = [];
				[...response].forEach((row) => {
					let rowData: TableData = {
						row,
						time: {value: moment(row.time).local().format('MM/DD/YYYY hh:mm:ss A') },
						mac_address: {value: row.mac_address},
						serial_number: {value: row.serial_number},
						reason: {value: row.reason && this.rejectionReasons[row.reason]? this.translateService.instant(this.rejectionReasons[row.reason]) : row.reason},
						ip_address: {value: row.ip_address},
						extra_info: {value: JSON.stringify(row.extra_info)},
						error: {value: row.error && row.error.length > 50? row.error.slice(0, 50) : row.error },
						options: {value: null, options:
									[
										{ text: this.translateService.instant('error_log.delete_logs'), action: (data) => {this.deleteLog(data.row)}}
									]}
					}
					tableData.push(rowData);
				});

				this.logInfo = tableData;
			}
		);

	}

	getKeys(variable) {
		return Object.keys(variable);
	}

	deleteLog(record) {
		this.adminService.deleteRejectionLog(record.id).subscribe(
			response => {
				let logInfo = this.logInfo.slice();

				for(var i in logInfo) {
					if (logInfo[i].id == record.id) {
						logInfo.splice(+i, 1);
						break;
					}
				}

				this.logInfo = logInfo;
			}
		);
	}

	checkDisableSubmitButton() {
		return (this.logOptions.getBy != 'date' &&
				!this.logOptions.value &&
				!this.logOptions.reason &&
				!this.logOptions.ipAddress)
			|| (this.logOptions.getBy == 'ip_address' && this.ipAddress.invalid);
	}
}
