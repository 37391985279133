import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { CustomHttpParams } from '../shared/loader/custom-httpParam';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '../shared/services/common.service';

@Injectable({
	providedIn: 'root'
})
export class SitesService {

	route = '/site/';
	specialFolderId = 1;
	sctInventorySiteId = 48;
	sctCustomerId = 1;
	defaultMapLocation = {
		latitude: 28.623421,
		longitude: -81.212219
	};
	workingDaysOptions = {
		force_5: 'force_5',
		force_6: 'force_6',
		force_7: 'force_7',
		operation_5: 'operation_5',
		operation_6: 'operation_6',
		operation: 'operation'
	};

	private siteAnalyticsData = {};
	public activeSiteAnalyticsData:Subject<any> = new Subject();

	constructor(
		private httpClient: HttpClient,
		private commonService: CommonService,
	) { }

	getCustomerSites(customerId, options) {
		return this.httpClient.post(this.route + 'getCustomerSites', {customerId, options}, {
			observe: "body"
		});
	}

	getSite(siteId, options) {
		return this.httpClient.post(this.route + 'getSite', {siteId, options}, {
			observe: "body"
		});
	}

	getSitesFirmwareData() {
		return this.httpClient.post(this.route + 'getSitesFirmwareData', {}, {
			observe: "body"
		});
	}

	getUpToDateSiteFw(siteId) {
		return this.httpClient.post(this.route + 'getUpToDateSiteFw', {siteId}, {
			observe: "body"
		});
	}

	getSiteFwUpdateRequests(type, siteId) {
		return this.httpClient.post(this.route + 'getSiteFwUpdateRequests', {type, siteId}, {
			observe: "body"
		});
	}

	getSmartRebatesSitesInfo() {
		return this.httpClient.post(this.route + 'getSmartRebatesSitesInfo', {}, {
			observe: "body"
		});
	}

	pushFirmwareUpdate(siteId, type, firmwareVersion) {
		return this.httpClient.post(this.route + 'pushFirmwareUpdate', {siteId, type, firmwareVersion}, {
			observe: "body"
		});
	}

	abortFirmwareUpdate(siteId) {
		return this.httpClient.post(this.route + 'abortFirmwareUpdate', {siteId}, {
			observe: "body"
		});
	}

	deleteSite(siteId) {
		return this.httpClient.post(this.route + 'deleteSite', {siteId}, {
			observe: "body"
		});
	}

	saveSite(site) {
		return this.httpClient.post(this.route + 'saveSite', site, {
			observe: "body"
		});
	}

	getSiteDevicesCount(siteId) {
		return this.httpClient.post(this.route + 'getSiteDevicesCount', {siteId}, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		});
	}

	getReportsData(siteId, chargLinkDevicesOnly) {
		return this.httpClient.post(this.route + 'getReportsData', {siteId, chargLinkDevicesOnly}, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		});
	}

	addSitesConnectivityStatusIcons(sites) {

		let icons = {
			green: '/images/site-connectivity/green-dot.png',
			yellow: '/images/site-connectivity/yellow-dot.png',
			red: '/images/site-connectivity/red-dot.png',
		};

		sites.map((site) => {

			let minutesDiff = moment().diff(moment(site.site_last_connect_time*1000), 'minutes');

			if(minutesDiff <= 30)
				site.icon = icons.green;
			else if(minutesDiff <= 12*60)
				site.icon = icons.yellow;
			else
				site.icon = icons.red;
		});
	}

	getSitesAnalyticsData(siteIds) {
		return this.httpClient.post(this.route + 'getSitesAnalyticsData', {siteIds}, {
			observe: "body"
		});
	}

	getSitesAnalyticsDataCached(siteIds) {
		const existsData = [];
		let notExistsSites = [];

		for(const siteID of siteIds) {
			if (this.siteAnalyticsData[siteID] !== undefined) {
				existsData.push(this.siteAnalyticsData[siteID]);
			} else {
				notExistsSites.push(siteID);
			}
		}

		if(!notExistsSites.length) {
			this.activeSiteAnalyticsData.next(existsData);
			return new Observable((observer) => {
				observer.next(existsData);
				observer.complete();
			});
		}

		this.httpClient.post(this.route + 'getSitesAnalyticsData', {siteIds: notExistsSites}, {
			observe: "body"
		}).subscribe((data: any) => {
			for(const siteID in data) {
				const siteId = +siteID;
				const siteData = this.decompressSiteAnalyticsData(data[siteId])
				existsData.push(siteData);
				this.siteAnalyticsData[siteId] = siteData;
				notExistsSites = notExistsSites.filter((item) => {
					return item !== siteId
				});
			}
			for(const siteID of notExistsSites) {
				this.siteAnalyticsData[siteID] = {};
			}
			this.activeSiteAnalyticsData.next(existsData);
			return new Observable((observer) => {
				observer.next(existsData);
				observer.complete();
			});
		});
	}

	private decompressSiteAnalyticsData(data) {
		return {
			ioTAh_devices: this.commonService.decompress(data.i, 'siteInfoBox'),
			chargLink_devices: this.commonService.decompress(data.c, 'siteInfoBox')
		};
	}

	getUserSitesInfo(options) {
		return this.httpClient.post(this.route + 'getUserSitesInfo', {options}, {
			observe: "body"
		});
	}

	getMaintenanceReport(siteId, options?) {
		return this.httpClient.post(this.route + 'getMaintenanceReport', {siteId, options}, {
			observe: "body"
		});
	}

	getSiteDevicesWarranty(siteId) {
		return this.httpClient.post(this.route + 'getSiteDevicesWarranty', {siteId}, {
			observe: "body"
		});
	}

	setSiteWorkingDays(siteId, siteWD) {
		return this.httpClient.post(this.route + 'setSiteWorkingDays', {siteId, siteWD}, {
			observe: "body"
		});
	}

	setSiteEndVoltageLevels(siteId, siteEndVoltageLevels) {
		return this.httpClient.post(this.route + 'setSiteEndVoltageLevels', {siteId, siteEndVoltageLevels}, {
			observe: "body"
		});
	}

	getSiteDownloadStatus(siteId) {
		return this.httpClient.post(this.route + 'getSiteDownloadStatus', {siteId}, {
			observe: "body"
		});
	}

	getEnterpriseSitesInfo(enterpriseId) {
		return this.httpClient.post(this.route + 'getEnterpriseSitesInfo', {enterpriseId}, {
			observe: "body"
		});
	}

	downloadConsumptionsReport(from: number, to: number, siteIds: number[], selectedStartDate: number) {
		return this.httpClient.post(this.route + 'get-sr-consumption-reports', { siteIds, from, to, selectedStartDate });
	}

	registrationPurposes(siteId: number) {
		return this.httpClient.post('reporting/registrationPurposes', { siteId });
	}

	getSitesWithCustomerInfo() {
		return this.httpClient.post(this.route + 'getSitesWithCustomerInfo', {});
	}

	getAllSiteGroups(getSiteInfo = true) {
		return this.httpClient.get(this.route + 'getAllSiteGroups', {
			observe: "body",
			params: {
				getSiteInfo
			}
		});
	}

	saveSiteGroups(groupInfo) {
		return this.httpClient.post(this.route + 'saveSiteGroups', { group: groupInfo })
	}

	deleteSiteGroups(groupId) {
		return this.httpClient.delete(this.route + 'deleteSiteGroups', {
			observe: "body",
			params: {
				groupId
			}
		})
	}
}
