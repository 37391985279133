import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminService } from "../../admin.service";
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, TableConfig, TableData} from 'sct-custom-table/sct-table/projects/sct-table/src/lib/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
		selector: 'app-connectivity-verification-statistics',
		templateUrl: './connectivity-verification-statistics.component.html',
		styleUrls: ['./connectivity-verification-statistics.component.css']
})

export class ConnectivityVerificationStatisticsComponent implements OnInit, OnDestroy {
	@ViewChild("devicesInfoModel") devicesInfoModel;
	@ViewChild("sitesInfoModel") sitesInfoModel;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: false,
		draggable: true,
		fileName: this.translate.instant('connectivity_verification.connectivity_verification_header'),
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'month', name: this.translate.instant('connectivity_verification.month'), type: 'string'},
		{ key: 'total_check', name: this.translate.instant('connectivity_verification.total_check'), type: 'number'},
		{ key: 'total_number_unique_devices', name: this.translate.instant('connectivity_verification.total_number_unique_devices'), type: 'number_link'},
		{ key: 'total_number_unique_sites', name: this.translate.instant('connectivity_verification.total_number_unique_sites'), type: 'number_link'},
	];

	sitesInfo: any;
	devicesInfo: any;
	devicesData: any[] = [];
	sitesData: any[] = [];

	constructor(
		private adminService: AdminService,
		private usersService: UsersService,
		private router: Router,
		private translate: TranslateService,
	) {}

	ngOnInit() {
			if (!this.usersService.hasAccessPermission(null, 'noc'))
					this.router.navigate(['/unauthorized']);

			this.getConnectivityVerificationStatistics();
	}

	getConnectivityVerificationStatistics() {
		this.adminService.getConnectivityVerificationStatistics().subscribe((response: any) => {
			if (!Object.keys(response).length)
					return;

			this.sitesInfo = response.sitesInfo;
			this.devicesInfo = response.devicesInfo;

			const tableData = [];
			for(const record of response.statisticsLogs) {
				tableData.push({
					id: record.id,
					month: {value: `${record.month} ${record.year}`},
					total_check: {value: record.total_checks},
					total_number_unique_devices: {value: record.mac_addresses.length, action: ()=> {this.showDevicesInfoModal({ id: record.id, macAddress: record.mac_addresses })}},
					total_number_unique_sites: {value: record.sites_ids.length, action: ()=> {this.showSitesInfoModal(record.sites_ids)}},
				})
			}

			this.tableData = tableData;
		});
	}

	showDevicesInfoModal(data) {
		this.devicesData = data.macAddress;
		this.devicesInfoModel.data = data.id;
		this.devicesInfoModel.show();
	}

	showSitesInfoModal(data) {
		this.sitesData = data;
		this.sitesInfoModel.show();
	}

	exportUniqueDevicesFile() {
		const fileData = this.tableData.filter(row => row.id == this.devicesInfoModel.data);
		const recordDate = fileData[0].month.value;
		const csvRows = [];
		const columnNames = [
			this.translate.instant('g.mac_address'),
			this.translate.instant('g.serial_number')
		];

		csvRows.push(columnNames);

		(this.devicesData || []).forEach(row => {
			csvRows.push([
				row,
				this.devicesInfo[row].serial_number
			]);
		});

		new AngularCsv(csvRows, `${this.translate.instant('connectivity_verification.connectivity_verification_header')} - ${recordDate} Devices`);
	}

	ngOnDestroy() {
		this.sitesInfoModel.hide();
		this.devicesInfoModel.hide();
	}
}
