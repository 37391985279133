import { Component } from '@angular/core';
import { ColumnsConfig, TableConfig, TableData } from '../../shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-defective-devices-page',
	templateUrl: './defective-devices-page.component.html',
	styleUrls: ['./defective-devices-page.component.css']
})

export class DefectiveDevicesPageComponent {
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		fileName: this.translateService.instant('nav.defective_devices_page')
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'serial_number', name: this.translateService.instant('devices.device_sn'), type: "link", hasSort: true, hasFilter: true },
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link', hasSort: true, hasFilter: true },
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link", hasSort: true, hasFilter: true },
		{ key: 'start_date', name: this.translateService.instant('devices.defective_start_date'), type: "date_with_ref", hasSort: true, hasFilter: true },
		{ key: 'end_date', name: this.translateService.instant('devices.defective_end_date'), type: "date_with_ref", hasSort: true, hasFilter: true },
		{ key: 'reason', name: this.translateService.instant('g.reason'), type: "string", hasSort: true, hasFilter: true },
		{ key: 'user_name', name: this.translateService.instant('g.done_by'), type: "string", hasSort: true, hasFilter: true },
	];

	constructor(
		private translateService: TranslateService,
		private usersService: UsersService,
		private router: Router,
		private devicesService: DeviceService,
		private sideMenuService: SideMenuService,
		private commonData: CommonDataService
	) {}


	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		// @ Development ENV. make the pagination limit 4 for just for testing
		if (window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.sideMenuService.hide();
		this.getAllDefectiveDevices();
	}

	getAllDefectiveDevices() {
		this.devicesService.getAllDefectiveDevices().subscribe(
			(data: any) => {
				const tableData: TableData[] = [];
				[...data].forEach(record => {
					let rewData: TableData  = {
						record,
						serial_number: {value: record.serial_number == 'deleted_device'? this.translateService.instant('g.device_deleted') : record.serial_number, link: record.serial_number == 'deleted_device'? null : ['/', record.customerId, record.siteId, record.mac_address, 'performance']},
						customer_name: {value: record.customerId == 0? this.translateService.instant('g.customer_deleted') : record.customer_name, link:  record.customerId == 0? null : ['/', record.customerId]},
						site_name: {value: record.siteId == 0 ? this.translateService.instant('g.site_deleted') :  record.site_name, link: record.siteId == 0 ? null : ['/', record.customerId, record.siteId]},
						start_date: {value: moment.utc(record.start_date*1000).format('MM/DD/YYYY')},
						end_date: {value: record.end_date? moment.utc(record.end_date*1000).format('MM/DD/YYYY') : '-'},
						reason: {value: record.reason},
						user_name: {value: record.user_name? record.user_name : '---'}
					};

					tableData.push(rewData);
				});

				this.tableData = tableData;
			}
		);
	}
}
