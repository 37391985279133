<div *ngIf="showDateRange" class="date-range {{withBorder ? 'white-box main-black p-4 border-radius-bottom' : ''}}">
	<button *ngIf="showLeftRightArrows" class="btn pt-0" [disabled]="calenderDisabled" (click)="handleDateChange(false)">
		<i class="fa fa-lg pointer fa-arrow-left main-blue" title="Back"></i>
	</button>

	<div class="input-group col{{withBorder ? '-3' : ''}} p-0 mr-3" *ngIf="showDateRange != 'to'">
		<div class="input-group-prepend">
			<span class="input-group-text">{{'g.start_date' | translate}}</span>
		</div>
		<input class="form-control" [ngClass]="{'text-danger': invalidDateRange || invalidFromDateInput}" [minDate]="minDate || deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDate" (ngModelChange)="datesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
		<div class="input-group-append pointer" (click)="startDB.toggle()">
			<span class="input-group-text"><i class="fa fa-calendar"></i></span>
		</div>
	</div>

	<div class="input-group col{{withBorder ? '-3' : ''}} p-0 mr-3" *ngIf="showDateRange != 'from'">
		<div class="input-group-prepend">
			<span class="input-group-text">{{'g.end_date' | translate}}</span>
		</div>
		<input class="form-control" [ngClass]="{'text-danger': invalidToDateInput}" placeholder="mm/dd/yyyy " [minDate]="minDate" [maxDate]="maxDate" name="endDB" [(ngModel)]="toDate" (ngModelChange)="datesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
		<div class="input-group-append pointer" (click)="endDB.toggle()">
			<span class="input-group-text"><i class="fa fa-calendar"></i></span>
		</div>
	</div>

	<button *ngIf="showLeftRightArrows" class="btn pt-0" (click)="handleDateChange(true)">
		<i class="fa fa-lg pointer fa-arrow-right main-blue" title="Next"></i>
	</button>
</div>