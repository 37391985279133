<div>
	<div class="d-flex justify-content-between align-items-center">
		<h2>{{'nav.rma-devices' | translate}}</h2>
    <a routerLink="/management/rma">
      <button
        class="btn btn-primary pointer"
        type="button"

        target="_blank"
        *ngIf="userService.hasAccessFunction('shipment_management')"
      >
        {{'devices.add_rma' | translate}}
      </button>
    </a>
	</div>

	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getRmaDevices($event)"
	></sct-custom-table>
</div>

<app-modal #confirmDialog  [hasConfirmButton]="false" [hasCloseButton]="false">
	<div>
		{{dialogMsg | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="confirmDialog.hide()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="moveFromRma(selectedMacAddress, selectedType)">{{'g.ok' | translate}}</button>
	</div>
</app-modal>
