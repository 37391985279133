<div class="border-radius-bottom m-2 main-black p-4 white-box">
    <div>
        <div class="card-body">
            <form>
                <!-- Date Range Section -->
                <div class="row mb-3">
                    <div class="col-md-2">
                        <label><b>{{'g.date_range' | translate}}</b></label>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'g.start_date' | translate}}</span>
                            </div>
                            <input [ngClass]="{'invalid-input': invalidStartDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [minDate]="minStudyDate" [maxDate]="maxStudyDate" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
                            <div class="input-group-append pointer" (click)="d1.toggle()">
                                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{'g.end_date' | translate}}</span>
                            </div>
                            <input [ngClass]="{'invalid-input': invalidStartDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [minDate]="minStudyDate" [maxDate]="maxStudyDate" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
                            <div class="input-group-append pointer" (click)="d2.toggle()">
                                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Working Days Section -->
                <div class="row mb-3">
                    <label class="col-md-2 col-form-label"><b>{{ 'operation_analysis.working_days' | translate }}</b></label>
                    <div class="col-md-10">
                        <div class="form-check form-check-inline" *ngFor="let day of commonService.daysText; let dayIndex = index;">
                            <input type="checkbox" class="form-check-input" [(ngModel)]="workingDays[dayIndex]" name="workingDays-{{dayIndex}}">
                            <label class="form-check-label">{{ day }}</label>
                        </div>
                    </div>
                </div>

                <!-- Shifts Section -->
                <div class="row mb-3">
                    <label class="col-md-2 col-form-label"><b>{{ 'operation_analysis.shifts' | translate }}</b></label>
                    <div class="col-md-10">
                        <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" [(ngModel)]="predictionSettings.shiftAutoDetect" [value]="false" name="shiftAutoDetect" (ngModelChange)="predictionSettings.showShiftsPeriods=true"/>
                            <label class="form-check-label">{{ 'operation_analysis.manual' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" class="form-check-input" [(ngModel)]="predictionSettings.shiftAutoDetect" [value]="true" name="shiftAutoDetect" (ngModelChange)="predictionSettings.showShiftsPeriods=false">
                            <label class="form-check-label">{{ 'operation_analysis.auto_detect' | translate }}</label>
                        </div>
                    </div>
                </div>

                <!-- Shift Periods Section -->
                <div class="col-md-10 offset-md-2" *ngIf="shifts.length > 0 && predictionSettings.showShiftsPeriods">
                    <table class="table table-bordered table-hover">
                        <thead class="table-secondary">
                            <tr>
                                <th>{{ 'operation_analysis.shift_number' | translate }}</th>
                                <th>{{ 'g.from' | translate }}</th>
                                <th>{{ 'g.to' | translate }}</th>
                                <th>{{ 'operation_analysis.shift_ahr' | translate }}</th>
                                <th>{{ 'g.action' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let shift of shifts; let i = index">
                                <td>Shift #{{ i + 1 }}</td>
                                <td>{{ shift.startTimeFormatted }}</td>
                                <td>{{ shift.endTimeFormatted }}</td>
                                <td>{{ shift.overrideShiftAHr }}</td>
                                <td>
                                    <button (click)="deleteShift(i)" class="btn btn-danger mr-2 btn-sm" title="Delete"><fa-icon icon="trash" size="1x" class="pointer text-success ms-3" title="attachments"></fa-icon>
                                        {{ 'g.delete' | translate }}</button>
                                    <button (click)="editShift(i)" class="btn btn-warning btn-sm" title="Edit"><fa-icon icon="edit" size="1x" class="pointer text-success ms-3" title="attachments"></fa-icon>
                                        {{ 'g.edit' | translate }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-12" *ngIf="predictionSettings.showShiftsPeriods">
                        <button (click)="ShiftsModal.show()" class="col-md-2 mb-2 offset-md-2 btn btn-primary">
                            <i class="glyphicon glyphicon-plus mr-2"></i>{{ 'operation_analysis.add_shift' | translate }}
                        </button> 
                    </div>
                </div>

                <!-- SOC Section -->
                <div class="row mb-3">
                    <label class="col-md-2 col-form-label"><b>{{ 'operation_analysis.soc' | translate }}</b></label>
                    <div class="col-md-5">
                        <label>{{ 'operation_analysis.min_daily_soc' | translate }}</label>
                        <input type="number" class="form-control" min="20" max="40" [(ngModel)]="predictionSettings.minSOC" name="minSOC">
                    </div>
                    <div class="col-md-5">
                        <label>{{ 'operation_analysis.max_daily_soc' | translate }}</label>
                        <input type="number" class="form-control" min="70" max="90" [(ngModel)]="predictionSettings.maxSOC" name="maxSOC">
                    </div>
                </div>

                <!-- In Use Settings Section -->
                <div class="mb-3">
                    <label class="form-label"><b>{{ 'operation_analysis.in_use_settings' | translate }}</b></label>
                    <div class="row">
                        <div class="col-md-10 offset-md-2">
                            <div class="mb-3" *ngIf="!predictionSettings.showShiftsPeriods">
                                <label class="form-label">{{ 'operation_analysis.min_inuse_ahr' | translate }}</label>
                                <input type="number" class="form-control col-md-6" min="0" [(ngModel)]="minInuseAhr" name="minInuseAhr">
                            </div>
                            
                            <div class="mb-3">
                                <label class="form-label">{{ 'operation_analysis.min_charge_time' | translate }}</label>
                                <div class="input-group">
                                    <input type="number" class="form-control col-md-6" min="10" max="120" [(ngModel)]="predictionSettings.minChargeOppurtinityTime" name="minChargeOppurtinityTime">
                                    <span class="input-group-text">{{ 'g.minutes' | translate }}</span>
                                </div>
                            </div>
                            
                            <div class="mb-3">
                                <label class="form-label">{{ 'operation_analysis.charger_efficiency' | translate }}</label>
                                <input type="number" class="form-control col-md-6" min="0" max="100" [(ngModel)]="predictionSettings.chargeEfficiency" name="chargeEfficiency">
                            </div>
                            
                            <div class="mb-3">
                                <label class="col-form-label">{{ 'operation_analysis.override_charge_rate' | translate }}</label>
                                    <div class="col-md-8 d-flex align-items-center">
                                        <label class="form-check-label mr-2">{{ 'operation_analysis.enabled' | translate }}</label>
                                        <input
                                            type="checkbox"
                                            class="form-check-input mr-2"
                                            [(ngModel)]="predictionSettings.overrideChargeRateEnabled"
                                            name="overrideChargeRateEnabled"
                                        />

                                        <input
                                            type="number"
                                            min="17"
                                            max="40"
                                            step="1"
                                            [(ngModel)]="predictionSettings.overrideChargeRate"
                                            name="overrideChargeRate"
                                            [disabled]="!predictionSettings.overrideChargeRateEnabled"
                                            class="form-control"
                                        />
                                    </div>
                            </div>

                            <div class="mb-3" *ngIf="!predictionSettings.showShiftsPeriods">
                                <label class="form-label">{{ 'operation_analysis.ignore_days_with_ebu' | translate }}</label>
                                <div class="input-group">
                                    <input type="number" class="form-control col-md-6" min="0" max="2" step="0.05" [(ngModel)]="predictionSettings.minDailyEBU" name="minDailyEBU">
                                    <span class="input-group-text">{{ 'operation_analysis.ebu' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mt-4">
                    <button class="btn btn-primary btn-lg" (click)="validateFormFields()">{{ 'operation_analysis.calculate' | translate }}</button>
                </div>
            </form>
            <!-- Prediction Results Section -->
            <div *ngIf="showPredictionResult" class="my-4 gap-2">
                <div class="mt-2">
                    <app-simple-widget [title]="'g.study_info' | translate" [isCollapsed]="false">
                        <div class="row">
                            <table class="table table-striped table-bordered">
                                <tbody>
                                    <tr>
                                        <td><b>{{ 'devices.study_name' | translate }}</b></td>
                                        <td>{{ studyInfo.name }}</td>
                                        <td><b>{{'devices.truck_id' | translate}}</b></td>
                                        <td>{{ device.config_info.truck_id }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>{{ 'operation_analysis.study_start_date' | translate }}</b></td>
                                        <td>{{ studyInfo.start_study_time*1000 | date:'MM/dd/yyyy' }}</td>
                                        <td><b>{{ 'operation_analysis.study_end_date' | translate }}</b></td>
                                        <td>{{!studyInfo.is_done? '-' : studyInfo.end_study_time*1000 | date:'MM/dd/yyyy'}}</td>
                                    </tr>
                                    <tr>
                                        <td><b>{{'operation_analysis.min_soc' | translate}}</b></td>
                                        <td>{{predictionSettings.minSOC}}%</td>
                                        <td><b>{{'operation_analysis.max_soc' | translate}}</b></td>
                                        <td>{{predictionSettings.maxSOC}}%</td>
                                    </tr>
                                    <tr>
                                        <td><b>{{'operation_analysis.average_daily_ahr' |translate}}</b></td>
                                        <td>{{predictionObject.avg_daily_inuse_ahr | number:'0.2-2'}}</td>
                                        <td><b>{{'operation_analysis.charge_rate' | translate}}</b></td>
                                        <td>{{predictionObject.minDailyChargeRate | number:'0.0-0'}}% ({{predictionObject.chargerType}})</td>
                                    </tr>
                                    <!-- Additional rows for results as needed -->
                                </tbody>
                            </table>
                            <table class="table">
                                <tr class="body-bg-color">
                                    <td>{{'operation_analysis.shift_number' |translate}}</td>
                                    <td>{{'operation_analysis.shift_start_time' |translate}}</td>
                                    <td>{{'operation_analysis.shift_end_time' | translate}}</td>
                                    <td>{{'operation_analysis.shift_ahr' | translate}}</td>
                                    <td>{{'operation_analysis.shift_start_soc' | translate}}</td>
                                    <td>{{'operation_analysis.shift_end_soc' | translate}}</td>
                                </tr>
            
                                <tr *ngFor="let shift of predictionObject.shifts; let index = index;">
                                    <td>{{index + 1}}</td>
                                    <td>{{shift.start | date:'hh:mm a'}}</td>
                                    <td>{{shift.end | date:'hh:mm a'}}</td>
                                    <td>{{shift.ahr | number:'0.2-2'}}</td>
                                    <td>{{(shift.aahr_start * 100 / device.config_info.battery_capacity) | number:'0.2-2'}}</td>
                                    <td>{{(shift.aahr_end * 100 / device.config_info.battery_capacity) | number:'0.2-2'}}</td>
                                </tr>
                            </table>
                        </div>
                    </app-simple-widget>
                </div>
                <div class="mt-2">
                    <app-simple-widget class="mt-2" [title]="'operation_analysis.recommended_charger' | translate" [isCollapsed]="false">
                        <div class="row justify-content-center align-items-center my-3">
                            <div class="col-md-2 text-center">
                                <img class="img-fluid recommended-charge-image" src="{{recommendedChargerImage}}" alt="Recommended Charger Image">
                            </div>
                            <div class="col-md-6 d-flex flex-column">
                                <div><strong>{{'devices.charger_type' | translate}}: </strong> <span>{{predictionObject.chargerType}}</span></div>
                                <div><strong>{{'operation_analysis.charger_model' | translate}}: </strong> <span>{{recommendedCharger}}</span></div>
                            </div>
                        </div>
                    </app-simple-widget>
                </div>
                <div class="mt-2">
                    <app-simple-widget class="mt-2" [title]="'operation_analysis.shifts_soc_chart_title'|translate" [isCollapsed]="false">
                        <sct-charts
                            [data]="chartObject.data['shiftSOC']"
                            [options]="chartObject.config"
                            [columnNames]="chartObject.columnNames"
                            [type]="'LineChart'"
                            [height]="500"
			            ></sct-charts>
                    </app-simple-widget>
                </div>
                <div class="mt-2">
                    <app-simple-widget class="mt-2" [title]="'operation_analysis.events_soc_charts_titel'|translate" [isCollapsed]="false">
                        <sct-charts
                            [data]="chartObject.data['eventSOC']"
                            [options]="chartObject.config"
                            [columnNames]="chartObject.columnNames"
                            [type]="'LineChart'"
                            [height]="500"
			            ></sct-charts>
                    </app-simple-widget>
                </div>
                <div class="mt-2">
                    <app-simple-widget [title]="'operation_analysis.shift_soc_chart_title_conv_type'|translate" [isCollapsed]="false">
                        <sct-charts
                            [data]="chartObject.data['shiftSOC-conventional']"
                            [options]="chartObject.config"
                            [columnNames]="chartObject.columnNames"
                            [type]="'LineChart'"
                            [height]="500"
			            ></sct-charts>
                    </app-simple-widget>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal #ShiftsModal [title]="'operation_analysis.shifts'| translate" [confirmBtnText]="isEditMode ? 'Update' : 'Add'" size="lg">
	<form class="form-horizontal">
		<div class="row margin-bottom">
			<label class="col-md-4">{{'operation_analysis.shift_start_time' | translate}}</label>
			<div class="col-md-6">
				<input name="shift-start-time" [(ngModel)]="shift.startTime" name="shift-startTime" class="form-control" required type="time">
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">{{'operation_analysis.shift_end_time' | translate}}</label>
			<div class="col-md-6">
				<input name="shift-end-time" [(ngModel)]="shift.endTime" name="shift-endTime" class="form-control" required type="time">
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">{{'operation_analysis.shift_ahr' | translate}}</label>
			<div class="col-md-6">
				<input name="shift-ahr" class="form-control" required min="device.config_info.battery_capacity*0.5" max="device.config_info.battery_capacity*0.8" step="5" [(ngModel)]="shift.overrideShiftAHr" name="shift-overrideShiftAHr">
			</div>
		</div>
	</form>
</app-modal>