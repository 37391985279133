import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ReceivingShipmentService } from '../receiving-shipment/receiving-shipment.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ColumnsConfig, TableConfig } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-receiving-shipment-history',
	templateUrl: './receiving-shipment-history.component.html',
	styleUrls: ['./receiving-shipment-history.component.css']
})
export class ReceivingShipmentHistoryComponent implements OnInit {
	@ViewChild("editCmPoNumberPopup") editCmPoNumberPopup: ModalComponent;
	@ViewChild("exportReceivedShipmentData") exportReceivedShipmentData;

	reportsHistory: any = [];
	source: number = this.deviceManagementService.shipmentSource.receive;
	fromDate: Date = new Date();
	toDate: Date = new Date();
	notValidPopupDate = false;
	deviceId: string = '';
	searchBy: string = 'serialNumber';
	invalidDevice: boolean = false;
	searchApplied: boolean = false;
	filteredData: any = [];
	reportHistoryData: any = [];

	// CmPoNumber
	newCmPoNumberValue = '';
	toEditReport = null;
	columnConfig: ColumnsConfig[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hideNoData: false,
	}

	constructor(
		private router: Router,
		public userService: UsersService,
		public deviceManagementService: DeviceManagementService,
		private receivingShipmentService: ReceivingShipmentService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private validationService: ValidationService,
		private notificationService: NotificationMessageService,
	) { }

	ngOnInit(): void {
		if(!this.userService.hasAccessFunction('shipment_management') && !this.userService.hasAccessFunction('cm_check_warnings'))
			this.router.navigate(['unauthorized']);

		if(this.router.url.includes('shipment-quality-history'))
			this.source = this.deviceManagementService.shipmentSource.quality;

		this.buildCustomTable();
		this.getReceiveShipmentReports();
	}

	buildCustomTable() {
		this.columnConfig.push(
			{key: 'date', name: this.translateService.instant('g.date'), type: 'date'},
			{key: 'user_name', name: this.translateService.instant('shipment.user_name'), type: 'string'});

		if (this.source == this.deviceManagementService.shipmentSource.receive)
			this.columnConfig.push({key: 'cm_po_number', name: this.translateService.instant('shipment.cm_po_number'), type: 'action_link'});

		this.columnConfig.push(
			{key: 'checked_devices', name: this.translateService.instant(this.source == this.deviceManagementService.shipmentSource.quality ? 'shipment.success_checked_no_devices' : 'shipment.success_no_devices'), type: 'number'},
			{key: 'total_devices', name: this.translateService.instant('shipment.total_no_devices'), type: 'number'}
		);

		if (this.source != this.deviceManagementService.shipmentSource.quality) {
			this.columnConfig.push({key: 'checked_quality', name: this.translateService.instant('shipment.checked_quality'),
				type: 'boolean', filterTrueText: this.translateService.instant('g.yes'), filterFalseText: this.translateService.instant('g.no')});
		}

		this.columnConfig.push(
			{key: 'result', name: this.translateService.instant('g.result'), type: 'link', hasSort: false, hasFilter: false});
	}

	ngAfterViewInit() {
		this.editCmPoNumberPopup.onClose.subscribe((ok) => {
			if(ok) {
				this.editCmPoNumber()
				return;
			}

			this.toggleEditCmPoNumberPopup(false, null);
		});
	}

	editCmPoNumber() {
		this.deviceManagementService.updateCmPoNumber(this.toEditReport.id, this.newCmPoNumberValue).subscribe(res => {
			for (const report of this.reportsHistory) {
				if (report.id == this.toEditReport.id)
					report.cm_po_number = this.newCmPoNumberValue;
			}
			this.notificationService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		})
	}

	getReceiveShipmentReports() {
		this.deviceManagementService.getReceiveShipmentHistory(this.source).subscribe((reports: any) => {
			const reportsRecords = this.formattRecords(reports);
			this.reportsHistory = reportsRecords;
			this.reportHistoryData = [...reportsRecords];
		})
	}

	formattRecords(reports) {
		return reports.map((row) => {
			const isReceiveSource = this.source === this.deviceManagementService.shipmentSource.receive;
			return {
				row,
				date: { value: row.action_time },
				user_name: { value: row.user_name },
				cm_po_number: {
					value: isReceiveSource ? row.cm_po_number : null,
					sortValue: isReceiveSource ? "_" + row.cm_po_number : "-", //! to handle sort as string not number
					action: isReceiveSource ? () => this.toggleEditCmPoNumberPopup(true, row) : null,
				},
				checked_devices: {
					value: `${row.success_no_devices} ${this.searchApplied ? '('+this.translateService.instant(this.getDeviceHistoryStatus(row.id))+')' : ''}`,
				},
				total_devices: { value: row.total_no_devices },
				checked_quality: {
					value: isReceiveSource ? row.checked_quality : null,
				},
				result: {
					value: this.translateService.instant('g.result'),
					link: ['/management', isReceiveSource ? 'receiving-shipment-history' : 'shipment-quality-history' , row.id ], target: '_blank',
				},
			};
		});
	}


	generateReceivedShipmentHistoryReport() {
		const zoneDiff = new Date().getTimezoneOffset() * -1;
		const from: any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		const to: any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));
		const dateRange = {from: moment(from).utc().startOf('day').unix(), to: moment(to).utc().endOf('day').unix()};

		if (this.fromDate && dateRange.from > dateRange.to)
			return this.notValidPopupDate = true;
		this.notValidPopupDate = false;

		const reportsIds = this.reportsHistory.map(report => report.row.id);
		this.deviceManagementService.generateReceivedShipmentHistoryReport(dateRange, this.source, reportsIds).subscribe((response: any) => {
			this.exportReceivedShipmentData.hide();
			switch (response?.length) {
				case 0:
					return this.notificationService.setMessage('translate|g.date_range_has_no_records', { type: 'warning' });
				default:
					response.map(record => {
						record.checked_quality = record.checked_quality
							? this.translateService.instant('g.yes')
							: this.translateService.instant('g.no');
					});
				return this.receivingShipmentService.generateHistoryCsvFile(response, this.translateService.instant('nav.receiving_shipment_history'), 'receiving_history');
			}
		});
	}

	filterReceivingHistoryDevices() {
		this.invalidDevice = false;
		this.deviceId = this.deviceId.trim();
		const validationRules = this.validationService.getFormValidationRules('editDevice');
		let rule = validationRules['serial_number'];
		this.searchBy = 'serialNumber';
		const isMac = this.commonService.adjustMacSearchString(this.deviceId);
		if (isMac.mac_address) {
			this.searchBy = 'hex';
			rule = validationRules['mac_address'];
		}

		const isValid = this.validationService.dataValidator(this.searchBy, rule, this.deviceId);
		if (!isValid) {
			this.invalidDevice = true;
			return;
		}

		this.searchApplied = true;
		this.deviceManagementService.searchReceiveShipmentHistoryDevices(this.deviceId, this.searchBy).subscribe((response: any) => {
			if (response.invalid_fields) {
				this.searchApplied = false;
				this.invalidDevice = true;
				return;
			}

			if (response.length == 0) {
				this.searchApplied = false;
				this.reportsHistory = [];
				return;
			}

			this.filteredDevicesHistoryData(response);
		});
	}

	filteredDevicesHistoryData(data) {
		this.filteredData = data;
		const filteredReportsIds = data.map(record => record.report_id);
		this.reportsHistory = this.reportHistoryData.filter(report => filteredReportsIds.includes(report.row.id));
		this.reportsHistory = this.reportHistoryData.map(report => report.row).filter(row => filteredReportsIds.includes(row.id));
		this.reportsHistory = this.formattRecords(this.reportsHistory)
	}

	getDeviceHistoryStatus(reportId) {
		const deviceHistory = this.filteredData.find(item => item.report_id == reportId);
		const status = this.receivingShipmentService.getReceivingShipmentDeviceStatus(deviceHistory?.status);
		switch(this.searchBy) {
			case 'serialNumber':
				if (deviceHistory?.serial_number == this.deviceId && status)
					return 'g.pass';
			case 'hex':
				if (deviceHistory?.mac_address == this.deviceId && status)
					return 'g.pass'
			default:
				return 'g.fail'
		}
	}

	resetSearchFilter() {
		this.deviceId = '';
		this.searchApplied = false;
		this.invalidDevice = false;
		this.getReceiveShipmentReports();
	}

	toggleEditCmPoNumberPopup(value: boolean, toEditReport = null) {
		this.toEditReport = toEditReport;
		this.newCmPoNumberValue = this.toEditReport ? this.toEditReport.cm_po_number : "";

		if(value)
			return this.editCmPoNumberPopup.show();

		this.editCmPoNumberPopup.hide();
	}
}
