<div class="main-black border-radius-bottom white-box mt-1" *ngIf="requestsList">
	<div class="card-body" *ngIf="requestsList.length > 0">
		<h6 class="mb-3">{{requestsList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
	<div class="card-body" *ngIf="requestsList.length == 0">
		<h3>{{'device_management.no_new_move' | translate}}</h3>
	</div>
</div>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-request="request">
		<span mat-menu-item class="text-success" (click)="confirmAction('approveRequest', request.id)">
			{{'device_management.approve' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmAction('rejectRequest', request.id)">
			{{'g.reject' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
