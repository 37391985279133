<div>
	<div class="white-box p-4">
		<h2>{{'nav.devices_location_mismatch' | translate}}</h2>
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
</div>
