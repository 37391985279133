<div>
	<div *ngIf="usersService.hasAccessFunction('site_management') && currentUser.isSCT">
		<button class="btn btn-primary mb20" type="button" [routerLink]="['/sites', customerId, 'edit', 0]">
			{{'g.add_new' | translate}}<fa-icon icon="plus"></fa-icon>
		</button>
	</div>
	<div class="boxs">
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
</div>

<app-modal #deleteModal title="{{'site.delete_site' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'g.del_site_prompt' | translate}}
</app-modal>
