<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div class="mt-5">
		<h2>{{'nav.registration_reports' | translate}}</h2>
	</div>

	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="sites">
	</sct-custom-table>
</div>
